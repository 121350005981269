import React from 'react';
import { motion } from 'framer-motion';
import ProductCarousel from './ProductCarousel';

interface ProductsSectionProps {
  onProductClick?: (productId: string) => void;
}

export default function ProductsSection({ onProductClick }: ProductsSectionProps) {
  return (
    <div id="products" className="relative py-24 overflow-hidden bg-black">
      {/* Background effects */}
      <div className="absolute inset-0">
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,_#0A0A0B_0%,_black_100%)]" />
        <motion.div
          className="absolute inset-0"
          style={{
            backgroundImage: 'radial-gradient(circle at center, rgba(8,247,254,0.03) 0%, transparent 1px)',
            backgroundSize: '30px 30px'
          }}
          animate={{
            opacity: [0.3, 0.5, 0.3]
          }}
          transition={{
            duration: 5,
            repeat: Infinity,
            ease: "easeInOut"
          }}
        />
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-5xl font-bold mb-6 bg-gradient-to-r from-[#08F7FE] to-[#FF2E63] text-transparent bg-clip-text">
            Our Products
          </h2>
          <p className="text-xl text-gray-400 max-w-3xl mx-auto">
            Cutting-edge solutions that transform the way we interact with technology
          </p>
        </motion.div>

        <ProductCarousel onProductClick={onProductClick} />
      </div>
    </div>
  );
}