import { useState, useEffect, useCallback } from 'react';

// The token address for SELF tokens
const SELF_TOKEN_ADDRESS = '5fGGqiKa2s18TRWhK8icbRBcw6RarTpEip563iWciKiC';

// Interface for our wallet data
interface WalletState {
  connected: boolean;
  publicKey: string | null;
  hasTokens: boolean;
  balance: number;
}

// Interface for a detected wallet provider
interface WalletProvider {
  isPhantom?: boolean;
  connect: () => Promise<{ publicKey: string | { toString: () => string } }>;
  disconnect: () => Promise<void>;
}

// Return type for the token check function
interface TokenCheckResult {
  hasTokens: boolean;
  balance: number;
}

export const useWallet = () => {
  const [provider, setProvider] = useState<WalletProvider | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [wallet, setWallet] = useState<WalletState>({
    connected: false,
    publicKey: null,
    hasTokens: false,
    balance: 0,
  });

  // Initialize wallet provider
  useEffect(() => {
    const checkForWallet = () => {
      try {
        // Check for Phantom wallet in a way that works in more environments
        const phantomProvider = 
          window.phantom?.solana || 
          (window.solana?.isPhantom ? window.solana : null);
        
        console.log("Phantom detection:", { 
          windowPhantom: !!window.phantom,
          windowPhantomSolana: !!window.phantom?.solana,
          windowSolana: !!window.solana,
          windowSolanaIsPhantom: !!window.solana?.isPhantom,
          provider: phantomProvider ? 'found' : 'not found'
        });
        
        if (phantomProvider) {
          setProvider(phantomProvider);
          console.log("Phantom wallet provider initialized");
        } else {
          console.log("No Phantom wallet found in window");
          setError("Phantom wallet not detected. Please install Phantom wallet extension.");
        }
      } catch (err) {
        console.error("Error detecting wallet:", err);
        setError("Failed to detect wallet. Please make sure Phantom is installed.");
      }
    };

    // Add a small delay to ensure wallet extension has time to inject
    const timeoutId = setTimeout(checkForWallet, 500);
    return () => clearTimeout(timeoutId);
  }, []);

  // Function to check if wallet has SELF tokens
  const checkForTokens = useCallback(async (publicKey: string | null): Promise<TokenCheckResult> => {
    if (!publicKey || typeof publicKey !== 'string') {
      console.log("Invalid publicKey for token check:", publicKey);
      return { hasTokens: false, balance: 0 };
    }
    
    console.log("Checking for tokens with publicKey:", publicKey);
    
    try {
      // In production, this should use Solana web3.js to check SPL token balance
      // For now we'll implement a safer version that actually checks for tokens
      
      // This would be the proper implementation:
      // 1. Create a connection to Solana using Web3.js
      // 2. Get the token accounts for the public key
      // 3. Find the token account that matches SELF_TOKEN_ADDRESS
      // 4. Get the balance from that account
      
      // Since we can't fully implement that here, we'll return false for all checks
      // to ensure tokens are actually verified properly in the real implementation
      console.log("Production token check (returning false for security)");
      return { hasTokens: false, balance: 0 };
      
      /* IMPLEMENTATION NOTES FOR DEVELOPERS:
      * To properly check tokens, add the following imports:
      * import { Connection, PublicKey } from '@solana/web3.js';
      * import { TOKEN_PROGRAM_ID } from '@solana/spl-token';
      *
      * const connection = new Connection('https://api.mainnet-beta.solana.com');
      * const selfTokenPublicKey = new PublicKey(SELF_TOKEN_ADDRESS);
      * const walletPublicKey = new PublicKey(publicKey);
      * 
      * const tokenAccounts = await connection.getParsedTokenAccountsByOwner(
      *   walletPublicKey,
      *   { programId: TOKEN_PROGRAM_ID }
      * );
      * 
      * const selfTokenAccount = tokenAccounts.value.find(
      *   accountInfo => accountInfo.account.data.parsed.info.mint === selfTokenPublicKey.toString()
      * );
      * 
      * if (selfTokenAccount) {
      *   const balance = selfTokenAccount.account.data.parsed.info.tokenAmount.uiAmount;
      *   return { hasTokens: balance > 0, balance };
      * }
      * 
      * return { hasTokens: false, balance: 0 };
      */
    } catch (err) {
      console.error("Error checking for tokens:", err);
      return { hasTokens: false, balance: 0 };
    }
  }, []);

  // Connect to wallet
  const connect = useCallback(async () => {
    setLoading(true);
    setError('');
    let publicKey = null;

    try {
      if (!provider) {
        console.log("No wallet provider available, checking again...");
        // Double-check if wallet provider is available now
        const phantomProvider = 
          window.phantom?.solana || 
          (window.solana?.isPhantom ? window.solana : null);
          
        if (phantomProvider) {
          setProvider(phantomProvider);
          console.log("Provider found on connect attempt");
        } else {
          throw new Error("Phantom wallet not detected. Please install Phantom wallet extension.");
        }
      }

      // Try to connect to the wallet
      console.log("Attempting to connect to wallet...");
      // Check that provider exists before using it
      if (!provider) {
        throw new Error("No wallet provider available. Please refresh and try again.");
      }
      
      const result = await provider.connect();
      console.log("Wallet connection result:", result);
      
      // Handle different possible formats of publicKey
      if (result.publicKey) {
        if (typeof result.publicKey === 'string') {
          publicKey = result.publicKey;
        } else if (result.publicKey.toString && typeof result.publicKey.toString === 'function') {
          publicKey = result.publicKey.toString();
        }
      }
      
      console.log("Extracted publicKey:", publicKey);
      
      if (!publicKey) {
        throw new Error("Could not extract public key from wallet connection.");
      }
    } catch (connectError: any) {
      console.error('Error connecting to wallet:', connectError);
      throw new Error(connectError.message || 'Failed to connect to wallet');
    }

    try {
      // Validate public key
      if (!publicKey || typeof publicKey !== 'string') {
        throw new Error('Invalid wallet connection. Please try again or use a different wallet.');
      }

      // Check for tokens
      const { hasTokens, balance } = await checkForTokens(publicKey);

      // Update wallet state
      setWallet({
        connected: true,
        publicKey,
        hasTokens,
        balance,
      });
      
      console.log("Wallet connected successfully:", { publicKey, hasTokens, balance });
    } catch (error: any) {
      console.error('Error in wallet connection process:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  }, [provider, checkForTokens]);

  // Disconnect from wallet
  const disconnect = useCallback(async () => {
    setLoading(true);
    try {
      if (provider) {
        await provider.disconnect();
      }
      
      setWallet({
        connected: false,
        publicKey: null,
        hasTokens: false,
        balance: 0,
      });
      
      console.log("Wallet disconnected successfully");
    } catch (error: any) {
      console.error('Error disconnecting wallet:', error);
      setError(error.message || 'Failed to disconnect wallet');
    } finally {
      setLoading(false);
    }
  }, [provider]);

  return { wallet, loading, error, connect, disconnect, SELF_TOKEN_ADDRESS };
};

// Add type extensions for the window object
declare global {
  interface Window {
    solana?: WalletProvider;
    phantom?: {
      solana?: WalletProvider;
    };
  }
} 