import React from 'react';
import { motion } from 'framer-motion';
import { Check } from 'lucide-react';

interface PricingCardProps {
  name: string;
  price: string;
  period: string;
  features: string[];
  cta: string;
  isPopular: boolean;
  subtext?: string;
  onCtaClick: () => void;
}

export function PricingCard({
  name,
  price,
  period,
  features,
  cta,
  isPopular,
  subtext,
  onCtaClick
}: PricingCardProps) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      className={`relative bg-gradient-to-b ${
        isPopular 
        ? 'from-emerald-900/30 to-emerald-900/10 border-emerald-500/40' 
        : 'from-gray-900/30 to-gray-900/10 border-gray-700/40'
      } rounded-xl p-6 border flex flex-col h-full`}
    >
      {isPopular && (
        <div className="absolute -top-3 inset-x-0 flex justify-center">
          <span className="bg-emerald-600 text-white text-xs font-semibold px-3 py-1 rounded-full uppercase tracking-wider">
            Most Popular
          </span>
        </div>
      )}
      
      <div className="mb-6">
        <h3 className="text-2xl font-semibold text-white mb-1">{name}</h3>
        {subtext && <p className="text-sm text-emerald-400 mb-2">{subtext}</p>}
        <div className="flex items-end">
          <span className="text-4xl font-bold text-white">{price}</span>
          <span className="text-gray-400 ml-1">{period}</span>
        </div>
      </div>
      
      <div className="flex-grow space-y-3 mb-6">
        {features.map((feature, i) => (
          <div key={i} className="flex items-start">
            <Check 
              className={`w-5 h-5 ${isPopular ? 'text-emerald-400' : 'text-gray-400'} mr-2 flex-shrink-0 mt-0.5`}
            />
            <span className="text-gray-300 text-sm">{feature}</span>
          </div>
        ))}
      </div>
      
      <motion.button
        onClick={onCtaClick}
        whileHover={{ scale: 1.03 }}
        whileTap={{ scale: 0.97 }}
        className={`py-3 px-6 rounded-lg text-white font-medium ${
          isPopular 
          ? 'bg-gradient-to-r from-emerald-600 to-teal-600 hover:from-emerald-700 hover:to-teal-700' 
          : 'bg-gray-800 hover:bg-gray-700'
        } transition-colors duration-200 w-full`}
      >
        {cta}
      </motion.button>
    </motion.div>
  );
} 