import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Send, AlertCircle, CheckCircle, Loader } from 'lucide-react';

// Contact form data type for Twitch AI inquiries
interface TwitchAIContactFormData {
  firstName: string;
  lastName: string;
  email: string;
  company: string;
  platform: string;
  audienceSize: string;
  message: string;
  consent: boolean;
}

// Apollo response type
interface ApolloResponse {
  success: boolean;
  message: string;
}

export default function TwitchAIContactForm() {
  const [formData, setFormData] = useState<TwitchAIContactFormData>({
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    platform: '',
    audienceSize: '',
    message: '',
    consent: false
  });

  const [errors, setErrors] = useState<Partial<Record<keyof TwitchAIContactFormData, string>>>({});
  const [submissionStatus, setSubmissionStatus] = useState<'idle' | 'submitting' | 'success' | 'error'>('idle');
  const [responseMessage, setResponseMessage] = useState('');

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    
    // Handle checkbox inputs
    if (type === 'checkbox') {
      const checked = (e.target as HTMLInputElement).checked;
      setFormData(prev => ({ ...prev, [name]: checked }));
    } else {
      setFormData(prev => ({ ...prev, [name]: value }));
    }
    
    // Clear error when field is edited
    if (errors[name as keyof TwitchAIContactFormData]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }
  };

  const validateForm = (): boolean => {
    const newErrors: Partial<Record<keyof TwitchAIContactFormData, string>> = {};
    
    // Required fields validation
    if (!formData.firstName.trim()) newErrors.firstName = 'First name is required';
    if (!formData.lastName.trim()) newErrors.lastName = 'Last name is required';
    if (!formData.email.trim()) newErrors.email = 'Email is required';
    if (!formData.message.trim()) newErrors.message = 'Message is required';
    
    // Email format validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (formData.email && !emailRegex.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address';
    }
    
    // Consent validation
    if (!formData.consent) newErrors.consent = 'Please consent to our privacy policy';
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) return;
    
    setSubmissionStatus('submitting');
    
    try {
      // Get Apollo API key from env
      const apiKey = import.meta.env.VITE_APOLLO_API_KEY;
      
      // Prepare data for Apollo
      const apolloData = {
        api_key: apiKey,
        person: {
          first_name: formData.firstName,
          last_name: formData.lastName,
          email: formData.email,
          organization_name: formData.company || 'Individual Creator'
        },
        add_person_to_sequence: false,
        additional_data: {
          streaming_platform: formData.platform,
          audience_size: formData.audienceSize,
          inquiry_details: formData.message
        },
        labels: ["AI Twitch Co-Host Inquiry", "Content Creator"],
        tags: ["AI Avatar", "Twitch Integration", "Gaming"],
        source_details: "AI Twitch Co-Host Inquiry Form"
      };
      
      // In development mode, simulate the API call
      if (process.env.NODE_ENV === 'development') {
        console.log('Development mode - Simulating Apollo API call with data:', apolloData);
        
        // Simulate API delay
        await new Promise(resolve => setTimeout(resolve, 1500));
        
        // Simulate success
        setSubmissionStatus('success');
        setResponseMessage('Thank you for your interest in our AI Twitch Co-hosting service! Our team will contact you within 24 hours to discuss how we can enhance your streaming experience.');
      } else {
        // In production, use the Netlify function to proxy the request
        const response = await fetch('/.netlify/functions/apollo-proxy?endpoint=contacts/add_or_update', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(apolloData)
        });
        
        const data: ApolloResponse = await response.json();
        
        if (data.success) {
          setSubmissionStatus('success');
          setResponseMessage('Thank you for your interest in our AI Twitch Co-hosting service! Our team will contact you within 24 hours to discuss how we can enhance your streaming experience.');
        } else {
          throw new Error(data.message || 'Failed to submit form');
        }
      }
    } catch (error) {
      console.error('Form submission error:', error);
      setSubmissionStatus('error');
      setResponseMessage('We encountered an error while submitting your form. Please try again later or contact us directly.');
    }
  };

  return (
    <div className="w-full max-w-xl mx-auto">
      {submissionStatus === 'success' ? (
        <motion.div
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          className="bg-green-900/20 border border-green-500/30 rounded-lg p-6 text-center"
        >
          <CheckCircle className="w-12 h-12 text-green-500 mx-auto mb-4" />
          <h3 className="text-xl font-bold text-white mb-2">
            Inquiry Received!
          </h3>
          <p className="text-green-200/80">
            {responseMessage}
          </p>
        </motion.div>
      ) : (
        <motion.form 
          onSubmit={handleSubmit}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="space-y-6"
        >
          <div className="text-center mb-8">
            <h3 className="text-xl font-semibold text-white mb-2">
              AI Twitch Co-Host Inquiry
            </h3>
            <p className="text-gray-400">
              Interested in having an AI avatar co-host your streams? Fill out this form and we'll get back to you with details!
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* First Name */}
            <div>
              <label htmlFor="firstName" className="block text-sm font-medium text-gray-300 mb-1">
                First Name*
              </label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                className={`w-full px-4 py-2 bg-gray-800 border ${
                  errors.firstName ? 'border-red-500' : 'border-gray-700'
                } rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-[#00FFFF]`}
              />
              {errors.firstName && (
                <p className="mt-1 text-sm text-red-500">{errors.firstName}</p>
              )}
            </div>

            {/* Last Name */}
            <div>
              <label htmlFor="lastName" className="block text-sm font-medium text-gray-300 mb-1">
                Last Name*
              </label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                className={`w-full px-4 py-2 bg-gray-800 border ${
                  errors.lastName ? 'border-red-500' : 'border-gray-700'
                } rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-[#00FFFF]`}
              />
              {errors.lastName && (
                <p className="mt-1 text-sm text-red-500">{errors.lastName}</p>
              )}
            </div>
          </div>

          {/* Email */}
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-1">
              Email Address*
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className={`w-full px-4 py-2 bg-gray-800 border ${
                errors.email ? 'border-red-500' : 'border-gray-700'
              } rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-[#00FFFF]`}
            />
            {errors.email && (
              <p className="mt-1 text-sm text-red-500">{errors.email}</p>
            )}
          </div>

          {/* Company/Channel */}
          <div>
            <label htmlFor="company" className="block text-sm font-medium text-gray-300 mb-1">
              Channel/Organization Name
            </label>
            <input
              type="text"
              id="company"
              name="company"
              value={formData.company}
              onChange={handleChange}
              placeholder="Your stream or organization name"
              className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-[#00FFFF]"
            />
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            {/* Streaming Platform */}
            <div>
              <label htmlFor="platform" className="block text-sm font-medium text-gray-300 mb-1">
                Streaming Platform
              </label>
              <select
                id="platform"
                name="platform"
                value={formData.platform}
                onChange={handleChange}
                className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-[#00FFFF]"
              >
                <option value="">Select Platform</option>
                <option value="Twitch">Twitch</option>
                <option value="YouTube">YouTube</option>
                <option value="Facebook Gaming">Facebook Gaming</option>
                <option value="TikTok">TikTok</option>
                <option value="Other">Other</option>
              </select>
            </div>

            {/* Audience Size */}
            <div>
              <label htmlFor="audienceSize" className="block text-sm font-medium text-gray-300 mb-1">
                Average Audience Size
              </label>
              <select
                id="audienceSize"
                name="audienceSize"
                value={formData.audienceSize}
                onChange={handleChange}
                className="w-full px-4 py-2 bg-gray-800 border border-gray-700 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-[#00FFFF]"
              >
                <option value="">Select Size</option>
                <option value="< 100">Less than 100 viewers</option>
                <option value="100-500">100-500 viewers</option>
                <option value="500-1000">500-1,000 viewers</option>
                <option value="1000-5000">1,000-5,000 viewers</option>
                <option value="5000+">5,000+ viewers</option>
              </select>
            </div>
          </div>

          {/* Message/Details */}
          <div>
            <label htmlFor="message" className="block text-sm font-medium text-gray-300 mb-1">
              Tell us about your streaming content and how you'd like to use an AI co-host*
            </label>
            <textarea
              id="message"
              name="message"
              rows={4}
              value={formData.message}
              onChange={handleChange}
              placeholder="Tell us about your gaming content, streaming style, and specific ideas for how an AI co-host could enhance your streams..."
              className={`w-full px-4 py-2 bg-gray-800 border ${
                errors.message ? 'border-red-500' : 'border-gray-700'
              } rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-[#00FFFF]`}
            />
            {errors.message && (
              <p className="mt-1 text-sm text-red-500">{errors.message}</p>
            )}
          </div>

          {/* Consent Checkbox */}
          <div className="flex items-start">
            <div className="flex items-center h-5">
              <input
                id="consent"
                name="consent"
                type="checkbox"
                checked={formData.consent}
                onChange={handleChange}
                className="h-4 w-4 text-[#00FFFF] focus:ring-[#00FFFF] border-gray-600 rounded"
              />
            </div>
            <div className="ml-3">
              <label htmlFor="consent" className={`text-sm ${errors.consent ? 'text-red-500' : 'text-gray-400'}`}>
                I consent to SELF Labs storing and processing my personal data in accordance with the Privacy Policy*
              </label>
            </div>
          </div>

          {/* Error Message */}
          {submissionStatus === 'error' && (
            <div className="flex items-center p-4 bg-red-900/20 border border-red-500/30 rounded-lg">
              <AlertCircle className="w-5 h-5 text-red-500 mr-2 flex-shrink-0" />
              <p className="text-sm text-red-200">
                {responseMessage}
              </p>
            </div>
          )}

          {/* Submit Button */}
          <button
            type="submit"
            disabled={submissionStatus === 'submitting'}
            className="w-full bg-gradient-to-r from-[#8A2BE2] to-[#4B0082] hover:from-[#9B4DFF] hover:to-[#5D0099] text-white font-semibold py-3 rounded-lg flex justify-center items-center transition-all duration-300"
          >
            {submissionStatus === 'submitting' ? (
              <>
                <Loader className="w-5 h-5 mr-2 animate-spin" />
                Submitting...
              </>
            ) : (
              <>
                <Send className="w-5 h-5 mr-2" />
                Submit Inquiry
              </>
            )}
          </button>
        </motion.form>
      )}
    </div>
  );
} 