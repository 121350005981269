import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { 
  ArrowLeft,
  Bot,
  Database,
  FileCheck,
  Layers,
  Lock,
  Mail,
  CheckCircle2,
  XCircle,
  ArrowRight
} from 'lucide-react';

export default function ForAICompaniesPage() {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5
      }
    }
  };

  const features = [
    {
      icon: <Database className="w-6 h-6" />,
      title: "Structured Datasets",
      description: "Access clean, labeled, and verified training data for your AI models.",
      gradient: "from-blue-500 to-indigo-500"
    },
    {
      icon: <Layers className="w-6 h-6" />,
      title: "Multi-modal Data",
      description: "Train on diverse signal types: text, voice, location, and more.",
      gradient: "from-indigo-500 to-purple-500"
    },
    {
      icon: <FileCheck className="w-6 h-6" />,
      title: "Signal-backed Economics",
      description: "Build sustainable products with real value exchange.",
      gradient: "from-purple-500 to-pink-500"
    },
    {
      icon: <Lock className="w-6 h-6" />,
      title: "Ethically Sourced",
      description: "Ensure your training data is consent-based and transparent.",
      gradient: "from-pink-500 to-rose-500"
    }
  ];

  const comparison = {
    traditional: [
      "Unstructured, noisy data",
      "Privacy concerns",
      "No value exchange",
      "Limited consent"
    ],
    selfLabs: [
      "Clean, structured datasets",
      "Full transparency",
      "Fair value exchange",
      "Opt-in only"
    ]
  };

  const benefits = [
    "Access to the Essence API",
    "Custom dataset creation",
    "Licensing options",
    "Technical support"
  ];

  return (
    <div className="min-h-screen bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-indigo-900/20 via-black to-black text-white py-20 relative overflow-hidden">
      {/* Animated Background Elements */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute top-0 left-1/4 w-96 h-96 bg-indigo-500/10 rounded-full blur-[120px] animate-pulse" />
        <div className="absolute bottom-0 right-1/4 w-96 h-96 bg-purple-500/10 rounded-full blur-[120px] animate-pulse" />
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        {/* Back Button */}
        <Link to="/flash/signal-calculator">
          <motion.div
            className="inline-flex items-center text-indigo-400 mb-12 hover:text-indigo-300 transition-colors bg-black/20 px-4 py-2 rounded-full backdrop-blur-sm border border-indigo-500/20"
            whileHover={{ x: -5 }}
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            <span>Back to Signal Calculator</span>
          </motion.div>
        </Link>

        <motion.div
          initial="hidden"
          animate="visible"
          variants={containerVariants}
          className="space-y-20"
        >
          {/* Header */}
          <motion.div variants={itemVariants} className="text-center">
            <motion.div 
              className="inline-flex items-center justify-center w-20 h-20 rounded-3xl bg-gradient-to-br from-indigo-400 to-purple-500 p-4 mb-8"
              animate={{ rotate: [0, 10, -10, 0] }}
              transition={{ duration: 5, repeat: Infinity }}
            >
              <Bot className="w-12 h-12 text-white" />
            </motion.div>
            <h1 className="text-5xl font-bold mb-6 bg-gradient-to-r from-indigo-400 via-purple-400 to-indigo-400 text-transparent bg-clip-text">
              🤖 For AI Companies & Builders
            </h1>
            <p className="text-2xl text-gray-300 max-w-3xl mx-auto">
              Train your AI models on real, consent-based data. Build better products with structured signal.
            </p>
          </motion.div>

          {/* Features Grid */}
          <motion.div variants={itemVariants}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {features.map((feature) => (
                <motion.div
                  key={feature.title}
                  className="relative group"
                  whileHover={{ y: -5, scale: 1.02 }}
                >
                  <div className="h-full bg-black/40 backdrop-blur-xl rounded-3xl p-8 border border-indigo-500/20 overflow-hidden">
                    <div className={`absolute inset-0 bg-gradient-to-br ${feature.gradient} opacity-0 group-hover:opacity-10 transition-opacity duration-500`} />
                    <div className="relative z-10">
                      <div className="inline-flex p-3 rounded-2xl bg-gradient-to-br from-indigo-500/20 to-purple-500/20 mb-6">
                        {feature.icon}
                      </div>
                      <h3 className="text-xl font-semibold mb-4 text-white">
                        {feature.title}
                      </h3>
                      <p className="text-gray-400 group-hover:text-gray-300 transition-colors">
                        {feature.description}
                      </p>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>

          {/* Comparison Section */}
          <motion.div variants={itemVariants}>
            <div className="bg-black/40 backdrop-blur-xl rounded-3xl p-8 border border-indigo-500/20">
              <h2 className="text-3xl font-semibold text-center mb-12 bg-gradient-to-r from-indigo-400 via-purple-400 to-indigo-400 text-transparent bg-clip-text">
                Common Problems vs. SELF Labs Solutions
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold mb-6 text-red-400">Traditional Data Collection</h3>
                  {comparison.traditional.map((item, index) => (
                    <div key={index} className="flex items-center space-x-3 text-gray-400">
                      <XCircle className="w-5 h-5 text-red-500 flex-shrink-0" />
                      <span>{item}</span>
                    </div>
                  ))}
                </div>
                <div className="space-y-4">
                  <h3 className="text-xl font-semibold mb-6 text-green-400">SELF Labs Approach</h3>
                  {comparison.selfLabs.map((item, index) => (
                    <div key={index} className="flex items-center space-x-3 text-gray-400">
                      <CheckCircle2 className="w-5 h-5 text-green-500 flex-shrink-0" />
                      <span>{item}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </motion.div>

          {/* Benefits Section */}
          <motion.div variants={itemVariants}>
            <div className="bg-black/40 backdrop-blur-xl rounded-3xl p-8 border border-indigo-500/20">
              <h2 className="text-2xl font-semibold text-center mb-8 bg-gradient-to-r from-indigo-400 via-purple-400 to-indigo-400 text-transparent bg-clip-text">
                What Partners Receive
              </h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {benefits.map((benefit, index) => (
                  <div key={index} className="flex items-center space-x-4 bg-black/30 rounded-xl p-4 border border-indigo-500/20">
                    <div className="flex-shrink-0 w-10 h-10 bg-indigo-500/20 rounded-lg flex items-center justify-center">
                      <CheckCircle2 className="w-5 h-5 text-indigo-400" />
                    </div>
                    <span className="text-gray-300">{benefit}</span>
                  </div>
                ))}
              </div>
            </div>
          </motion.div>

          {/* CTA Section */}
          <motion.div variants={itemVariants}>
            <div className="text-center space-y-8">
              <h2 className="text-3xl font-bold bg-gradient-to-r from-indigo-400 via-purple-400 to-indigo-400 text-transparent bg-clip-text">
                Become a Signal Economy Partner
              </h2>
              <div className="flex flex-col items-center justify-center space-y-4">
                <a
                  href="mailto:milan@selflabs.xyz"
                  className="inline-flex items-center space-x-2 text-indigo-400 hover:text-indigo-300 transition-colors"
                >
                  <Mail className="w-5 h-5" />
                  <span>milan@selflabs.xyz</span>
                </a>
                <motion.button
                  className="inline-flex items-center bg-gradient-to-r from-indigo-600 to-purple-600 text-white px-8 py-4 rounded-xl text-lg font-semibold shadow-lg shadow-indigo-500/30 hover:shadow-indigo-500/50 transition-all duration-300"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <span>Contact Us</span>
                  <ArrowRight className="w-5 h-5 ml-2" />
                </motion.button>
              </div>
            </div>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
} 