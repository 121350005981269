import { motion } from 'framer-motion';
import { ArrowLeft, FileText, Phone, Shield, Brain, Lock, Wallet, Bell, Mail, UserCheck, Scale, RefreshCcw } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function TermsPage() {
  return (
    <div className="min-h-screen bg-black text-white">
      {/* Background Pattern */}
      <div className="fixed inset-0">
        <motion.div
          className="absolute inset-0"
          animate={{
            backgroundPosition: ['0% 0%', '100% 100%'],
          }}
          transition={{
            duration: 20,
            repeat: Infinity,
            ease: "linear"
          }}
          style={{
            backgroundImage: 'radial-gradient(circle at center, rgba(99, 102, 241, 0.1) 0%, transparent 20%)',
            backgroundSize: '40px 40px',
          }}
        />
      </div>

      <div className="relative max-w-4xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
        {/* Navigation */}
        <div className="mb-12">
          <Link to="/self-phone">
            <motion.button
              className="flex items-center text-gray-400 hover:text-white transition-colors"
              whileHover={{ x: -5 }}
            >
              <ArrowLeft className="w-5 h-5 mr-2" />
              Back to SELF Phone
            </motion.button>
          </Link>
        </div>

        {/* Header */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <h1 className="text-4xl md:text-5xl font-bold mb-4 bg-gradient-to-r from-indigo-400 via-purple-400 to-indigo-400 text-transparent bg-clip-text">
            SELF Phone & Flash Kit – Terms & Conditions
          </h1>
          <p className="text-gray-400">Effective April 2025 | SELF Labs Inc.</p>
        </motion.div>

        {/* Content */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
          className="prose prose-invert max-w-none"
        >
          {/* Agreement to Terms */}
          <section className="mb-16">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <FileText className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-2xl font-bold text-white">1. Agreement to Terms</h2>
            </div>
            <div className="bg-indigo-950/40 rounded-xl p-6 border border-indigo-500/20">
              <p className="text-gray-300 leading-relaxed">
                By purchasing, activating, or using the SELF Phone or the SELF Flash Kit, you agree to be bound by these Terms and Conditions. These terms govern your use of the hardware, software, AI systems, and any services provided through SELF Labs, including but not limited to the Role Model AI platform, Aura Shield, and the Signal Network.
              </p>
            </div>
          </section>

          {/* Products & Services */}
          <section className="mb-16">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <Phone className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-2xl font-bold text-white">2. Products & Services</h2>
            </div>
            <div className="space-y-6">
              <div className="bg-indigo-950/40 rounded-xl p-6 border border-indigo-500/20">
                <h3 className="text-xl font-bold text-indigo-400 mb-4">a. SELF Phone</h3>
                <p className="text-gray-300">
                  The SELF Phone is a custom mobile device preloaded with SELF OS, Role Model AI, and Aura Shield. It includes 1-year of free Aura Shield protection and access to the Signal Economy.
                </p>
              </div>
              <div className="bg-purple-950/40 rounded-xl p-6 border border-purple-500/20">
                <h3 className="text-xl font-bold text-purple-400 mb-4">b. SELF Flash Kit</h3>
                <p className="text-gray-300">
                  The Flash Kit enables Android users to convert compatible devices into SELF-powered phones. By using the Flash Kit, you acknowledge the modification of your device and accept all risks associated with flashing your firmware.
                </p>
              </div>
            </div>
          </section>

          {/* Eligibility */}
          <section className="mb-16">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <UserCheck className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-2xl font-bold text-white">3. Eligibility</h2>
            </div>
            <div className="bg-indigo-950/40 rounded-xl p-6 border border-indigo-500/20">
              <p className="text-gray-300">
                You must be 18 years or older to purchase or flash a device with SELF software. By using any SELF product, you confirm that you have the legal authority to do so.
              </p>
            </div>
          </section>

          {/* No Service Contracts */}
          <section className="mb-16">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <Scale className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-2xl font-bold text-white">4. No Service Contracts</h2>
            </div>
            <div className="bg-indigo-950/40 rounded-xl p-6 border border-indigo-500/20">
              <p className="text-gray-300">
                SELF Phones operate independently from traditional carriers. No subscription or service plan is required. However, satellite and Wi-Fi connectivity may depend on third-party availability and performance.
              </p>
            </div>
          </section>

          {/* AI Agent Usage */}
          <section className="mb-16">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <Brain className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-2xl font-bold text-white">5. AI Agent Usage</h2>
            </div>
            <div className="bg-indigo-950/40 rounded-xl p-6 border border-indigo-500/20">
              <p className="text-gray-300">
                Your use of AI assistants via Role Model AI must comply with all local and international laws. SELF Labs is not liable for decisions made by AI agents acting on your behalf. You retain full responsibility for configuring agent permissions.
              </p>
            </div>
          </section>

          {/* Earnings and ESSENCE Tokens */}
          <section className="mb-16">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <Wallet className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-2xl font-bold text-white">6. Earnings and ESSENCE Tokens</h2>
            </div>
            <div className="bg-gradient-to-br from-indigo-950/40 to-purple-950/40 rounded-xl p-6 border border-indigo-500/20">
              <p className="text-gray-300">
                Users may earn rewards through device usage and data monetization via Aura. Earnings are not guaranteed. SELF Labs does not offer investment advice or guarantee token value. ESSENCE is a utility token and has no intrinsic or fiat-equivalent value unless explicitly stated.
              </p>
            </div>
          </section>

          {/* Data & Privacy */}
          <section className="mb-16">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <Shield className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-2xl font-bold text-white">7. Data & Privacy</h2>
            </div>
            <div className="bg-gradient-to-br from-indigo-950/40 to-purple-950/40 rounded-xl p-6 border border-indigo-500/20">
              <p className="text-gray-300">
                By using the device, you acknowledge and accept the Privacy Policy. All data collection is optional and user-controlled. SELF Labs does not sell your data. Aura Shield provides encryption, zero-knowledge routing, and selective data monetization.
              </p>
            </div>
          </section>

          {/* Warranty and Liability */}
          <section className="mb-16">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <Shield className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-2xl font-bold text-white">8. Warranty and Liability</h2>
            </div>
            <div className="bg-gradient-to-br from-indigo-950/40 to-purple-950/40 rounded-xl p-6 border border-indigo-500/20">
              <p className="text-gray-300">
                SELF Phones include a 1-year limited warranty against hardware failure. The Flash Kit is a software-only tool and does not carry a warranty. SELF Labs is not responsible for bricked or damaged devices caused by incorrect flashing or user error.
              </p>
            </div>
          </section>

          {/* Returns & Refunds */}
          <section className="mb-16">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <RefreshCcw className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-2xl font-bold text-white">9. Returns & Refunds</h2>
            </div>
            <div className="bg-gradient-to-br from-indigo-950/40 to-purple-950/40 rounded-xl p-6 border border-indigo-500/20">
              <p className="text-gray-300">
                All sales are final once the Flash Kit is delivered or the SELF Phone is shipped. Refunds may be granted under limited conditions and must be requested within 14 days of purchase.
              </p>
            </div>
          </section>

          {/* Modifications */}
          <section className="mb-16">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <Bell className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-2xl font-bold text-white">10. Modifications</h2>
            </div>
            <div className="bg-gradient-to-br from-indigo-950/40 to-purple-950/40 rounded-xl p-6 border border-indigo-500/20">
              <p className="text-gray-300">
                SELF Labs reserves the right to modify these terms at any time. Updates will be communicated via the Signal Dashboard or email. Continued use after changes constitutes acceptance of the revised terms.
              </p>
            </div>
          </section>

          {/* Contact Information */}
          <section className="mb-16">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <Mail className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-2xl font-bold text-white">11. Contact Information</h2>
            </div>
            <div className="bg-gradient-to-br from-indigo-950/40 to-purple-950/40 rounded-xl p-8 border border-indigo-500/20">
              <p className="text-gray-300 mb-6">For questions or support, contact:</p>
              <div className="space-y-4">
                <p className="text-gray-300">
                  <span className="text-indigo-400 font-semibold">Email:</span>{' '}
                  <a href="mailto:support@rolemodel.ai" className="hover:text-indigo-400 transition-colors">
                    support@rolemodel.ai
                  </a>
                </p>
                <p className="text-gray-300">
                  <span className="text-indigo-400 font-semibold">Address:</span>{' '}
                  SELF Labs DAO LLC<br />
                  30 N Gould Ste 100<br />
                  Sheridan, WY 82801
                </p>
              </div>
            </div>
          </section>

          {/* Footer Quote */}
          <section className="mb-16">
            <div className="bg-gradient-to-br from-indigo-950/40 to-purple-950/40 rounded-xl p-8 border border-indigo-500/20">
              <p className="text-xl font-semibold text-indigo-400 mb-4">
                Signal is sovereignty. You own it. Use it wisely.
              </p>
              <p className="text-gray-400">
                — SELF Labs Inc.
              </p>
            </div>
          </section>
        </motion.div>
      </div>
    </div>
  );
} 