import { motion } from 'framer-motion';
import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Phone, Shield, Brain, Lock, Wallet, Sparkles, Users, Timer, Check, Download, Wrench, Zap, HelpCircle, ArrowRight, CheckCircle2, Loader2, Mail, Smartphone, User } from 'lucide-react';
import StripeCheckoutModal from './StripeCheckoutModal';
import { useFlash } from '../../contexts/FlashContext';
import { useStripe } from '../../contexts/StripeContext';
import { useApollo } from '../../contexts/ApolloContext';

interface WaitlistFormData {
  fullName: string;
  email: string;
  phoneModel: string;
  phoneNumber: string;
}

const FlashKitPage = () => {
  const navigate = useNavigate();
  const { startFlash } = useFlash();
  const { openCheckout } = useStripe();
  const { addContact } = useApollo();
  const [showStripeModal, setShowStripeModal] = useState(false);
  const [selectedTier, setSelectedTier] = useState('basic');
  const [isCheckoutOpen, setIsCheckoutOpen] = useState(false);
  const [showWaitlistForm, setShowWaitlistForm] = useState(false);
  const [formData, setFormData] = useState<WaitlistFormData>({
    fullName: '',
    email: '',
    phoneModel: '',
    phoneNumber: ''
  });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleFlashOrder = (tier: string) => {
    setSelectedTier(tier);
    setShowStripeModal(true);
  };

  const handleFlashNow = () => {
    setIsCheckoutOpen(true);
  };

  const handleWaitlistSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      // Add contact to Apollo.io
      await addContact({
        name: formData.fullName,
        email: formData.email,
        phoneModel: formData.phoneModel,
        source: 'selflabs.xyz',
        tags: ['waitlist']
      });

      setShowSuccess(true);
      setTimeout(() => {
        setShowSuccess(false);
        setShowWaitlistForm(false);
        setFormData({ fullName: '', email: '', phoneModel: '', phoneNumber: '' });
      }, 3000);
    } catch (error) {
      console.error('Error submitting waitlist form:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleCheckout = async () => {
    try {
      setIsLoading(true);
      await openCheckout('flash');
    } catch (error) {
      console.error('Error:', error);
      setError(error instanceof Error ? error.message : 'An error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#0A0A0B] via-black to-[#0A0A0B] text-white">
      <Helmet>
        <title>SELF Flash Kit - Transform Your Android Device | SELF Labs</title>
        <meta name="description" content="Transform your Android device into a SELF-powered device with Role Model AI, Aura Shield, and private earnings. Flash your phone now for $149." />
        <meta property="og:title" content="SELF Flash Kit - Transform Your Android Device" />
        <meta property="og:description" content="Transform your Android device into a SELF-powered device with Role Model AI, Aura Shield, and private earnings. Flash your phone now for $149." />
        <meta property="og:image" content="/assets/images/self-phone-preview.png" />
        <meta property="og:url" content="https://selflabs.ai/flash" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="SELF Flash Kit - Transform Your Android Device" />
        <meta name="twitter:description" content="Transform your Android device into a SELF-powered device with Role Model AI, Aura Shield, and private earnings. Flash your phone now for $149." />
        <meta name="twitter:image" content="/assets/images/self-phone-preview.png" />
        <meta name="keywords" content="SELF Flash Kit, Android flash, Role Model AI, Aura Shield, private earnings, mobile technology, SELF Labs, phone customization" />
      </Helmet>
      {/* Background Pattern */}
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,rgba(138,43,226,0.03)_0%,transparent_1px)] bg-[length:24px_24px]" />
      
      {/* Gradient Overlay */}
      <div className="absolute inset-0 bg-gradient-to-b from-purple-950/50 via-black to-black" />
      
      <div className="container mx-auto px-4 py-16 relative z-10">
        <div className="max-w-6xl mx-auto">
          {/* Hero Section */}
          <motion.section
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center mb-16"
          >
            <h1 className="text-4xl md:text-6xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 to-purple-400">
              Two Ways to Join the Signal
            </h1>
            <p className="text-xl text-gray-300 max-w-2xl mx-auto">
              Choose your path to experience the future of mobile technology with SELF Flash Kit
            </p>
          </motion.section>

          {/* Options Section */}
          <motion.section
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="grid md:grid-cols-2 gap-8 mb-16"
          >
            {/* Waitlist Option */}
            <div className="bg-indigo-950/40 backdrop-blur-xl rounded-3xl border border-indigo-500/20 p-8">
              <div className="flex items-center gap-4 mb-6">
                <Users className="w-8 h-8 text-indigo-400" />
                <h2 className="text-2xl font-bold">Join the Waitlist</h2>
              </div>
              <p className="text-gray-300 mb-8">
                Be among the first to experience SELF Flash Kit. We'll notify you when your flash window opens. Includes a free eSIM to replace your current phone plan.
              </p>
              <button
                onClick={() => setShowWaitlistForm(true)}
                className="w-full bg-indigo-600 hover:bg-indigo-500 text-white rounded-xl px-6 py-3 font-medium transition-colors flex items-center justify-center gap-2"
              >
                Join Waitlist (Free)
                <ArrowRight className="w-5 h-5" />
              </button>
            </div>

            {/* Priority Access Option */}
            <div className="bg-purple-950/40 backdrop-blur-xl rounded-3xl border border-purple-500/20 p-8">
              <div className="flex items-center gap-4 mb-6">
                <Zap className="w-8 h-8 text-purple-400" />
                <h2 className="text-2xl font-bold">Priority Access</h2>
              </div>
              <p className="text-gray-300 mb-8">
                Secure your spot in the first wave of flashing. Get early access, priority support, and a free eSIM to replace your current phone plan.
              </p>
              <button
                onClick={handleCheckout}
                className="w-full bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-500 hover:to-indigo-500 text-white rounded-xl px-6 py-3 font-medium transition-colors flex items-center justify-center gap-2"
              >
                Reserve Now - $149
                <ArrowRight className="w-5 h-5" />
              </button>
            </div>
          </motion.section>

          {/* Waitlist Form Modal */}
          {showWaitlistForm && (
            <div className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center z-50">
              <motion.div
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                className="bg-indigo-950/90 backdrop-blur-xl rounded-3xl border border-indigo-500/20 p-8 max-w-md w-full mx-4"
              >
                <h2 className="text-2xl font-bold mb-6">Join the Signal Waitlist</h2>
                <form onSubmit={handleWaitlistSubmit} className="space-y-6">
                  <div>
                    <label className="block text-sm font-medium text-gray-300 mb-2">Full Name</label>
                    <div className="relative">
                      <User className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                      <input
                        type="text"
                        required
                        value={formData.fullName}
                        onChange={(e) => setFormData({ ...formData, fullName: e.target.value })}
                        className="w-full bg-black/30 border border-indigo-500/20 rounded-xl pl-10 pr-4 py-3 text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        placeholder="Enter your full name"
                      />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-300 mb-2">Email Address</label>
                    <div className="relative">
                      <Mail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                      <input
                        type="email"
                        required
                        value={formData.email}
                        onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                        className="w-full bg-black/30 border border-indigo-500/20 rounded-xl pl-10 pr-4 py-3 text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        placeholder="Enter your email"
                      />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-300 mb-2">Phone Model</label>
                    <div className="relative">
                      <Smartphone className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                      <input
                        type="text"
                        required
                        value={formData.phoneModel}
                        onChange={(e) => setFormData({ ...formData, phoneModel: e.target.value })}
                        className="w-full bg-black/30 border border-indigo-500/20 rounded-xl pl-10 pr-4 py-3 text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        placeholder="e.g., Samsung Galaxy S21"
                      />
                    </div>
                  </div>
                  <div>
                    <label className="block text-sm font-medium text-gray-300 mb-2">Phone Number</label>
                    <div className="relative">
                      <Phone className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-5 h-5" />
                      <input
                        type="tel"
                        required
                        value={formData.phoneNumber}
                        onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
                        className="w-full bg-black/30 border border-indigo-500/20 rounded-xl pl-10 pr-4 py-3 text-white focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        placeholder="Enter your phone number"
                      />
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <button
                      type="button"
                      onClick={() => setShowWaitlistForm(false)}
                      className="flex-1 bg-gray-800 hover:bg-gray-700 text-white rounded-xl px-6 py-3 font-medium transition-colors"
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      disabled={isSubmitting}
                      className="flex-1 bg-indigo-600 hover:bg-indigo-500 text-white rounded-xl px-6 py-3 font-medium transition-colors flex items-center justify-center gap-2"
                    >
                      {isSubmitting ? (
                        <>
                          <Loader2 className="w-5 h-5 animate-spin" />
                          Submitting...
                        </>
                      ) : (
                        'Join Waitlist'
                      )}
                    </button>
                  </div>
                </form>
              </motion.div>
            </div>
          )}

          {/* Success Message */}
          {showSuccess && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              className="fixed bottom-8 right-8 bg-green-600 text-white rounded-xl px-6 py-4 flex items-center gap-2 shadow-lg"
            >
              <CheckCircle2 className="w-5 h-5" />
              <span>You've joined the Signal Waitlist!</span>
            </motion.div>
          )}

          {/* Features Section */}
          <motion.section
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
            className="grid md:grid-cols-3 gap-8"
          >
            <div className="bg-black/30 rounded-2xl p-6 border border-indigo-500/10">
              <div className="flex items-center gap-4 mb-4">
                <Brain className="w-6 h-6 text-indigo-400" />
                <h3 className="text-lg font-semibold">Role Model AI</h3>
              </div>
              <p className="text-gray-400">
                Your personal AI assistant that learns and adapts to your preferences
              </p>
            </div>
            <div className="bg-black/30 rounded-2xl p-6 border border-indigo-500/10">
              <div className="flex items-center gap-4 mb-4">
                <Shield className="w-6 h-6 text-indigo-400" />
                <h3 className="text-lg font-semibold">Aura Shield</h3>
              </div>
              <p className="text-gray-400">
                Advanced privacy protection and data security features
              </p>
            </div>
            <div className="bg-black/30 rounded-2xl p-6 border border-indigo-500/10">
              <div className="flex items-center gap-4 mb-4">
                <Zap className="w-6 h-6 text-indigo-400" />
                <h3 className="text-lg font-semibold">Signal Sync</h3>
              </div>
              <p className="text-gray-400">
                Seamless integration with your digital ecosystem
              </p>
            </div>
            <div className="bg-black/30 rounded-2xl p-6 border border-indigo-500/10">
              <div className="flex items-center gap-4 mb-4">
                <Smartphone className="w-6 h-6 text-indigo-400" />
                <h3 className="text-xl font-semibold">Free Phone Service</h3>
              </div>
              <p className="text-gray-300">
                Replace your current phone plan with our complimentary eSIM service, eliminating your monthly phone bill while maintaining premium connectivity.
              </p>
            </div>
          </motion.section>
        </div>
      </div>

      {/* How It Works */}
      <section className="py-20 bg-black/50">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-16">How It Works</h2>
          <div className="grid md:grid-cols-3 gap-8 max-w-5xl mx-auto">
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="bg-purple-950/30 p-8 rounded-2xl backdrop-blur-sm border border-purple-500/20"
            >
              <Download className="w-12 h-12 text-purple-400 mb-4" />
              <h3 className="text-xl font-semibold mb-4">Step 1</h3>
              <p className="text-gray-400">Download the Flash Kit or request USB kit</p>
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="bg-purple-950/30 p-8 rounded-2xl backdrop-blur-sm border border-purple-500/20"
            >
              <Wrench className="w-12 h-12 text-purple-400 mb-4" />
              <h3 className="text-xl font-semibold mb-4">Step 2</h3>
              <p className="text-gray-400">Run the installer (rooted or non-rooted paths available)</p>
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="bg-purple-950/30 p-8 rounded-2xl backdrop-blur-sm border border-purple-500/20"
            >
              <Zap className="w-12 h-12 text-purple-400 mb-4" />
              <h3 className="text-xl font-semibold mb-4">Step 3</h3>
              <p className="text-gray-400">Start using Role Model AI + earn through Aura instantly</p>
            </motion.div>
          </div>
          <div className="text-center mt-8">
            <p className="text-gray-400 flex items-center justify-center">
              <Shield className="w-5 h-5 mr-2" />
              Your data is protected by Aura Shield by default
            </p>
          </div>
        </div>
      </section>

      {/* Flash Kit Pricing */}
      <section className="relative py-32 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-indigo-950/20 via-purple-950/20 to-black/20" />
        <div className="absolute inset-0 bg-[url('/signal-pattern.png')] opacity-5" />
        
        {/* Animated gradient orbs */}
        <div className="absolute top-0 right-1/4 w-96 h-96 bg-indigo-500/20 rounded-full blur-3xl animate-pulse" />
        <div className="absolute bottom-0 left-1/4 w-96 h-96 bg-purple-500/20 rounded-full blur-3xl animate-pulse" />
        
        <div className="container mx-auto px-4 relative z-10">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-5xl md:text-6xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 via-purple-400 to-blue-400 mb-6">
              Choose Your Flash Kit
            </h2>
            <p className="text-xl text-gray-300 max-w-3xl mx-auto">
              Transform your existing device into a SELF Phone with our comprehensive flash kit.
            </p>
          </motion.div>

          <div className="max-w-2xl mx-auto">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              viewport={{ once: true }}
              className="bg-indigo-950/40 backdrop-blur-xl rounded-3xl border border-indigo-500/20 p-8 md:p-12 shadow-2xl shadow-indigo-500/10"
            >
              <div className="flex items-center justify-between mb-8">
                <div>
                  <h3 className="text-3xl font-bold text-white mb-2">Complete Flash Kit</h3>
                  <p className="text-indigo-300">Everything you need to transform your device</p>
                </div>
                <div className="text-4xl font-bold text-white">$149</div>
              </div>

              <div className="space-y-6 mb-8">
                <div className="grid md:grid-cols-2 gap-4">
                  <div className="flex items-start gap-3 bg-indigo-950/30 p-4 rounded-xl">
                    <div className="p-2 bg-indigo-500/10 rounded-lg">
                      <motion.div
                        animate={{ rotate: [0, 10, 0] }}
                        transition={{ duration: 2, repeat: Infinity }}
                        className="text-indigo-400"
                      >
                        🤖
                      </motion.div>
                    </div>
                    <div>
                      <h4 className="font-semibold text-white">AI Agent</h4>
                      <p className="text-sm text-gray-400">Personal AI assistant with voice matching</p>
                    </div>
                  </div>

                  <div className="flex items-start gap-3 bg-indigo-950/30 p-4 rounded-xl">
                    <div className="p-2 bg-indigo-500/10 rounded-lg">
                      <motion.div
                        animate={{ rotate: [0, 10, 0] }}
                        transition={{ duration: 2, repeat: Infinity }}
                        className="text-indigo-400"
                      >
                        🛡️
                      </motion.div>
                    </div>
                    <div>
                      <h4 className="font-semibold text-white">Aura Shield</h4>
                      <p className="text-sm text-gray-400">Advanced privacy protection system</p>
                    </div>
                  </div>

                  <div className="flex items-start gap-3 bg-indigo-950/30 p-4 rounded-xl">
                    <div className="p-2 bg-indigo-500/10 rounded-lg">
                      <motion.div
                        animate={{ rotate: [0, 10, 0] }}
                        transition={{ duration: 2, repeat: Infinity }}
                        className="text-indigo-400"
                      >
                        💫
                      </motion.div>
                    </div>
                    <div>
                      <h4 className="font-semibold text-white">Role Model AI</h4>
                      <p className="text-sm text-gray-400">Full personality customization</p>
                    </div>
                  </div>

                  <div className="flex items-start gap-3 bg-indigo-950/30 p-4 rounded-xl">
                    <div className="p-2 bg-indigo-500/10 rounded-lg">
                      <motion.div
                        animate={{ rotate: [0, 10, 0] }}
                        transition={{ duration: 2, repeat: Infinity }}
                        className="text-indigo-400"
                      >
                        🔄
                      </motion.div>
                    </div>
                    <div>
                      <h4 className="font-semibold text-white">Multi-agent Automation</h4>
                      <p className="text-sm text-gray-400">Seamless task coordination</p>
                    </div>
                  </div>

                  <div className="flex items-start gap-3 bg-indigo-950/30 p-4 rounded-xl">
                    <div className="p-2 bg-indigo-500/10 rounded-lg">
                      <motion.div
                        animate={{ rotate: [0, 10, 0] }}
                        transition={{ duration: 2, repeat: Infinity }}
                        className="text-indigo-400"
                      >
                        💰
                      </motion.div>
                    </div>
                    <div>
                      <h4 className="font-semibold text-white">Private Earning System</h4>
                      <p className="text-sm text-gray-400">Monetize your data and attention</p>
                    </div>
                  </div>

                  <div className="flex items-start gap-3 bg-indigo-950/30 p-4 rounded-xl">
                    <div className="p-2 bg-indigo-500/10 rounded-lg">
                      <motion.div
                        animate={{ rotate: [0, 10, 0] }}
                        transition={{ duration: 2, repeat: Infinity }}
                        className="text-indigo-400"
                      >
                        🔒
                      </motion.div>
                    </div>
                    <div>
                      <h4 className="font-semibold text-white">Deep System Integration</h4>
                      <p className="text-sm text-gray-400">Full OS replacement & custom launcher</p>
                    </div>
                  </div>
                </div>
              </div>

              <button
                onClick={handleFlashNow}
                className="w-full bg-gradient-to-r from-indigo-600 to-purple-600 hover:from-indigo-500 hover:to-purple-500 text-white rounded-xl px-8 py-4 font-semibold transition-all duration-300 transform hover:scale-[1.02] focus:scale-[0.98]"
              >
                Flash Now
              </button>

              <p className="text-center text-sm text-gray-400 mt-6">
                Compatible with most Android devices. iOS support coming soon.
              </p>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Why Flash? */}
      <section className="py-20 bg-black/50">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-16">Why Flash?</h2>
          <div className="grid md:grid-cols-2 gap-8 max-w-4xl mx-auto">
            <motion.div
              whileHover={{ scale: 1.02 }}
              className="bg-purple-950/30 p-8 rounded-2xl backdrop-blur-sm border border-purple-500/20"
            >
              <h3 className="text-2xl font-semibold mb-6">Join the Network</h3>
              <ul className="space-y-4">
                <li className="flex items-center">
                  <Check className="w-5 h-5 text-purple-400 mr-2" />
                  <span>No new hardware needed</span>
                </li>
                <li className="flex items-center">
                  <Check className="w-5 h-5 text-purple-400 mr-2" />
                  <span>Works on most Android phones</span>
                </li>
                <li className="flex items-center">
                  <Check className="w-5 h-5 text-purple-400 mr-2" />
                  <span>No subscription, ever</span>
                </li>
                <li className="flex items-center">
                  <Check className="w-5 h-5 text-purple-400 mr-2" />
                  <span>Option to upgrade to SELF Phone later</span>
                </li>
              </ul>
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.02 }}
              className="bg-purple-950/30 p-8 rounded-2xl backdrop-blur-sm border border-purple-500/20"
            >
              <h3 className="text-2xl font-semibold mb-6">Unlock Features</h3>
              <ul className="space-y-4">
                <li className="flex items-center">
                  <Check className="w-5 h-5 text-purple-400 mr-2" />
                  <span>Activate your AI</span>
                </li>
                <li className="flex items-center">
                  <Check className="w-5 h-5 text-purple-400 mr-2" />
                  <span>Protect your data</span>
                </li>
                <li className="flex items-center">
                  <Check className="w-5 h-5 text-purple-400 mr-2" />
                  <span>Earn daily rewards</span>
                </li>
              </ul>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Phone Compatibility Section */}
      <section className="py-20 bg-black/50">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-16">Can My Phone Be Flashed?</h2>
          <p className="text-xl text-gray-400 text-center mb-12 max-w-3xl mx-auto">
            Not every Android is built the same — but many can still be upgraded to resonate.
          </p>

          {/* Best Phones */}
          <div className="max-w-4xl mx-auto mb-16">
            <h3 className="text-2xl font-semibold mb-6 text-purple-400">✅ Best Phones for SELF Flash</h3>
            <p className="text-gray-400 mb-8">These phones are known to be easy to convert into a Resonance Phone:</p>
            <div className="grid md:grid-cols-2 gap-6">
              <motion.div
                whileHover={{ scale: 1.02 }}
                className="bg-purple-950/30 p-6 rounded-xl backdrop-blur-sm border border-purple-500/20"
              >
                <h4 className="text-lg font-semibold mb-2">Google Pixel (3 to 8)</h4>
                <p className="text-gray-400">Our top recommendation. Easy to flash, developer-friendly.</p>
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.02 }}
                className="bg-purple-950/30 p-6 rounded-xl backdrop-blur-sm border border-purple-500/20"
              >
                <h4 className="text-lg font-semibold mb-2">OnePlus (All models)</h4>
                <p className="text-gray-400">Rootable, customizable, and perfect for signal transformation.</p>
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.02 }}
                className="bg-purple-950/30 p-6 rounded-xl backdrop-blur-sm border border-purple-500/20"
              >
                <h4 className="text-lg font-semibold mb-2">Fairphone (3–5)</h4>
                <p className="text-gray-400">Open-source and ethically designed. Fully compatible.</p>
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.02 }}
                className="bg-purple-950/30 p-6 rounded-xl backdrop-blur-sm border border-purple-500/20"
              >
                <h4 className="text-lg font-semibold mb-2">Motorola G / E Series</h4>
                <p className="text-gray-400">Great budget options with flashable firmware.</p>
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.02 }}
                className="bg-purple-950/30 p-6 rounded-xl backdrop-blur-sm border border-purple-500/20"
              >
                <h4 className="text-lg font-semibold mb-2">Sony Xperia (XZ and up)</h4>
                <p className="text-gray-400">Unlockable bootloaders and clean Android experience.</p>
              </motion.div>
            </div>
            <p className="text-gray-400 mt-6">If you have one of these, flashing is simple and reversible.</p>
          </div>

          {/* Incompatible Phones */}
          <div className="max-w-4xl mx-auto mb-16">
            <h3 className="text-2xl font-semibold mb-6 text-red-400">⚠️ Phones That May Not Work Well</h3>
            <p className="text-gray-400 mb-8">Some devices have carrier restrictions or locked bootloaders, making them harder (or impossible) to convert:</p>
            <div className="grid md:grid-cols-2 gap-6">
              <motion.div
                whileHover={{ scale: 1.02 }}
                className="bg-red-950/30 p-6 rounded-xl backdrop-blur-sm border border-red-500/20"
              >
                <h4 className="text-lg font-semibold mb-2">Carrier-locked phones</h4>
                <p className="text-gray-400">AT&T, Verizon, etc.</p>
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.02 }}
                className="bg-red-950/30 p-6 rounded-xl backdrop-blur-sm border border-red-500/20"
              >
                <h4 className="text-lg font-semibold mb-2">Newer Samsung devices</h4>
                <p className="text-gray-400">Galaxy S20+ and up – US models</p>
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.02 }}
                className="bg-red-950/30 p-6 rounded-xl backdrop-blur-sm border border-red-500/20"
              >
                <h4 className="text-lg font-semibold mb-2">Amazon Fire Devices</h4>
                <p className="text-gray-400">Fire Phones or Tablets</p>
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.02 }}
                className="bg-red-950/30 p-6 rounded-xl backdrop-blur-sm border border-red-500/20"
              >
                <h4 className="text-lg font-semibold mb-2">Huawei post-2020</h4>
                <p className="text-gray-400">Google restrictions</p>
              </motion.div>
              <motion.div
                whileHover={{ scale: 1.02 }}
                className="bg-red-950/30 p-6 rounded-xl backdrop-blur-sm border border-red-500/20"
              >
                <h4 className="text-lg font-semibold mb-2">iPhones</h4>
                <p className="text-gray-400">Not supported</p>
              </motion.div>
            </div>
            <p className="text-gray-400 mt-6">If your phone is on this list, we recommend trying a different device or ordering a pre-flashed one from us.</p>
          </div>

          {/* Reversibility */}
          <div className="max-w-4xl mx-auto mb-16">
            <h3 className="text-2xl font-semibold mb-6 text-purple-400">🔄 Can I Undo the Flash Later?</h3>
            <div className="bg-purple-950/30 p-8 rounded-2xl backdrop-blur-sm border border-purple-500/20">
              <p className="text-gray-300 mb-6">Yes. You can always return your phone to its original state.</p>
              <ul className="space-y-4">
                <li className="flex items-start">
                  <Check className="w-5 h-5 text-purple-400 mr-2 mt-1" />
                  <span className="text-gray-400">If you used our Launcher or APK installer, just uninstall or reset your phone.</span>
                </li>
                <li className="flex items-start">
                  <Check className="w-5 h-5 text-purple-400 mr-2 mt-1" />
                  <span className="text-gray-400">If you used our Full Flash (rooted version), you can revert using tools like TWRP, Fastboot, or your manufacturer's software.</span>
                </li>
                <li className="flex items-start">
                  <Check className="w-5 h-5 text-purple-400 mr-2 mt-1" />
                  <span className="text-gray-400">We'll include a Restore Guide PDF to walk you through every step.</span>
                </li>
              </ul>
              <p className="text-gray-300 mt-6">You're in control of your signal — always.</p>
            </div>
          </div>

          {/* Compatibility Check */}
          <div className="max-w-4xl mx-auto text-center">
            <h3 className="text-2xl font-semibold mb-6 text-purple-400">📲 Not Sure If Your Phone Works?</h3>
            <p className="text-gray-400 mb-8">
              We're building a compatibility assistant that will tell you in real-time.
              In the meantime, you can:
            </p>
            <div className="flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-8">
              <a
                href="mailto:support@rolemodel.ai"
                className="text-purple-400 hover:text-purple-300 transition-colors flex items-center space-x-2"
              >
                <span>Email us at support@rolemodel.ai</span>
                <span>→</span>
              </a>
              <span className="text-gray-400">or</span>
              <a
                href="#"
                className="text-purple-400 hover:text-purple-300 transition-colors flex items-center space-x-2"
              >
                <span>Refer to our Flash Guide PDF</span>
                <span>→</span>
              </a>
            </div>
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="py-20 bg-black/50">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-16">Frequently Asked Questions</h2>
          <div className="max-w-3xl mx-auto space-y-8">
            <motion.div
              whileHover={{ scale: 1.02 }}
              className="bg-purple-950/30 p-8 rounded-2xl backdrop-blur-sm border border-purple-500/20"
            >
              <h3 className="text-xl font-semibold mb-4">Does this void my warranty?</h3>
              <p className="text-gray-400">No. The SELF Flash Kit is designed to work within manufacturer guidelines.</p>
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.02 }}
              className="bg-purple-950/30 p-8 rounded-2xl backdrop-blur-sm border border-purple-500/20"
            >
              <h3 className="text-xl font-semibold mb-4">Can I revert back?</h3>
              <p className="text-gray-400">Yes. The Flash Kit includes a simple rollback option if you ever want to return to your original setup.</p>
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.02 }}
              className="bg-purple-950/30 p-8 rounded-2xl backdrop-blur-sm border border-purple-500/20"
            >
              <h3 className="text-xl font-semibold mb-4">What phones are compatible?</h3>
              <p className="text-gray-400">Most Android phones running Android 8.0 or newer are compatible. Check our compatibility guide for details.</p>
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.02 }}
              className="bg-purple-950/30 p-8 rounded-2xl backdrop-blur-sm border border-purple-500/20"
            >
              <h3 className="text-xl font-semibold mb-4">Do I need to know anything about crypto?</h3>
              <p className="text-gray-400">Nope. It just works. The earning system is fully automated and integrated into your daily use.</p>
            </motion.div>
          </div>
          <div className="text-center mt-12">
            <Link 
              to="/flash/docs/tech-guide" 
              className="text-purple-400 hover:text-purple-300 transition-colors flex items-center justify-center space-x-2"
            >
              <span>📄 View Full Tech Guide</span>
              <ArrowRight className="w-5 h-5" />
            </Link>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="py-12 bg-black/50 border-t border-purple-500/20">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-3 gap-8">
            <div>
              <h3 className="text-xl font-semibold mb-4">SELF Labs</h3>
              <p className="text-gray-400">Powered by Role Model AI + Aura Protocol</p>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-4">Connect</h3>
              <div className="flex space-x-4">
                <a href="https://x.com/selflabsinc" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-purple-400 transition-colors">Twitter</a>
                <a href="https://t.me/+2uJEHPAt4204M2Zh" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-purple-400 transition-colors">Telegram</a>
              </div>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-4">Resources</h3>
              <div className="flex flex-col space-y-2">
                <Link to="/flash/signal-economy" className="text-gray-400 hover:text-indigo-400 transition-colors">Signal Economy</Link>
                <Link to="/flash/docs/tech-guide" className="text-gray-400 hover:text-indigo-400 transition-colors">Technical Guide</Link>
                <Link to="/flash/onboarding" className="text-gray-400 hover:text-indigo-400 transition-colors">Onboarding</Link>
              </div>
            </div>
          </div>
          <div className="mt-8 pt-8 border-t border-purple-500/20 text-center text-gray-400">
            <a href="mailto:support@rolemodel.ai" className="hover:text-purple-400 transition-colors">
              support@rolemodel.ai
            </a>
          </div>
        </div>
      </footer>

      {/* Stripe Checkout Modal */}
      <StripeCheckoutModal
        isOpen={isCheckoutOpen}
        onClose={() => setIsCheckoutOpen(false)}
        isFlashKit={true}
      />
    </div>
  );
};

export default FlashKitPage; 