import React from 'react';
import { motion } from 'framer-motion';
import { LucideIcon } from 'lucide-react';

interface ColorScheme {
  bg: string;
  text: string;
  gradient: string;
}

interface ProductCardProps {
  title: string;
  description: string;
  icon: LucideIcon;
  features: string[];
  color: ColorScheme;
  onLearnMore?: () => void;
}

export default function ProductCard({ title, description, icon: Icon, features, color, onLearnMore }: ProductCardProps) {
  return (
    <motion.div
      className="group relative max-w-lg mx-auto px-4 sm:px-0"
      whileHover={{ y: -5 }}
      transition={{ duration: 0.3 }}
    >
      <div className="absolute -inset-[1px] rounded-2xl bg-gradient-to-r from-white/10 via-white/5 to-white/10 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />

      <div className="relative bg-[#0A0A0B] rounded-2xl p-6 sm:p-8 h-full border border-white/5">
        <motion.div
          className={`absolute -inset-2 ${color.gradient} rounded-3xl blur-3xl opacity-0 group-hover:opacity-10 transition-opacity duration-300`}
        />

        <motion.div
          whileHover={{ rotate: 5, scale: 1.1 }}
          transition={{ type: "spring", stiffness: 300 }}
          className="relative mb-4 sm:mb-6"
        >
          <Icon className={`h-8 w-8 sm:h-10 sm:w-10 ${color.text}`} />
        </motion.div>

        <h3 className="text-xl sm:text-2xl font-semibold text-white mb-3 sm:mb-4 relative">
          {title}
        </h3>

        <p className="text-sm sm:text-base text-gray-400 mb-4 sm:mb-6 relative">
          {description}
        </p>

        <div className="space-y-2 sm:space-y-3 relative">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: -10 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.1 }}
              className="flex items-center gap-2"
            >
              <motion.div 
                className={`w-1 h-1 rounded-full ${color.bg}`}
                whileHover={{ scale: 1.5 }}
              />
              <span className="text-xs sm:text-sm text-gray-300">{feature}</span>
            </motion.div>
          ))}
        </div>

        <motion.button
          onClick={onLearnMore}
          className={`relative mt-6 sm:mt-8 px-4 sm:px-6 py-1.5 sm:py-2 text-xs sm:text-sm text-white/80 rounded-lg border border-white/10 
            ${color.bg.replace('bg-', 'hover:bg-')} transition-colors duration-300`}
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
        >
          {title === "SELF Phone" ? "Pre-order" : "Learn More"}
        </motion.button>
      </div>
    </motion.div>
  );
}