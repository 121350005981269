import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FileText, Download } from 'lucide-react';
import { supabase } from '../../../utils/supabase';

export function DocumentsList() {
  const [documents, setDocuments] = useState<any[]>([]);

  useEffect(() => {
    fetchDocuments();
  }, []);

  async function fetchDocuments() {
    try {
      const { data, error } = await supabase
        .from('financial_documents')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setDocuments(data || []);
    } catch (error) {
      console.error('Error fetching documents:', error);
    }
  }

  return (
    <div className="bg-[#0A0A0B] border border-white/10 rounded-xl p-6">
      <h2 className="text-xl font-semibold text-white mb-6">Financial Documents</h2>
      
      <div className="space-y-4">
        {documents.map((doc, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: index * 0.1 }}
            className="flex items-center justify-between p-4 bg-white/5 rounded-lg"
          >
            <div className="flex items-center space-x-4">
              <FileText className="w-5 h-5 text-[#00FFFF]" />
              <div>
                <div className="text-white font-medium">{doc.title}</div>
                <div className="text-sm text-gray-400">
                  {doc.date} • {doc.type} • {doc.size}
                </div>
              </div>
            </div>
            
            <motion.button
              whileHover={{ scale: 1.1 }}
              className="p-2 text-gray-400 hover:text-white"
            >
              <Download className="w-5 h-5" />
            </motion.button>
          </motion.div>
        ))}
      </div>
    </div>
  );
}