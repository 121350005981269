import React from 'react';
import { motion } from 'framer-motion';
import { Brain, Cpu, Atom } from 'lucide-react';

const technologies = [
  {
    icon: Brain,
    title: "Artificial Intelligence",
    description: "Pushing the boundaries of machine cognition with advanced neural networks and deep learning systems.",
    color: "from-[#FF2E63]",
    features: ["Neural Networks", "Deep Learning", "Natural Language Processing"]
  },
  {
    icon: Cpu,
    title: "Neural Interfaces",
    description: "Bridging the gap between human consciousness and digital realms through direct neural connections.",
    color: "from-[#08F7FE]",
    features: ["Brain-Computer Interface", "Neural Mapping", "Cognitive Enhancement"]
  },
  {
    icon: Atom,
    title: "Quantum Computing",
    description: "Harnessing quantum mechanics to solve complex problems beyond classical computing capabilities.",
    color: "from-[#B4FF39]",
    features: ["Quantum Algorithms", "Error Correction", "Quantum Encryption"]
  }
];

export default function Technologies() {
  return (
    <div id="technologies" className="relative py-24 overflow-hidden">
      <div className="absolute inset-0 bg-[#0A0A0B]">
        {/* Animated grid lines */}
        <div className="absolute inset-0 opacity-20">
          {[...Array(20)].map((_, i) => (
            <motion.div
              key={i}
              className="absolute h-px w-full bg-gradient-to-r from-transparent via-[#08F7FE]/20 to-transparent"
              style={{ top: `${i * 5}%` }}
              animate={{
                opacity: [0.2, 0.5, 0.2],
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                delay: i * 0.1,
              }}
            />
          ))}
        </div>
      </div>

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-5xl font-bold mb-6 bg-gradient-to-r from-[#08F7FE] to-[#B4FF39] text-transparent bg-clip-text">
            Core Technologies
          </h2>
          <p className="text-xl text-gray-400 max-w-3xl mx-auto">
            Our breakthrough technologies are reshaping the future of human-machine interaction
          </p>
        </motion.div>

        <div className="grid lg:grid-cols-3 gap-8">
          {technologies.map((tech, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.2 }}
              whileHover={{ scale: 1.02 }}
              className="relative group"
            >
              <div className="bg-[#121214] rounded-xl p-8 border border-gray-800">
                {/* Glowing background */}
                <motion.div
                  className={`absolute inset-0 bg-gradient-to-br ${tech.color} to-transparent opacity-0 group-hover:opacity-10 rounded-xl transition-opacity duration-300`}
                />

                <tech.icon className={`h-12 w-12 mb-6 ${tech.color.replace('from-', 'text-')}`} />
                
                <h3 className="text-2xl font-bold text-white mb-4">{tech.title}</h3>
                <p className="text-gray-400 mb-6">{tech.description}</p>
                
                <div className="space-y-2">
                  {tech.features.map((feature, i) => (
                    <motion.div
                      key={i}
                      initial={{ opacity: 0, x: -20 }}
                      whileInView={{ opacity: 1, x: 0 }}
                      viewport={{ once: true }}
                      transition={{ delay: 0.5 + (i * 0.1) }}
                      className="flex items-center space-x-2"
                    >
                      <div className={`w-1.5 h-1.5 rounded-full bg-gradient-to-r ${tech.color} to-transparent`} />
                      <span className="text-gray-300">{feature}</span>
                    </motion.div>
                  ))}
                </div>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
}