import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { ArrowLeft, DollarSign, Database, LineChart, Shield, Users, Brain } from 'lucide-react';
import { Link } from 'react-router-dom';
import Modal from '../shared/Modal';
import ContactForm from '../forms/ContactForm';

export default function UBICaseStudy() {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const openContactModal = () => setIsContactModalOpen(true);
  const closeContactModal = () => setIsContactModalOpen(false);
  
  return (
    <div className="min-h-screen bg-black text-white">
      <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
        <div className="mb-8">
          <Link to="/">
            <motion.button
              className="flex items-center text-gray-400 hover:text-white"
              whileHover={{ x: -5 }}
              transition={{ duration: 0.2 }}
            >
              <ArrowLeft className="w-5 h-5 mr-2" />
              Back to Home
            </motion.button>
          </Link>
        </div>
        
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className="text-4xl md:text-5xl font-bold mb-6 bg-gradient-to-r from-pink-400 to-red-600 text-transparent bg-clip-text">
            Universal Basic Income and Data Monetization
          </h1>
          
          <div className="mb-8">
            <div className="flex flex-wrap gap-2 mb-4">
              <span className="px-3 py-1 text-sm bg-pink-900/50 text-pink-300 rounded-full">
                AuRA Technology
              </span>
              <span className="px-3 py-1 text-sm bg-pink-900/50 text-pink-300 rounded-full">
                Data Monetization
              </span>
              <span className="px-3 py-1 text-sm bg-pink-900/50 text-pink-300 rounded-full">
                Universal Basic Income
              </span>
            </div>
          </div>
          
          <div className="prose prose-invert max-w-none mb-12">
            <p className="text-xl text-gray-300 mb-6">
              A synergistic approach to Universal Basic Income through the AuRA platform's data monetization capabilities, 
              enabling individuals to generate substantial income by leveraging their personal data.
            </p>
            
            <h2 className="text-2xl font-bold mt-8 mb-4">Introduction</h2>
            <p className="mb-4">
              The concept of Universal Basic Income (UBI) has garnered significant attention as a potential solution to growing 
              income inequality, job displacement due to automation, and economic instability. Traditionally, UBI proposes 
              providing citizens with a fixed income to cover basic living expenses, irrespective of their employment status. 
              However, funding such a program on a large scale has remained a challenge.
            </p>
            
            <p className="mb-4">
              With the advent of data-driven economies, innovative models for generating UBI have emerged. One such approach 
              is the monetization of personal data, an asset that individuals generate daily. This case study explores how the 
              AuRA (Augmented Universal Real-Time Assistant) platform, with its advanced data monetization capabilities, can 
              facilitate UBI through data-driven earnings. By leveraging personal data across various domains, individuals can 
              earn a sustainable base income, contributing to the realization of UBI.
            </p>
            
            <h2 className="text-2xl font-bold mt-12 mb-4">AuRA's Role in Facilitating Universal Basic Income</h2>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
              <div className="bg-gradient-to-b from-pink-900/20 to-pink-900/5 rounded-xl p-6 border border-pink-500/20">
                <div className="flex items-center mb-4">
                  <div className="w-12 h-12 bg-pink-900/70 rounded-full flex items-center justify-center mr-4">
                    <Database className="h-6 w-6 text-pink-300" />
                  </div>
                  <h3 className="text-xl font-semibold text-white">Data Monetization as a Revenue Stream</h3>
                </div>
                <p className="text-gray-300">
                  AuRA presents a comprehensive model for monetizing personal data, enabling individuals to earn significant income 
                  through the sale of their data across multiple platforms. Users can generate income from diverse data sources such as 
                  financial data, social media, health and fitness, shopping, and entertainment.
                </p>
              </div>
              
              <div className="bg-gradient-to-b from-pink-900/20 to-pink-900/5 rounded-xl p-6 border border-pink-500/20">
                <div className="flex items-center mb-4">
                  <div className="w-12 h-12 bg-pink-900/70 rounded-full flex items-center justify-center mr-4">
                    <DollarSign className="h-6 w-6 text-pink-300" />
                  </div>
                  <h3 className="text-xl font-semibold text-white">Projected Earnings and Universal Base Income</h3>
                </div>
                <p className="text-gray-300">
                  The monetization of personal data offers a path to achieving a substantial base income. AuRA's strategic data plan 
                  projects potential earnings ranging from $8,500 per month ($102,000 per year) on the low end to $15,000 per month 
                  ($180,000 per year) on the high end.
                </p>
              </div>
            </div>
            
            <div className="bg-gradient-to-r from-pink-900/20 to-red-900/20 rounded-xl p-8 mb-12 relative overflow-hidden">
              <div className="absolute -top-10 -left-10 w-40 h-40 bg-pink-600/10 rounded-full blur-3xl z-0"></div>
              <div className="absolute -bottom-10 -right-10 w-40 h-40 bg-red-600/10 rounded-full blur-3xl z-0"></div>
              <div className="relative z-10">
                <h3 className="text-xl font-semibold mb-4 text-white">Scaling for a Universal Basic Income Model</h3>
                <p className="text-gray-300">
                  AuRA's plan estimates significant revenue generation even with its current user base of approximately 102,461 users. 
                  If scaled to a future goal of 10 million users, the revenue potential is enormous, offering a pathway for sustainable, 
                  data-driven UBI. By applying a 1% fee on data earnings, AuRA also generates revenue for platform maintenance while 
                  maintaining high returns for users.
                </p>
              </div>
            </div>
            
            <h2 className="text-2xl font-bold mt-12 mb-4">Implementation Strategy for Data Monetization and UBI</h2>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
              <div className="bg-gradient-to-b from-pink-900/20 to-pink-900/5 rounded-xl p-6 border border-pink-500/20">
                <div className="flex items-center mb-4">
                  <div className="w-12 h-12 bg-pink-900/70 rounded-full flex items-center justify-center mr-4">
                    <Database className="h-6 w-6 text-pink-300" />
                  </div>
                  <h3 className="text-xl font-semibold text-white">Data Integration and Collection</h3>
                </div>
                <p className="text-gray-300">
                  The success of AuRA's UBI-driven data monetization strategy depends on the continuous, automated collection of personal 
                  data from multiple sources. This includes financial data, health metrics, shopping histories, and more. By integrating 
                  real-time data collection, AuRA ensures that users' datasets remain relevant, increasing their market value.
                </p>
              </div>
              
              <div className="bg-gradient-to-b from-pink-900/20 to-pink-900/5 rounded-xl p-6 border border-pink-500/20">
                <div className="flex items-center mb-4">
                  <div className="w-12 h-12 bg-pink-900/70 rounded-full flex items-center justify-center mr-4">
                    <Shield className="h-6 w-6 text-pink-300" />
                  </div>
                  <h3 className="text-xl font-semibold text-white">User Incentives and Ethical Considerations</h3>
                </div>
                <p className="text-gray-300">
                  AuRA implements various incentives to encourage user participation in the data monetization process, such as profit-sharing 
                  models and rewards programs. Ethical considerations are also paramount. Data privacy is ensured through anonymization 
                  techniques, and users retain full control over their data.
                </p>
              </div>
            </div>
            
            <h2 className="text-2xl font-bold mt-12 mb-4">Universal Basic Income: Addressing Economic Disparities</h2>
            
            <div className="space-y-6 mb-12">
              <div className="flex flex-col md:flex-row gap-6 items-start">
                <div className="flex-shrink-0 w-16 h-16 bg-gradient-to-br from-pink-600 to-red-600 rounded-full flex items-center justify-center">
                  <Brain className="h-8 w-8 text-white" />
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-3 text-pink-300">Automation and Job Displacement</h3>
                  <p className="text-gray-300">
                    As automation and AI continue to reshape industries, many workers are left without viable employment options. 
                    Data monetization offers a new form of income, compensating for job loss due to automation.
                  </p>
                </div>
              </div>
              
              <div className="flex flex-col md:flex-row gap-6 items-start">
                <div className="flex-shrink-0 w-16 h-16 bg-gradient-to-br from-pink-600 to-red-600 rounded-full flex items-center justify-center">
                  <DollarSign className="h-8 w-8 text-white" />
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-3 text-pink-300">Income Inequality</h3>
                  <p className="text-gray-300">
                    By enabling individuals to generate income based on personal data, AuRA helps bridge the income gap. This model 
                    provides a steady income stream, regardless of employment status, supporting financial independence.
                  </p>
                </div>
              </div>
              
              <div className="flex flex-col md:flex-row gap-6 items-start">
                <div className="flex-shrink-0 w-16 h-16 bg-gradient-to-br from-pink-600 to-red-600 rounded-full flex items-center justify-center">
                  <LineChart className="h-8 w-8 text-white" />
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-3 text-pink-300">Sustainability</h3>
                  <p className="text-gray-300">
                    Unlike traditional UBI models reliant on government subsidies, data-driven UBI is self-sustaining. As long as 
                    individuals continue generating and sharing data, they have the potential to earn a base income.
                  </p>
                </div>
              </div>
            </div>
            
            <h2 className="text-2xl font-bold mt-12 mb-4">Challenges and Limitations</h2>
            
            <div className="bg-gradient-to-r from-pink-900/20 to-red-900/20 rounded-xl p-8 mb-12 relative overflow-hidden">
              <div className="absolute -top-10 -left-10 w-40 h-40 bg-pink-600/10 rounded-full blur-3xl z-0"></div>
              <div className="absolute -bottom-10 -right-10 w-40 h-40 bg-red-600/10 rounded-full blur-3xl z-0"></div>
              <div className="relative z-10 space-y-4">
                <p className="text-gray-300">
                  While data monetization offers significant potential for UBI, there are challenges to consider:
                </p>
                <ul className="list-disc pl-6 space-y-2 text-gray-300">
                  <li>
                    <strong className="text-pink-300">Data Privacy:</strong> Ensuring that personal data is securely managed and anonymized is critical to protecting user privacy.
                  </li>
                  <li>
                    <strong className="text-pink-300">Market Fluctuations:</strong> The value of personal data may fluctuate based on demand and market conditions. Developing dynamic pricing models and diversifying data sources can mitigate these risks.
                  </li>
                  <li>
                    <strong className="text-pink-300">Accessibility:</strong> Access to data monetization platforms like AuRA requires digital literacy and access to technology, which may limit participation for some populations.
                  </li>
                </ul>
              </div>
            </div>
            
            <h2 className="text-2xl font-bold mt-12 mb-4">Conclusion</h2>
            
            <p className="mb-4">
              The AuRA Comprehensive Data Monetization Plan presents a viable framework for realizing Universal Basic Income through 
              data monetization. By leveraging personal data as a valuable asset, individuals can generate substantial income, potentially 
              surpassing traditional UBI figures. As we move towards a data-driven economy, platforms like AuRA offer a self-sustaining 
              and equitable solution to the challenges of income inequality and job displacement, paving the way for a more financially 
              stable future.
            </p>
            
            <div className="bg-gradient-to-r from-pink-900/20 to-red-900/20 rounded-xl p-8 mt-12 mb-8">
              <h3 className="text-xl font-semibold mb-4 text-center text-white">Interested in AuRA's Data Monetization?</h3>
              <p className="text-gray-300 text-center mb-6">
                Discover how you can participate in this revolutionary approach to Universal Basic Income through data monetization.
              </p>
              <div className="flex justify-center">
                <motion.button
                  onClick={openContactModal}
                  className="bg-gradient-to-r from-pink-600 to-red-600 hover:from-pink-700 hover:to-red-700 text-white px-8 py-3 rounded-lg font-semibold"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Contact Us to Learn More
                </motion.button>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
      
      {/* Contact Form Modal */}
      <Modal 
        isOpen={isContactModalOpen} 
        onClose={closeContactModal}
        title="Learn More About AuRA Data Monetization"
      >
        <ContactForm />
      </Modal>
    </div>
  );
} 