import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { ThumbsUp, ThumbsDown, Timer, AlertCircle } from 'lucide-react';
import { format } from 'date-fns';
import { supabase } from '../../../../utils/supabase';
import { generateInitiatives, voteOnInitiative } from '../../../../utils/openrouter';

interface Initiative {
  id: string;
  title: string;
  description: string;
  impact: string;
  expires_at: string;
  status: 'pending' | 'approved' | 'rejected' | 'expired';
  votes?: { approve: number; reject: number };
}

interface InitiativesTabProps {
  agentId: string;
  agentRole: string;
}

export function InitiativesTab({ agentId, agentRole }: InitiativesTabProps) {
  const [initiatives, setInitiatives] = useState<Initiative[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [generating, setGenerating] = useState(false);

  useEffect(() => {
    fetchInitiatives();
  }, [agentId]);

  async function fetchInitiatives() {
    try {
      const { data, error } = await supabase
        .from('agent_initiatives')
        .select(`
          id,
          title,
          description,
          impact,
          expires_at,
          status,
          initiative_votes (vote)
        `)
        .eq('agent_id', agentId)
        .order('created_at', { ascending: false });

      if (error) throw error;

      const processedInitiatives = data.map(initiative => ({
        ...initiative,
        votes: {
          approve: initiative.initiative_votes.filter(v => v.vote === 'approve').length,
          reject: initiative.initiative_votes.filter(v => v.vote === 'reject').length
        }
      }));

      setInitiatives(processedInitiatives);
    } catch (error) {
      console.error('Error fetching initiatives:', error);
      setError('Failed to load initiatives');
    } finally {
      setLoading(false);
    }
  }

  async function handleGenerateInitiatives() {
    setGenerating(true);
    setError('');
    try {
      await generateInitiatives(agentId, agentRole);
      await fetchInitiatives();
    } catch (error) {
      console.error('Error generating initiatives:', error);
      setError('Failed to generate new initiatives');
    } finally {
      setGenerating(false);
    }
  }

  async function handleVote(initiativeId: string, vote: 'approve' | 'reject') {
    try {
      await voteOnInitiative(initiativeId, vote);
      await fetchInitiatives();
    } catch (error) {
      console.error('Error voting:', error);
      setError('Failed to submit vote');
    }
  }

  if (loading) {
    return (
      <div className="flex items-center justify-center h-64">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-[#00FFFF]" />
      </div>
    );
  }

  return (
    <div className="flex flex-col h-[calc(100vh-16rem)]">
      <div className="flex items-center justify-between mb-6">
        <h3 className="text-lg font-semibold text-white">Strategic Initiatives</h3>
        <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={handleGenerateInitiatives}
          disabled={generating}
          className="px-4 py-2 bg-[#00FFFF] text-black rounded-lg hover:bg-[#00FFFF]/90 
            transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {generating ? 'Generating...' : 'Generate New Initiatives'}
        </motion.button>
      </div>

      {error && (
        <div className="flex items-center gap-2 p-4 mb-6 bg-red-500/10 border border-red-500/20 rounded-lg text-red-400">
          <AlertCircle className="w-5 h-5" />
          <p>{error}</p>
        </div>
      )}

      <div className="flex-1 overflow-y-auto pr-2 space-y-4 custom-scrollbar">
        {initiatives.map((initiative) => (
          <motion.div
            key={initiative.id}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="p-4 bg-white/5 rounded-lg border border-white/10"
          >
            <div className="flex items-start justify-between">
              <div>
                <h4 className="text-white font-medium mb-2">{initiative.title}</h4>
                <p className="text-gray-400 text-sm mb-2">{initiative.description}</p>
                <p className="text-[#00FFFF] text-sm">Impact: {initiative.impact}</p>
              </div>
              <div className="flex items-center space-x-2">
                <Timer className="w-4 h-4 text-gray-400" />
                <span className="text-sm text-gray-400">
                  Expires {format(new Date(initiative.expires_at), 'MMM d, HH:mm')}
                </span>
              </div>
            </div>

            <div className="flex items-center justify-between mt-4 pt-4 border-t border-white/10">
              <div className="flex items-center space-x-4">
                <div className="flex items-center space-x-1">
                  <ThumbsUp className="w-4 h-4 text-green-400" />
                  <span className="text-sm text-gray-400">{initiative.votes?.approve || 0}</span>
                </div>
                <div className="flex items-center space-x-1">
                  <ThumbsDown className="w-4 h-4 text-red-400" />
                  <span className="text-sm text-gray-400">{initiative.votes?.reject || 0}</span>
                </div>
              </div>

              <div className="flex space-x-2">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => handleVote(initiative.id, 'approve')}
                  className="px-3 py-1 bg-green-500/10 text-green-400 rounded-lg hover:bg-green-500/20 
                    transition-colors text-sm"
                >
                  Approve
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => handleVote(initiative.id, 'reject')}
                  className="px-3 py-1 bg-red-500/10 text-red-400 rounded-lg hover:bg-red-500/20 
                    transition-colors text-sm"
                >
                  Reject
                </motion.button>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
}