import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Save, AlertCircle, RefreshCw } from 'lucide-react';
import { supabase } from '../../../utils/supabase';
import { calculateCompanyMetrics } from '../../../utils/metrics';

interface Metrics {
  current_valuation: string;
  active_users: string;
  revenue_growth: string;
  ai_usage: string;
}

export function MetricsManager() {
  const [metrics, setMetrics] = useState<Metrics>({
    current_valuation: '',
    active_users: '',
    revenue_growth: '',
    ai_usage: ''
  });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchMetrics();
  }, []);

  async function fetchMetrics() {
    try {
      const { data, error } = await supabase
        .from('company_metrics')
        .select('*')
        .single();

      if (error) throw error;
      if (data) {
        setMetrics(data);
      }
    } catch (error) {
      console.error('Error fetching metrics:', error);
      setError('Failed to load metrics');
    }
  }

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      const metricsData = {
        id: '00000000-0000-0000-0000-000000000000',
        ...metrics
      };
      
      const { error } = await supabase
        .from('company_metrics')
        .upsert([metricsData]);

      if (error) throw error;
      
      // Refresh metrics after update
      await fetchMetrics();
    } catch (error) {
      console.error('Error updating metrics:', error);
      setError('Failed to update metrics');
    } finally {
      setIsLoading(false);
    }
  }

  async function handleRefreshMetrics() {
    setIsLoading(true);
    setError('');
    try {
      await calculateCompanyMetrics();
      await fetchMetrics();
    } catch (error) {
      console.error('Error refreshing metrics:', error);
      setError('Failed to refresh metrics');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className="bg-[#121214] rounded-xl p-6 border border-white/10">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold text-white">Financial Metrics</h2>
        <motion.button
          onClick={handleRefreshMetrics}
          className="flex items-center gap-2 px-4 py-2 bg-[#00FFFF]/10 text-[#00FFFF] rounded-lg
            hover:bg-[#00FFFF]/20 transition-colors duration-200 disabled:opacity-50"
          whileHover={{ scale: isLoading ? 1 : 1.02 }}
          whileTap={{ scale: isLoading ? 1 : 0.98 }}
          disabled={isLoading}
        >
          <RefreshCw className={`w-4 h-4 ${isLoading ? 'animate-spin' : ''}`} />
          Refresh Metrics
        </motion.button>
      </div>

      {error && (
        <div className="flex items-center gap-2 p-4 mb-6 bg-red-500/10 border border-red-500/20 rounded-lg text-red-400">
          <AlertCircle className="w-5 h-5" />
          <p>{error}</p>
        </div>
      )}

      <form onSubmit={handleSubmit} className="grid md:grid-cols-2 gap-6">
        <div>
          <label className="block text-sm font-medium text-gray-300 mb-2">
            Current Valuation
          </label>
          <input
            type="text"
            value={metrics.current_valuation}
            onChange={(e) => setMetrics({ ...metrics, current_valuation: e.target.value })}
            className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-3 text-white"
            placeholder="e.g., $2.8B"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-300 mb-2">
            Active Users
          </label>
          <input
            type="text"
            value={metrics.active_users}
            onChange={(e) => setMetrics({ ...metrics, active_users: e.target.value })}
            className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-3 text-white"
            placeholder="e.g., 2.4M"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-300 mb-2">
            Revenue Growth
          </label>
          <input
            type="text"
            value={metrics.revenue_growth}
            onChange={(e) => setMetrics({ ...metrics, revenue_growth: e.target.value })}
            className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-3 text-white"
            placeholder="e.g., 156%"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-300 mb-2">
            AI Model Usage
          </label>
          <input
            type="text"
            value={metrics.ai_usage}
            onChange={(e) => setMetrics({ ...metrics, ai_usage: e.target.value })}
            className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-3 text-white"
            placeholder="e.g., 890K"
          />
        </div>

        <div className="md:col-span-2">
          <motion.button
            type="submit"
            className="w-full bg-[#FF3366] text-white font-semibold py-3 px-4 rounded-lg 
              hover:bg-[#FF3366]/90 transition-colors duration-200 disabled:opacity-50"
            whileHover={{ scale: isLoading ? 1 : 1.02 }}
            whileTap={{ scale: isLoading ? 1 : 0.98 }}
            disabled={isLoading}
          >
            <span className="flex items-center justify-center gap-2">
              <Save className="w-4 h-4" />
              {isLoading ? 'Saving...' : 'Save Metrics'}
            </span>
          </motion.button>
        </div>
      </form>
    </div>
  );
}