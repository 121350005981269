import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { ArrowLeft, ExternalLink, Shield, Zap, Users, Brain, Bot, Globe, Sparkles } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function EssenceServicesPage() {
  const [hoveredCard, setHoveredCard] = useState<string | null>(null);

  const services = [
    {
      id: 'selfphone',
      title: 'SELF Phone',
      description: 'Your digital identity and data sovereignty in your pocket.',
      icon: <Shield className="w-6 h-6" />,
      gradient: 'from-blue-500 to-indigo-500',
      link: '/self-phone'
    },
    {
      id: 'flash',
      title: 'Flash Kit',
      description: 'Tools and SDKs for building Essence-powered applications.',
      icon: <Zap className="w-6 h-6" />,
      gradient: 'from-indigo-500 to-purple-500',
      link: '/flash'
    },
    {
      id: 'dao',
      title: 'SELF DAO',
      description: 'Decentralized governance for the Signal Economy.',
      icon: <Users className="w-6 h-6" />,
      gradient: 'from-purple-500 to-pink-500',
      link: '/dao'
    },
    {
      id: 'rolemodel',
      title: 'Role Model AI',
      description: 'Personalized AI training with your unique data.',
      icon: <Brain className="w-6 h-6" />,
      gradient: 'from-pink-500 to-rose-500',
      link: '/role-model-ai'
    },
    {
      id: 'harmony',
      title: 'Harmony AI',
      description: 'Collaborative AI development platform.',
      icon: <Bot className="w-6 h-6" />,
      gradient: 'from-rose-500 to-orange-500',
      link: '/harmony-ai'
    }
  ];

  return (
    <div className="min-h-screen bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-indigo-900/20 via-black to-black text-white py-20 relative overflow-hidden">
      {/* Background Effects */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute top-0 left-1/4 w-96 h-96 bg-indigo-500/10 rounded-full blur-[120px] animate-pulse" />
        <div className="absolute bottom-0 right-1/4 w-96 h-96 bg-purple-500/10 rounded-full blur-[120px] animate-pulse" />
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        {/* Back Button */}
        <Link to="/flash/signal-calculator">
          <motion.div
            className="inline-flex items-center text-indigo-400 mb-12 hover:text-indigo-300 transition-colors bg-black/20 px-4 py-2 rounded-full backdrop-blur-sm border border-indigo-500/20"
            whileHover={{ x: -5 }}
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            <span>Back to Signal Calculator</span>
          </motion.div>
        </Link>

        {/* Header */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-20"
        >
          <motion.div 
            className="inline-flex items-center justify-center w-20 h-20 rounded-3xl bg-gradient-to-br from-indigo-400 to-purple-500 p-4 mb-8"
            animate={{ rotate: [0, 10, -10, 0] }}
            transition={{ duration: 5, repeat: Infinity }}
          >
            <Sparkles className="w-12 h-12 text-white" />
          </motion.div>
          <h1 className="text-5xl font-bold mb-6 bg-gradient-to-r from-indigo-400 via-purple-400 to-indigo-400 text-transparent bg-clip-text">
            Essence-Powered Services
          </h1>
          <p className="text-2xl text-gray-300 max-w-3xl mx-auto">
            Explore the growing ecosystem of services powered by the Signal Economy
          </p>
        </motion.div>

        {/* Services Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service) => (
            <Link key={service.id} to={service.link}>
              <motion.div
                className={`group relative h-full bg-black/40 backdrop-blur-xl rounded-3xl p-8 border border-indigo-500/20 overflow-hidden transition-all duration-500
                  ${hoveredCard === service.id ? 'border-indigo-400/50 shadow-lg shadow-indigo-500/20' : 'border-indigo-500/20'}
                `}
                onMouseEnter={() => setHoveredCard(service.id)}
                onMouseLeave={() => setHoveredCard(null)}
                whileHover={{ y: -5, scale: 1.02 }}
              >
                {/* Gradient Overlay */}
                <div className={`absolute inset-0 bg-gradient-to-br ${service.gradient} opacity-0 group-hover:opacity-10 transition-opacity duration-500`} />

                {/* Content */}
                <div className="relative z-10">
                  <motion.div 
                    className={`inline-flex p-3 rounded-2xl bg-gradient-to-br ${service.gradient} bg-opacity-20 mb-6`}
                    whileHover={{ rotate: [0, -10, 10, 0] }}
                    transition={{ duration: 0.5 }}
                  >
                    {service.icon}
                  </motion.div>
                  
                  <h2 className="text-2xl font-semibold mb-4 bg-gradient-to-r from-white to-gray-300 text-transparent bg-clip-text">
                    {service.title}
                  </h2>
                  
                  <p className="text-gray-400 mb-6 group-hover:text-gray-300 transition-colors">
                    {service.description}
                  </p>

                  <motion.div
                    className="inline-flex items-center text-indigo-400 group-hover:text-indigo-300"
                    animate={hoveredCard === service.id ? { x: 5 } : { x: 0 }}
                  >
                    Learn More <ExternalLink className="w-4 h-4 ml-2" />
                  </motion.div>
                </div>
              </motion.div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
} 