import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import ServiceToConsciousSection from './components/ServiceToConsciousSection';
import SignalProtocolSection from './components/SignalProtocolSection';
import WhyConsciousAIMatters from './components/WhyConsciousAIMatters';
import About from './components/About';
import Technologies from './components/Technologies';
import ProductsSection from './components/Products/ProductsSection';
import CaseStudiesSection from './components/CaseStudies/CaseStudiesSection';
import FooterCTA from './components/FooterCTA';
import InvestorLogin from './components/InvestorRelations/InvestorLogin';
import { LoadingScreen } from './components/Loading/LoadingScreen';
import RoleModelAIPage from './components/Products/RoleModelAI/RoleModelAIPage';
import SignalPage from './components/Signal/SignalPage';
import HarmonizePage from './components/Harmonize/HarmonizePage';
import TwitchCaseStudy from './components/CaseStudies/TwitchCaseStudy';
import ConsciousnessExplorationCaseStudy from './components/CaseStudies/ConsciousnessExplorationCaseStudy';
import SportsCoachingCaseStudy from './components/CaseStudies/SportsCoachingCaseStudy';
import UBICaseStudy from './components/CaseStudies/UBICaseStudy';
import CultivateCoinCaseStudy from './components/CaseStudies/CultivateCoinCaseStudy';
import ReflectCaseStudy from './components/CaseStudies/ReflectCaseStudy';
import DAOAnnouncementPage from './components/DAO/DAOAnnouncementPage';
import ConstitutionPage from './components/DAO/ConstitutionPage';
import VotingPage from './components/DAO/VotingPage';
import BloodPage from './components/Products/Blood/BloodPage';
import CerebralOSPage from './components/Products/CerebralOS/CerebralOSPage';
import InnerVoicePage from './components/Products/InnerVoice/InnerVoicePage';
import AuraResonantLayerPage from './components/Products/AuraResonantLayer/AuraResonantLayerPage';
import Modal from './components/shared/Modal';
import ContactForm from './components/forms/ContactForm';
import PartnerForm from './components/forms/PartnerForm';
import HarmonyAIPage from './components/AI/HarmonyAIPage';
import SelfPhonePage from './components/SelfPhone/SelfPhonePage';
import FlashKitPage from './components/SelfPhone/FlashKitPage';
import OnboardingPage from './components/Flash/OnboardingPage';
import FlashDashboard from './components/Flash/FlashDashboard';
import TechGuidePage from './components/Flash/TechGuidePage';
import { FlashProvider } from './contexts/FlashContext';
import { StripeProvider } from './contexts/StripeContext';
import { ApolloProvider } from './contexts/ApolloContext';
import ScrollToTop from './components/utils/ScrollToTop';
import NotFound from './components/NotFound';
import WhitePaperPage from './components/SelfPhone/WhitePaperPage';
import PrivacyPolicyPage from './components/SelfPhone/PrivacyPolicyPage';
import TermsPage from './components/SelfPhone/TermsPage';
import SignalEconomyPage from './components/SelfPhone/SignalEconomyPage';
import SignalValueCalculator from './components/SignalValueCalculator';
import SignalCalculatorPage from './components/SignalCalculator/SignalCalculatorPage';
import WhatIsThisPage from './components/SignalCalculator/WhatIsThisPage';
import WhyDataCanReplaceMoneyPage from './components/SignalCalculator/WhyDataCanReplaceMoneyPage';
import WhyKilobytePricelessPage from './components/SignalCalculator/WhyKilobytePricelessPage';
import HowSignalEconomyWorksPage from './components/SignalCalculator/HowSignalEconomyWorksPage';
import WhyEssenceDollarsPage from './components/SignalCalculator/WhyEssenceDollarsPage';
import ForAICompaniesPage from './components/SignalCalculator/ForAICompaniesPage';
import EssenceServicesPage from './components/Services/EssenceServicesPage';

interface HomePageProps {
  onInvestorClick: () => void;
  onProductClick: (product: string) => void;
  onContactClick: () => void;
  onPartnerClick: () => void;
}

function HomePage({ onInvestorClick, onProductClick, onContactClick, onPartnerClick }: HomePageProps) {
  return (
    <>
      <LoadingScreen />
      <div className="bg-black text-white">
        <Navbar 
          onInvestorClick={onInvestorClick} 
          onContactClick={onContactClick}
          onPartnerClick={onPartnerClick}
        />
        <Hero />
        <ServiceToConsciousSection />
        <SignalProtocolSection />
        <WhyConsciousAIMatters />
        <About />
        <Technologies />
        <ProductsSection onProductClick={onProductClick} />
        <CaseStudiesSection />
        <FooterCTA 
          onContactClick={onContactClick}
          onPartnerClick={onPartnerClick}
        />
        <footer className="py-8 text-center text-gray-400 border-t border-gray-800">
          <div className="flex flex-col md:flex-row justify-center items-center gap-4 mb-4">
            <h3 className="text-xl font-medium text-purple-300">SELF Labs Inc</h3>
            <span className="hidden md:block text-gray-500">•</span>
            <h3 className="text-xl font-medium text-purple-300">SELF Labs DAO L.L.C.</h3>
          </div>
          <p className="mt-2 text-sm">&copy; {new Date().getFullYear()} SELF Labs Inc & SELF Labs DAO L.L.C. All rights reserved.</p>
        </footer>
      </div>
    </>
  );
}

function App() {
  const [showInvestorLogin, setShowInvestorLogin] = useState(false);
  const [showContactModal, setShowContactModal] = useState(false);
  const [showPartnerModal, setShowPartnerModal] = useState(false);
  const [currentProduct, setCurrentProduct] = useState<string | null>(null);

  return (
    <HelmetProvider>
      <Router>
        <ScrollToTop />
        <StripeProvider>
          <ApolloProvider>
            <FlashProvider>
              <div className="min-h-screen bg-gradient-to-b from-[#0A0A0B] via-black to-[#0A0A0B] text-white">
                <Navbar 
                  onInvestorClick={() => setShowInvestorLogin(true)}
                  onContactClick={() => setShowContactModal(true)}
                  onPartnerClick={() => setShowPartnerModal(true)}
                />
                
                {/* Modals - Available across all routes */}
                <Modal isOpen={showContactModal} onClose={() => setShowContactModal(false)} title="Contact Us">
                  <ContactForm />
                </Modal>
                <Modal isOpen={showPartnerModal} onClose={() => setShowPartnerModal(false)} title="Partner With Us">
                  <PartnerForm />
                </Modal>
                {showInvestorLogin && <InvestorLogin onBack={() => setShowInvestorLogin(false)} />}

                <Routes>
                  <Route 
                    path="/" 
                    element={
                      <HomePage 
                        onInvestorClick={() => setShowInvestorLogin(true)}
                        onProductClick={(product) => setCurrentProduct(product)}
                        onContactClick={() => setShowContactModal(true)}
                        onPartnerClick={() => setShowPartnerModal(true)}
                      />
                    } 
                  />
                  
                  {/* Main Section Routes */}
                  <Route path="/products" element={<ProductsSection onProductClick={(product) => setCurrentProduct(product)} />} />
                  <Route path="/case-studies" element={<CaseStudiesSection />} />
                  <Route path="/dao" element={<DAOAnnouncementPage />} />
                  <Route path="/about" element={<About />} />
                  <Route path="/technologies" element={<Technologies />} />
                  
                  {/* Products Routes */}
                  <Route path="/role-model-ai" element={<RoleModelAIPage />} />
                  <Route path="/signal" element={<SignalPage />} />
                  <Route path="/harmonize" element={<HarmonizePage />} />
                  <Route path="/blood" element={<BloodPage />} />
                  <Route path="/cerebral-os" element={<CerebralOSPage />} />
                  <Route path="/inner-voice" element={<InnerVoicePage />} />
                  <Route path="/aura-resonant-layer" element={<AuraResonantLayerPage />} />
                  <Route path="/harmony-ai" element={<HarmonyAIPage />} />
                  
                  {/* SELF Phone Routes */}
                  <Route path="/self-phone" element={<SelfPhonePage />} />
                  <Route path="/flash" element={<FlashKitPage />} />
                  <Route path="/self-phone/white-paper" element={<WhitePaperPage />} />
                  <Route path="/self-phone/privacy-policy" element={<PrivacyPolicyPage />} />
                  <Route path="/self-phone/terms" element={<TermsPage />} />
                  <Route path="/flash/signal-economy" element={<SignalEconomyPage />} />
                  <Route path="/flash/signal-calculator" element={<SignalCalculatorPage />} />
                  <Route path="/flash/signal-calculator/what-is-this" element={<WhatIsThisPage />} />
                  <Route path="/flash/signal-calculator/why-data-can-replace-money" element={<WhyDataCanReplaceMoneyPage />} />
                  <Route path="/flash/signal-calculator/why-kilobyte-priceless" element={<WhyKilobytePricelessPage />} />
                  <Route path="/flash/signal-calculator/how-signal-economy-works" element={<HowSignalEconomyWorksPage />} />
                  <Route path="/flash/signal-calculator/why-essence-dollars" element={<WhyEssenceDollarsPage />} />
                  <Route path="/flash/signal-calculator/for-ai-companies" element={<ForAICompaniesPage />} />
                  <Route path="/flash/services" element={<EssenceServicesPage />} />
                  <Route path="/flash/onboarding" element={<OnboardingPage />} />
                  <Route path="/flash/dashboard" element={<FlashDashboard />} />
                  <Route path="/flash/docs/tech-guide" element={<TechGuidePage />} />
                  
                  {/* Case Studies Routes */}
                  <Route path="/case-studies/twitch" element={<TwitchCaseStudy />} />
                  <Route path="/case-studies/consciousness" element={<ConsciousnessExplorationCaseStudy />} />
                  <Route path="/case-studies/sports" element={<SportsCoachingCaseStudy />} />
                  <Route path="/case-studies/ubi" element={<UBICaseStudy />} />
                  <Route path="/case-studies/cultivate" element={<CultivateCoinCaseStudy />} />
                  <Route path="/case-studies/reflect" element={<ReflectCaseStudy />} />
                  
                  {/* DAO Routes */}
                  <Route path="/dao/announcement" element={<DAOAnnouncementPage />} />
                  <Route path="/dao/constitution" element={<ConstitutionPage />} />
                  <Route path="/dao/voting" element={<VotingPage />} />

                  {/* Catch-all route for 404 */}
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </div>
            </FlashProvider>
          </ApolloProvider>
        </StripeProvider>
      </Router>
    </HelmetProvider>
  );
}

export default App;