import React, { createContext, useContext, useState, useEffect } from 'react';
import { FlashUser, FlashDevice, FlashStatus } from '../types/flash';

interface FlashContextType {
  user: FlashUser | null;
  device: FlashDevice | null;
  flashStatus: FlashStatus;
  isLoading: boolean;
  error: string | null;
  setUser: (user: FlashUser | null) => void;
  setDevice: (device: FlashDevice | null) => void;
  setFlashStatus: (status: FlashStatus) => void;
  startFlash: () => Promise<void>;
  resetFlash: () => Promise<void>;
  restoreDevice: () => Promise<void>;
}

const FlashContext = createContext<FlashContextType | undefined>(undefined);

export function FlashProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState<FlashUser | null>(null);
  const [device, setDevice] = useState<FlashDevice | null>(null);
  const [flashStatus, setFlashStatus] = useState<FlashStatus>('not_started');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // Load user data on mount
  useEffect(() => {
    const loadUserData = async () => {
      try {
        setIsLoading(true);
        // TODO: Implement API call to load user data
        // const response = await fetch('/api/flash/user');
        // const userData = await response.json();
        // setUser(userData);
      } catch (err) {
        setError('Failed to load user data');
      } finally {
        setIsLoading(false);
      }
    };

    loadUserData();
  }, []);

  const startFlash = async () => {
    try {
      setIsLoading(true);
      setError(null);
      setFlashStatus('preparing');

      // Simulate flash process
      const steps: FlashStatus[] = ['preparing', 'installing', 'setting_up_ai', 'activating_shield', 'finalizing'];
      
      for (const step of steps) {
        setFlashStatus(step);
        await new Promise(resolve => setTimeout(resolve, 3000));
      }

      setFlashStatus('complete');
      
      // TODO: Implement actual flash process
      // const response = await fetch('/api/flash/start', {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json' },
      //   body: JSON.stringify({ deviceId: device?.id }),
      // });
      
      // if (!response.ok) throw new Error('Flash process failed');
    } catch (err) {
      setError('Failed to start flash process');
      setFlashStatus('error');
    } finally {
      setIsLoading(false);
    }
  };

  const resetFlash = async () => {
    try {
      setIsLoading(true);
      setError(null);
      setFlashStatus('not_started');
      
      // TODO: Implement actual reset process
      // await fetch('/api/flash/reset', {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json' },
      //   body: JSON.stringify({ deviceId: device?.id }),
      // });
    } catch (err) {
      setError('Failed to reset flash process');
    } finally {
      setIsLoading(false);
    }
  };

  const restoreDevice = async () => {
    try {
      setIsLoading(true);
      setError(null);
      
      // TODO: Implement actual restore process
      // await fetch('/api/flash/restore', {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json' },
      //   body: JSON.stringify({ deviceId: device?.id }),
      // });
      
      if (device) {
        setDevice({
          ...device,
          status: 'restored',
        });
      }
    } catch (err) {
      setError('Failed to restore device');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <FlashContext.Provider
      value={{
        user,
        device,
        flashStatus,
        isLoading,
        error,
        setUser,
        setDevice,
        setFlashStatus,
        startFlash,
        resetFlash,
        restoreDevice,
      }}
    >
      {children}
    </FlashContext.Provider>
  );
}

export function useFlash() {
  const context = useContext(FlashContext);
  if (context === undefined) {
    throw new Error('useFlash must be used within a FlashProvider');
  }
  return context;
} 