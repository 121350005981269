import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { ArrowLeft, ExternalLink, Mic, Wand2, BrainCircuit, Music, Heart, Blocks, Sparkles, Check } from 'lucide-react';
import { Link } from 'react-router-dom';
import Modal from '../../shared/Modal';
import ContactForm from '../../forms/ContactForm';
import { FrequencyCard } from './FrequencyCard';
import { PricingCard } from './PricingCard';
import { FaqItem } from './FaqItem';

export default function InnerVoicePage() {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [activeFaq, setActiveFaq] = useState<number | null>(null);

  const openContactModal = () => setIsContactModalOpen(true);
  const closeContactModal = () => setIsContactModalOpen(false);
  
  const visitInnerVoice = () => {
    window.open('https://tryinnervoice.com', '_blank');
  };
  
  const toggleFaq = (index: number) => {
    if (activeFaq === index) {
      setActiveFaq(null);
    } else {
      setActiveFaq(index);
    }
  };
  
  const frequencies = [
    {
      hz: "396 Hz",
      name: "Liberation",
      description: "Release fear and guilt, ground yourself",
      color: "from-red-600 to-red-800"
    },
    {
      hz: "417 Hz",
      name: "Change",
      description: "Facilitate change and transformation",
      color: "from-orange-600 to-orange-800"
    },
    {
      hz: "528 Hz",
      name: "Transformation",
      description: "DNA repair and transformation",
      color: "from-yellow-600 to-yellow-800"
    },
    {
      hz: "639 Hz",
      name: "Connection",
      description: "Connect with spiritual family",
      color: "from-green-600 to-green-800"
    },
    {
      hz: "741 Hz",
      name: "Expression",
      description: "Solve problems, express yourself",
      color: "from-blue-600 to-blue-800"
    },
    {
      hz: "852 Hz",
      name: "Intuition",
      description: "Return to spiritual order",
      color: "from-purple-600 to-purple-800"
    }
  ];
  
  const pricingPlans = [
    {
      name: "Free",
      price: "$0",
      period: "Free forever",
      features: [
        "3 meditation sessions/month",
        "Basic voice training (one-time)",
        "Basic voice quality",
        "Basic progress tracking"
      ],
      cta: "Join Now",
      isPopular: false
    },
    {
      name: "Pro",
      price: "$14.99",
      period: "/mo",
      features: [
        "Unlimited meditation sessions",
        "Unlimited high-quality voice training",
        "Premium voice quality",
        "Full voice customization (tone, pitch)",
        "Re-train voice anytime",
        "Advanced progress tracking"
      ],
      cta: "Subscribe to Pro",
      isPopular: true
    },
    {
      name: "Pro Annual",
      price: "$149.99",
      period: "/year",
      features: [
        "Everything in monthly Pro",
        "2 months free",
        "Priority support",
        "Early access to new features"
      ],
      subtext: "Save 17% yearly",
      cta: "Subscribe to Annual Pro",
      isPopular: false
    }
  ];
  
  const faqs = [
    {
      question: "What is Inner Voice?",
      answer: "Inner Voice is a personalized AI meditation guide that uses your own voice to create intimate and meaningful meditation experiences. Our technology allows you to become your own guide, enhancing your mindfulness practice with authentic self-guided meditation enhanced by AI."
    },
    {
      question: "How does the voice training work?",
      answer: "Voice training is simple and takes just 40 seconds. You'll read a set of carefully crafted 'I Am' affirmations, which our AI then analyzes to capture your voice's unique essence. The AI enhances your voice while maintaining its authenticity, creating the perfect meditation guide that sounds just like you."
    },
    {
      question: "Is my voice data secure?",
      answer: "Absolutely. We take your privacy seriously. Your voice data is encrypted and stored securely. We never share your voice data with third parties, and you can request deletion of your voice data at any time through your account settings."
    },
    {
      question: "What's included in the free plan?",
      answer: "The free plan gives you 3 meditation sessions per month, basic voice training (one-time), basic voice quality, and basic progress tracking. It's a great way to experience the power of Inner Voice before upgrading to our Pro plans for unlimited sessions and advanced features."
    }
  ];
  
  return (
    <div className="min-h-screen bg-black text-white">
      {/* Hero Section */}
      <section className="relative overflow-hidden py-20">
        <div className="absolute inset-0 bg-gradient-to-b from-emerald-900/20 to-black"></div>
        <div className="absolute inset-0 opacity-30" style={{
          backgroundImage: 'radial-gradient(circle at center, rgba(16, 185, 129, 0.1) 0%, transparent 70%)',
          backgroundSize: '20px 20px'
        }}></div>
        
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative z-10">
          <div className="mb-8">
            <Link to="/">
              <motion.button
                className="flex items-center text-gray-400 hover:text-white"
                whileHover={{ x: -5 }}
                transition={{ duration: 0.2 }}
              >
                <ArrowLeft className="w-5 h-5 mr-2" />
                Back to Home
              </motion.button>
            </Link>
          </div>
          
          <div className="flex flex-col md:flex-row gap-12 items-center">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.6 }}
              className="flex-1"
            >
              <div className="flex items-center gap-3 mb-4">
                <Music className="h-8 w-8 text-emerald-400" />
                <h1 className="text-3xl sm:text-4xl md:text-5xl font-bold bg-gradient-to-r from-emerald-400 to-teal-500 text-transparent bg-clip-text">
                  Inner Voice
                </h1>
              </div>
              
              <h2 className="text-4xl md:text-5xl font-bold mb-6 text-white">
                Your Personal AI Meditation Guide, In Your Voice
              </h2>
              
              <p className="text-xl text-gray-300 mb-8 max-w-2xl">
                Create personalized meditation experiences with AI-powered guidance, using your own voice for a more intimate and meaningful practice.
              </p>
              
              <div className="flex flex-wrap gap-4">
                <motion.button
                  onClick={visitInnerVoice}
                  className="px-8 py-4 bg-gradient-to-r from-emerald-600 to-teal-600 rounded-lg text-white font-semibold flex items-center gap-2"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Try Inner Voice For FREE
                  <ExternalLink className="w-5 h-5" />
                </motion.button>
                
                <motion.button
                  onClick={openContactModal}
                  className="px-8 py-4 bg-emerald-900/50 border border-emerald-600/30 rounded-lg text-white font-semibold"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Contact Us
                </motion.button>
              </div>
            </motion.div>
            
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.6, delay: 0.2 }}
              className="flex-1 relative"
            >
              <div className="flex flex-col items-center">
                <motion.div
                  initial={{ scale: 0.8, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ delay: 0.2, duration: 0.8 }}
                  className="relative"
                >
                  <div className="absolute inset-0 rounded-full blur-3xl bg-gradient-to-br from-emerald-400/20 to-teal-500/20"></div>
                  <div className="relative">
                    <div className="w-64 h-64 bg-gradient-to-br from-emerald-500 to-teal-600 rounded-full flex items-center justify-center">
                      <div className="w-56 h-56 bg-black/90 rounded-full flex items-center justify-center">
                        <Wand2 className="h-24 w-24 text-emerald-400" />
                      </div>
                    </div>
                  </div>
                </motion.div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>
      
      {/* Process Section */}
      <section className="py-20 bg-gradient-to-b from-black to-emerald-950/20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-3xl md:text-4xl font-bold mb-6 bg-gradient-to-r from-emerald-400 to-teal-500 text-transparent bg-clip-text">
              Voice AI Technology
            </h2>
            <p className="text-2xl text-white max-w-2xl mx-auto">
              Become Your Own Guide
            </p>
            <p className="text-lg text-gray-300 max-w-3xl mx-auto mt-4">
              Transform your meditation practice with AI-enhanced guidance in your own voice, 
              creating a uniquely personal and powerful mindfulness experience.
            </p>
          </motion.div>
          
          <div className="grid md:grid-cols-3 gap-8">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="bg-gradient-to-b from-emerald-900/20 to-emerald-900/5 rounded-xl border border-emerald-500/20 p-8 relative overflow-hidden"
            >
              <div className="absolute -top-10 -right-10 w-40 h-40 bg-emerald-600/10 rounded-full blur-3xl"></div>
              <div className="relative">
                <div className="w-12 h-12 bg-emerald-900/70 rounded-full flex items-center justify-center mb-6">
                  <span className="text-emerald-400 text-xl font-bold">1</span>
                </div>
                
                <h3 className="text-xl font-semibold text-white mb-3">Record Your Voice</h3>
                <p className="text-gray-300">
                  Take 40 seconds to read our carefully crafted 'I Am' affirmations. 
                  These powerful statements will be used to capture your voice's unique essence.
                </p>
                
                <div className="mt-6 bg-emerald-900/30 p-4 rounded-lg">
                  <div className="flex gap-3">
                    <Mic className="w-5 h-5 text-emerald-400 flex-shrink-0 mt-1" />
                    <p className="text-sm text-emerald-200 italic">
                      "I am calm. I am centered. I am peaceful. I am connected to the present moment..."
                    </p>
                  </div>
                </div>
              </div>
            </motion.div>
            
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.1 }}
              className="bg-gradient-to-b from-teal-900/20 to-teal-900/5 rounded-xl border border-teal-500/20 p-8 relative overflow-hidden"
            >
              <div className="absolute -top-10 -right-10 w-40 h-40 bg-teal-600/10 rounded-full blur-3xl"></div>
              <div className="relative">
                <div className="w-12 h-12 bg-teal-900/70 rounded-full flex items-center justify-center mb-6">
                  <span className="text-teal-400 text-xl font-bold">2</span>
                </div>
                
                <h3 className="text-xl font-semibold text-white mb-3">AI Enhancement</h3>
                <p className="text-gray-300">
                  Our advanced AI analyzes and enhances your voice, maintaining its authenticity 
                  while optimizing it for meditation guidance.
                </p>
                
                <div className="mt-6 bg-teal-900/30 p-4 rounded-lg">
                  <div className="flex gap-3">
                    <BrainCircuit className="w-5 h-5 text-teal-400 flex-shrink-0 mt-1" />
                    <p className="text-sm text-teal-200 italic">
                      "Our neural network preserves your unique vocal characteristics while enhancing clarity and flow."
                    </p>
                  </div>
                </div>
              </div>
            </motion.div>
            
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
              className="bg-gradient-to-b from-cyan-900/20 to-cyan-900/5 rounded-xl border border-cyan-500/20 p-8 relative overflow-hidden"
            >
              <div className="absolute -top-10 -right-10 w-40 h-40 bg-cyan-600/10 rounded-full blur-3xl"></div>
              <div className="relative">
                <div className="w-12 h-12 bg-cyan-900/70 rounded-full flex items-center justify-center mb-6">
                  <span className="text-cyan-400 text-xl font-bold">3</span>
                </div>
                
                <h3 className="text-xl font-semibold text-white mb-3">Personal Guide</h3>
                <p className="text-gray-300">
                  Experience meditation guided by your own voice, creating a deeply personal 
                  and intimate practice that resonates with your inner self.
                </p>
                
                <div className="mt-6 bg-cyan-900/30 p-4 rounded-lg">
                  <div className="flex gap-3">
                    <Heart className="w-5 h-5 text-cyan-400 flex-shrink-0 mt-1" />
                    <p className="text-sm text-cyan-200 italic">
                      "Hearing your own voice guide you creates powerful self-connection and deeper meditative states."
                    </p>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>
      
      {/* Frequencies Section */}
      <section className="py-20 bg-gradient-to-b from-emerald-950/20 to-black">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-3xl md:text-4xl font-bold mb-6 bg-gradient-to-r from-emerald-400 to-teal-500 text-transparent bg-clip-text">
              Solfeggio Frequencies
            </h2>
            <p className="text-2xl text-white max-w-2xl mx-auto">
              Discover Sacred Frequencies
            </p>
            <p className="text-lg text-gray-300 max-w-3xl mx-auto mt-4">
              Experience the healing power of Solfeggio frequencies, ancient tones that can help restore balance to mind, body, and spirit.
            </p>
          </motion.div>
          
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-6 gap-4">
            {frequencies.map((freq, index) => (
              <FrequencyCard
                key={index}
                hz={freq.hz}
                name={freq.name}
                description={freq.description}
                color={freq.color}
              />
            ))}
          </div>
          
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="mt-20 text-center"
          >
            <h3 className="text-2xl font-bold text-white mb-4">Experience Deep Meditation</h3>
            <p className="text-gray-300 max-w-3xl mx-auto mb-8">
              Immerse yourself in a transformative journey with our AI-guided meditation experience, 
              enhanced by sacred Solfeggio frequencies and your own voice.
            </p>
            
            <motion.button
              onClick={visitInnerVoice}
              className="px-8 py-4 bg-gradient-to-r from-emerald-600 to-teal-600 rounded-lg text-white font-semibold"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Start Your Journey
            </motion.button>
          </motion.div>
        </div>
      </section>
      
      {/* Features Section */}
      <section className="py-20 bg-gradient-to-b from-black to-emerald-950/20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-3xl md:text-4xl font-bold mb-6 bg-gradient-to-r from-emerald-400 to-teal-500 text-transparent bg-clip-text">
              Discover Your Inner Voice
            </h2>
          </motion.div>
          
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="bg-emerald-900/10 border border-emerald-500/20 rounded-xl p-6"
            >
              <div className="h-12 w-12 bg-emerald-900/50 rounded-full flex items-center justify-center mb-4">
                <Mic className="h-6 w-6 text-emerald-400" />
              </div>
              <h3 className="text-xl font-semibold text-white mb-3">Personalized Guidance</h3>
              <p className="text-gray-400">
                Experience meditation guided by your own voice, creating a deeply personal practice.
              </p>
            </motion.div>
            
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.1 }}
              className="bg-emerald-900/10 border border-emerald-500/20 rounded-xl p-6"
            >
              <div className="h-12 w-12 bg-emerald-900/50 rounded-full flex items-center justify-center mb-4">
                <Music className="h-6 w-6 text-emerald-400" />
              </div>
              <h3 className="text-xl font-semibold text-white mb-3">Sacred Frequencies</h3>
              <p className="text-gray-400">
                Enhance your practice with carefully selected Solfeggio frequencies for optimal healing.
              </p>
            </motion.div>
            
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
              className="bg-emerald-900/10 border border-emerald-500/20 rounded-xl p-6"
            >
              <div className="h-12 w-12 bg-emerald-900/50 rounded-full flex items-center justify-center mb-4">
                <BrainCircuit className="h-6 w-6 text-emerald-400" />
              </div>
              <h3 className="text-xl font-semibold text-white mb-3">AI Enhancement</h3>
              <p className="text-gray-400">
                Our AI technology maintains the authenticity of your voice while optimizing it for meditation.
              </p>
            </motion.div>
            
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.3 }}
              className="bg-emerald-900/10 border border-emerald-500/20 rounded-xl p-6"
            >
              <div className="h-12 w-12 bg-emerald-900/50 rounded-full flex items-center justify-center mb-4">
                <Sparkles className="h-6 w-6 text-emerald-400" />
              </div>
              <h3 className="text-xl font-semibold text-white mb-3">Deep Transformation</h3>
              <p className="text-gray-400">
                Access profound states of consciousness and facilitate lasting positive change.
              </p>
            </motion.div>
          </div>
        </div>
      </section>
      
      {/* Pricing Section */}
      <section className="py-20 bg-gradient-to-b from-emerald-950/20 to-black">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-3xl md:text-4xl font-bold mb-6 bg-gradient-to-r from-emerald-400 to-teal-500 text-transparent bg-clip-text">
              Choose Your Journey
            </h2>
            <p className="text-lg text-gray-300 max-w-3xl mx-auto">
              Select the plan that best fits your meditation practice and spiritual growth goals.
            </p>
          </motion.div>
          
          <div className="grid md:grid-cols-3 gap-8">
            {pricingPlans.map((plan, index) => (
              <PricingCard
                key={index}
                name={plan.name}
                price={plan.price}
                period={plan.period}
                features={plan.features}
                cta={plan.cta}
                isPopular={plan.isPopular}
                subtext={plan.subtext}
                onCtaClick={visitInnerVoice}
              />
            ))}
          </div>
        </div>
      </section>
      
      {/* FAQ Section */}
      <section className="py-20 bg-black">
        <div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-3xl md:text-4xl font-bold mb-6 bg-gradient-to-r from-emerald-400 to-teal-500 text-transparent bg-clip-text">
              Frequently Asked Questions
            </h2>
            <p className="text-lg text-gray-300">
              Find answers to common questions about Inner Voice and start your meditation journey.
            </p>
          </motion.div>
          
          <div className="space-y-2">
            {faqs.map((faq, index) => (
              <FaqItem
                key={index}
                question={faq.question}
                answer={faq.answer}
                isActive={activeFaq === index}
                onClick={() => toggleFaq(index)}
              />
            ))}
          </div>
          
          <div className="text-center mt-8">
            <motion.button
              onClick={visitInnerVoice}
              className="text-emerald-400 hover:text-emerald-300 font-medium"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              View all FAQs
            </motion.button>
          </div>
        </div>
      </section>
      
      {/* CTA Section */}
      <section className="py-20 bg-gradient-to-b from-black to-emerald-950/20">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="bg-gradient-to-r from-emerald-900/20 to-teal-900/20 border border-emerald-500/30 rounded-xl p-12">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="text-center"
            >
              <h2 className="text-3xl md:text-4xl font-bold mb-6 text-white">
                Begin Your Mindfulness Journey Today
              </h2>
              <p className="text-xl text-gray-300 max-w-3xl mx-auto mb-8">
                Join thousands of users who have discovered deeper meditation experiences with Inner Voice.
              </p>
              
              <motion.button
                onClick={visitInnerVoice}
                className="px-8 py-4 bg-gradient-to-r from-emerald-600 to-teal-600 rounded-lg text-white font-semibold"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Create Your Guide Now
              </motion.button>
              
              <p className="text-gray-400 mt-4">
                Transform your meditation practice with AI-powered guidance in your own voice.
              </p>
            </motion.div>
          </div>
        </div>
      </section>
      
      {/* Footer */}
      <footer className="py-16 bg-black border-t border-gray-800">
        <div className="container mx-auto px-4 md:px-6">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div className="col-span-1 md:col-span-2">
              <h3 className="text-xl font-semibold text-purple-400 mb-4">About Inner Voice</h3>
              <p className="text-gray-400 mb-6">
                Inner Voice is your personal AI guide for self-discovery, reflection, and consciousness exploration, developed by SELF Labs to foster authentic inner dialogue.
              </p>
              
              <div className="flex flex-col md:flex-row justify-start items-center gap-4 mb-4">
                <h3 className="text-xl font-medium text-purple-300">SELF Labs Inc</h3>
                <span className="hidden md:block text-gray-500">•</span>
                <h3 className="text-xl font-medium text-purple-300">SELF Labs DAO L.L.C.</h3>
              </div>
            </div>
            
            <div>
              <h3 className="text-xl font-semibold text-purple-400 mb-4">Links</h3>
              <ul className="space-y-2">
                <li><a href="/" className="text-gray-400 hover:text-white transition-colors">Home</a></li>
                <li><a href="/dao" className="text-gray-400 hover:text-white transition-colors">DAO</a></li>
                <li><a href="/harmony-ai" className="text-gray-400 hover:text-white transition-colors">Harmony AI</a></li>
                <li><a href="/aura-llm" className="text-gray-400 hover:text-white transition-colors">AURA LLM</a></li>
              </ul>
            </div>
            
            <div>
              <h3 className="text-xl font-semibold text-purple-400 mb-4">Community</h3>
              <ul className="space-y-2">
                <li><a href="#" className="text-gray-400 hover:text-white transition-colors">Twitter</a></li>
                <li><a href="https://t.me/+2uJEHPAt4204M2Zh" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white transition-colors">Telegram</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white transition-colors">GitHub</a></li>
              </ul>
            </div>
          </div>
          
          <div className="border-t border-gray-800 mt-12 pt-8 text-center">
            <p className="text-gray-500">&copy; {new Date().getFullYear()} SELF Labs Inc & SELF Labs DAO L.L.C. All rights reserved.</p>
          </div>
        </div>
      </footer>
      
      {/* Contact Form Modal */}
      <Modal 
        isOpen={isContactModalOpen} 
        onClose={closeContactModal}
        title="Contact Inner Voice"
      >
        <ContactForm />
      </Modal>
    </div>
  );
} 