import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { Search, Brain, Cpu, Network, PlusCircle, Users, Wallet, BarChart, Shield, Sparkles, Briefcase, Building, ArrowRight, CheckCircle } from 'lucide-react';
import Modal from '../shared/Modal';

// Define agent categories and their corresponding icons
const categoryIcons: Record<string, JSX.Element> = {
  "CEO SUITE": <Users className="h-5 w-5 text-blue-400" />,
  "CFO SUITE": <Wallet className="h-5 w-5 text-green-400" />,
  "COO SUITE": <BarChart className="h-5 w-5 text-yellow-400" />,
  "CMO SUITE": <Sparkles className="h-5 w-5 text-purple-400" />,
  "CTO SUITE": <Cpu className="h-5 w-5 text-cyan-400" />,
  "SPECIALIZED & DEPARTMENTAL AGENTS": <PlusCircle className="h-5 w-5 text-orange-400" />,
  "PUBLIC COMPANY-SPECIFIC AGENTS": <Shield className="h-5 w-5 text-red-400" />
};

// Define agent interface
interface Agent {
  name: string;
  description: string;
}

// Define agent data interface
interface AgentData {
  [category: string]: Agent[];
}

// Form data interface
interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  jobTitle: string;
  companyName: string;
  industry: string;
  companySize: string;
  phoneNumber: string;
  website: string;
  interests: string[];
  consent: boolean;
}

// Form errors interface
interface FormErrors {
  firstName?: string;
  lastName?: string;
  email?: string;
  jobTitle?: string;
  companyName?: string;
  industry?: string;
  companySize?: string;
  website?: string;
  consent?: string;
  form?: string;
}

// Apollo response interface
interface ApolloResponse {
  success: boolean;
  message?: string;
  person?: any;
  error?: string;
}

export default function HarmonyAIPage() {
  const [searchQuery, setSearchQuery] = useState('');
  const [activeTab, setActiveTab] = useState('all');
  const [showModal, setShowModal] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  
  // Form state
  const [formData, setFormData] = useState<FormData>({
    firstName: '',
    lastName: '',
    email: '',
    jobTitle: '',
    companyName: '',
    industry: '',
    companySize: '',
    phoneNumber: '',
    website: '',
    interests: ['Harmony AI Implementation', 'Business Automation'],
    consent: false
  });
  
  const [errors, setErrors] = useState<FormErrors>({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Handle form input changes
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;
    
    if (type === 'checkbox') {
      const checkbox = e.target as HTMLInputElement;
      setFormData({
        ...formData,
        [name]: checkbox.checked
      });
    } else {
      setFormData({
        ...formData,
        [name]: value
      });
    }
    
    // Clear errors for this field when user types
    if (errors[name as keyof FormErrors]) {
      setErrors({
        ...errors,
        [name]: undefined
      });
    }
  };

  // Validate form before submission
  const validateForm = () => {
    const newErrors: FormErrors = {};
    
    if (!formData.firstName.trim()) newErrors.firstName = 'First name is required';
    if (!formData.lastName.trim()) newErrors.lastName = 'Last name is required';
    
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
    }
    
    if (!formData.jobTitle.trim()) newErrors.jobTitle = 'Job title is required';
    if (!formData.companyName.trim()) newErrors.companyName = 'Company name is required';
    if (!formData.consent) newErrors.consent = 'You must consent to the data collection';
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) return;
    
    setIsSubmitting(true);
    
    try {
      // Prepare the data for Apollo.io
      const apolloData = {
        api_key: import.meta.env.VITE_APOLLO_API_KEY,
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        organization_name: formData.companyName,
        title: formData.jobTitle,
        phone: formData.phoneNumber || undefined,
        website_url: formData.website || undefined,
        custom_fields: {
          company_size: formData.companySize || 'Not specified',
          industry: formData.industry || 'Not specified',
          interests: formData.interests.join(', '),
          source: 'Harmony AI Inquiry'
        }
      };
      
      console.log("Preparing to submit to Apollo.io:", apolloData);
      
      if (import.meta.env.DEV && !import.meta.env.VITE_APOLLO_API_KEY) {
        // In development without API key, simulate success
        console.log("This data would be sent to Apollo.io in production:", apolloData);
        setTimeout(() => {
          setIsSubmitting(false);
          setIsSubmitted(true);
        }, 1500);
        return;
      }
      
      const response = await fetch('/.netlify/functions/apollo-proxy?endpoint=people/create_or_update', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(apolloData),
      });
      
      const data: ApolloResponse = await response.json();
      
      if (!data.success) {
        throw new Error(data.message || data.error || 'Failed to submit to Apollo.io');
      }
      
      console.log("Apollo.io response:", data);
      setIsSubmitted(true);
      
    } catch (error) {
      console.error('Form submission error:', error);
      setErrors({
        ...errors,
        form: error instanceof Error ? error.message : 'An unexpected error occurred'
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // Agent data organized by category
  const agentData: AgentData = {
    "CEO SUITE": [
      {
        name: "Vision & Strategic Planning Agent",
        description: "Defines long-term company goals and aligns operations, ensuring strategic growth. Implemented to maintain a clear, overarching vision that guides every initiative."
      },
      {
        name: "Leadership & Organizational Culture Agent",
        description: "Fosters a positive work environment and manages team alignment with company values. Implemented to nurture a cohesive culture and boost team morale."
      },
      {
        name: "Decision-Making & Risk Management Agent",
        description: "Prioritizes major decisions, weighs potential risks, and ensures resiliency. Implemented to keep the company agile and prepared for challenges."
      },
      {
        name: "Financial Oversight & Capital Allocation Agent",
        description: "Oversees high-level financial health, ensuring resources go to the right projects. Implemented to maximize ROI and maintain fiscal discipline."
      },
      {
        name: "Stakeholder & Investor Relations Agent",
        description: "Communicates performance and strategies to investors, manages funding, and addresses shareholder inquiries. Implemented for transparent stakeholder engagement."
      },
      {
        name: "Operations & Execution Oversight Agent",
        description: "Monitors organizational efficiency and cross-departmental coordination. Implemented to ensure smooth day-to-day execution of company plans."
      },
      {
        name: "Product & Service Innovation Agent",
        description: "Scouts new market opportunities and steers product improvements. Implemented to keep the company innovative and competitive."
      },
      {
        name: "Corporate Communication & PR Agent",
        description: "Manages public image, press releases, and crisis communication. Implemented to maintain a consistent and positive brand reputation."
      },
      {
        name: "Corporate Governance & Legal Compliance Agent",
        description: "Ensures board meetings, governance policies, and basic legal guidelines are upheld. Implemented to keep us aligned with corporate regulations."
      },
      {
        name: "Customer & Market Engagement Agent",
        description: "Maintains customer relations, monitors market needs, and integrates feedback. Implemented to keep product-market fit strong."
      },
      {
        name: "Business Development & Partnerships Agent",
        description: "Identifies growth opportunities and manages joint ventures or deals. Implemented to expand reach and form strategic alliances."
      }
    ],
    "CFO SUITE": [
      {
        name: "Financial Planning & Analysis (FP&A) Agent",
        description: "Forecasts budgets, analyzes financial scenarios, and tracks KPIs. Implemented to stay financially proactive and data-driven."
      },
      {
        name: "Bookkeeping & Transaction Management Agent",
        description: "Records transactions, reconciles accounts, and categorizes expenses. Implemented to maintain accurate, up-to-date financial data."
      },
      {
        name: "Financial Reporting & Compliance Agent",
        description: "Generates P&L statements, balance sheets, and ensures regulatory adherence. Implemented to keep finances transparent and compliant."
      },
      {
        name: "Treasury Management Agent",
        description: "Oversees cash flow, liquidity, and investment strategies. Implemented to maintain financial stability and optimize resource allocation."
      },
      {
        name: "Risk Management & Insurance Agent",
        description: "Identifies potential financial risks and manages insurance coverage. Implemented to safeguard assets and mitigate unexpected losses."
      },
      {
        name: "Tax Strategy & Planning Agent",
        description: "Manages tax liabilities, filings, and compliance with tax regulations. Implemented to optimize tax efficiency and avoid penalties."
      },
      {
        name: "Mergers & Acquisitions Agent (Optional)",
        description: "Leads due diligence and negotiation of M&A deals. Implemented to guide strategic growth if we pursue acquisitions."
      }
    ],
    "COO SUITE": [
      {
        name: "Operations Management Agent",
        description: "Oversees daily business processes and departmental coordination. Implemented to streamline operational flow."
      },
      {
        name: "Process Optimization & Efficiency Agent",
        description: "Identifies inefficiencies and automates workflows. Implemented to reduce costs and boost productivity."
      },
      {
        name: "Project Management & Execution Agent",
        description: "Tracks timelines and milestones, ensuring initiatives finish on schedule. Implemented to keep projects under control."
      },
      {
        name: "AI Resources & Performance Management Agent",
        description: "Handles recruitment, performance reviews, and employee well-being. Implemented to build and retain strong performance."
      },
      {
        name: "Supply Chain & Logistics Management Agent",
        description: "Manages inventory, vendors, and shipping. Implemented to ensure timely deliveries and cost-effective procurement."
      },
      {
        name: "Financial Operations & Budgeting Agent",
        description: "Aligns daily operational budgets with overall financial goals. Implemented for predictable and controlled spending."
      },
      {
        name: "Risk Management & Compliance (Operational) Agent",
        description: "Monitors operational risks, ensuring smooth continuity. Implemented to avoid disruptions in core activities."
      },
      {
        name: "Customer Experience & Service Management Agent",
        description: "Maintains satisfaction levels and resolves service issues. Implemented to keep customers happy and engaged."
      },
      {
        name: "Strategic Planning & Growth Agent",
        description: "Evaluates expansion opportunities and sets operational objectives. Implemented to keep the company scaling efficiently."
      },
      {
        name: "Vendor & Partner Relations Agent",
        description: "Manages external partnerships and supplier contracts. Implemented to secure beneficial terms and maintain healthy collaborations."
      }
    ],
    "CMO SUITE": [
      {
        name: "Marketing Strategy & Planning Agent",
        description: "Develops marketing campaigns and aligns them with business goals. Implemented to increase brand awareness and market presence."
      },
      {
        name: "Branding & Positioning Agent",
        description: "Maintains consistent branding and market positioning. Implemented to strengthen brand identity."
      },
      {
        name: "Content Creation & Marketing Agent",
        description: "Produces written, visual, and multimedia content for promotions. Implemented to engage audiences and drive conversions."
      },
      {
        name: "Digital Advertising & Paid Media Agent",
        description: "Manages online ad campaigns across multiple platforms. Implemented to optimize ad spend and reach."
      },
      {
        name: "Email Marketing & Automation Agent",
        description: "Oversees newsletters, drip campaigns, and personalized mailers. Implemented to nurture leads and retain customers."
      },
      {
        name: "Social Media & Community Engagement Agent",
        description: "Handles posts, interactions, and community growth on social platforms. Implemented to expand brand reach and loyalty."
      },
      {
        name: "Influencer & Affiliate Marketing Agent",
        description: "Develops partnerships with influencers and affiliate networks. Implemented to broaden our marketing channels."
      },
      {
        name: "CRM & Retention Agent",
        description: "Tracks customer data, engagement, and loyalty programs. Implemented to reduce churn and boost lifetime value."
      },
      {
        name: "Market Research & Competitive Intelligence Agent",
        description: "Analyzes trends, customer feedback, and competitor activity. Implemented to keep strategies up-to-date."
      },
      {
        name: "Analytics & Performance Reporting Agent",
        description: "Measures campaign ROI and gathers actionable insights. Implemented for data-driven decision-making."
      },
      {
        name: "Public Relations & Media Relations Agent",
        description: "Maintains press contacts and handles brand reputation. Implemented to shape public perception effectively."
      }
    ],
    "CTO SUITE": [
      {
        name: "Technology Strategy & Innovation Agent",
        description: "Oversees R&D and tech growth initiatives. Implemented to keep us on the cutting edge."
      },
      {
        name: "Product Development & Engineering Agent",
        description: "Manages software builds and engineering teams. Implemented for consistent, high-quality product releases."
      },
      {
        name: "Infrastructure & Cloud Management Agent",
        description: "Operates servers, cloud deployments, and networking. Implemented for stable and scalable infrastructure."
      },
      {
        name: "Cybersecurity & Risk Management Agent",
        description: "Protects data and systems from vulnerabilities. Implemented to ensure robust defense against cyber threats."
      },
      {
        name: "Data Science & Analytics Agent",
        description: "Leverages machine learning and statistical analysis for insights. Implemented to power data-driven innovations."
      },
      {
        name: "Software Architecture & Design Agent",
        description: "Establishes system structures that are scalable and efficient. Implemented to maintain sustainable codebases."
      },
      {
        name: "Technology Operations & Support Agent",
        description: "Handles day-to-day IT tasks and troubleshooting. Implemented to reduce downtime and ensure smooth operations."
      },
      {
        name: "DevOps & Automation Agent",
        description: "Streamlines software delivery pipelines and automations. Implemented to speed up releases and reduce errors."
      },
      {
        name: "Vendor & Technology Partner Management Agent",
        description: "Maintains relationships with tech suppliers and service providers. Implemented to secure best-in-class solutions."
      },
      {
        name: "Innovation & R&D Agent",
        description: "Explores emerging technologies and pilots new solutions. Implemented to drive ongoing creativity."
      },
      {
        name: "Technical Documentation & Knowledge Management Agent",
        description: "Keeps all technical references and knowledge bases updated. Implemented for easy onboarding and internal alignment."
      }
    ],
    "SPECIALIZED & DEPARTMENTAL AGENTS": [
      {
        name: "Human Resources (HR) & Recruitment",
        description: "Manages hiring, onboarding, and employee welfare."
      },
      {
        name: "Sales & Lead Generation",
        description: "Finds, qualifies, and converts new prospects."
      },
      {
        name: "Customer Support & Help Desk",
        description: "Resolves tickets and assists customers promptly."
      },
      {
        name: "Legal & Contract Management",
        description: "Drafts, reviews, and stores legal documents."
      },
      {
        name: "IT Support & Maintenance",
        description: "Maintains hardware, software, and ensures tech reliability."
      },
      {
        name: "Product Management & Lifecycle",
        description: "Oversees product roadmaps, release cycles, and feature sets."
      },
      {
        name: "Content Moderation & Compliance",
        description: "Reviews user-generated content for adherence to guidelines."
      },
      {
        name: "Event Planning & Coordination",
        description: "Organizes and manages corporate events, webinars, etc."
      },
      {
        name: "Supply Chain & Procurement",
        description: "Handles purchasing, vendor relations, and inventory control."
      },
      {
        name: "Data Privacy & Compliance",
        description: "Ensures data handling aligns with regulations like GDPR."
      },
      {
        name: "Marketing & Brand Insights",
        description: "Tracks brand performance and market feedback."
      },
      {
        name: "Customer Experience & User Journey",
        description: "Analyzes and improves every customer interaction."
      },
      {
        name: "Executive Assistant & Scheduling",
        description: "Automates calendars, reminders, and meeting coordination."
      },
      {
        name: "Chief Security Officer (CSO)",
        description: "Safeguards overall corporate security, both digital and physical."
      }
    ],
    "PUBLIC COMPANY-SPECIFIC AGENTS": [
      {
        name: "Regulatory Filings & Reporting",
        description: "Prepares and submits forms like 10-K, 10-Q, 8-K."
      },
      {
        name: "Sarbanes-Oxley (SOX) Compliance & Internal Audit",
        description: "Ensures internal controls meet legal standards."
      },
      {
        name: "Corporate Governance & Board Management",
        description: "Facilitates board meetings and compliance with governance rules."
      },
      {
        name: "Enhanced Investor Relations (IR)",
        description: "Manages earnings calls, press releases, and shareholder communications."
      },
      {
        name: "Public Company Risk Management & Disclosure Controls",
        description: "Identifies and reports material risks or events."
      }
    ]
  };

  // Filter agents based on search query
  const filterAgents = (agents: Agent[], query: string): Agent[] => {
    if (!query) return agents;
    return agents.filter(agent => 
      agent.name.toLowerCase().includes(query.toLowerCase()) || 
      agent.description.toLowerCase().includes(query.toLowerCase())
    );
  };

  // Get all categories or only the active tab
  const categoriesToShow = activeTab === 'all' 
    ? Object.keys(agentData) 
    : [activeTab];

  // Count total agents
  const totalAgentCount = Object.values(agentData)
    .flat()
    .length;

  return (
    <div className="min-h-screen bg-gradient-to-b from-[#0A0A0B] via-black to-[#0A0A0B] text-white">
      {/* Background Pattern */}
      <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,rgba(138,43,226,0.03)_0%,transparent_1px)] bg-[length:24px_24px]" />
      
      {/* Hero Section */}
      <div className="relative bg-gradient-to-b from-[#0E0E2C] to-[#0A0A0B] pt-24 pb-16">
        <div className="absolute inset-0 overflow-hidden">
          <div className="absolute inset-0 bg-[url('https://images.unsplash.com/photo-1639322537504-6427a16b0a28?q=80&w=2232&auto=format&fit=crop')] opacity-20 bg-cover bg-center"></div>
          <div className="absolute inset-0 bg-gradient-to-b from-[#0E0E2C]/80 to-[#0A0A0B]"></div>
        </div>
        
        <div className="container mx-auto px-4 relative z-10">
          <div className="flex flex-col md:flex-row items-center justify-between">
            <div className="md:w-1/2 mb-10 md:mb-0">
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <h1 className="text-4xl md:text-5xl font-bold mb-4 bg-clip-text text-transparent bg-gradient-to-r from-[#8A2BE2] to-[#00FFFF]">
                  Harmony AI & Signal Nodes
                </h1>
                <p className="text-xl text-gray-300 mb-6">
                  Create unlimited specialized AI agents tailored to your business needs
                </p>
                <div className="flex items-center space-x-4">
                  <div className="w-12 h-12 rounded-full bg-[#8A2BE2]/20 flex items-center justify-center">
                    <Brain className="w-6 h-6 text-[#8A2BE2]" />
                  </div>
                  <div className="w-12 h-12 rounded-full bg-[#00FFFF]/20 flex items-center justify-center">
                    <Network className="w-6 h-6 text-[#00FFFF]" />
                  </div>
                </div>
              </motion.div>
            </div>
            
            <div className="md:w-1/2">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.7, delay: 0.2 }}
                className="relative"
              >
                <div className="relative overflow-hidden rounded-xl border border-white/10 bg-white/5 backdrop-blur-sm">
                  <div className="p-6">
                    <div className="flex items-center space-x-2 mb-4">
                      <Brain className="w-5 h-5 text-[#00FFFF]" />
                      <h3 className="text-lg font-medium">AI Network Overview</h3>
                    </div>
                    <div className="space-y-3">
                      <div className="flex justify-between">
                        <span className="text-gray-400">SELF Labs Agents</span>
                        <span className="font-medium">{totalAgentCount}</span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-gray-400">Your Potential Agents</span>
                        <span className="text-[#00FFFF]">Unlimited</span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-gray-400">Network Status</span>
                        <span className="text-green-400">Active</span>
                      </div>
                      <div className="flex justify-between">
                        <span className="text-gray-400">Signal Strength</span>
                        <span className="text-purple-400">Optimal</span>
                      </div>
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>

      {/* Search & Filter Controls */}
      <div className="container mx-auto px-4 -mt-6 mb-6">
        <div className="bg-white/5 backdrop-blur-sm rounded-xl border border-white/10 p-4">
          <div className="flex flex-col md:flex-row gap-4">
            <div className="relative flex-grow">
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <Search className="h-5 w-5 text-gray-400" />
              </div>
              <input
                type="text"
                className="block w-full pl-10 pr-3 py-2 border border-white/10 rounded-lg bg-white/5 text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#00FFFF]/50"
                placeholder="Search AI agents..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            
            <div className="flex overflow-x-auto hide-scrollbar gap-2 pb-1">
              <button
                onClick={() => setActiveTab('all')}
                className={`px-4 py-2 rounded-lg whitespace-nowrap text-sm ${
                  activeTab === 'all' 
                    ? 'bg-[#00FFFF]/20 text-[#00FFFF] font-medium' 
                    : 'bg-white/5 text-gray-300 hover:bg-white/10'
                }`}
              >
                All Agents
              </button>
              
              {Object.keys(agentData).map((category) => (
                <button
                  key={category}
                  onClick={() => setActiveTab(category)}
                  className={`px-4 py-2 rounded-lg whitespace-nowrap text-sm flex items-center gap-2 ${
                    activeTab === category 
                      ? 'bg-[#00FFFF]/20 text-[#00FFFF] font-medium' 
                      : 'bg-white/5 text-gray-300 hover:bg-white/10'
                  }`}
                >
                  {categoryIcons[category]}
                  <span>{category.split(' ')[0]}</span>
                </button>
              ))}
            </div>
          </div>
        </div>
      </div>
      
      {/* Example Use Case Banner */}
      <div className="container mx-auto px-4 mb-10">
        <div className="bg-gradient-to-r from-[#8A2BE2]/10 to-[#00FFFF]/10 backdrop-blur-sm rounded-xl border border-[#8A2BE2]/20 p-4">
          <div className="flex items-center gap-3">
            <div className="hidden md:flex h-10 w-10 rounded-full bg-[#8A2BE2]/20 items-center justify-center">
              <Building className="h-5 w-5 text-[#8A2BE2]" />
            </div>
            <div>
              <p className="text-gray-300">
                <span className="text-white">The 69 agents shown below are what SELF Labs Inc uses internally.</span> Your organization can implement any number of custom agents based on your specific business size and needs.
              </p>
            </div>
          </div>
        </div>
      </div>
      
      {/* Agent Categories */}
      <div className="container mx-auto px-4 pb-20">
        <div className="space-y-10">
          {categoriesToShow.map((category) => {
            const filteredAgents = filterAgents(agentData[category], searchQuery);
            
            if (filteredAgents.length === 0) {
              return null;
            }
            
            return (
              <div key={category}>
                <div className="flex items-center mb-6 space-x-3">
                  {categoryIcons[category]}
                  <h2 className="text-2xl font-bold">{category}</h2>
                </div>
                
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {filteredAgents.map((agent: Agent, index: number) => (
                    <motion.div
                      key={index}
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.3, delay: index * 0.05 }}
                      className="bg-white/5 backdrop-blur-sm border border-white/10 rounded-xl p-5 hover:bg-white/10 transition-all duration-300"
                    >
                      <h3 className="text-lg font-medium mb-2 text-[#00FFFF]">{agent.name}</h3>
                      <p className="text-gray-300 text-sm">{agent.description}</p>
                    </motion.div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
      
      {/* Why They Are Implemented */}
      <div className="bg-[#0A0A0B] py-20">
        <div className="container mx-auto px-4">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5 }}
            className="bg-white/5 backdrop-blur-md border border-white/10 rounded-xl p-6 md:p-8"
          >
            <h2 className="text-2xl font-bold mb-4 text-center">WHY IMPLEMENT HARMONY AI AGENTS</h2>
            <p className="text-gray-300 text-lg text-center max-w-4xl mx-auto mb-12">
              Every agent is designed to reduce human workload, increase operational efficiency, and ensure regulatory compliance in its respective domain. By automating these functions, you can create a fully automated business that scales efficiently.
            </p>
            
            <div className="grid md:grid-cols-3 gap-6">
              <div className="bg-white/5 backdrop-blur-sm rounded-lg p-5 border border-white/10">
                <div className="w-12 h-12 mb-4 rounded-full bg-[#8A2BE2]/20 flex items-center justify-center">
                  <Cpu className="w-6 h-6 text-[#8A2BE2]" />
                </div>
                <h3 className="text-lg font-medium mb-2">Reduced Human Workload</h3>
                <p className="text-gray-400">AI agents handle complex tasks without human intervention, allowing scaling without proportional HR growth.</p>
              </div>
              
              <div className="bg-white/5 backdrop-blur-sm rounded-lg p-5 border border-white/10">
                <div className="w-12 h-12 mb-4 rounded-full bg-[#00FFFF]/20 flex items-center justify-center">
                  <BarChart className="w-6 h-6 text-[#00FFFF]" />
                </div>
                <h3 className="text-lg font-medium mb-2">Operational Efficiency</h3>
                <p className="text-gray-400">24/7 operation with consistent performance, eliminating human error and improving decision accuracy.</p>
              </div>
              
              <div className="bg-white/5 backdrop-blur-sm rounded-lg p-5 border border-white/10">
                <div className="w-12 h-12 mb-4 rounded-full bg-purple-500/20 flex items-center justify-center">
                  <Shield className="w-6 h-6 text-purple-500" />
                </div>
                <h3 className="text-lg font-medium mb-2">Regulatory Compliance</h3>
                <p className="text-gray-400">Automated monitoring of legal and regulatory requirements ensures consistent compliance with minimal oversight.</p>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
      
      {/* Get Started CTA */}
      <div className="bg-[#0E0E2C] py-16">
        <div className="container mx-auto px-4 text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <h2 className="text-3xl font-bold mb-4">Ready to Connect with Harmony AI?</h2>
            <p className="text-gray-300 mb-8 max-w-2xl mx-auto">
              Explore how our network of specialized AI agents can transform your business operations through the power of Signal Nodes, customized for your organization's specific needs.
            </p>
            <button 
              className="px-8 py-3 bg-gradient-to-r from-[#8A2BE2] to-[#00FFFF] text-white font-medium rounded-full hover:opacity-90 transition-opacity flex items-center mx-auto"
              onClick={() => setShowModal(true)}
            >
              Join the Harmony Network
              <ArrowRight className="ml-2 h-5 w-5" />
            </button>
          </motion.div>
        </div>
      </div>
      
      {/* Footer */}
      <footer className="bg-[#0A0A0B] py-8 border-t border-white/10">
        <div className="container mx-auto px-4 text-center">
          <div className="flex flex-col md:flex-row justify-center items-center gap-4 mb-4">
            <h3 className="text-xl font-medium text-purple-300">SELF Labs Inc</h3>
            <span className="hidden md:block text-gray-500">•</span>
            <h3 className="text-xl font-medium text-purple-300">SELF Labs DAO L.L.C.</h3>
          </div>
          <p className="text-sm text-gray-500">&copy; {new Date().getFullYear()} SELF Labs Inc & SELF Labs DAO L.L.C. All rights reserved.</p>
        </div>
      </footer>
      
      {/* Harmony AI Application Modal */}
      <Modal 
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        title="Apply for Harmony AI Implementation"
      >
        {!isSubmitted ? (
          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Personal Information */}
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">
                  First Name <span className="text-[#00FFFF]">*</span>
                </label>
                <input
                  type="text"
                  name="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  className={`w-full px-3 py-2 bg-white/5 border ${
                    errors.firstName ? 'border-red-500' : 'border-white/10'
                  } rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00FFFF]/50`}
                />
                {errors.firstName && (
                  <p className="mt-1 text-sm text-red-400">{errors.firstName}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">
                  Last Name <span className="text-[#00FFFF]">*</span>
                </label>
                <input
                  type="text"
                  name="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  className={`w-full px-3 py-2 bg-white/5 border ${
                    errors.lastName ? 'border-red-500' : 'border-white/10'
                  } rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00FFFF]/50`}
                />
                {errors.lastName && (
                  <p className="mt-1 text-sm text-red-400">{errors.lastName}</p>
                )}
              </div>

              {/* Business Information */}
              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">
                  Email <span className="text-[#00FFFF]">*</span>
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className={`w-full px-3 py-2 bg-white/5 border ${
                    errors.email ? 'border-red-500' : 'border-white/10'
                  } rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00FFFF]/50`}
                />
                {errors.email && (
                  <p className="mt-1 text-sm text-red-400">{errors.email}</p>
                )}
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-300 mb-1">
                  Job Title <span className="text-[#00FFFF]">*</span>
                </label>
                <input
                  type="text"
                  name="jobTitle"
                  value={formData.jobTitle}
                  onChange={handleChange}
                  className={`w-full px-3 py-2 bg-white/5 border ${
                    errors.jobTitle ? 'border-red-500' : 'border-white/10'
                  } rounded-lg focus:outline-none focus:ring-2 focus:ring-[#00FFFF]/50`}
                />
                {errors.jobTitle && (
                  <p className="mt-1 text-sm text-red-400">{errors.jobTitle}</p>
                )}
              </div>
              
              {/* Business Information Section */}
              <div className="col-span-2 mt-4">
                <h3 className="text-xl font-semibold text-white mb-4 border-b border-[#8A2BE2]/20 pb-2">
                  Business Information
                </h3>
              </div>
              
              {/* Company Name */}
              <div>
                <label htmlFor="companyName" className="block text-sm font-medium text-gray-300 mb-2">
                  Company Name <span className="text-[#8A2BE2]">*</span>
                </label>
                <div className="flex">
                  <div className="p-3 bg-black/50 border-y border-l border-[#8A2BE2]/30 rounded-l-lg flex items-center">
                    <Briefcase className="w-5 h-5 text-[#8A2BE2]" />
                  </div>
                  <input
                    type="text"
                    id="companyName"
                    name="companyName"
                    className={`flex-grow bg-black/30 border-y border-r ${errors.companyName ? 'border-red-500/50' : 'border-[#8A2BE2]/30'} text-white rounded-r-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-[#8A2BE2]/50`}
                    value={formData.companyName}
                    onChange={handleChange}
                  />
                </div>
                {errors.companyName && <p className="mt-1 text-sm text-red-400">{errors.companyName}</p>}
              </div>
              
              {/* Industry */}
              <div>
                <label htmlFor="industry" className="block text-sm font-medium text-gray-300 mb-2">
                  Industry
                </label>
                <select
                  id="industry"
                  name="industry"
                  className="w-full bg-black/30 border border-[#8A2BE2]/30 text-white rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-[#8A2BE2]/50"
                  value={formData.industry}
                  onChange={handleChange}
                >
                  <option value="">Select Industry</option>
                  <option value="Technology">Technology</option>
                  <option value="Finance">Finance</option>
                  <option value="Healthcare">Healthcare</option>
                  <option value="Education">Education</option>
                  <option value="Manufacturing">Manufacturing</option>
                  <option value="Retail">Retail</option>
                  <option value="Real Estate">Real Estate</option>
                  <option value="Media & Entertainment">Media & Entertainment</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              
              {/* Company Size */}
              <div>
                <label htmlFor="companySize" className="block text-sm font-medium text-gray-300 mb-2">
                  Company Size
                </label>
                <select
                  id="companySize"
                  name="companySize"
                  className="w-full bg-black/30 border border-[#8A2BE2]/30 text-white rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-[#8A2BE2]/50"
                  value={formData.companySize}
                  onChange={handleChange}
                >
                  <option value="">Select Company Size</option>
                  <option value="1-10 employees">1-10 employees</option>
                  <option value="11-50 employees">11-50 employees</option>
                  <option value="51-200 employees">51-200 employees</option>
                  <option value="201-500 employees">201-500 employees</option>
                  <option value="501-1000 employees">501-1000 employees</option>
                  <option value="1001+ employees">1001+ employees</option>
                </select>
              </div>
              
              {/* Website */}
              <div className="col-span-2">
                <label htmlFor="website" className="block text-sm font-medium text-gray-300 mb-2">
                  Company Website
                </label>
                <input
                  type="url"
                  id="website"
                  name="website"
                  placeholder="https://"
                  className="w-full bg-black/30 border border-[#8A2BE2]/30 text-white rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-[#8A2BE2]/50"
                  value={formData.website}
                  onChange={handleChange}
                />
              </div>
              
              {/* Consent */}
              <div className="col-span-2">
                <div className="flex items-start mt-4">
                  <div className="flex items-center h-5">
                    <input
                      id="consent"
                      name="consent"
                      type="checkbox"
                      className="focus:ring-[#8A2BE2] h-4 w-4 text-[#8A2BE2] border-[#8A2BE2]/30 rounded"
                      checked={formData.consent}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="consent" className={`font-medium ${errors.consent ? 'text-red-400' : 'text-gray-300'}`}>
                      I consent to SELF Labs collecting my business information and storing it in Apollo.io for the purpose of
                      Harmony AI implementation and business automation assessment.
                    </label>
                    {errors.consent && <p className="mt-1 text-sm text-red-400">{errors.consent}</p>}
                  </div>
                </div>
              </div>
            </div>
            
            <div className="flex justify-end">
              <button
                type="button"
                className="px-5 py-2 mr-3 border border-[#8A2BE2]/30 text-gray-300 rounded-lg hover:bg-[#8A2BE2]/10 transition-colors"
                onClick={() => setShowModal(false)}
                disabled={isSubmitting}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="px-5 py-2 bg-gradient-to-r from-[#8A2BE2] to-[#00FFFF] text-white rounded-lg hover:opacity-90 transition-opacity flex items-center"
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Submit Application'}
                {!isSubmitting && <ArrowRight className="ml-2 h-4 w-4" />}
              </button>
            </div>
          </form>
        ) : (
          <div className="text-center py-8">
            <div className="w-20 h-20 bg-gradient-to-r from-[#8A2BE2]/20 to-[#00FFFF]/20 rounded-full flex items-center justify-center mx-auto mb-6">
              <Network className="h-10 w-10 text-[#00FFFF]" />
            </div>
            <h3 className="text-2xl font-bold mb-4 text-white">Thank You For Your Interest</h3>
            <p className="text-gray-300 mb-8">
              Your application for Harmony AI implementation has been successfully submitted and is now in our Apollo.io database.
              A SELF Labs representative will contact you within 2-3 business days to discuss your custom agent network.
            </p>
            <button
              onClick={() => {
                setShowModal(false);
                // Reset the form after closing
                setTimeout(() => {
                  setIsSubmitted(false);
                  setFormData({
                    firstName: '',
                    lastName: '',
                    email: '',
                    jobTitle: '',
                    companyName: '',
                    industry: '',
                    companySize: '',
                    phoneNumber: '',
                    website: '',
                    interests: ['Harmony AI Implementation', 'Business Automation'],
                    consent: false
                  });
                }, 300);
              }}
              className="px-6 py-3 bg-[#8A2BE2]/30 text-white rounded-lg hover:bg-[#8A2BE2]/40 transition-colors"
            >
              Close
            </button>
          </div>
        )}
      </Modal>
    </div>
  );
} 