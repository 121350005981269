import React from 'react';
import { motion } from 'framer-motion';
import { Brain, ExternalLink, Cpu, Mic, Code } from 'lucide-react';
import { FeatureGrid } from './FeatureGrid';
import { VoiceAgentSection } from './VoiceAgentSection';
import { AIPersonalities } from './AIPersonalities';
import { PricingSection } from './PricingSection';

export default function RoleModelAIPage() {
  // Function to navigate to the rolemodel.ai website
  const visitRoleModelAI = () => {
    window.open('https://rolemodel.ai', '_blank');
  };
  
  // Function to navigate to the concierge.rolemodel.ai website
  const visitConciergeAI = () => {
    window.open('https://concierge.rolemodel.ai', '_blank');
  };

  return (
    <div className="min-h-screen bg-black">
      {/* Hero Section */}
      <div className="relative min-h-screen flex items-center justify-center overflow-hidden">
        {/* Background Animation */}
        <div className="absolute inset-0 bg-gradient-to-b from-purple-900/20 to-black" />
        <motion.div
          className="absolute inset-0"
          animate={{
            backgroundPosition: ['0% 0%', '100% 100%'],
          }}
          transition={{
            duration: 20,
            repeat: Infinity,
            ease: "linear"
          }}
          style={{
            backgroundImage: 'radial-gradient(circle at center, rgba(147, 51, 234, 0.1) 0%, transparent 20%)',
            backgroundSize: '60px 60px',
          }}
        />

        <div className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-32 text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
          >
            <div className="flex justify-center mb-6">
              <Brain className="w-24 h-24 text-purple-500" />
            </div>
            
            <div className="flex justify-center items-center mb-4">
              <h1 className="text-6xl md:text-7xl font-bold bg-gradient-to-r from-purple-400 to-pink-600 text-transparent bg-clip-text">
                Role Model AI
              </h1>
            </div>
            
            <div className="flex flex-wrap justify-center gap-3 mb-8">
              {["Concierge AI", "AuRA", "AI Tool Directory", "Developer Console", "BYOM"].map((product, i) => (
                <span key={i} className="inline-block px-3 py-1 bg-purple-900/30 text-purple-300 rounded-full text-sm">
                  {product}
                </span>
              ))}
            </div>
            
            <p className="text-xl md:text-2xl text-gray-300 mb-8 max-w-3xl mx-auto">
              Discover the future of AI integration with our comprehensive suite of tools and services 
              for developers, businesses, and AI enthusiasts
            </p>
            
            <div className="mb-8 p-4 bg-yellow-900/20 border border-yellow-500/30 rounded-lg inline-block">
              <p className="text-yellow-300">
                Now accepting Trump coin, SOL & USDC for all subscription plans!
              </p>
            </div>
            
            <div className="flex flex-wrap justify-center gap-4">
              <motion.button
                onClick={visitRoleModelAI}
                className="bg-gradient-to-r from-purple-600 to-pink-600 text-white px-8 py-4 rounded-lg text-lg font-semibold flex items-center gap-2 hover:from-purple-700 hover:to-pink-700 transition-colors"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Visit rolemodel.ai
                <ExternalLink className="w-5 h-5" />
              </motion.button>
              
              <motion.button
                onClick={visitConciergeAI}
                className="bg-purple-900/50 text-white border border-purple-500/50 px-8 py-4 rounded-lg text-lg font-semibold hover:bg-purple-900/70 transition-colors"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Try Concierge AI
              </motion.button>
            </div>
          </motion.div>
        </div>
      </div>

      {/* Core Features Section */}
      <div className="py-24 bg-black">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            className="text-center mb-16"
          >
            <h2 className="text-4xl font-bold mb-6 bg-gradient-to-r from-purple-400 to-pink-600 text-transparent bg-clip-text">
              Powerful AI Solutions for Everyone
            </h2>
            <p className="text-xl text-gray-400 max-w-3xl mx-auto">
              Welcome to Role Model AI
            </p>
          </motion.div>

          <div className="grid md:grid-cols-3 gap-8">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              className="bg-purple-900/10 backdrop-blur-sm rounded-xl p-6 border border-purple-500/20"
            >
              <Cpu className="w-12 h-12 text-purple-400 mb-4" />
              <h3 className="text-xl font-semibold text-white mb-2">Advanced AI Models</h3>
              <p className="text-gray-400">
                Access state-of-the-art language models and AI technology through our intuitive platform.
              </p>
            </motion.div>
            
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.1 }}
              className="bg-purple-900/10 backdrop-blur-sm rounded-xl p-6 border border-purple-500/20"
            >
              <Mic className="w-12 h-12 text-purple-400 mb-4" />
              <h3 className="text-xl font-semibold text-white mb-2">Voice Creation</h3>
              <p className="text-gray-400">
                Create custom AI assistants with your voice, personality, and knowledge base.
              </p>
            </motion.div>
            
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
              className="bg-purple-900/10 backdrop-blur-sm rounded-xl p-6 border border-purple-500/20"
            >
              <Code className="w-12 h-12 text-purple-400 mb-4" />
              <h3 className="text-xl font-semibold text-white mb-2">Developer Tools</h3>
              <p className="text-gray-400">
                Build and deploy AI applications with our comprehensive API and developer console.
              </p>
            </motion.div>
          </div>
        </div>
      </div>

      {/* Voice Agent Section */}
      <VoiceAgentSection />
      
      {/* Feature Grid */}
      <FeatureGrid />
      
      {/* AI Personalities */}
      <AIPersonalities />
      
      {/* Pricing Section */}
      <PricingSection />
      
      {/* CTA Section */}
      <div className="py-24 bg-gradient-to-b from-purple-950/20 to-black">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
          >
            <h2 className="text-4xl font-bold mb-6 bg-gradient-to-r from-purple-400 to-pink-600 text-transparent bg-clip-text">
              Ready to Get Started?
            </h2>
            <p className="text-xl text-gray-300 mb-8 max-w-3xl mx-auto">
              Join our community of developers and AI enthusiasts
            </p>
            
            <div className="flex flex-wrap justify-center gap-4">
              <motion.button
                onClick={visitRoleModelAI}
                className="bg-gradient-to-r from-purple-600 to-pink-600 text-white px-8 py-4 rounded-lg text-lg font-semibold flex items-center gap-2 hover:from-purple-700 hover:to-pink-700 transition-colors"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Visit rolemodel.ai
                <ExternalLink className="w-5 h-5" />
              </motion.button>
            </div>
          </motion.div>
        </div>
      </div>
      
      {/* Footer */}
      <footer className="py-12 bg-black border-t border-gray-800">
        <div className="container mx-auto px-4 md:px-6">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div className="col-span-1 md:col-span-2">
              <h3 className="text-xl font-semibold text-purple-400 mb-4">About Role Model AI</h3>
              <p className="text-gray-400 mb-6">
                Role Model AI is designed to embody and learn from various experts, historical figures, and archetypes to provide deep, insightful guidance across domains.
              </p>
              
              <div className="flex flex-col md:flex-row justify-start items-center gap-4 mb-4">
                <h3 className="text-xl font-medium text-purple-300">SELF Labs Inc</h3>
                <span className="hidden md:block text-gray-500">•</span>
                <h3 className="text-xl font-medium text-purple-300">SELF Labs DAO L.L.C.</h3>
              </div>
            </div>
            
            <div>
              <h3 className="text-xl font-semibold text-purple-400 mb-4">Links</h3>
              <ul className="space-y-2">
                <li><a href="/" className="text-gray-400 hover:text-white transition-colors">Home</a></li>
                <li><a href="/dao" className="text-gray-400 hover:text-white transition-colors">DAO</a></li>
                <li><a href="/harmony-ai" className="text-gray-400 hover:text-white transition-colors">Harmony AI</a></li>
                <li><a href="/aura-llm" className="text-gray-400 hover:text-white transition-colors">AURA LLM</a></li>
              </ul>
            </div>
            
            <div>
              <h3 className="text-xl font-semibold text-purple-400 mb-4">Community</h3>
              <ul className="space-y-2">
                <li><a href="#" className="text-gray-400 hover:text-white transition-colors">Twitter</a></li>
                <li><a href="https://t.me/+2uJEHPAt4204M2Zh" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white transition-colors">Telegram</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white transition-colors">GitHub</a></li>
              </ul>
            </div>
          </div>
          
          <div className="border-t border-gray-800 mt-12 pt-8 text-center">
            <p className="text-gray-500">&copy; {new Date().getFullYear()} SELF Labs Inc & SELF Labs DAO L.L.C. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
}