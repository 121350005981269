import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { ArrowLeft, Brain, Zap, Cpu, Beaker, Shield, Microscope } from 'lucide-react';
import { Link } from 'react-router-dom';
import Modal from '../../shared/Modal';
import ContactForm from '../../forms/ContactForm';

export default function BloodPage() {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const openContactModal = () => setIsContactModalOpen(true);
  const closeContactModal = () => setIsContactModalOpen(false);
  
  return (
    <div className="min-h-screen bg-black text-white">
      <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
        <div className="mb-8">
          <Link to="/">
            <motion.button
              className="flex items-center text-gray-400 hover:text-white"
              whileHover={{ x: -5 }}
              transition={{ duration: 0.2 }}
            >
              <ArrowLeft className="w-5 h-5 mr-2" />
              Back to Home
            </motion.button>
          </Link>
        </div>
        
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className="text-4xl md:text-5xl font-bold mb-6 bg-gradient-to-r from-red-500 to-purple-600 text-transparent bg-clip-text">
            BLOOD: Next-Generation Computing Architecture
          </h1>
          
          <div className="mb-8">
            <div className="flex flex-wrap gap-2 mb-4">
              <span className="px-3 py-1 text-sm bg-red-900/50 text-red-300 rounded-full">
                Bio-nanotechnology
              </span>
              <span className="px-3 py-1 text-sm bg-red-900/50 text-red-300 rounded-full">
                Advanced Computing
              </span>
              <span className="px-3 py-1 text-sm bg-red-900/50 text-red-300 rounded-full">
                Neural Networks
              </span>
              <span className="px-3 py-1 text-sm bg-red-900/50 text-red-300 rounded-full">
                Patent Pending
              </span>
            </div>
          </div>
          
          <div className="prose prose-invert max-w-none mb-12">
            <p className="text-xl text-gray-300 mb-6 leading-relaxed">
              <span className="font-bold text-red-400">BLOOD</span> (Biological and Lightweight Organics Optimized for Dynamic memory systems) represents 
              a revolutionary breakthrough in advanced computing platform architecture, developed through years of research by SELF Labs. 
              This proprietary technology transcends traditional computing paradigms, with patent-pending innovations 
              (US Patent Application 18/901,437) that promise to redefine what's possible in artificial intelligence, neural networks, and computational processing.
            </p>
            
            <div className="my-10 bg-gradient-to-r from-red-900/30 to-purple-900/30 rounded-xl p-8">
              <h3 className="text-xl font-bold mb-6 text-center">Transformative Technology at the Intersection of Biology and Computing</h3>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="bg-red-900/20 border border-red-500/30 rounded-lg p-6">
                  <div className="flex items-start mb-3">
                    <Brain className="w-6 h-6 text-red-400 mr-2 mt-1" />
                    <h4 className="text-lg font-semibold text-red-300">Bio-Inspired Architecture</h4>
                  </div>
                  <p className="text-gray-300">
                    Inspired by biological systems, BLOOD creates a sophisticated vein-like network within computing platforms, 
                    enabling enhanced memory storage, communication, and computational capabilities beyond traditional architectures.
                  </p>
                </div>
                
                <div className="bg-purple-900/20 border border-purple-500/30 rounded-lg p-6">
                  <div className="flex items-start mb-3">
                    <Zap className="w-6 h-6 text-purple-400 mr-2 mt-1" />
                    <h4 className="text-lg font-semibold text-purple-300">Superior Computational Power</h4>
                  </div>
                  <p className="text-gray-300">
                    Our proprietary integration techniques create computational processes that potentially 
                    surpass traditional quantum computing, with massive parallel processing and enhanced 
                    adaptability for complex problem-solving.
                  </p>
                </div>
                
                <div className="bg-blue-900/20 border border-blue-500/30 rounded-lg p-6">
                  <div className="flex items-start mb-3">
                    <Cpu className="w-6 h-6 text-blue-400 mr-2 mt-1" />
                    <h4 className="text-lg font-semibold text-blue-300">Advanced Neural Networks</h4>
                  </div>
                  <p className="text-gray-300">
                    BLOOD enables significantly enhanced neural connectivity, plasticity, and network 
                    synchronization, creating AI systems with unprecedented learning abilities, adaptability, 
                    and decision-making processes.
                  </p>
                </div>
                
                <div className="bg-indigo-900/20 border border-indigo-500/30 rounded-lg p-6">
                  <div className="flex items-start mb-3">
                    <Beaker className="w-6 h-6 text-indigo-400 mr-2 mt-1" />
                    <h4 className="text-lg font-semibold text-indigo-300">Multidisciplinary Innovation</h4>
                  </div>
                  <p className="text-gray-300">
                    Combining advanced technologies from multiple disciplines, BLOOD represents a new 
                    paradigm in computing that bridges biology, nanotechnology, artificial intelligence, 
                    and computational neuroscience.
                  </p>
                </div>
              </div>
            </div>
            
            <h2 className="text-2xl font-bold mt-12 mb-4">Applications Across Industries</h2>
            
            <div className="space-y-8 mb-12">
              <div className="flex flex-col md:flex-row gap-6 items-start">
                <div className="bg-gradient-to-br from-red-600 to-red-800 rounded-full p-4 flex items-center justify-center w-16 h-16 md:w-20 md:h-20 flex-shrink-0">
                  <Shield className="w-8 h-8 text-white" />
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2 text-red-300">Advanced Computing</h3>
                  <p className="text-gray-300">
                    BLOOD technology creates computing systems with superior processing capabilities that can handle 
                    complex computations beyond the scope of traditional quantum computers. This has significant 
                    implications for cryptography, complex simulations, and big data processing.
                  </p>
                </div>
              </div>
              
              <div className="flex flex-col md:flex-row gap-6 items-start">
                <div className="bg-gradient-to-br from-purple-600 to-purple-800 rounded-full p-4 flex items-center justify-center w-16 h-16 md:w-20 md:h-20 flex-shrink-0">
                  <Cpu className="w-8 h-8 text-white" />
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2 text-purple-300">Next-Generation AI</h3>
                  <p className="text-gray-300">
                    Artificial intelligence systems powered by BLOOD demonstrate significantly enhanced learning abilities, 
                    adaptability, and decision-making processes. This can revolutionize autonomous systems, predictive 
                    analytics, and machine learning applications across industries.
                  </p>
                </div>
              </div>
              
              <div className="flex flex-col md:flex-row gap-6 items-start">
                <div className="bg-gradient-to-br from-blue-600 to-blue-800 rounded-full p-4 flex items-center justify-center w-16 h-16 md:w-20 md:h-20 flex-shrink-0">
                  <Microscope className="w-8 h-8 text-white" />
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2 text-blue-300">Research & Development</h3>
                  <p className="text-gray-300">
                    BLOOD provides an unprecedented platform for computational neuroscience, brain-computer interfaces, 
                    and advanced neural network research. This opens new frontiers in understanding neural behaviors 
                    and developing next-generation computing architectures.
                  </p>
                </div>
              </div>
            </div>
            
            <div className="bg-gradient-to-r from-red-900/30 to-purple-900/30 border border-red-500/20 rounded-lg p-6 my-8">
              <blockquote className="text-xl italic text-gray-300">
                "BLOOD represents the culmination of years of research at the intersection of biology and computing. 
                We've created a technology that doesn't just improve on existing paradigms—it completely reimagines 
                what's possible for advanced computing platforms and AI systems."
                <footer className="text-right mt-4 text-gray-400">— Founder of SELF Labs</footer>
              </blockquote>
            </div>
            
            <h2 className="text-2xl font-bold mt-12 mb-4">Why BLOOD Changes Everything</h2>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-12">
              <div className="bg-gradient-to-b from-red-900/20 to-red-900/10 border border-red-500/20 rounded-lg p-6">
                <h3 className="text-xl font-semibold mb-3 text-red-300">Beyond Traditional Computing</h3>
                <ul className="list-disc pl-6 space-y-2 text-gray-300">
                  <li>Potential to surpass quantum computing capabilities</li>
                  <li>Enhanced memory storage and retrieval efficiency</li>
                  <li>Massive parallel processing capabilities</li>
                  <li>Significantly reduced power requirements</li>
                  <li>Revolutionary approach to computational architecture</li>
                </ul>
              </div>
              
              <div className="bg-gradient-to-b from-purple-900/20 to-purple-900/10 border border-purple-500/20 rounded-lg p-6">
                <h3 className="text-xl font-semibold mb-3 text-purple-300">Transformative Integration</h3>
                <ul className="list-disc pl-6 space-y-2 text-gray-300">
                  <li>Seamless integration with existing computing platforms</li>
                  <li>Scalable architecture for various implementation sizes</li>
                  <li>Adaptable to specific computational needs</li>
                  <li>Customizable configurations for specialized applications</li>
                  <li>Forward compatibility with emerging technologies</li>
                </ul>
              </div>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
              <div className="bg-gray-800/50 rounded-lg p-6 text-center flex flex-col items-center">
                <div className="text-4xl font-bold text-red-400 mb-3">100x</div>
                <p className="text-gray-300">
                  potential performance increase over traditional computing architectures in complex computational tasks
                </p>
              </div>
              
              <div className="bg-gray-800/50 rounded-lg p-6 text-center flex flex-col items-center">
                <div className="text-4xl font-bold text-red-400 mb-3">85%</div>
                <p className="text-gray-300">
                  reduction in power consumption compared to equivalent computational systems
                </p>
              </div>
              
              <div className="bg-gray-800/50 rounded-lg p-6 text-center flex flex-col items-center">
                <div className="text-4xl font-bold text-red-400 mb-3">10+</div>
                <p className="text-gray-300">
                  years of projected technological advantage over conventional computing approaches
                </p>
              </div>
            </div>
            
            <h2 className="text-2xl font-bold mt-12 mb-4">Become a BLOOD Technology Partner</h2>
            
            <p className="mb-4">
              BLOOD technology is protected by pending patents and represents a significant competitive advantage 
              for organizations looking to lead the next generation of computing and AI applications. We offer 
              licensing partnerships and custom implementations for qualified organizations.
            </p>
            
            <p className="mb-4">
              Our team of experts works closely with partners to understand their specific requirements and 
              develop tailored solutions that maximize the benefits of BLOOD technology for their applications.
            </p>
            
            <div className="bg-gradient-to-r from-red-900/20 to-purple-900/20 rounded-lg p-6 mb-8">
              <h3 className="text-xl font-semibold mb-3 text-center text-red-300">Patent Protection</h3>
              <p className="text-gray-300 text-center">
                BLOOD technology is protected under US Patent Application 18/901,437
              </p>
            </div>
            
            <p className="mt-8 text-gray-300">
              Interested in learning more about BLOOD technology and how it could transform your organization's computing capabilities?
              <button 
                onClick={openContactModal}
                className="text-red-400 hover:text-red-300 ml-2 underline focus:outline-none"
              >
                Contact us to discuss partnership opportunities.
              </button>
            </p>
          </div>
        </motion.div>
      </div>
      
      {/* Contact Form Modal */}
      <Modal 
        isOpen={isContactModalOpen} 
        onClose={closeContactModal}
        title="BLOOD Technology Partnership Inquiry"
      >
        <ContactForm />
      </Modal>
    </div>
  );
} 