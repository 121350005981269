import { Brain, Bot, Headphones, ShoppingBag, Database, Cpu, MessageSquare, Microscope, Phone } from 'lucide-react';

export const products = [
  {
    title: "SELF Phone",
    description: "A privacy-first smartphone that integrates with AuRA Protocol, offering advanced security features and seamless AI capabilities while protecting your personal data.",
    icon: Phone,
    color: {
      bg: "bg-indigo-950/50",
      text: "text-indigo-300",
      gradient: "bg-gradient-to-br from-indigo-600/30 via-blue-600/30 to-cyan-600/30"
    },
    features: [
      "AuRA Protocol Integration",
      "Advanced Privacy Protection",
      "AI-Powered Features",
      "Secure Data Management"
    ]
  },
  {
    title: "BLOOD",
    description: "Revolutionary bio-nanotechnology system integrating engineered exosomes and optogenetics for advanced computing platforms and humanoid robots, offering unprecedented memory and processing capabilities.",
    icon: Microscope,
    color: {
      bg: "bg-violet-950/50",
      text: "text-violet-300",
      gradient: "bg-gradient-to-br from-violet-600/30 via-purple-600/30 to-fuchsia-600/30"
    },
    features: [
      "Bio-nanotechnology Integration",
      "Enhanced Synaptic Plasticity", 
      "Quantum-level Processing",
      "Advanced Memory Systems"
    ]
  },
  {
    title: "Role Model AI",
    description: "The ultimate all-in-one AI experience with access to over 100 language models and personalized assistants.",
    icon: Brain,
    color: {
      bg: "bg-purple-950/50",
      text: "text-purple-300",
      gradient: "bg-gradient-to-br from-purple-600/30 via-fuchsia-600/30 to-pink-600/30"
    },
    features: [
      "100+ Language Models",
      "AuRA Data Protection",
      "Concierge AI Integration",
      "Custom AI Assistants"
    ]
  },
  {
    title: "Cerebral OS",
    description: "Revolutionary brain-computer interface operating system enabling seamless control of any connected device or application.",
    icon: Cpu,
    color: {
      bg: "bg-cyan-950/50",
      text: "text-cyan-300",
      gradient: "bg-gradient-to-br from-cyan-600/30 via-blue-600/30 to-indigo-600/30"
    },
    features: [
      "Universal Device Control",
      "Neural Command Interface",
      "Real-time Thought Translation",
      "Cross-platform Integration"
    ]
  },
  {
    title: "Inner Voice",
    description: "Personalized AI meditation app that lets you create your own meditation guide, choosing practices and frequencies tailored to your spiritual journey.",
    icon: MessageSquare,
    color: {
      bg: "bg-emerald-950/50",
      text: "text-emerald-300",
      gradient: "bg-gradient-to-br from-emerald-600/30 via-teal-600/30 to-green-600/30"
    },
    features: [
      "AI Voice Cloning",
      "Custom Meditation Programs",
      "Frequency Selection",
      "Personalized Practice Paths"
    ]
  },
  {
    title: "Concierge AI",
    description: "Your personal AI assistant that learns and adapts to your needs, providing seamless support across all aspects of life.",
    icon: Bot,
    color: {
      bg: "bg-blue-950/50",
      text: "text-blue-300",
      gradient: "bg-gradient-to-br from-blue-600/30 via-cyan-600/30 to-teal-600/30"
    },
    features: [
      "Personalized Learning",
      "Multi-domain Expertise",
      "Natural Conversation",
      "Task Automation"
    ]
  },
  {
    title: "AuRA Resonant Layer",
    description: "Our flagship resonant middleware layer revolutionizing AI interaction by creating an ethical, economical connection between humans and AI, establishing a collaborative growth ecosystem.",
    icon: Database,
    color: {
      bg: "bg-pink-950/50",
      text: "text-pink-300",
      gradient: "bg-gradient-to-br from-pink-600/30 via-rose-600/30 to-red-600/30"
    },
    features: [
      "Signal Layer Integration",
      "Data Ownership System",
      "Ethical AI Framework",
      "Value Recognition"
    ]
  },
  {
    title: "AYOTA Marketplace",
    description: "Your one-stop destination for high-performance computers, cutting-edge hardware components, and specialized software solutions.",
    icon: ShoppingBag,
    color: {
      bg: "bg-amber-950/50",
      text: "text-amber-300",
      gradient: "bg-gradient-to-br from-amber-600/30 via-orange-600/30 to-yellow-600/30"
    },
    features: [
      "Custom PC Builds",
      "Premium Components",
      "Specialized Software",
      "Expert Support"
    ]
  }
];