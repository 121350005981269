import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { X, MessageSquare, Clock, ArrowRight, Lightbulb } from 'lucide-react';
import { format } from 'date-fns';
import type { LucideIcon } from 'lucide-react';
import { InitiativesTab } from './InitiativesTab';
import { ChatTab } from './ChatTab';

interface AgentDetailsProps {
  agent: {
    id: string;
    title: string;
    description: string;
    icon: LucideIcon;
    metrics?: {
      label: string;
      value: string;
    }[];
  };
  onClose: () => void;
}

interface Activity {
  id: number;
  timestamp: Date;
  message: string;
  status: 'completed' | 'in_progress' | 'pending';
}

export function AgentDetails({ agent, onClose }: AgentDetailsProps) {
  const [activeTab, setActiveTab] = useState('activity');
  const [activities, setActivities] = useState<Activity[]>([]);

  useEffect(() => {
    // Generate relevant activities based on agent type
    const agentActivities = getAgentActivities(agent.title);
    setActivities(agentActivities);
  }, [agent.title]);

  const getAgentActivities = (agentTitle: string): Activity[] => {
    const now = new Date();
    const thirtyMinutesAgo = new Date(now.getTime() - 30 * 60000);
    const oneHourAgo = new Date(now.getTime() - 60 * 60000);

    switch (agentTitle) {
      case 'Lead Generation Agent':
        return [
          {
            id: 1,
            timestamp: now,
            message: 'Generated 25 new qualified leads',
            status: 'completed'
          },
          {
            id: 2,
            timestamp: thirtyMinutesAgo,
            message: 'Analyzing target company profiles',
            status: 'in_progress'
          },
          {
            id: 3,
            timestamp: oneHourAgo,
            message: 'Updated outreach campaign parameters',
            status: 'completed'
          }
        ];
      case 'Social Media Agent':
        return [
          {
            id: 1,
            timestamp: now,
            message: 'Scheduled content for next week',
            status: 'completed'
          },
          {
            id: 2,
            timestamp: thirtyMinutesAgo,
            message: 'Analyzing engagement metrics',
            status: 'in_progress'
          },
          {
            id: 3,
            timestamp: oneHourAgo,
            message: 'Generated social media report',
            status: 'completed'
          }
        ];
      case 'Bookkeeping Agent':
        return [
          {
            id: 1,
            timestamp: now,
            message: 'Reconciled monthly transactions',
            status: 'completed'
          },
          {
            id: 2,
            timestamp: thirtyMinutesAgo,
            message: 'Preparing quarterly tax report',
            status: 'in_progress'
          },
          {
            id: 3,
            timestamp: oneHourAgo,
            message: 'Updated expense categories',
            status: 'completed'
          }
        ];
      case 'Meeting Agent':
        return [
          {
            id: 1,
            timestamp: now,
            message: 'Hosted investor presentation',
            status: 'completed'
          },
          {
            id: 2,
            timestamp: thirtyMinutesAgo,
            message: 'Preparing meeting summary',
            status: 'in_progress'
          },
          {
            id: 3,
            timestamp: oneHourAgo,
            message: 'Scheduled follow-up calls',
            status: 'completed'
          }
        ];
      case 'Phone Agent':
        return [
          {
            id: 1,
            timestamp: now,
            message: 'Handled 15 investor inquiries',
            status: 'completed'
          },
          {
            id: 2,
            timestamp: thirtyMinutesAgo,
            message: 'Updating call routing rules',
            status: 'in_progress'
          },
          {
            id: 3,
            timestamp: oneHourAgo,
            message: 'Generated call analytics report',
            status: 'completed'
          }
        ];
      case 'CTO Coding Agent':
        return [
          {
            id: 1,
            timestamp: now,
            message: 'Deployed new API endpoints',
            status: 'completed'
          },
          {
            id: 2,
            timestamp: thirtyMinutesAgo,
            message: 'Optimizing database queries',
            status: 'in_progress'
          },
          {
            id: 3,
            timestamp: oneHourAgo,
            message: 'Code review completed',
            status: 'completed'
          }
        ];
      default:
        return [];
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 bg-black/90 backdrop-blur-sm z-50 flex items-center justify-center"
      >
        <motion.div
          initial={{ scale: 0.9, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          exit={{ scale: 0.9, opacity: 0 }}
          className="bg-[#121214] rounded-xl w-full max-w-4xl max-h-[90vh] overflow-hidden"
        >
          <div className="flex items-center justify-between p-6 border-b border-white/10">
            <div className="flex items-center space-x-4">
              <div className="p-2 bg-white/5 rounded-lg">
                <agent.icon className="w-6 h-6 text-[#00FFFF]" />
              </div>
              <div>
                <h2 className="text-xl font-semibold text-white">{agent.title}</h2>
                <p className="text-sm text-gray-400">{agent.description}</p>
              </div>
            </div>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-white transition-colors"
            >
              <X className="w-6 h-6" />
            </button>
          </div>

          <div className="p-6">
            <div className="flex space-x-4 mb-6">
              <button
                onClick={() => setActiveTab('activity')}
                className={`px-4 py-2 rounded-lg transition-colors ${
                  activeTab === 'activity'
                    ? 'bg-white/10 text-white'
                    : 'text-gray-400 hover:text-white'
                }`}
              >
                Activity Log
              </button>
              <button
                onClick={() => setActiveTab('chat')}
                className={`px-4 py-2 rounded-lg transition-colors ${
                  activeTab === 'chat'
                    ? 'bg-white/10 text-white'
                    : 'text-gray-400 hover:text-white'
                }`}
              >
                Chat
              </button>
              <button
                onClick={() => setActiveTab('initiatives')}
                className={`px-4 py-2 rounded-lg transition-colors ${
                  activeTab === 'initiatives'
                    ? 'bg-white/10 text-white'
                    : 'text-gray-400 hover:text-white'
                }`}
              >
                <div className="flex items-center space-x-2">
                  <Lightbulb className="w-4 h-4" />
                  <span>Initiatives</span>
                </div>
              </button>
            </div>

            {activeTab === 'activity' && (
              <div className="space-y-4">
                {activities.map((activity) => (
                  <div
                    key={activity.id}
                    className="flex items-start space-x-4 p-4 bg-white/5 rounded-lg"
                  >
                    <Clock className="w-5 h-5 text-gray-400 flex-shrink-0 mt-1" />
                    <div className="flex-1">
                      <div className="flex items-center justify-between">
                        <span className="text-white">{activity.message}</span>
                        <span className="text-sm text-gray-400">
                          {format(activity.timestamp, 'HH:mm')}
                        </span>
                      </div>
                      <span className={`text-sm ${
                        activity.status === 'completed' ? 'text-green-400' : 'text-blue-400'
                      }`}>
                        {activity.status === 'completed' ? 'Completed' : 'In Progress'}
                      </span>
                    </div>
                  </div>
                ))}
              </div>
            )}

            {activeTab === 'chat' && (
              <ChatTab agentId={agent.id} agentRole={agent.title} />
            )}

            {activeTab === 'initiatives' && (
              <InitiativesTab agentId={agent.id} agentRole={agent.title} />
            )}
          </div>
        </motion.div>
      </motion.div>
    </AnimatePresence>
  );
}