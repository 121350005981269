import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { ArrowLeft, Timer, Zap, Coins, History, Share2, Sparkles, Headset } from 'lucide-react';
import { Link } from 'react-router-dom';
import Modal from '../shared/Modal';
import ContactForm from '../forms/ContactForm';

export default function ReflectCaseStudy() {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const openContactModal = () => setIsContactModalOpen(true);
  const closeContactModal = () => setIsContactModalOpen(false);
  
  return (
    <div className="min-h-screen bg-black text-white">
      <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
        <div className="mb-8">
          <Link to="/">
            <motion.button
              className="flex items-center text-gray-400 hover:text-white"
              whileHover={{ x: -5 }}
              transition={{ duration: 0.2 }}
            >
              <ArrowLeft className="w-5 h-5 mr-2" />
              Back to Home
            </motion.button>
          </Link>
        </div>
        
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className="text-4xl md:text-5xl font-bold mb-6 bg-gradient-to-r from-purple-400 to-blue-600 text-transparent bg-clip-text">
            Reflect: Relive Your Memories In The Metaverse
          </h1>
          
          <div className="mb-8">
            <div className="flex flex-wrap gap-2 mb-4">
              <span className="px-3 py-1 text-sm bg-purple-900/50 text-purple-300 rounded-full">
                Metaverse
              </span>
              <span className="px-3 py-1 text-sm bg-purple-900/50 text-purple-300 rounded-full">
                Memory NFTs
              </span>
              <span className="px-3 py-1 text-sm bg-purple-900/50 text-purple-300 rounded-full">
                VR Experience
              </span>
            </div>
          </div>
          
          <div className="prose prose-invert max-w-none mb-12">
            <p className="text-xl text-gray-300 mb-6">
              By now I'm sure everyone has heard of the fantasy of time travel, but what if I could tell you that we can make that fantasy a reality? In Virtual Reality, that is.
            </p>
            
            <div className="my-12">
              <img 
                src="https://i.ibb.co/W4p5N6qD/u9921589829-Memories-in-a-paraell-universe-v-6-1-a2d09bd0-87b7-45c6-8ee4-7d46ce2d1376-2.png" 
                alt="Reflect - Relive Your Memories" 
                className="w-full h-64 md:h-96 object-cover rounded-xl"
              />
            </div>
            
            <h2 className="text-2xl font-bold mt-8 mb-4">Introduction to Reflect</h2>
            <p className="mb-4">
              Reflect is an innovative simulation game created by Milan Cheeks, Alan Charity, and SELF Labs Inc. It's based on the principles of memory, allowing users to relive their memories in the Metaverse by minting them as NFTs. 
            </p>
            
            <p className="mb-4">
              By owning a Reflect Mint Pass, you are able to have your memories transformed into interactive worlds populated with AI Reflection Avatars to interact with, all powered by high-resolution gameplay using Unity & Unreal Engine 5.
            </p>
            
            <h2 className="text-2xl font-bold mt-12 mb-4">How Does It Work?</h2>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
              <div className="bg-gradient-to-b from-purple-900/20 to-purple-900/5 rounded-xl p-6 border border-purple-500/20">
                <div className="flex items-center mb-4">
                  <div className="w-12 h-12 bg-purple-900/70 rounded-full flex items-center justify-center mr-4">
                    <Headset className="h-6 w-6 text-purple-300" />
                  </div>
                  <h3 className="text-xl font-semibold text-white">Tiered Access System</h3>
                </div>
                <p className="text-gray-300">
                  There are 4 tiers of Mint Passes available, each allowing you to mint memories from different eras:
                </p>
                <ul className="list-disc pl-6 mt-3 space-y-1 text-gray-300">
                  <li><span className="text-purple-300 font-medium">Common Tier:</span> Mint memories up to 2 years in the past</li>
                  <li><span className="text-purple-300 font-medium">Rare Tier:</span> Mint memories up to 10 years in the past</li>
                  <li><span className="text-purple-300 font-medium">Ultra-Rare Tier:</span> Mint memories up to 20 years in the past</li>
                  <li><span className="text-purple-300 font-medium">Legendary Tier:</span> Mint memories from any era</li>
                </ul>
              </div>
              
              <div className="bg-gradient-to-b from-purple-900/20 to-purple-900/5 rounded-xl p-6 border border-purple-500/20">
                <div className="flex items-center mb-4">
                  <div className="w-12 h-12 bg-purple-900/70 rounded-full flex items-center justify-center mr-4">
                    <Timer className="h-6 w-6 text-purple-300" />
                  </div>
                  <h3 className="text-xl font-semibold text-white">Three-Step Process</h3>
                </div>
                <p className="text-gray-300">
                  Each memory can be minted by following a simple three-step process:
                </p>
                <ol className="list-decimal pl-6 mt-3 space-y-1 text-gray-300">
                  <li>Choose which Tier Pass you would like to mint</li>
                  <li>Upload a picture or video of your memory and provide a detailed description</li>
                  <li>Mint your NFT, stake it, and have your world created by Mirror Image Studios</li>
                </ol>
              </div>
            </div>
            
            <div className="bg-gradient-to-r from-purple-900/20 to-blue-900/20 rounded-xl p-8 mb-12 relative overflow-hidden">
              <div className="absolute -top-10 -left-10 w-40 h-40 bg-purple-600/10 rounded-full blur-3xl z-0"></div>
              <div className="absolute -bottom-10 -right-10 w-40 h-40 bg-blue-600/10 rounded-full blur-3xl z-0"></div>
              <div className="relative z-10">
                <h3 className="text-xl font-semibold mb-4 text-white">Immersive Gameplay Experience</h3>
                <p className="text-gray-300">
                  Each memory is fully immersive with high-end graphics depicting your memory in a real-world setting. AI Reflection Avatars enhance the experience by allowing you to interact with each person in your memory. These avatars can speak in the voices of people from your memory, creating an authentic experience that feels like stepping back in time.
                </p>
                <p className="text-gray-300 mt-4">
                  For example, if you mint a memory of your 10-year-old birthday party, you can relive this memory and interact with everyone who was in attendance. You can talk and fully interact with these avatars, and even continue the simulation beyond the original memory, with your decisions determining what happens next.
                </p>
                <p className="text-gray-300 mt-4">
                  By continuing the memory, the avatars will adapt, and each memory can become a civilization of these "living" Smart NPCs, creating endless possibilities for how your memories evolve.
                </p>
              </div>
            </div>
            
            <h2 className="text-2xl font-bold mt-12 mb-4">Quantum Jumping: A Life With No Regrets</h2>
            
            <div className="space-y-6 mb-12">
              <div className="flex flex-col md:flex-row gap-6 items-start">
                <div className="flex-shrink-0 w-16 h-16 bg-gradient-to-br from-purple-600 to-blue-600 rounded-full flex items-center justify-center">
                  <Zap className="h-8 w-8 text-white" />
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-3 text-purple-300">Reimagine Your Past</h3>
                  <p className="text-gray-300">
                    Have you ever thought about a memory and contemplated how you could have done something differently? With Reflect's Quantum Jumping feature, you no longer need to wonder about alternative outcomes. This function allows you to go back into a memory and make different decisions than what you made in the past.
                  </p>
                  <p className="text-gray-300 mt-3">
                    For example, using the same 10-year-old birthday party example, you can choose not to go to the party and see what might have happened instead. This feature allows you to explore countless "what if" scenarios in your own personal history.
                  </p>
                </div>
              </div>
            </div>
            
            <h2 className="text-2xl font-bold mt-12 mb-4">NFT Trading and Social Experience</h2>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
              <div className="bg-gradient-to-b from-purple-900/20 to-purple-900/5 rounded-xl p-6 border border-purple-500/20">
                <div className="flex items-center mb-4">
                  <div className="w-12 h-12 bg-purple-900/70 rounded-full flex items-center justify-center mr-4">
                    <Coins className="h-6 w-6 text-purple-300" />
                  </div>
                  <h3 className="text-xl font-semibold text-white">Trading Memories</h3>
                </div>
                <p className="text-gray-300">
                  Once you receive your mint pass, you can sell it or buy others on NFT marketplaces like OpenSea at any time. You can sell it before your world is created or even after the simulation begins, creating a dynamic marketplace for memories.
                </p>
              </div>
              
              <div className="bg-gradient-to-b from-purple-900/20 to-purple-900/5 rounded-xl p-6 border border-purple-500/20">
                <div className="flex items-center mb-4">
                  <div className="w-12 h-12 bg-purple-900/70 rounded-full flex items-center justify-center mr-4">
                    <Share2 className="h-6 w-6 text-purple-300" />
                  </div>
                  <h3 className="text-xl font-semibold text-white">Open World Concept</h3>
                </div>
                <p className="text-gray-300">
                  Reflect features an open world concept similar to Decentraland or Sandbox, but instead of plots, there's a conjunction of different memories. This allows anyone to join your memory and relive the past with you, creating a social dimension to the experience of revisiting memories.
                </p>
              </div>
            </div>
            
            <h2 className="text-2xl font-bold mt-12 mb-4">Historic Memories</h2>
            
            <div className="flex flex-col md:flex-row gap-6 items-start mb-12">
              <div className="flex-shrink-0 w-16 h-16 bg-gradient-to-br from-purple-600 to-blue-600 rounded-full flex items-center justify-center">
                <History className="h-8 w-8 text-white" />
              </div>
              <div>
                <h3 className="text-xl font-semibold mb-3 text-purple-300">Relive Historical Moments</h3>
                <p className="text-gray-300">
                  Historic memories are a significant feature of the Reflect game and are exclusive to Legendary Tier Mint Pass holders. These users have the ability to collaborate with institutions like museums to recreate historical memories such as Martin Luther King's "I Have A Dream" speech or even go further back to experience life with George Washington.
                </p>
                <p className="text-gray-300 mt-3">
                  This feature transforms Reflect from a personal memory archive into an interactive historical record, allowing users to experience pivotal moments in human history firsthand.
                </p>
              </div>
            </div>
            
            <h2 className="text-2xl font-bold mt-12 mb-4">Getting Started</h2>
            
            <div className="bg-gradient-to-r from-purple-900/20 to-blue-900/20 rounded-xl p-8 mb-12 relative overflow-hidden">
              <div className="absolute -top-10 -left-10 w-40 h-40 bg-purple-600/10 rounded-full blur-3xl z-0"></div>
              <div className="absolute -bottom-10 -right-10 w-40 h-40 bg-blue-600/10 rounded-full blur-3xl z-0"></div>
              <div className="relative z-10">
                <h3 className="text-xl font-semibold mb-4 text-white">Exclusive to Mirror Image One Console</h3>
                <p className="text-gray-300">
                  Reflect is an exclusive game on the Mirror Image One Console and for a limited time, the game comes FREE with every pre-order. The console serves as the gateway to your memories, providing the necessary technology to bring your past to life in vivid detail.
                </p>
                <p className="text-gray-300 mt-4">
                  To learn more about the game and to pre-order your console, visit:
                </p>
                <div className="flex flex-col md:flex-row gap-4 mt-6">
                  <a href="http://www.reflectgame.xyz" target="_blank" rel="noopener noreferrer" className="text-purple-300 hover:text-purple-200 flex items-center">
                    <Sparkles className="h-5 w-5 mr-2" />
                    www.reflectgame.xyz
                  </a>
                  <a href="http://www.mirrorimageone.com" target="_blank" rel="noopener noreferrer" className="text-purple-300 hover:text-purple-200 flex items-center">
                    <Headset className="h-5 w-5 mr-2" />
                    www.mirrorimageone.com
                  </a>
                </div>
              </div>
            </div>
            
            <div className="bg-gradient-to-r from-purple-900/20 to-blue-900/20 rounded-xl p-8 mt-12 mb-8">
              <h3 className="text-xl font-semibold mb-4 text-center text-white">Interested in Learning More About Reflect?</h3>
              <p className="text-gray-300 text-center mb-6">
                Discover how you can relive your most cherished memories in breathtaking detail and explore the limitless possibilities of memory-based virtual reality.
              </p>
              <div className="flex justify-center">
                <motion.button
                  onClick={openContactModal}
                  className="bg-gradient-to-r from-purple-600 to-blue-600 hover:from-purple-700 hover:to-blue-700 text-white px-8 py-3 rounded-lg font-semibold"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Contact Us to Learn More
                </motion.button>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
      
      {/* Contact Form Modal */}
      <Modal 
        isOpen={isContactModalOpen} 
        onClose={closeContactModal}
        title="Learn More About Reflect"
      >
        <ContactForm />
      </Modal>
    </div>
  );
} 