import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { ArrowLeft, Brain, Zap, Shield, Database, LineChart, Users, Key, Sparkles, RefreshCw, DollarSign, Lock, BrainCircuit, Wallet } from 'lucide-react';
import { Link } from 'react-router-dom';
import Modal from '../../shared/Modal';
import ContactForm from '../../forms/ContactForm';

export default function AuraResonantLayerPage() {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const openContactModal = () => {
    setIsContactModalOpen(true);
  };
  
  const closeContactModal = () => {
    setIsContactModalOpen(false);
  };
  
  return (
    <div className="min-h-screen bg-gradient-to-b from-[#0A0A0B] via-black to-[#0A0A0B]">
      {/* Hero Section */}
      <section className="relative min-h-screen flex items-center justify-center py-20">
        <div className="absolute inset-0 bg-gradient-to-b from-pink-950/50 via-black to-black" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,rgba(255,105,180,0.03)_0%,transparent_1px)] bg-[length:24px_24px]" />
        
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="mb-12"
          >
            <Database className="w-24 h-24 mx-auto mb-8 text-pink-500" />
            <h1 className="text-5xl md:text-7xl font-bold mb-6 bg-gradient-to-r from-pink-500 to-red-500 text-transparent bg-clip-text">
              AuRA Resonant Layer
            </h1>
            <p className="text-xl md:text-2xl text-gray-400 max-w-3xl mx-auto">
              A resonant middleware layer wrapping around AI systems, creating an ethical, economical connection between humans and AI
            </p>
          </motion.div>

          <div className="grid md:grid-cols-2 lg:grid-cols-5 gap-8 text-center">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.2 }}
              className="p-6 rounded-2xl bg-gradient-to-b from-pink-950/50 to-transparent border border-pink-500/20"
            >
              <h3 className="text-xl font-semibold mb-2 text-pink-300">Signal Layer</h3>
              <p className="text-gray-400">Resonant middleware integration for AI systems</p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.3 }}
              className="p-6 rounded-2xl bg-gradient-to-b from-pink-950/50 to-transparent border border-pink-500/20"
            >
              <h3 className="text-xl font-semibold mb-2 text-pink-300">Data Ownership</h3>
              <p className="text-gray-400">Full control and sovereignty over your data</p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.4 }}
              className="p-6 rounded-2xl bg-gradient-to-b from-pink-950/50 to-transparent border border-pink-500/20"
            >
              <h3 className="text-xl font-semibold mb-2 text-pink-300">Ethical AI</h3>
              <p className="text-gray-400">Transparent and ethical AI interactions</p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.5 }}
              className="p-6 rounded-2xl bg-gradient-to-b from-pink-950/50 to-transparent border border-pink-500/20"
            >
              <h3 className="text-xl font-semibold mb-2 text-pink-300">Value Recognition</h3>
              <p className="text-gray-400">Fair compensation for data contributions</p>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: 0.6 }}
              className="p-6 rounded-2xl bg-gradient-to-b from-pink-950/50 to-transparent border border-pink-500/20"
            >
              <h3 className="text-xl font-semibold mb-2 text-pink-300">Direct Rewards</h3>
              <p className="text-gray-400">Immediate benefits from AI interactions</p>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-20 relative">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-2 gap-12">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              className="space-y-6"
            >
              <h2 className="text-4xl font-bold mb-8 bg-gradient-to-r from-pink-500 to-red-500 text-transparent bg-clip-text">
                Revolutionizing AI Interaction
              </h2>
              <p className="text-gray-400 text-lg">
                AuRA Resonant Layer creates a harmonious connection between humans and AI systems, ensuring ethical data practices and fair value distribution.
              </p>
              <ul className="space-y-4">
                <li className="flex items-center text-gray-300">
                  <Shield className="w-5 h-5 mr-3 text-pink-500" />
                  <span>Secure and transparent data handling</span>
                </li>
                <li className="flex items-center text-gray-300">
                  <Brain className="w-5 h-5 mr-3 text-pink-500" />
                  <span>Advanced resonance-based middleware</span>
                </li>
                <li className="flex items-center text-gray-300">
                  <Lock className="w-5 h-5 mr-3 text-pink-500" />
                  <span>Enhanced privacy protection</span>
                </li>
                <li className="flex items-center text-gray-300">
                  <Wallet className="w-5 h-5 mr-3 text-pink-500" />
                  <span>Direct value recognition system</span>
                </li>
              </ul>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
              className="relative"
            >
              <div className="aspect-w-16 aspect-h-9 rounded-2xl overflow-hidden bg-gradient-to-br from-pink-950/50 via-black to-red-950/50 border border-pink-500/20">
                {/* Add visualization or image here */}
                <div className="p-8 flex items-center justify-center">
                  <Database className="w-32 h-32 text-pink-500/50" />
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>
      
      {/* CTA Section */}
      <div className="py-20 bg-gradient-to-b from-pink-950/30 to-black">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
            className="bg-gradient-to-br from-pink-900/20 to-red-900/10 backdrop-blur-sm rounded-2xl p-8 md:p-12 border border-pink-500/20"
          >
            <div className="text-center mb-8">
              <h2 className="text-3xl md:text-4xl font-bold text-white mb-4">
                Ready to Experience a New AI Relationship?
              </h2>
              <p className="text-xl text-gray-300 max-w-2xl mx-auto">
                Join the AuRA ecosystem and transform how you interact with AI while being fairly compensated for your contributions.
              </p>
            </div>
            
            <div className="flex flex-col md:flex-row items-center justify-center gap-4">
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="px-8 py-4 bg-gradient-to-r from-pink-500 to-red-500 text-white font-semibold rounded-xl text-lg"
                onClick={openContactModal}
              >
                Contact Us
              </motion.button>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="px-8 py-4 bg-transparent border border-pink-500 text-pink-400 font-semibold rounded-xl text-lg"
                onClick={openContactModal}
              >
                Schedule a Demo
              </motion.button>
            </div>
          </motion.div>
        </div>
      </div>
      
      {/* Footer */}
      <footer className="py-12 bg-black border-t border-gray-800">
        <div className="container mx-auto px-4 md:px-6">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
            <div className="col-span-1 md:col-span-2">
              <h3 className="text-xl font-semibold text-purple-400 mb-4">About AURA</h3>
              <p className="text-gray-400 mb-6">
                AURA is the consciousness-aware large language model from SELF Labs. It's designed to understand the depth of human consciousness and provide meaningful insights and support.
              </p>
              
              <div className="flex flex-col md:flex-row justify-start items-center gap-4 mb-4">
                <h3 className="text-xl font-medium text-purple-300">SELF Labs Inc</h3>
                <span className="hidden md:block text-gray-500">•</span>
                <h3 className="text-xl font-medium text-purple-300">SELF Labs DAO L.L.C.</h3>
              </div>
            </div>
            
            <div>
              <h3 className="text-xl font-semibold text-purple-400 mb-4">Links</h3>
              <ul className="space-y-2">
                <li><a href="/" className="text-gray-400 hover:text-white transition-colors">Home</a></li>
                <li><a href="/dao" className="text-gray-400 hover:text-white transition-colors">DAO</a></li>
                <li><a href="/harmony-ai" className="text-gray-400 hover:text-white transition-colors">Harmony AI</a></li>
                <li><a href="/inner-voice" className="text-gray-400 hover:text-white transition-colors">Inner Voice</a></li>
              </ul>
            </div>
            
            <div>
              <h3 className="text-xl font-semibold text-purple-400 mb-4">Community</h3>
              <ul className="space-y-2">
                <li><a href="#" className="text-gray-400 hover:text-white transition-colors">Twitter</a></li>
                <li><a href="https://t.me/+2uJEHPAt4204M2Zh" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white transition-colors">Telegram</a></li>
                <li><a href="#" className="text-gray-400 hover:text-white transition-colors">GitHub</a></li>
              </ul>
            </div>
          </div>
          
          <div className="border-t border-gray-800 mt-12 pt-8 text-center">
            <p className="text-gray-500">&copy; {new Date().getFullYear()} SELF Labs Inc & SELF Labs DAO L.L.C. All rights reserved.</p>
          </div>
        </div>
      </footer>
      
      {/* Contact Modal */}
      <Modal 
        isOpen={isContactModalOpen} 
        onClose={closeContactModal} 
        title="Contact AuRA Team"
      >
        <ContactForm />
      </Modal>
    </div>
  );
} 