import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { ArrowLeft, Activity, Video, Brain, Zap } from 'lucide-react';
import { Link } from 'react-router-dom';
import Modal from '../shared/Modal';
import SportsCoachingContactForm from '../forms/SportsCoachingContactForm';

export default function SportsCoachingCaseStudy() {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const openContactModal = () => setIsContactModalOpen(true);
  const closeContactModal = () => setIsContactModalOpen(false);
  
  return (
    <div className="min-h-screen bg-black text-white">
      <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
        <div className="mb-8">
          <Link to="/">
            <motion.button
              className="flex items-center text-gray-400 hover:text-white"
              whileHover={{ x: -5 }}
              transition={{ duration: 0.2 }}
            >
              <ArrowLeft className="w-5 h-5 mr-2" />
              Back to Home
            </motion.button>
          </Link>
        </div>
        
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className="text-4xl md:text-5xl font-bold mb-6 bg-gradient-to-r from-teal-400 to-blue-600 text-transparent bg-clip-text">
            AI-Powered Sports Coaching
          </h1>
          
          <div className="mb-8">
            <div className="flex flex-wrap gap-2 mb-4">
              <span className="px-3 py-1 text-sm bg-blue-900/50 text-blue-300 rounded-full">
                Computer Vision
              </span>
              <span className="px-3 py-1 text-sm bg-blue-900/50 text-blue-300 rounded-full">
                Large Language Models
              </span>
              <span className="px-3 py-1 text-sm bg-blue-900/50 text-blue-300 rounded-full">
                Real-time Analysis
              </span>
              <span className="px-3 py-1 text-sm bg-blue-900/50 text-blue-300 rounded-full">
                Performance Optimization
              </span>
            </div>
          </div>
          
          <div className="prose prose-invert max-w-none mb-12">
            <p className="text-xl text-gray-300 mb-6">
              Our AI-Powered Sports Coaching system transforms athletic training by providing real-time performance analysis 
              and personalized improvement strategies through advanced video processing and large language models.
            </p>
            
            <h2 className="text-2xl font-bold mt-8 mb-4">Revolutionizing Sports Analysis Through AI</h2>
            <p className="mb-4">
              Traditional sports coaching relies heavily on human observation, which is inherently limited by attention span, 
              subjective perception, and the ability to process multiple events simultaneously. Our AI-Powered Sports Coaching 
              platform overcomes these limitations by capturing and analyzing every moment of practice and gameplay.
            </p>
            
            <div className="my-10 bg-gradient-to-r from-blue-900/30 to-teal-900/30 rounded-xl p-8">
              <h3 className="text-xl font-bold mb-6 text-center">The Four Pillars of AI Sports Analysis</h3>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="bg-blue-900/20 border border-blue-500/30 rounded-lg p-6">
                  <div className="flex items-start mb-3">
                    <Video className="w-6 h-6 text-blue-400 mr-2 mt-1" />
                    <h4 className="text-lg font-semibold text-blue-300">Video Capture & Processing</h4>
                  </div>
                  <p className="text-gray-300">
                    Multiple camera angles capture every movement during practice and games. Advanced computer vision 
                    algorithms track player positions, movements, ball trajectories, and posture in real-time.
                  </p>
                </div>
                
                <div className="bg-teal-900/20 border border-teal-500/30 rounded-lg p-6">
                  <div className="flex items-start mb-3">
                    <Brain className="w-6 h-6 text-teal-400 mr-2 mt-1" />
                    <h4 className="text-lg font-semibold text-teal-300">LLM Analysis Engine</h4>
                  </div>
                  <p className="text-gray-300">
                    Our specialized large language models process video data alongside historical performance metrics, 
                    identifying patterns, strengths, weaknesses, and opportunities for improvement that might escape 
                    the human eye.
                  </p>
                </div>
                
                <div className="bg-cyan-900/20 border border-cyan-500/30 rounded-lg p-6">
                  <div className="flex items-start mb-3">
                    <Zap className="w-6 h-6 text-cyan-400 mr-2 mt-1" />
                    <h4 className="text-lg font-semibold text-cyan-300">Real-Time Feedback System</h4>
                  </div>
                  <p className="text-gray-300">
                    Coaches receive instant insights via earpiece or tablet during training sessions and games. 
                    Players access personalized feedback through mobile notifications or smart wearable devices.
                  </p>
                </div>
                
                <div className="bg-indigo-900/20 border border-indigo-500/30 rounded-lg p-6">
                  <div className="flex items-start mb-3">
                    <Activity className="w-6 h-6 text-indigo-400 mr-2 mt-1" />
                    <h4 className="text-lg font-semibold text-indigo-300">Adaptive Training Programs</h4>
                  </div>
                  <p className="text-gray-300">
                    Our AI generates personalized training regimens that evolve as players improve, targeting specific 
                    skills while considering physical condition, recovery needs, and psychological factors.
                  </p>
                </div>
              </div>
            </div>
            
            <h2 className="text-2xl font-bold mt-12 mb-4">How It Works: The Technical Process</h2>
            
            <div className="space-y-8 mb-12">
              <div className="flex flex-col md:flex-row gap-6 items-center">
                <div className="bg-gradient-to-br from-blue-600 to-blue-800 rounded-full p-4 flex items-center justify-center w-16 h-16 md:w-20 md:h-20 flex-shrink-0">
                  <span className="text-2xl font-bold">1</span>
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2 text-blue-300">Video Intake & Processing</h3>
                  <p className="text-gray-300">
                    High-definition cameras capture training sessions and games from multiple angles. Our proprietary 
                    computer vision algorithms process this data in real-time, identifying and tracking individual players, 
                    mapping their movements, analyzing form, and calculating metrics like speed, acceleration, and positional data.
                  </p>
                </div>
              </div>
              
              <div className="flex flex-col md:flex-row gap-6 items-center">
                <div className="bg-gradient-to-br from-teal-600 to-teal-800 rounded-full p-4 flex items-center justify-center w-16 h-16 md:w-20 md:h-20 flex-shrink-0">
                  <span className="text-2xl font-bold">2</span>
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2 text-teal-300">Context Integration</h3>
                  <p className="text-gray-300">
                    The system enriches visual data with contextual information including game situation, opponent strategies, 
                    historical performance data, and sport-specific rules and tactics. This creates a comprehensive dataset that 
                    provides the necessary context for meaningful analysis.
                  </p>
                </div>
              </div>
              
              <div className="flex flex-col md:flex-row gap-6 items-center">
                <div className="bg-gradient-to-br from-cyan-600 to-cyan-800 rounded-full p-4 flex items-center justify-center w-16 h-16 md:w-20 md:h-20 flex-shrink-0">
                  <span className="text-2xl font-bold">3</span>
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2 text-cyan-300">LLM Analysis</h3>
                  <p className="text-gray-300">
                    Our specialized large language models analyze the integrated data, identifying patterns, errors, 
                    and opportunities. The LLMs have been trained on millions of hours of sports footage and expert coaching 
                    strategies, allowing them to recognize subtle technical issues and tactical advantages that might be missed 
                    by human observers.
                  </p>
                </div>
              </div>
              
              <div className="flex flex-col md:flex-row gap-6 items-center">
                <div className="bg-gradient-to-br from-indigo-600 to-indigo-800 rounded-full p-4 flex items-center justify-center w-16 h-16 md:w-20 md:h-20 flex-shrink-0">
                  <span className="text-2xl font-bold">4</span>
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2 text-indigo-300">Strategy Generation</h3>
                  <p className="text-gray-300">
                    Based on the analysis, our AI generates actionable strategies and personalized recommendations. 
                    These can range from immediate tactical adjustments during games to long-term skill development plans 
                    for individual players and coordinated team strategies.
                  </p>
                </div>
              </div>
              
              <div className="flex flex-col md:flex-row gap-6 items-center">
                <div className="bg-gradient-to-br from-purple-600 to-purple-800 rounded-full p-4 flex items-center justify-center w-16 h-16 md:w-20 md:h-20 flex-shrink-0">
                  <span className="text-2xl font-bold">5</span>
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2 text-purple-300">Real-Time Delivery</h3>
                  <p className="text-gray-300">
                    Insights and recommendations are delivered in real-time through multiple channels. Coaches receive 
                    tactical suggestions via earpieces or tablets during games, while players can access personalized 
                    feedback through mobile devices or smart wearables immediately after plays or during breaks.
                  </p>
                </div>
              </div>
            </div>
            
            <h2 className="text-2xl font-bold mt-12 mb-4">Real-World Applications & Benefits</h2>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-12">
              <div className="bg-gradient-to-b from-blue-900/20 to-blue-900/10 border border-blue-500/20 rounded-lg p-6">
                <h3 className="text-xl font-semibold mb-3 text-blue-300">For Coaches</h3>
                <ul className="list-disc pl-6 space-y-2 text-gray-300">
                  <li>Access to comprehensive, objective data on player and team performance</li>
                  <li>Real-time tactical suggestions during games based on evolving situations</li>
                  <li>Ability to focus on motivation and team dynamics while AI handles data analysis</li>
                  <li>Personalized training program generation for each player's development needs</li>
                  <li>Historical trend analysis to track long-term development and strategy effectiveness</li>
                </ul>
              </div>
              
              <div className="bg-gradient-to-b from-teal-900/20 to-teal-900/10 border border-teal-500/20 rounded-lg p-6">
                <h3 className="text-xl font-semibold mb-3 text-teal-300">For Players</h3>
                <ul className="list-disc pl-6 space-y-2 text-gray-300">
                  <li>Immediate feedback on technique and performance without waiting for coach review</li>
                  <li>Personalized skill development plans that evolve with progress</li>
                  <li>Objective insights into strengths and areas for improvement</li>
                  <li>Injury prevention through form correction and exertion monitoring</li>
                  <li>Mental performance coaching based on behavioral and performance patterns</li>
                </ul>
              </div>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
              <div className="bg-gray-800/50 rounded-lg p-6 text-center flex flex-col items-center">
                <div className="text-4xl font-bold text-blue-400 mb-3">32%</div>
                <p className="text-gray-300">
                  improvement in shooting accuracy for basketball players after 8 weeks of AI-guided training
                </p>
              </div>
              
              <div className="bg-gray-800/50 rounded-lg p-6 text-center flex flex-col items-center">
                <div className="text-4xl font-bold text-blue-400 mb-3">47%</div>
                <p className="text-gray-300">
                  reduction in player injuries through AI-monitored form correction and fatigue management
                </p>
              </div>
              
              <div className="bg-gray-800/50 rounded-lg p-6 text-center flex flex-col items-center">
                <div className="text-4xl font-bold text-blue-400 mb-3">28%</div>
                <p className="text-gray-300">
                  increase in successful in-game adjustments when coaches utilized real-time AI recommendations
                </p>
              </div>
            </div>
            
            <h2 className="text-2xl font-bold mt-12 mb-4">Case Study: Elite Basketball Program</h2>
            
            <p className="mb-4">
              When implemented with a Division I college basketball program, our AI coaching system delivered 
              remarkable results. The coaching staff integrated our platform into their daily training routines 
              and game preparation.
            </p>
            
            <div className="mb-8">
              <h4 className="text-xl font-semibold mb-3 text-blue-300">Key Outcomes:</h4>
              <ul className="list-disc pl-6 space-y-2 text-gray-300">
                <li>A 22% increase in three-point shooting percentage within one season</li>
                <li>18% improvement in defensive efficiency, measured by opponent points per possession</li>
                <li>41% reduction in practice time spent on video review, allowing more active training</li>
                <li>Players reported greater confidence in their development and understanding of the game</li>
                <li>Coaching staff cited improved work-life balance by automating routine analysis tasks</li>
              </ul>
            </div>
            
            <div className="bg-gradient-to-r from-blue-900/30 to-teal-900/30 border border-blue-500/20 rounded-lg p-6 my-8">
              <blockquote className="text-xl italic text-gray-300">
                "The AI coaching system has transformed how we prepare, train, and compete. It's like having an 
                additional staff of expert analysts working around the clock. The real-time insights during games 
                have been game-changers for our tactical adjustments."
                <footer className="text-right mt-4 text-gray-400">— Head Coach, Division I Basketball Program</footer>
              </blockquote>
            </div>
            
            <h2 className="text-2xl font-bold mt-12 mb-4">Future Innovations</h2>
            
            <p className="mb-4">
              As we continue to develop our AI sports coaching platform, several exciting innovations are on the horizon:
            </p>
            
            <ul className="list-disc pl-6 mb-6 space-y-2">
              <li><strong>Neural Interface Integration:</strong> Direct feedback through non-invasive neural interfaces, allowing players to receive suggestions without auditory or visual distractions</li>
              <li><strong>Predictive Injury Prevention:</strong> AI algorithms that can identify movement patterns indicating potential injury risk before symptoms manifest</li>
              <li><strong>Cross-Sport Knowledge Transfer:</strong> Systems that can apply successful strategies and techniques from one sport to another</li>
              <li><strong>Emotional and Psychological Analysis:</strong> Integration of facial recognition and biometrics to assess player mental state and provide psychological coaching</li>
              <li><strong>AR Visualization:</strong> Augmented reality displays that overlay tactical suggestions and form corrections in real-time during practice</li>
            </ul>
            
            <p className="mt-8 text-gray-300">
              Interested in implementing AI-powered coaching for your team or sports organization?
              <button 
                onClick={openContactModal}
                className="text-teal-400 hover:text-teal-300 ml-2 underline focus:outline-none"
              >
                Contact us to learn more.
              </button>
            </p>
          </div>
        </motion.div>
      </div>
      
      {/* Contact Form Modal */}
      <Modal 
        isOpen={isContactModalOpen} 
        onClose={closeContactModal}
        title="AI Sports Coaching Inquiry"
      >
        <SportsCoachingContactForm />
      </Modal>
    </div>
  );
} 