import React, { useState } from 'react';
import { Send, CheckCircle, Users, Globe, Briefcase } from 'lucide-react';

// Partner form data type
interface PartnerFormData {
  firstName: string;
  lastName: string;
  email: string;
  jobTitle: string;
  companyName: string;
  industry: string;
  companySize: string;
  website: string;
  partnershipType: string;
  message: string;
  consent: boolean;
}

interface ApolloResponse {
  success: boolean;
  message?: string;
  person?: any;
  error?: string;
}

export default function PartnerForm() {
  const [formData, setFormData] = useState<PartnerFormData>({
    firstName: '',
    lastName: '',
    email: '',
    jobTitle: '',
    companyName: '',
    industry: '',
    companySize: '',
    website: '',
    partnershipType: '',
    message: '',
    consent: false
  });
  
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    
    if (type === 'checkbox') {
      const checkbox = e.target as HTMLInputElement;
      setFormData(prev => ({
        ...prev,
        [name]: checkbox.checked
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
    
    // Clear error when field is edited
    if (errors[name]) {
      setErrors(prev => {
        const newErrors = {...prev};
        delete newErrors[name];
        return newErrors;
      });
    }
  };
  
  // Industry options for dropdown
  const industries = [
    "Technology", "Finance", "Healthcare", "Education", "Manufacturing", 
    "Retail", "Media", "Real Estate", "Energy", "Consulting", "Other"
  ];
  
  // Company size options
  const companySizes = [
    "1-10", "11-50", "51-200", "201-500", "501-1000", "1000+"
  ];
  
  // Partnership types
  const partnershipTypes = [
    "Technology Integration", "Research Collaboration", "Investment Opportunity",
    "Distribution Partner", "Industry Alliance", "Other"
  ];
  
  const validateForm = () => {
    const newErrors: Record<string, string> = {};
    
    if (!formData.firstName) newErrors.firstName = 'First name is required';
    if (!formData.lastName) newErrors.lastName = 'Last name is required';
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
    }
    if (!formData.companyName) newErrors.companyName = 'Company name is required';
    if (!formData.partnershipType) newErrors.partnershipType = 'Partnership type is required';
    if (!formData.message) newErrors.message = 'Message is required';
    if (!formData.consent) newErrors.consent = 'Consent is required';
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) return;
    
    setIsLoading(true);
    
    try {
      // Get the API key from environment variables
      const apiKey = import.meta.env.VITE_APOLLO_API_KEY || "-lYu4hfq8S6woY-K_qS0lA";
      
      // Prepare the data for Apollo.io
      const apolloData = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        title: formData.jobTitle,
        organization_name: formData.companyName,
        website_url: formData.website,
        // Add these fields required by Apollo
        source: "SELF Labs Website",
        legal_basis: "consent_form",
        team_id: "SELF Labs",
        labels: ["Partnership Inquiry", formData.partnershipType],
        custom_fields: {
          industry: formData.industry,
          company_size: formData.companySize,
          partnership_type: formData.partnershipType,
          message: formData.message,
          source_details: "Partnership Form Submission"
        }
      };
      
      console.log("Preparing to submit to Apollo.io:", apolloData);
      
      try {
        // For development, we'll simulate success to test the form flow
        if (window.location.hostname === 'localhost') {
          console.log("Development mode detected. Simulating successful API call.");
          // Simulate API delay
          await new Promise(resolve => setTimeout(resolve, 1500));
          
          // Log what would be sent to Apollo in production
          console.log("This data would be sent to Apollo.io in production:", apolloData);
          
          // Set as submitted
          setIsSubmitted(true);
        } else {
          // In production, use the Netlify function to proxy the request
          const response = await fetch('/.netlify/functions/apollo-proxy?endpoint=people/create_or_update', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(apolloData)
          });
          
          const data: ApolloResponse = await response.json();
          
          // Check if the request was successful
          if (!response.ok) {
            throw new Error(data.message || data.error || 'Failed to submit to Apollo.io');
          }
          
          console.log("Apollo.io response:", data);
          
          // If we get here, the submission was successful
          setIsSubmitted(true);
        }
      } catch (apiError) {
        console.error('API call error:', apiError);
        // For demo purposes, if the API call fails in development, still show success
        if (window.location.hostname === 'localhost') {
          console.log("Simulating success despite API error for development testing");
          setIsSubmitted(true);
        } else {
          throw apiError; // Re-throw in production
        }
      }
    } catch (error) {
      console.error('Error in form submission process:', error);
      setErrors({ 
        form: 'Form submission encountered an error. Please try again later or contact us directly.'
      });
    } finally {
      setIsLoading(false);
    }
  };
  
  return (
    <div className="w-full">
      {!isSubmitted ? (
        <form onSubmit={handleSubmit} className="space-y-5">
          {errors.form && (
            <div className="bg-red-500/10 border border-red-500/30 text-red-400 p-4 rounded-lg mb-6">
              {errors.form}
            </div>
          )}
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
            {/* Contact Information */}
            <div className="col-span-2">
              <h3 className="text-lg font-semibold text-white mb-4 border-b border-[#8A2BE2]/20 pb-2">
                Contact Information
              </h3>
            </div>
            
            {/* First Name */}
            <div>
              <label htmlFor="firstName" className="block text-sm font-medium text-gray-300 mb-2">
                First Name <span className="text-[#8A2BE2]">*</span>
              </label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                className={`w-full bg-black/30 border ${errors.firstName ? 'border-red-500/50' : 'border-[#8A2BE2]/30'} text-white rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-[#8A2BE2]/50`}
                value={formData.firstName}
                onChange={handleChange}
              />
              {errors.firstName && <p className="mt-1 text-sm text-red-400">{errors.firstName}</p>}
            </div>
            
            {/* Last Name */}
            <div>
              <label htmlFor="lastName" className="block text-sm font-medium text-gray-300 mb-2">
                Last Name <span className="text-[#8A2BE2]">*</span>
              </label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                className={`w-full bg-black/30 border ${errors.lastName ? 'border-red-500/50' : 'border-[#8A2BE2]/30'} text-white rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-[#8A2BE2]/50`}
                value={formData.lastName}
                onChange={handleChange}
              />
              {errors.lastName && <p className="mt-1 text-sm text-red-400">{errors.lastName}</p>}
            </div>
            
            {/* Email */}
            <div>
              <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-2">
                Business Email <span className="text-[#8A2BE2]">*</span>
              </label>
              <input
                type="email"
                id="email"
                name="email"
                className={`w-full bg-black/30 border ${errors.email ? 'border-red-500/50' : 'border-[#8A2BE2]/30'} text-white rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-[#8A2BE2]/50`}
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && <p className="mt-1 text-sm text-red-400">{errors.email}</p>}
            </div>
            
            {/* Job Title */}
            <div>
              <label htmlFor="jobTitle" className="block text-sm font-medium text-gray-300 mb-2">
                Job Title
              </label>
              <input
                type="text"
                id="jobTitle"
                name="jobTitle"
                className="w-full bg-black/30 border border-[#8A2BE2]/30 text-white rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-[#8A2BE2]/50"
                value={formData.jobTitle}
                onChange={handleChange}
              />
            </div>
            
            {/* Business Information */}
            <div className="col-span-2 mt-4">
              <h3 className="text-lg font-semibold text-white mb-4 border-b border-[#8A2BE2]/20 pb-2">
                Business Information
              </h3>
            </div>
            
            {/* Company Name */}
            <div>
              <label htmlFor="companyName" className="block text-sm font-medium text-gray-300 mb-2">
                Company Name <span className="text-[#8A2BE2]">*</span>
              </label>
              <div className="flex">
                <div className="p-3 bg-black/50 border-y border-l border-[#8A2BE2]/30 rounded-l-lg flex items-center">
                  <Briefcase className="w-5 h-5 text-[#8A2BE2]" />
                </div>
                <input
                  type="text"
                  id="companyName"
                  name="companyName"
                  className={`flex-grow bg-black/30 border-y border-r ${errors.companyName ? 'border-red-500/50' : 'border-[#8A2BE2]/30'} text-white rounded-r-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-[#8A2BE2]/50`}
                  value={formData.companyName}
                  onChange={handleChange}
                />
              </div>
              {errors.companyName && <p className="mt-1 text-sm text-red-400">{errors.companyName}</p>}
            </div>
            
            {/* Industry */}
            <div>
              <label htmlFor="industry" className="block text-sm font-medium text-gray-300 mb-2">
                Industry
              </label>
              <select
                id="industry"
                name="industry"
                className="w-full bg-black/30 border border-[#8A2BE2]/30 text-white rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-[#8A2BE2]/50"
                value={formData.industry}
                onChange={handleChange}
              >
                <option value="">Select Industry</option>
                {industries.map((industry) => (
                  <option key={industry} value={industry}>{industry}</option>
                ))}
              </select>
            </div>
            
            {/* Company Size */}
            <div>
              <label htmlFor="companySize" className="block text-sm font-medium text-gray-300 mb-2">
                Company Size
              </label>
              <div className="flex">
                <div className="p-3 bg-black/50 border-y border-l border-[#8A2BE2]/30 rounded-l-lg flex items-center">
                  <Users className="w-5 h-5 text-[#8A2BE2]" />
                </div>
                <select
                  id="companySize"
                  name="companySize"
                  className="flex-grow bg-black/30 border-y border-r border-[#8A2BE2]/30 text-white rounded-r-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-[#8A2BE2]/50"
                  value={formData.companySize}
                  onChange={handleChange}
                >
                  <option value="">Select Company Size</option>
                  {companySizes.map((size) => (
                    <option key={size} value={size}>{size} employees</option>
                  ))}
                </select>
              </div>
            </div>
            
            {/* Website */}
            <div>
              <label htmlFor="website" className="block text-sm font-medium text-gray-300 mb-2">
                Company Website
              </label>
              <div className="flex">
                <div className="p-3 bg-black/50 border-y border-l border-[#8A2BE2]/30 rounded-l-lg flex items-center">
                  <Globe className="w-5 h-5 text-[#8A2BE2]" />
                </div>
                <input
                  type="url"
                  id="website"
                  name="website"
                  placeholder="https://"
                  className="flex-grow bg-black/30 border-y border-r border-[#8A2BE2]/30 text-white rounded-r-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-[#8A2BE2]/50"
                  value={formData.website}
                  onChange={handleChange}
                />
              </div>
            </div>
            
            {/* Partnership Information */}
            <div className="col-span-2 mt-4">
              <h3 className="text-lg font-semibold text-white mb-4 border-b border-[#8A2BE2]/20 pb-2">
                Partnership Details
              </h3>
            </div>
            
            {/* Partnership Type */}
            <div className="col-span-2">
              <label htmlFor="partnershipType" className="block text-sm font-medium text-gray-300 mb-2">
                Partnership Type <span className="text-[#8A2BE2]">*</span>
              </label>
              <select
                id="partnershipType"
                name="partnershipType"
                className={`w-full bg-black/30 border ${errors.partnershipType ? 'border-red-500/50' : 'border-[#8A2BE2]/30'} text-white rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-[#8A2BE2]/50`}
                value={formData.partnershipType}
                onChange={handleChange}
              >
                <option value="">Select Partnership Type</option>
                {partnershipTypes.map((type) => (
                  <option key={type} value={type}>{type}</option>
                ))}
              </select>
              {errors.partnershipType && <p className="mt-1 text-sm text-red-400">{errors.partnershipType}</p>}
            </div>
            
            {/* Message */}
            <div className="col-span-2">
              <label htmlFor="message" className="block text-sm font-medium text-gray-300 mb-2">
                Tell us more about your partnership proposal <span className="text-[#8A2BE2]">*</span>
              </label>
              <textarea
                id="message"
                name="message"
                rows={4}
                className={`w-full bg-black/30 border ${errors.message ? 'border-red-500/50' : 'border-[#8A2BE2]/30'} text-white rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-[#8A2BE2]/50`}
                value={formData.message}
                onChange={handleChange}
                placeholder="Please describe what you're looking to accomplish through this partnership..."
              />
              {errors.message && <p className="mt-1 text-sm text-red-400">{errors.message}</p>}
            </div>
            
            {/* Consent */}
            <div className="col-span-2 mt-4">
              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="consent"
                  name="consent"
                  checked={formData.consent}
                  onChange={handleChange}
                  className={`w-5 h-5 bg-black/30 border ${errors.consent ? 'border-red-500/50' : 'border-[#8A2BE2]/30'} rounded`}
                />
                <label htmlFor="consent" className="ml-2 text-sm text-gray-300">
                  I consent to SELF Labs collecting and storing my information for the purpose of evaluating potential partnership opportunities. <span className="text-[#8A2BE2]">*</span>
                </label>
              </div>
              {errors.consent && <p className="mt-1 text-sm text-red-400">{errors.consent}</p>}
            </div>
          </div>
          
          <div className="mt-6">
            <button
              type="submit"
              disabled={isLoading}
              className="w-full bg-gradient-to-r from-[#8A2BE2] to-[#4B0082] text-white px-8 py-3 rounded-lg flex items-center justify-center disabled:opacity-70"
            >
              {isLoading ? (
                <>
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Processing...
                </>
              ) : (
                <>
                  <span className="mr-2">Submit Partnership Inquiry</span>
                  <Send size={18} />
                </>
              )}
            </button>
          </div>
        </form>
      ) : (
        <div className="bg-[#8A2BE2]/10 p-6 rounded-lg border border-[#8A2BE2]/30 text-center">
          <CheckCircle className="w-12 h-12 text-[#8A2BE2] mx-auto mb-4" />
          <h3 className="text-xl font-bold text-white mb-2">Partnership Inquiry Submitted</h3>
          <p className="text-gray-300 mb-4">
            Thank you for your interest in partnering with SELF Labs. We've received your inquiry.
          </p>
          <p className="text-gray-300">
            Our team will review your proposal and get back to you within 3-5 business days to discuss potential next steps.
          </p>
        </div>
      )}
    </div>
  );
} 