import { supabase } from './supabase';
import { products } from '../data/products';

// AI Industry benchmarks (updated quarterly)
const AI_INDUSTRY_BENCHMARKS = {
  valuationMultiples: {
    revenue: 15, // Average revenue multiple for AI companies
    users: 100, // Value per active user in USD
    growth: 2.5 // Multiple increase based on YoY growth
  },
  averageMetrics: {
    growth: 85, // Average YoY growth rate for AI companies
    retention: 0.85 // Average user retention rate
  }
};

interface ProductMetrics {
  activeUsers: number;
  revenue: number;
  growth: number;
}

export async function calculateCompanyMetrics() {
  try {
    // Fetch metrics for all products
    const productMetrics = await aggregateProductMetrics();
    
    // Calculate total active users
    const totalUsers = Object.values(productMetrics).reduce(
      (sum, metrics) => sum + metrics.activeUsers, 
      0
    );

    // Calculate total revenue
    const totalRevenue = Object.values(productMetrics).reduce(
      (sum, metrics) => sum + metrics.revenue, 
      0
    );

    // Calculate average growth rate
    const avgGrowth = Object.values(productMetrics).reduce(
      (sum, metrics) => sum + metrics.growth, 
      0
    ) / Object.keys(productMetrics).length;

    // Calculate company valuation
    const valuation = calculateValuation(totalRevenue, totalUsers, avgGrowth);

    // Format metrics for display
    const formattedMetrics = {
      current_valuation: formatCurrency(valuation),
      active_users: formatNumber(totalUsers),
      revenue_growth: `${avgGrowth.toFixed(1)}%`,
      ai_usage: formatNumber(calculateAIUsage(totalUsers))
    };

    // Update metrics in database
    const { error } = await supabase
      .from('company_metrics')
      .upsert([{
        id: '00000000-0000-0000-0000-000000000000',
        ...formattedMetrics
      }]);

    if (error) throw error;
    return formattedMetrics;
  } catch (error) {
    console.error('Error calculating company metrics:', error);
    throw error;
  }
}

async function aggregateProductMetrics(): Promise<Record<string, ProductMetrics>> {
  // In a real implementation, this would fetch actual metrics from each product's API
  // For now, we'll simulate with realistic data based on product type
  
  const metrics: Record<string, ProductMetrics> = {};
  
  for (const product of products) {
    const baseUsers = Math.floor(Math.random() * 500000) + 100000;
    metrics[product.title] = {
      activeUsers: baseUsers,
      revenue: baseUsers * (Math.random() * 10 + 5), // $5-15 per user
      growth: Math.floor(Math.random() * 100) + 50 // 50-150% growth
    };
  }
  
  return metrics;
}

function calculateValuation(revenue: number, users: number, growth: number): number {
  const { valuationMultiples } = AI_INDUSTRY_BENCHMARKS;
  
  // Calculate different valuation components
  const revenueValuation = revenue * valuationMultiples.revenue;
  const userValuation = users * valuationMultiples.users;
  const growthMultiplier = 1 + (growth / 100 * valuationMultiples.growth);
  
  // Weighted average of different valuation methods
  const valuation = (
    (revenueValuation * 0.4) + 
    (userValuation * 0.3) + 
    ((revenueValuation + userValuation) / 2 * growthMultiplier * 0.3)
  );
  
  return valuation;
}

function calculateAIUsage(totalUsers: number): number {
  // Estimate AI model calls based on active users
  // Assume each user makes an average of 50 AI calls per month
  return totalUsers * 50;
}

function formatCurrency(value: number): string {
  if (value >= 1e9) {
    return `$${(value / 1e9).toFixed(1)}B`;
  } else if (value >= 1e6) {
    return `$${(value / 1e6).toFixed(1)}M`;
  }
  return `$${value.toFixed(0)}`;
}

function formatNumber(value: number): string {
  if (value >= 1e6) {
    return `${(value / 1e6).toFixed(1)}M`;
  } else if (value >= 1e3) {
    return `${(value / 1e3).toFixed(1)}K`;
  }
  return value.toString();
}