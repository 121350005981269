import { createContext, useContext, ReactNode } from 'react';

interface ApolloContact {
  name: string;
  email: string;
  phoneModel: string;
  source: string;
  tags: string[];
}

interface ApolloContextType {
  addContact: (contact: ApolloContact) => Promise<void>;
}

const ApolloContext = createContext<ApolloContextType | undefined>(undefined);

export function ApolloProvider({ children }: { children: ReactNode }) {
  const addContact = async (contact: ApolloContact) => {
    try {
      const response = await fetch('/api/apollo/add-contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(contact),
      });

      if (!response.ok) {
        throw new Error('Failed to add contact to Apollo');
      }
    } catch (error) {
      console.error('Error adding contact to Apollo:', error);
      throw error;
    }
  };

  return (
    <ApolloContext.Provider value={{ addContact }}>
      {children}
    </ApolloContext.Provider>
  );
}

export function useApollo() {
  const context = useContext(ApolloContext);
  if (context === undefined) {
    throw new Error('useApollo must be used within an ApolloProvider');
  }
  return context;
} 