import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Mail, ExternalLink, Inbox, Users } from 'lucide-react';
import { supabase } from '../../../../../utils/supabase';

interface EmailStats {
  handled: number;
  responseRate: string;
  avgResponseTime: string;
}

interface EmailConnection {
  email_address: string;
  account_type: 'support' | 'personal';
}

interface EmailAgentCardProps {
  onViewDetails: () => void;
}

export function EmailAgentCard({ onViewDetails }: EmailAgentCardProps) {
  const [connections, setConnections] = useState<EmailConnection[]>([]);
  const [supportStats, setSupportStats] = useState<EmailStats>({
    handled: 0,
    responseRate: '0%',
    avgResponseTime: '0m'
  });
  const [personalStats, setPersonalStats] = useState<EmailStats>({
    handled: 0,
    responseRate: '0%',
    avgResponseTime: '0m'
  });

  useEffect(() => {
    checkEmailConnections();
  }, []);

  async function checkEmailConnections() {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      const { data, error } = await supabase
        .from('email_accounts')
        .select('*')
        .or(`account_type.eq.support,and(account_type.eq.personal,user_id.eq.${user.id})`);

      if (error) throw error;

      setConnections(data || []);

      // In a real app, fetch actual stats
      if (data?.some(conn => conn.account_type === 'support')) {
        setSupportStats({
          handled: 342,
          responseRate: '95.8%',
          avgResponseTime: '2.5m'
        });
      }

      if (data?.some(conn => conn.account_type === 'personal')) {
        setPersonalStats({
          handled: 127,
          responseRate: '94.2%',
          avgResponseTime: '3.5m'
        });
      }
    } catch (error) {
      console.error('Error checking email connections:', error);
    }
  }

  return (
    <motion.div
      whileHover={{ scale: 1.02 }}
      className="bg-[#121214] rounded-xl p-6 border border-white/10"
    >
      <div className="flex items-start justify-between mb-4">
        <div className="flex items-center space-x-3">
          <div className="p-2 bg-white/5 rounded-lg">
            <Mail className="w-6 h-6 text-[#00FFFF]" />
          </div>
          <div>
            <h3 className="text-lg font-semibold text-white">Email Management Agent</h3>
            <div className="flex items-center space-x-4 mt-1">
              <div className="flex items-center space-x-2">
                <Users className="w-4 h-4 text-purple-400" />
                <span className="text-sm text-gray-400">
                  {connections.some(c => c.account_type === 'support') ? 'Support Connected' : 'No Support Email'}
                </span>
              </div>
              <div className="flex items-center space-x-2">
                <Inbox className="w-4 h-4 text-cyan-400" />
                <span className="text-sm text-gray-400">
                  {connections.some(c => c.account_type === 'personal') ? 'Personal Connected' : 'No Personal Email'}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <p className="text-gray-400 text-sm mb-4">
        AI-powered email management for both support and personal communications
      </p>

      {connections.length > 0 ? (
        <div className="space-y-4 mb-4">
          {connections.some(c => c.account_type === 'support') && (
            <div className="bg-purple-900/10 p-4 rounded-lg border border-purple-500/20">
              <div className="flex items-center space-x-2 mb-3">
                <Users className="w-4 h-4 text-purple-400" />
                <h4 className="text-white font-medium">Support Email Stats</h4>
              </div>
              <div className="grid grid-cols-3 gap-4">
                <div>
                  <div className="text-sm text-gray-400">Handled</div>
                  <div className="text-white font-medium">{supportStats.handled}</div>
                </div>
                <div>
                  <div className="text-sm text-gray-400">Response Rate</div>
                  <div className="text-white font-medium">{supportStats.responseRate}</div>
                </div>
                <div>
                  <div className="text-sm text-gray-400">Avg Time</div>
                  <div className="text-white font-medium">{supportStats.avgResponseTime}</div>
                </div>
              </div>
            </div>
          )}

          {connections.some(c => c.account_type === 'personal') && (
            <div className="bg-cyan-900/10 p-4 rounded-lg border border-cyan-500/20">
              <div className="flex items-center space-x-2 mb-3">
                <Inbox className="w-4 h-4 text-cyan-400" />
                <h4 className="text-white font-medium">Personal Email Stats</h4>
              </div>
              <div className="grid grid-cols-3 gap-4">
                <div>
                  <div className="text-sm text-gray-400">Handled</div>
                  <div className="text-white font-medium">{personalStats.handled}</div>
                </div>
                <div>
                  <div className="text-sm text-gray-400">Response Rate</div>
                  <div className="text-white font-medium">{personalStats.responseRate}</div>
                </div>
                <div>
                  <div className="text-sm text-gray-400">Avg Time</div>
                  <div className="text-white font-medium">{personalStats.avgResponseTime}</div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="space-y-3 mb-4">
          <motion.a
            href="#connect-support"
            className="flex items-center justify-center gap-2 w-full bg-purple-500/10 text-purple-400 py-3 rounded-lg
              hover:bg-purple-500/20 transition-colors"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            Connect Support Email
            <ExternalLink className="w-4 h-4" />
          </motion.a>

          <motion.a
            href="#connect-personal"
            className="flex items-center justify-center gap-2 w-full bg-cyan-500/10 text-cyan-400 py-3 rounded-lg
              hover:bg-cyan-500/20 transition-colors"
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            Connect Personal Email
            <ExternalLink className="w-4 h-4" />
          </motion.a>
        </div>
      )}

      <motion.button
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        onClick={onViewDetails}
        className="w-full bg-white/5 hover:bg-white/10 text-white py-2 rounded-lg transition-colors"
      >
        View Details
      </motion.button>
    </motion.div>
  );
}