import React from 'react';
import { motion } from 'framer-motion';

export function HeroTitle() {
  return (
    <div className="relative">
      <motion.div
        className="absolute -inset-20 bg-[#8A2BE2]/5 rounded-full blur-[120px]"
        animate={{
          scale: [1, 1.2, 1],
          opacity: [0.3, 0.5, 0.3],
        }}
        transition={{
          duration: 5,
          repeat: Infinity,
          ease: "easeInOut",
        }}
      />
      
      <motion.h1
        className="relative text-8xl md:text-[12rem] font-bold tracking-tighter text-center leading-none"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <div className="inline-block bg-gradient-to-r from-white via-[#8A2BE2] to-white text-transparent bg-clip-text pb-4">
          Pioneering
        </div>
        <br />
        <div className="inline-block bg-gradient-to-r from-[#8A2BE2] via-white to-[#4B0082] text-transparent bg-clip-text">
          Conscious AI
        </div>
      </motion.h1>
    </div>
  );
}