import { motion } from 'framer-motion';
import { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { Phone, Shield, Brain, Lock, Wallet, Sparkles, Users, Timer, Check, Satellite, Coins, Signal, MessageSquare, Mic, Globe, Bot } from 'lucide-react';
import StripeCheckoutModal from './StripeCheckoutModal';
import LifestyleCarousel from './LifestyleCarousel';

const SelfPhonePage = () => {
  const navigate = useNavigate();
  const [showStripeModal, setShowStripeModal] = useState(false);
  const [isFlashKit, setIsFlashKit] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handlePreOrder = () => {
    setIsFlashKit(false);
    setShowStripeModal(true);
  };

  const handleFlashKit = () => {
    navigate('/flash');
  };

  return (
    <div className="min-h-screen bg-black text-white">
      <Helmet>
        <title>SELF Phone - Your Voice. Your AI. Your Signal. | SELF Labs</title>
        <meta name="description" content="The world's first satellite smartphone with personal AI agents and built-in earnings. Pre-order now for $299 and join the future of mobile technology." />
        <meta property="og:title" content="SELF Phone - Your Voice. Your AI. Your Signal." />
        <meta property="og:description" content="The world's first satellite smartphone with personal AI agents and built-in earnings. Pre-order now for $299 and join the future of mobile technology." />
        <meta property="og:image" content="/assets/images/self-phone-preview.svg" />
        <meta property="og:url" content="https://selflabs.ai/self-phone" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="SELF Phone - Your Voice. Your AI. Your Signal." />
        <meta name="twitter:description" content="The world's first satellite smartphone with personal AI agents and built-in earnings. Pre-order now for $299 and join the future of mobile technology." />
        <meta name="twitter:image" content="/assets/images/self-phone-preview.svg" />
        <meta name="keywords" content="SELF Phone, AI smartphone, satellite phone, Role Model AI, Aura Shield, private earnings, mobile technology, SELF Labs" />
      </Helmet>
      {/* Hero Section */}
      <section className="relative min-h-screen flex items-center justify-center overflow-hidden py-20">
        <div className="absolute inset-0 bg-gradient-to-b from-indigo-950/50 via-black to-black" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,rgba(138,43,226,0.03)_0%,transparent_1px)] bg-[length:24px_24px]" />
        
        <div className="container mx-auto px-4 relative z-10">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-left"
            >
              <h1 className="text-5xl md:text-7xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 via-blue-400 to-cyan-400">
                Your Voice. Your AI. Your Signal.
              </h1>
              <p className="text-xl md:text-2xl text-gray-300 mb-8">
                The world's first satellite smartphone with personal AI agents and built-in earnings.
              </p>
              <div className="flex flex-col sm:flex-row gap-4">
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={handlePreOrder}
                  className="bg-gradient-to-r from-indigo-600 to-blue-600 text-white px-8 py-4 rounded-2xl text-lg font-semibold shadow-lg shadow-indigo-500/30 hover:shadow-indigo-500/50 transition-all duration-300"
                >
                  Pre-Order for $299
                </motion.button>
                <motion.button
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={handleFlashKit}
                  className="bg-gradient-to-r from-purple-600 to-indigo-600 text-white px-8 py-4 rounded-2xl text-lg font-semibold shadow-lg shadow-purple-500/30 hover:shadow-purple-500/50 transition-all duration-300"
                >
                  Flash Kit $149
                </motion.button>
              </div>
            </motion.div>

            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.8, delay: 0.2 }}
              className="relative flex justify-center items-center"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-indigo-500/20 via-purple-500/20 to-blue-500/20 rounded-full blur-3xl" />
              <motion.img
                src="/assets/images/self-phone-preview.svg"
                alt="SELF Phone Preview"
                className="relative w-full max-w-[400px] rounded-3xl shadow-2xl shadow-indigo-500/20"
                whileHover={{ scale: 1.02 }}
                transition={{ duration: 0.2 }}
              />
            </motion.div>
          </div>
        </div>
      </section>

      {/* What You Get */}
      <section className="py-20 bg-black/50">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-16">What You Get</h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="bg-indigo-950/30 p-8 rounded-2xl backdrop-blur-sm border border-indigo-500/20"
            >
              <Globe className="w-12 h-12 text-indigo-400 mb-4" />
              <h3 className="text-xl font-semibold mb-4">Free Global Signal</h3>
              <p className="text-gray-400">Free global satellite signal — no carrier plan needed</p>
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="bg-indigo-950/30 p-8 rounded-2xl backdrop-blur-sm border border-indigo-500/20"
            >
              <Brain className="w-12 h-12 text-indigo-400 mb-4" />
              <h3 className="text-xl font-semibold mb-4">Role Model AI</h3>
              <p className="text-gray-400">Access 50+ LLMs in one place with built-in AI agents</p>
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="bg-indigo-950/30 p-8 rounded-2xl backdrop-blur-sm border border-indigo-500/20"
            >
              <Mic className="w-12 h-12 text-indigo-400 mb-4" />
              <h3 className="text-xl font-semibold mb-4">Personal AI Voice</h3>
              <p className="text-gray-400">Train your own AI agent with your voice</p>
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="bg-indigo-950/30 p-8 rounded-2xl backdrop-blur-sm border border-indigo-500/20"
            >
              <MessageSquare className="w-12 h-12 text-indigo-400 mb-4" />
              <h3 className="text-xl font-semibold mb-4">Agent Communication</h3>
              <p className="text-gray-400">Agent-to-agent encrypted walkie-talkie system</p>
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="bg-indigo-950/30 p-8 rounded-2xl backdrop-blur-sm border border-indigo-500/20"
            >
              <Shield className="w-12 h-12 text-indigo-400 mb-4" />
              <h3 className="text-xl font-semibold mb-4">Aura Shield</h3>
              <p className="text-gray-400">Always-on, always-free data protection</p>
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="bg-indigo-950/30 p-8 rounded-2xl backdrop-blur-sm border border-indigo-500/20"
            >
              <Coins className="w-12 h-12 text-indigo-400 mb-4" />
              <h3 className="text-xl font-semibold mb-4">Private Earnings</h3>
              <p className="text-gray-400">Phone earns as you use it through the Private Earning System</p>
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="bg-indigo-950/30 p-8 rounded-2xl backdrop-blur-sm border border-indigo-500/20"
            >
              <Sparkles className="w-12 h-12 text-indigo-400 mb-4" />
              <h3 className="text-xl font-semibold mb-4">Early Access</h3>
              <p className="text-gray-400">Early access to AI features & future tools</p>
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="bg-indigo-950/30 p-8 rounded-2xl backdrop-blur-sm border border-indigo-500/20"
            >
              <Signal className="w-12 h-12 text-indigo-400 mb-4" />
              <h3 className="text-xl font-semibold mb-4">Signal Governance</h3>
              <p className="text-gray-400">Participate in Signal Governance decisions</p>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Product Features */}
      <section className="relative py-32 bg-black/50 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-indigo-950/20 via-purple-950/20 to-black/20" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,rgba(138,43,226,0.03)_0%,transparent_1px)] bg-[length:24px_24px] opacity-5" />
        
        {/* Animated gradient orbs */}
        <div className="absolute top-0 right-0 w-[500px] h-[500px] bg-indigo-500/20 rounded-full blur-3xl animate-pulse" />
        <div className="absolute bottom-0 left-0 w-[500px] h-[500px] bg-purple-500/20 rounded-full blur-3xl animate-pulse" />
        
        <div className="container mx-auto px-4 relative z-10">
          <div className="grid lg:grid-cols-2 gap-16 items-center">
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
              className="relative"
            >
              <div className="absolute inset-0 bg-gradient-to-r from-indigo-500/20 via-purple-500/20 to-blue-500/20 rounded-3xl blur-2xl" />
              <motion.img
                src="/assets/images/self-phone-features.png"
                alt="SELF Phone Features"
                className="relative rounded-3xl shadow-2xl shadow-indigo-500/20 w-full"
                whileHover={{ scale: 1.02 }}
                transition={{ duration: 0.2 }}
              />
            </motion.div>

            <motion.div
              initial={{ opacity: 0, x: 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              transition={{ duration: 0.8 }}
              viewport={{ once: true }}
              className="space-y-8"
            >
              <h2 className="text-4xl md:text-5xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 via-purple-400 to-blue-400">
                Designed for Your Digital Life
              </h2>
              
              <div className="space-y-6">
                <motion.div
                  whileHover={{ scale: 1.02 }}
                  className="bg-indigo-950/30 p-6 rounded-2xl backdrop-blur-sm border border-indigo-500/20"
                >
                  <h3 className="text-xl font-semibold mb-2 text-indigo-400">Seamless Integration</h3>
                  <p className="text-gray-300">Experience fluid interaction between your AI agents and daily tasks, all through an intuitive interface.</p>
                </motion.div>

                <motion.div
                  whileHover={{ scale: 1.02 }}
                  className="bg-indigo-950/30 p-6 rounded-2xl backdrop-blur-sm border border-indigo-500/20"
                >
                  <h3 className="text-xl font-semibold mb-2 text-indigo-400">Satellite Connectivity</h3>
                  <p className="text-gray-300">Stay connected anywhere with direct satellite communication, no traditional carrier required.</p>
                </motion.div>

                <motion.div
                  whileHover={{ scale: 1.02 }}
                  className="bg-indigo-950/30 p-6 rounded-2xl backdrop-blur-sm border border-indigo-500/20"
                >
                  <h3 className="text-xl font-semibold mb-2 text-indigo-400">Privacy First</h3>
                  <p className="text-gray-300">Built-in Aura Shield ensures your data remains protected and under your control at all times.</p>
                </motion.div>

                <motion.div
                  whileHover={{ scale: 1.02 }}
                  className="bg-indigo-950/30 p-6 rounded-2xl backdrop-blur-sm border border-indigo-500/20"
                >
                  <h3 className="text-xl font-semibold mb-2 text-indigo-400">Earn As You Use</h3>
                  <p className="text-gray-300">Every interaction contributes to your earnings through our innovative reward system.</p>
                </motion.div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Earning System */}
      <section className="py-20 bg-black/50">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-8">Your Phone Pays You Back</h2>
          <p className="text-xl text-gray-400 text-center mb-16 max-w-3xl mx-auto">
            Every SELF Phone includes an earning system that rewards you as your AI handles tasks, protects your data, and keeps you in control.
          </p>
          <div className="grid md:grid-cols-3 gap-8">
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="bg-indigo-950/30 p-8 rounded-2xl backdrop-blur-sm border border-indigo-500/20"
            >
              <Bot className="w-12 h-12 text-indigo-400 mb-4" />
              <h3 className="text-xl font-semibold mb-4">You Use AI</h3>
              <p className="text-gray-400">Your AI agents handle tasks and protect your data</p>
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="bg-indigo-950/30 p-8 rounded-2xl backdrop-blur-sm border border-indigo-500/20"
            >
              <Brain className="w-12 h-12 text-indigo-400 mb-4" />
              <h3 className="text-xl font-semibold mb-4">AI Performs Tasks</h3>
              <p className="text-gray-400">AI completes tasks and generates value</p>
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="bg-indigo-950/30 p-8 rounded-2xl backdrop-blur-sm border border-indigo-500/20"
            >
              <Coins className="w-12 h-12 text-indigo-400 mb-4" />
              <h3 className="text-xl font-semibold mb-4">Rewards Increase</h3>
              <p className="text-gray-400">Your reward balance grows automatically</p>
            </motion.div>
          </div>
          <div className="text-center mt-12">
            <div className="flex flex-col sm:flex-row items-center justify-center space-y-2 sm:space-y-0 sm:space-x-4">
              <Link to="/self-phone/white-paper" className="text-indigo-400 hover:text-indigo-300 transition-colors flex items-center space-x-2">
                <span>📄 Learn how it works</span>
              </Link>
              <span className="hidden sm:inline text-gray-500">•</span>
              <Link to="/flash/signal-economy" className="text-indigo-400 hover:text-indigo-300 transition-colors flex items-center space-x-2">
                <span>View the Signal Economy White Paper</span>
                <span>→</span>
              </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Aura Shield */}
      <section className="py-20 bg-black/50">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-8">Privacy Should Be the Default</h2>
          <p className="text-xl text-gray-400 text-center mb-16 max-w-3xl mx-auto">
            Aura Shield is always on — and always free. Built into every SELF Phone.
          </p>
          <div className="grid md:grid-cols-2 gap-8">
            <motion.div
              whileHover={{ scale: 1.02 }}
              className="bg-indigo-950/30 p-8 rounded-2xl backdrop-blur-sm border border-indigo-500/20"
            >
              <h3 className="text-2xl font-semibold mb-6">End-to-End Protection</h3>
              <ul className="space-y-4">
                <li className="flex items-center">
                  <Check className="w-5 h-5 text-indigo-400 mr-2" />
                  <span>No monthly subscription</span>
                </li>
                <li className="flex items-center">
                  <Check className="w-5 h-5 text-indigo-400 mr-2" />
                  <span>Choose what to share or lock</span>
                </li>
                <li className="flex items-center">
                  <Check className="w-5 h-5 text-indigo-400 mr-2" />
                  <span>Built into every SELF Phone</span>
                </li>
              </ul>
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.02 }}
              className="bg-indigo-950/30 p-8 rounded-2xl backdrop-blur-sm border border-indigo-500/20"
            >
              <h3 className="text-2xl font-semibold mb-6">Signal Vault</h3>
              <ul className="space-y-4">
                <li className="flex items-center">
                  <Check className="w-5 h-5 text-indigo-400 mr-2" />
                  <span>Secure data storage</span>
                </li>
                <li className="flex items-center">
                  <Check className="w-5 h-5 text-indigo-400 mr-2" />
                  <span>Private signal routing</span>
                </li>
                <li className="flex items-center">
                  <Check className="w-5 h-5 text-indigo-400 mr-2" />
                  <span>Zero-knowledge encryption</span>
                </li>
              </ul>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Role Model AI Integration */}
      <section className="py-20 bg-black/50">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-8">Role Model AI Integration</h2>
          <p className="text-xl text-gray-400 text-center mb-16 max-w-3xl mx-auto">
            Every SELF Phone runs Role Model AI — your own personal AI system that automates your life, talks in your voice, and connects to other agents securely.
          </p>
          <div className="grid md:grid-cols-3 gap-8">
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="bg-indigo-950/30 p-8 rounded-2xl backdrop-blur-sm border border-indigo-500/20"
            >
              <Brain className="w-12 h-12 text-indigo-400 mb-4" />
              <h3 className="text-xl font-semibold mb-4">Multiple LLMs</h3>
              <p className="text-gray-400">Access to GPT-4, Claude, Perplexity, and more</p>
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="bg-indigo-950/30 p-8 rounded-2xl backdrop-blur-sm border border-indigo-500/20"
            >
              <Mic className="w-12 h-12 text-indigo-400 mb-4" />
              <h3 className="text-xl font-semibold mb-4">Voice Creation</h3>
              <p className="text-gray-400">AI learns your tone and style</p>
            </motion.div>
            <motion.div
              whileHover={{ scale: 1.05 }}
              className="bg-indigo-950/30 p-8 rounded-2xl backdrop-blur-sm border border-indigo-500/20"
            >
              <MessageSquare className="w-12 h-12 text-indigo-400 mb-4" />
              <h3 className="text-xl font-semibold mb-4">Agent Tasks</h3>
              <p className="text-gray-400">Schedule meetings, generate content, message other AIs</p>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Imagine This... Lifestyle Carousel */}
      <section className="relative py-32 bg-black/50 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-indigo-950/20 via-purple-950/20 to-black/20" />
        <div className="absolute inset-0 bg-[radial-gradient(circle_at_center,rgba(138,43,226,0.03)_0%,transparent_1px)] bg-[length:24px_24px] opacity-5" />
        
        {/* Animated gradient orbs */}
        <div className="absolute top-0 left-1/4 w-96 h-96 bg-indigo-500/20 rounded-full blur-3xl animate-pulse" />
        <div className="absolute bottom-0 right-1/4 w-96 h-96 bg-purple-500/20 rounded-full blur-3xl animate-pulse" />
        
        <div className="container mx-auto px-4 relative z-10">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            viewport={{ once: true }}
            className="text-center"
          >
            <h2 className="text-5xl md:text-6xl font-bold text-center mb-6 bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 via-purple-400 to-blue-400">
              Imagine This...
            </h2>
            <p className="text-xl md:text-2xl text-gray-300 text-center mb-16 max-w-3xl mx-auto leading-relaxed">
              Real-life moments made effortless, protected, and more rewarding with your SELF Phone.
            </p>
          </motion.div>
          
          <div className="relative">
            <div className="absolute inset-x-0 top-1/2 -translate-y-1/2 h-px bg-gradient-to-r from-transparent via-indigo-500/20 to-transparent" />
            <div className="absolute inset-y-0 left-0 w-20 bg-gradient-to-r from-black to-transparent z-10" />
            <div className="absolute inset-y-0 right-0 w-20 bg-gradient-to-l from-black to-transparent z-10" />
            
            <LifestyleCarousel />
          </div>

          <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            viewport={{ once: true }}
            className="text-center mt-16"
          >
            <p className="text-3xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-purple-400 to-indigo-400">
              This isn't sci-fi. It's SELF.
            </p>
          </motion.div>
        </div>
      </section>

      {/* Pre-Order Section */}
      <section className="py-20 bg-black/50">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold text-center mb-16">Choose Your Path</h2>
          <div className="grid md:grid-cols-2 gap-8 max-w-6xl mx-auto">
            {/* SELF Phone Option */}
            <motion.div
              whileHover={{ scale: 1.02 }}
              className="bg-indigo-950/30 p-8 rounded-2xl backdrop-blur-sm border border-indigo-500/20"
            >
              <h3 className="text-3xl font-bold mb-6">Get the SELF Phone</h3>
              <p className="text-4xl font-bold text-indigo-400 mb-8">$299</p>
              <ul className="space-y-4 mb-8">
                <li className="flex items-center">
                  <Check className="w-5 h-5 text-indigo-400 mr-2" />
                  <span>SELF Phone with free global satellite signal</span>
                </li>
                <li className="flex items-center">
                  <Check className="w-5 h-5 text-indigo-400 mr-2" />
                  <span>1 Year Aura Shield</span>
                </li>
                <li className="flex items-center">
                  <Check className="w-5 h-5 text-indigo-400 mr-2" />
                  <span>Custom Resonance Skin</span>
                </li>
                <li className="flex items-center">
                  <Check className="w-5 h-5 text-indigo-400 mr-2" />
                  <span>Role Model AI Access</span>
                </li>
                <li className="flex items-center">
                  <Check className="w-5 h-5 text-indigo-400 mr-2" />
                  <span>Private Earning System</span>
                </li>
                <li className="flex items-center">
                  <Check className="w-5 h-5 text-indigo-400 mr-2" />
                  <span>Signal Governance Access</span>
                </li>
                <li className="flex items-center">
                  <Check className="w-5 h-5 text-indigo-400 mr-2" />
                  <span>Early claim to private reward balance</span>
                </li>
              </ul>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={handlePreOrder}
                className="w-full bg-gradient-to-r from-indigo-600 to-blue-600 text-white px-8 py-4 rounded-2xl text-lg font-semibold shadow-lg shadow-indigo-500/30 hover:shadow-indigo-500/50 transition-all duration-300"
              >
                Secure My SELF Phone – $299
              </motion.button>
            </motion.div>

            {/* Flash Option */}
            <motion.div
              whileHover={{ scale: 1.02 }}
              className="bg-indigo-950/30 p-8 rounded-2xl backdrop-blur-sm border border-indigo-500/20"
            >
              <h3 className="text-3xl font-bold mb-6">Already Have an Android?</h3>
              <p className="text-xl text-gray-300 mb-8">Flash it. Activate Signal. Earn with AI.</p>
              <p className="text-gray-400 mb-8">
                You don't need to buy a new phone to join the signal. Flash your existing Android device and unlock all the same features — Role Model AI, Aura Shield, private earnings, and agent-based automation.
              </p>
              <motion.button
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => navigate('/flash')}
                className="w-full bg-gradient-to-r from-purple-600 to-indigo-600 text-white px-8 py-4 rounded-2xl text-lg font-semibold shadow-lg shadow-purple-500/30 hover:shadow-purple-500/50 transition-all duration-300"
              >
                Flash My Android – Learn More →
              </motion.button>
            </motion.div>
          </div>
        </div>
      </section>

      {/* Testimonials */}
      <section className="py-20 bg-black/50">
        <div className="container mx-auto px-4">
          <div className="max-w-3xl mx-auto text-center">
            <blockquote className="text-2xl md:text-3xl font-light italic text-gray-300">
              "This is more than a smartphone. It's a signal-powered extension of who you are."
            </blockquote>
            <div className="mt-4">
              <p className="text-indigo-400 font-semibold">— Milan Cheeks</p>
              <p className="text-gray-400 text-sm">Root, Founder, CEO of SELF Labs Inc</p>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="py-12 bg-black/50 border-t border-indigo-500/20">
        <div className="container mx-auto px-4">
          <div className="grid md:grid-cols-3 gap-8">
            <div>
              <h3 className="text-xl font-semibold mb-4">SELF Labs</h3>
              <p className="text-gray-400">Powered by Role Model AI + Aura Protocol</p>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-4">Connect</h3>
              <div className="flex space-x-4">
                <a href="https://x.com/selflabsinc" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-indigo-400 transition-colors">Twitter</a>
                <a href="https://t.me/+2uJEHPAt4204M2Zh" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-indigo-400 transition-colors">Telegram</a>
              </div>
            </div>
            <div>
              <h3 className="text-xl font-semibold mb-4">Resources</h3>
              <div className="flex flex-col space-y-2">
                <Link to="/self-phone/white-paper" className="text-gray-400 hover:text-indigo-400 transition-colors">White Paper</Link>
                <Link to="/self-phone/privacy-policy" className="text-gray-400 hover:text-indigo-400 transition-colors">Privacy Policy</Link>
                <Link to="/self-phone/terms" className="text-gray-400 hover:text-indigo-400 transition-colors">Terms</Link>
              </div>
            </div>
          </div>
          <div className="mt-8 pt-8 border-t border-indigo-500/20 text-center text-gray-400">
            <a href="mailto:support@rolemodel.ai" className="hover:text-indigo-400 transition-colors">
              support@rolemodel.ai
            </a>
          </div>
        </div>
      </footer>

      {/* Stripe Checkout Modal */}
      <StripeCheckoutModal
        isOpen={showStripeModal}
        onClose={() => setShowStripeModal(false)}
        isFlashKit={isFlashKit}
      />
    </div>
  );
};

export default SelfPhonePage; 