import { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, ChevronRight, Utensils, Brain, Coffee, Globe, MessageSquare, Shield, Wallet, Clock } from 'lucide-react';

const cards = [
  {
    icon: <Utensils className="w-8 h-8" />,
    text: "You ask your agent, 'What's the healthiest meal I can make with what's in my fridge?' — and it answers in your voice, like a personal chef."
  },
  {
    icon: <Brain className="w-8 h-8" />,
    text: "Your AI learns your morning routine and automatically starts your day — from adjusting your smart home to briefing you on your schedule."
  },
  {
    icon: <Coffee className="w-8 h-8" />,
    text: "Walking into your favorite coffee shop, your agent has already ordered your usual drink and paid with your earned rewards."
  },
  {
    icon: <Globe className="w-8 h-8" />,
    text: "Traveling abroad? Your phone connects to satellites directly — no roaming, no carriers, just pure global signal."
  },
  {
    icon: <MessageSquare className="w-8 h-8" />,
    text: "Your AI agent chats with your friend's AI to find the perfect time for dinner, considering both your schedules and preferences."
  },
  {
    icon: <Shield className="w-8 h-8" />,
    text: "Your data stays yours. Aura Shield automatically encrypts everything, letting you choose what to share and what to keep private."
  },
  {
    icon: <Wallet className="w-8 h-8" />,
    text: "As your AI handles tasks throughout the day, your reward balance grows — paying you back for your data and attention."
  },
  {
    icon: <Clock className="w-8 h-8" />,
    text: "Need to reschedule multiple appointments? Your AI handles it all, communicating naturally with other systems in your voice."
  }
];

const LifestyleCarousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      handleNext();
    }, 5000);

    return () => clearInterval(timer);
  }, [currentIndex]);

  const handleNext = () => {
    setDirection(1);
    setCurrentIndex((prevIndex) => (prevIndex + 1) % cards.length);
  };

  const handlePrevious = () => {
    setDirection(-1);
    setCurrentIndex((prevIndex) => (prevIndex - 1 + cards.length) % cards.length);
  };

  const slideVariants = {
    enter: (direction: number) => ({
      x: direction > 0 ? 1000 : -1000,
      opacity: 0
    }),
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1
    },
    exit: (direction: number) => ({
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0
    })
  };

  return (
    <div className="relative max-w-4xl mx-auto">
      <div className="relative h-[200px] overflow-hidden">
        <AnimatePresence initial={false} custom={direction}>
          <motion.div
            key={currentIndex}
            custom={direction}
            variants={slideVariants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: "spring", stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 }
            }}
            className="absolute w-full"
          >
            <div className="bg-indigo-950/40 backdrop-blur-xl rounded-3xl p-8 border border-indigo-500/20 shadow-xl">
              <div className="flex items-start gap-6">
                <div className="p-4 bg-indigo-500/10 rounded-2xl">
                  <motion.div
                    initial={{ rotate: -10 }}
                    animate={{ rotate: 10 }}
                    transition={{ duration: 2, repeat: Infinity, repeatType: "reverse" }}
                    className="text-indigo-400"
                  >
                    {cards[currentIndex].icon}
                  </motion.div>
                </div>
                <p className="text-xl text-gray-200 flex-1 leading-relaxed">
                  {cards[currentIndex].text}
                </p>
              </div>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>

      {/* Navigation */}
      <div className="flex justify-center items-center mt-8 gap-4">
        <button
          onClick={handlePrevious}
          className="p-2 rounded-full bg-indigo-950/50 border border-indigo-500/20 text-indigo-400 hover:bg-indigo-900/50 transition-colors"
        >
          <ChevronLeft className="w-6 h-6" />
        </button>
        
        <div className="flex gap-2">
          {cards.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentIndex(index)}
              className={`w-2 h-2 rounded-full transition-all duration-300 ${
                index === currentIndex
                  ? 'bg-indigo-400 w-6'
                  : 'bg-indigo-500/20 hover:bg-indigo-500/40'
              }`}
            />
          ))}
        </div>

        <button
          onClick={handleNext}
          className="p-2 rounded-full bg-indigo-950/50 border border-indigo-500/20 text-indigo-400 hover:bg-indigo-900/50 transition-colors"
        >
          <ChevronRight className="w-6 h-6" />
        </button>
      </div>
    </div>
  );
};

export default LifestyleCarousel; 