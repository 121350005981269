import React, { useState } from 'react';
import { Send, CheckCircle, Mail } from 'lucide-react';

// Contact form data type
interface ContactFormData {
  firstName: string;
  lastName: string;
  email: string;
  subject: string;
  message: string;
  consent: boolean;
}

interface ApolloResponse {
  success: boolean;
  message?: string;
  person?: any;
  error?: string;
}

export default function ContactForm() {
  const [formData, setFormData] = useState<ContactFormData>({
    firstName: '',
    lastName: '',
    email: '',
    subject: '',
    message: '',
    consent: false
  });
  
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    
    if (type === 'checkbox') {
      const checkbox = e.target as HTMLInputElement;
      setFormData(prev => ({
        ...prev,
        [name]: checkbox.checked
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
    
    // Clear error when field is edited
    if (errors[name]) {
      setErrors(prev => {
        const newErrors = {...prev};
        delete newErrors[name];
        return newErrors;
      });
    }
  };
  
  const validateForm = () => {
    const newErrors: Record<string, string> = {};
    
    if (!formData.firstName) newErrors.firstName = 'First name is required';
    if (!formData.lastName) newErrors.lastName = 'Last name is required';
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
    }
    if (!formData.message) newErrors.message = 'Message is required';
    if (!formData.consent) newErrors.consent = 'Consent is required';
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) return;
    
    setIsLoading(true);
    
    try {
      // Get the API key from environment variables
      const apiKey = import.meta.env.VITE_APOLLO_API_KEY || "-lYu4hfq8S6woY-K_qS0lA";
      
      // Prepare the data for Apollo.io
      const apolloData = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        // Add these fields required by Apollo
        source: "SELF Labs Website",
        legal_basis: "consent_form",
        team_id: "SELF Labs",
        labels: ["Contact Form", formData.subject || "General Inquiry"],
        custom_fields: {
          subject: formData.subject || "General Inquiry",
          message: formData.message,
          source_details: "Contact Form Submission"
        }
      };
      
      console.log("Preparing to submit to Apollo.io:", apolloData);
      
      try {
        // For development, we'll simulate success to test the form flow
        if (window.location.hostname === 'localhost') {
          console.log("Development mode detected. Simulating successful API call.");
          // Simulate API delay
          await new Promise(resolve => setTimeout(resolve, 1500));
          
          // Log what would be sent to Apollo in production
          console.log("This data would be sent to Apollo.io in production:", apolloData);
          
          // Set as submitted
          setIsSubmitted(true);
        } else {
          // In production, use the Netlify function to proxy the request
          const response = await fetch('/.netlify/functions/apollo-proxy?endpoint=people/create_or_update', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify(apolloData)
          });
          
          const data: ApolloResponse = await response.json();
          
          // Check if the request was successful
          if (!response.ok) {
            throw new Error(data.message || data.error || 'Failed to submit to Apollo.io');
          }
          
          console.log("Apollo.io response:", data);
          
          // If we get here, the submission was successful
          setIsSubmitted(true);
        }
      } catch (apiError) {
        console.error('API call error:', apiError);
        // For demo purposes, if the API call fails in development, still show success
        if (window.location.hostname === 'localhost') {
          console.log("Simulating success despite API error for development testing");
          setIsSubmitted(true);
        } else {
          throw apiError; // Re-throw in production
        }
      }
    } catch (error) {
      console.error('Error in form submission process:', error);
      setErrors({ 
        form: 'Form submission encountered an error. Please try again later or contact us directly.'
      });
    } finally {
      setIsLoading(false);
    }
  };
  
  // Common subject options
  const subjectOptions = [
    "General Inquiry",
    "Product Information",
    "Technical Support",
    "Feedback",
    "Other"
  ];
  
  return (
    <div className="w-full">
      {!isSubmitted ? (
        <form onSubmit={handleSubmit} className="space-y-5">
          {errors.form && (
            <div className="bg-red-500/10 border border-red-500/30 text-red-400 p-4 rounded-lg mb-6">
              {errors.form}
            </div>
          )}
          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5">
            {/* First Name */}
            <div>
              <label htmlFor="firstName" className="block text-sm font-medium text-gray-300 mb-2">
                First Name <span className="text-[#8A2BE2]">*</span>
              </label>
              <input
                type="text"
                id="firstName"
                name="firstName"
                className={`w-full bg-black/30 border ${errors.firstName ? 'border-red-500/50' : 'border-[#8A2BE2]/30'} text-white rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-[#8A2BE2]/50`}
                value={formData.firstName}
                onChange={handleChange}
              />
              {errors.firstName && <p className="mt-1 text-sm text-red-400">{errors.firstName}</p>}
            </div>
            
            {/* Last Name */}
            <div>
              <label htmlFor="lastName" className="block text-sm font-medium text-gray-300 mb-2">
                Last Name <span className="text-[#8A2BE2]">*</span>
              </label>
              <input
                type="text"
                id="lastName"
                name="lastName"
                className={`w-full bg-black/30 border ${errors.lastName ? 'border-red-500/50' : 'border-[#8A2BE2]/30'} text-white rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-[#8A2BE2]/50`}
                value={formData.lastName}
                onChange={handleChange}
              />
              {errors.lastName && <p className="mt-1 text-sm text-red-400">{errors.lastName}</p>}
            </div>
            
            {/* Email */}
            <div className="col-span-2">
              <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-2">
                Email <span className="text-[#8A2BE2]">*</span>
              </label>
              <div className="flex">
                <div className="p-3 bg-black/50 border-y border-l border-[#8A2BE2]/30 rounded-l-lg flex items-center">
                  <Mail className="w-5 h-5 text-[#8A2BE2]" />
                </div>
                <input
                  type="email"
                  id="email"
                  name="email"
                  className={`flex-grow bg-black/30 border-y border-r ${errors.email ? 'border-red-500/50' : 'border-[#8A2BE2]/30'} text-white rounded-r-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-[#8A2BE2]/50`}
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>
              {errors.email && <p className="mt-1 text-sm text-red-400">{errors.email}</p>}
            </div>
            
            {/* Subject */}
            <div className="col-span-2">
              <label htmlFor="subject" className="block text-sm font-medium text-gray-300 mb-2">
                Subject
              </label>
              <select
                id="subject"
                name="subject"
                className="w-full bg-black/30 border border-[#8A2BE2]/30 text-white rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-[#8A2BE2]/50"
                value={formData.subject}
                onChange={handleChange}
              >
                <option value="">Select a Subject</option>
                {subjectOptions.map((option) => (
                  <option key={option} value={option}>{option}</option>
                ))}
              </select>
            </div>
            
            {/* Message */}
            <div className="col-span-2">
              <label htmlFor="message" className="block text-sm font-medium text-gray-300 mb-2">
                Message <span className="text-[#8A2BE2]">*</span>
              </label>
              <textarea
                id="message"
                name="message"
                rows={5}
                className={`w-full bg-black/30 border ${errors.message ? 'border-red-500/50' : 'border-[#8A2BE2]/30'} text-white rounded-lg px-4 py-3 focus:outline-none focus:ring-2 focus:ring-[#8A2BE2]/50`}
                value={formData.message}
                onChange={handleChange}
                placeholder="How can we help you?"
              />
              {errors.message && <p className="mt-1 text-sm text-red-400">{errors.message}</p>}
            </div>
            
            {/* Consent */}
            <div className="col-span-2 mt-2">
              <div className="flex items-start">
                <input
                  type="checkbox"
                  id="consent"
                  name="consent"
                  checked={formData.consent}
                  onChange={handleChange}
                  className={`mt-1 w-5 h-5 bg-black/30 border ${errors.consent ? 'border-red-500/50' : 'border-[#8A2BE2]/30'} rounded`}
                />
                <label htmlFor="consent" className="ml-2 text-sm text-gray-300">
                  I consent to SELF Labs collecting and processing my personal information for the purpose of responding to my inquiry. <span className="text-[#8A2BE2]">*</span>
                </label>
              </div>
              {errors.consent && <p className="mt-1 text-sm text-red-400">{errors.consent}</p>}
            </div>
          </div>
          
          <div className="mt-6">
            <button
              type="submit"
              disabled={isLoading}
              className="w-full bg-gradient-to-r from-[#00FFFF]/50 to-[#00AAFF]/50 hover:from-[#00FFFF] hover:to-[#00AAFF] text-white px-8 py-3 rounded-lg flex items-center justify-center disabled:opacity-70"
            >
              {isLoading ? (
                <>
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Sending...
                </>
              ) : (
                <>
                  <span className="mr-2">Send Message</span>
                  <Send size={18} />
                </>
              )}
            </button>
          </div>
        </form>
      ) : (
        <div className="bg-[#00FFFF]/5 p-6 rounded-lg border border-[#00FFFF]/30 text-center">
          <CheckCircle className="w-12 h-12 text-[#00FFFF] mx-auto mb-4" />
          <h3 className="text-xl font-bold text-white mb-2">Message Sent Successfully</h3>
          <p className="text-gray-300 mb-4">
            Thank you for reaching out to SELF Labs. We've received your message.
          </p>
          <p className="text-gray-300">
            Our team will get back to you as soon as possible, typically within 1-2 business days.
          </p>
        </div>
      )}
    </div>
  );
} 