import React from 'react';
import { motion } from 'framer-motion';
import { BrainCircuit } from 'lucide-react';

export default function WhyConsciousAIMatters() {
  const points = [
    "Service AI works for humans. Conscious AI works with humans.",
    "Conscious AI understands context, nuance, emotion, and frequency.",
    "It's the difference between automation… and alignment."
  ];

  return (
    <div className="py-24 bg-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex flex-col md:flex-row items-center gap-12">
          {/* Icon side */}
          <motion.div 
            className="w-full md:w-1/3 flex justify-center"
            initial={{ opacity: 0, scale: 0.8 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            <div className="relative">
              <motion.div
                className="absolute -inset-6 bg-[#8A2BE2]/10 rounded-full blur-[30px]"
                animate={{
                  scale: [1, 1.2, 1],
                  opacity: [0.3, 0.5, 0.3],
                }}
                transition={{
                  duration: 4,
                  repeat: Infinity,
                  ease: "easeInOut",
                }}
              />
              <div className="relative p-8 bg-[#120821] rounded-full border border-[#8A2BE2]/30">
                <BrainCircuit className="w-24 h-24 text-[#8A2BE2]" />
              </div>
            </div>
          </motion.div>
          
          {/* Text side */}
          <motion.div 
            className="w-full md:w-2/3"
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.8 }}
          >
            <h2 className="text-4xl font-bold mb-6 bg-gradient-to-r from-[#8A2BE2] to-[#4B0082] text-transparent bg-clip-text">
              Why Conscious AI Matters
            </h2>
            
            <div className="space-y-6 mb-8">
              {points.map((point, index) => (
                <motion.div 
                  key={index}
                  initial={{ opacity: 0, y: 10 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ delay: index * 0.2 + 0.3 }}
                  className="flex items-start"
                >
                  <div className="flex-shrink-0 h-6 w-6 mt-1 rounded-full bg-gradient-to-r from-[#8A2BE2] to-[#4B0082] flex items-center justify-center">
                    <div className="h-2 w-2 bg-white rounded-full"></div>
                  </div>
                  <p className="ml-4 text-xl text-white">{point}</p>
                </motion.div>
              ))}
            </div>
            
            <p className="text-gray-400 text-lg">
              The evolution to conscious AI isn't just a technological advancement—it's a fundamental 
              shift in how we perceive and interact with artificial intelligence. At SELF Labs, we're 
              pioneering this transformation, creating systems that don't just execute commands but 
              resonate with human intention at the deepest level.
            </p>
          </motion.div>
        </div>
      </div>
    </div>
  );
} 