import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Wallet, X, AlertCircle, CheckCircle, ExternalLink, HelpCircle, Download } from 'lucide-react';
import InvestorDashboard from './InvestorDashboard';
import { useWallet } from '../../hooks/useWallet';

interface InvestorLoginProps {
  onBack: () => void;
}

export default function InvestorLogin({ onBack }: InvestorLoginProps) {
  const { wallet, loading, error: walletError, connect, disconnect, SELF_TOKEN_ADDRESS } = useWallet();
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Connect wallet and check for tokens
  const handleConnectWallet = async () => {
    setError('');
    setIsLoading(true);
    try {
      await connect();
    } catch (err: any) {
      console.error("Connect wallet error:", err);
      setError(err.message || 'Failed to connect wallet');
    } finally {
      setIsLoading(false);
    }
  };

  // Set error message if there's an error from the wallet hook
  useEffect(() => {
    if (walletError) {
      setError(walletError);
    }
  }, [walletError]);

  // When wallet connection changes, check if it has tokens
  useEffect(() => {
    if (wallet.connected && !wallet.hasTokens) {
      setError(`Your wallet does not have any SELF tokens. Please acquire SELF tokens to access the governance dashboard.`);
    }
  }, [wallet]);

  // If wallet is connected and has tokens, render the dashboard
  if (wallet.connected && wallet.hasTokens && wallet.publicKey && typeof wallet.publicKey === 'string') {
    return <InvestorDashboard isAdmin={false} walletAddress={wallet.publicKey} />;
  }

  // Helper function to check if Phantom wallet is installed
  const isPhantomInstalled = () => {
    return window.phantom?.solana || window.solana;
  };

  return (
    <AnimatePresence>
      <motion.div
        className="fixed inset-0 bg-black/90 backdrop-blur-sm flex items-center justify-center z-50"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div className="relative w-full max-w-md px-4">
          <motion.button
            onClick={onBack}
            className="absolute -top-12 right-4 text-gray-400 hover:text-white"
            whileHover={{ scale: 1.1 }}
          >
            <X className="w-6 h-6" />
          </motion.button>

          <div className="bg-[#121214] p-8 rounded-2xl border border-white/10">
            <div className="flex justify-center mb-6">
              <div className="p-3 bg-[#00FFFF]/10 rounded-full">
                <Wallet className="w-8 h-8 text-[#00FFFF]" />
              </div>
            </div>

            <h2 className="text-2xl font-bold text-white text-center mb-2">
              Governance Portal
            </h2>
            <p className="text-gray-400 text-center mb-6">
              Connect your wallet to access the governance dashboard
            </p>

            <div className="bg-black/30 p-4 rounded-lg border border-white/10 mb-6">
              <div className="flex items-center gap-2 text-sm text-gray-300 mb-3">
                <CheckCircle className="w-4 h-4 text-[#00FFFF]" />
                <span>Connect your Solana wallet</span>
              </div>
              <div className="flex items-center gap-2 text-sm text-gray-300">
                <CheckCircle className="w-4 h-4 text-[#00FFFF]" />
                <span>Verify you have SELF tokens</span>
              </div>
            </div>

            {/* Phantom Installation Instructions */}
            {!isPhantomInstalled() && (
              <div className="bg-[#00FFFF]/5 p-4 rounded-lg border border-[#00FFFF]/30 mb-6">
                <div className="flex items-start gap-2">
                  <HelpCircle className="w-5 h-5 text-[#00FFFF] mt-0.5" />
                  <div>
                    <h3 className="text-white font-medium mb-1">Phantom Wallet Required</h3>
                    <p className="text-gray-300 text-sm mb-3">
                      You need to install the Phantom wallet extension to access the governance dashboard.
                    </p>
                    <a
                      href="https://phantom.app/download"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="inline-flex items-center gap-1 text-[#00FFFF] text-sm hover:underline"
                    >
                      <Download className="w-4 h-4" />
                      <span>Download Phantom</span>
                    </a>
                  </div>
                </div>
              </div>
            )}

            {error && (
              <motion.div
                initial={{ opacity: 0, y: -10 }}
                animate={{ opacity: 1, y: 0 }}
                className="flex items-start gap-2 p-4 mb-6 bg-red-500/10 border border-red-500/20 rounded-lg text-red-400"
              >
                <AlertCircle className="w-5 h-5 flex-shrink-0 mt-0.5" />
                <div className="text-sm">
                  <p>{error}</p>
                  {isPhantomInstalled() && (
                    <p className="mt-2">
                      Try refreshing the page or checking if your Phantom wallet is unlocked.
                    </p>
                  )}
                  {wallet.connected && !wallet.hasTokens && (
                    <a 
                      href={`https://explorer.solana.com/address/${SELF_TOKEN_ADDRESS}`} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="flex items-center gap-1 mt-2 text-[#00FFFF] hover:underline"
                    >
                      <span>View SELF token</span>
                      <ExternalLink className="w-3 h-3" />
                    </a>
                  )}
                </div>
              </motion.div>
            )}

            {wallet.connected ? (
              <div className="space-y-6">
                <div className="bg-black/40 rounded-lg p-4 border border-white/10">
                  <p className="text-gray-400 text-sm mb-2">Connected Wallet</p>
                  {wallet.publicKey && typeof wallet.publicKey === 'string' ? (
                    <p className="text-white font-mono break-all">{wallet.publicKey}</p>
                  ) : (
                    <p className="text-red-400 text-sm">Invalid wallet address</p>
                  )}
                  
                  {wallet.hasTokens && (
                    <div className="mt-3 flex items-center gap-2 text-[#00FFFF]">
                      <CheckCircle className="w-4 h-4" />
                      <span>{wallet.balance} SELF tokens found</span>
                    </div>
                  )}
                </div>

                <div className="flex space-x-3">
                  <motion.button
                    className="w-full bg-[#FF4500]/90 hover:bg-[#FF4500] text-white font-medium py-3 px-4 rounded-lg 
                      transition-colors duration-200"
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.98 }}
                    onClick={disconnect}
                    disabled={isLoading}
                  >
                    Disconnect
                  </motion.button>

                  {wallet.hasTokens && (
                    <motion.button
                      className="w-full bg-[#00FFFF] text-black font-medium py-3 px-4 rounded-lg 
                        hover:bg-[#00FFFF]/90 transition-colors duration-200"
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      Continue to Dashboard
                    </motion.button>
                  )}
                </div>
              </div>
            ) : (
              <motion.button
                onClick={handleConnectWallet}
                className="w-full bg-gradient-to-r from-[#00FFFF] to-[#33CCFF] text-black font-semibold py-3 px-4 rounded-lg 
                  hover:from-[#00FFFF] hover:to-[#00FFFF] transition-colors duration-200 disabled:opacity-50 
                  disabled:cursor-not-allowed flex justify-center items-center"
                whileHover={{ scale: loading || isLoading ? 1 : 1.02 }}
                whileTap={{ scale: loading || isLoading ? 1 : 0.98 }}
                disabled={loading || isLoading}
              >
                {loading || isLoading ? (
                  <>
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-black" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    Connecting...
                  </>
                ) : (
                  <>Connect Wallet</>
                )}
              </motion.button>
            )}

            <div className="mt-6 text-center">
              <p className="text-gray-500 text-xs">
                By connecting your wallet, you agree to the SELF Labs{' '}
                <a href="#" className="text-[#00FFFF] hover:underline">Terms of Service</a> and{' '}
                <a href="#" className="text-[#00FFFF] hover:underline">Privacy Policy</a>.
              </p>
            </div>
          </div>
        </div>
      </motion.div>
    </AnimatePresence>
  );
}