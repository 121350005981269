import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Upload, Trash2, File, AlertCircle } from 'lucide-react';
import { supabase } from '../../../utils/supabase';

export function DocumentManager() {
  const [documents, setDocuments] = useState<any[]>([]);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    fetchDocuments();
  }, []);

  async function fetchDocuments() {
    try {
      const { data, error } = await supabase
        .from('financial_documents')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setDocuments(data || []);
    } catch (error) {
      console.error('Error fetching documents:', error);
      setError('Failed to load documents');
    }
  }

  async function handleFileUpload(event: React.ChangeEvent<HTMLInputElement>) {
    try {
      setUploading(true);
      setError('');
      const file = event.target.files?.[0];
      if (!file) return;

      // Upload file to Supabase Storage
      const fileExt = file.name.split('.').pop();
      const fileName = `${Math.random()}.${fileExt}`;
      const { data: fileData, error: uploadError } = await supabase.storage
        .from('financial-documents')
        .upload(fileName, file);

      if (uploadError) throw uploadError;

      // Create document record
      const { error: dbError } = await supabase.from('financial_documents').insert({
        title: file.name,
        file_url: fileData.path,
        file_type: file.type,
        file_size: `${(file.size / (1024 * 1024)).toFixed(2)} MB`,
      });

      if (dbError) throw dbError;
      fetchDocuments();
    } catch (error) {
      console.error('Error uploading document:', error);
      setError('Failed to upload document');
    } finally {
      setUploading(false);
    }
  }

  async function handleDelete(id: string) {
    try {
      const { error } = await supabase
        .from('financial_documents')
        .delete()
        .eq('id', id);

      if (error) throw error;
      setDocuments(documents.filter(doc => doc.id !== id));
    } catch (error) {
      console.error('Error deleting document:', error);
      setError('Failed to delete document');
    }
  }

  return (
    <div className="bg-[#121214] rounded-xl p-6 border border-white/10">
      <h2 className="text-xl font-semibold text-white mb-6">Financial Documents</h2>

      {error && (
        <div className="flex items-center gap-2 p-4 mb-6 bg-red-500/10 border border-red-500/20 rounded-lg text-red-400">
          <AlertCircle className="w-5 h-5" />
          <p>{error}</p>
        </div>
      )}

      <div className="mb-8">
        <label className="block w-full aspect-video rounded-lg border-2 border-dashed border-white/20 
          hover:border-[#FF3366]/50 transition-colors duration-200 cursor-pointer bg-black/20">
          <input
            type="file"
            className="hidden"
            onChange={handleFileUpload}
            disabled={uploading}
          />
          <div className="flex flex-col items-center justify-center h-full">
            <Upload className="w-8 h-8 text-gray-400 mb-2" />
            <p className="text-sm text-gray-400">
              {uploading ? 'Uploading...' : 'Click to upload or drag and drop'}
            </p>
          </div>
        </label>
      </div>

      <div className="space-y-4">
        {documents.map((doc) => (
          <motion.div
            key={doc.id}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            className="flex items-center justify-between p-4 bg-black/20 rounded-lg"
          >
            <div className="flex items-center space-x-4">
              <File className="w-5 h-5 text-[#FF3366]" />
              <div>
                <p className="text-white font-medium">{doc.title}</p>
                <p className="text-sm text-gray-400">
                  {new Date(doc.created_at).toLocaleDateString()} • {doc.file_type} • {doc.file_size}
                </p>
              </div>
            </div>
            
            <motion.button
              onClick={() => handleDelete(doc.id)}
              className="text-gray-400 hover:text-red-400 p-2"
              whileHover={{ scale: 1.1 }}
            >
              <Trash2 className="w-4 h-4" />
            </motion.button>
          </motion.div>
        ))}
      </div>
    </div>
  );
}