import React from 'react';
import { motion } from 'framer-motion';

export function HeroBackground() {
  return (
    <>
      {/* Base gradient */}
      <div className="absolute inset-0 bg-gradient-to-b from-black via-[#0A0A0B] to-black" />
      
      {/* Animated grid */}
      <div className="absolute inset-0">
        <motion.div 
          className="absolute inset-0"
          animate={{
            opacity: [0.3, 0.5, 0.3]
          }}
          transition={{
            duration: 5,
            repeat: Infinity,
            ease: "easeInOut"
          }}
          style={{
            backgroundImage: 'radial-gradient(circle at center, rgba(0, 255, 255, 0.03) 0%, transparent 1px)',
            backgroundSize: '30px 30px'
          }}
        />
        
        {/* Floating orbs */}
        {[...Array(3)].map((_, i) => (
          <motion.div
            key={i}
            className="absolute w-[40rem] h-[40rem] rounded-full opacity-20"
            style={{
              background: i === 0 
                ? 'radial-gradient(circle, rgba(0, 255, 255, 0.1) 0%, transparent 70%)'
                : i === 1
                ? 'radial-gradient(circle, rgba(255, 51, 102, 0.1) 0%, transparent 70%)'
                : 'radial-gradient(circle, rgba(255, 255, 255, 0.05) 0%, transparent 70%)',
              left: `${(i * 30) % 100}%`,
              top: `${(i * 40) % 100}%`,
            }}
            animate={{
              x: [0, 100, 0],
              y: [0, -50, 0],
              scale: [1, 1.2, 1],
            }}
            transition={{
              duration: 20 + i * 5,
              repeat: Infinity,
              ease: "linear"
            }}
          />
        ))}
      </div>

      {/* Subtle scanlines */}
      <div className="absolute inset-0 bg-[linear-gradient(transparent_0%,_rgba(255,255,255,0.05)_50%,_transparent_100%)] bg-[length:100%_4px] pointer-events-none" />
    </>
  );
}