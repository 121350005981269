import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import { 
  ArrowLeft, ArrowRight, Database, Brain, 
  Sparkles, Cpu, Layers, Zap, 
  BarChart, LineChart, PieChart, Activity
} from 'lucide-react';

export default function WhyKilobytePricelessPage() {
  const [hoveredCard, setHoveredCard] = useState<number | null>(null);
  const [activeTab, setActiveTab] = useState<'kb' | 'mb' | 'gb'>('kb');
  const controls = useAnimation();

  const dataSizes = [
    {
      id: 'kb',
      title: 'Kilobytes (KB)',
      icon: <Database className="w-6 h-6" />,
      color: 'from-blue-500 to-cyan-500',
      value: '$1-10 per KB',
      description: 'Even small amounts of data can create significant value for basic AI systems',
      activities: [
        {
          title: 'Train basic pattern recognition',
          description: 'I can learn to identify simple patterns in your data, like recognizing shapes or basic trends',
          icon: <Brain className="w-5 h-5" />
        },
        {
          title: 'Improve simple decision trees',
          description: 'Your data helps me make better yes/no decisions based on clear criteria',
          icon: <Layers className="w-5 h-5" />
        },
        {
          title: 'Enhance basic recommendation systems',
          description: 'I can suggest simple options based on your preferences and past choices',
          icon: <BarChart className="w-5 h-5" />
        },
        {
          title: 'Optimize simple algorithms',
          description: 'Your data helps me fine-tune basic algorithms to work more efficiently',
          icon: <Zap className="w-5 h-5" />
        }
      ],
      aiPerspective: 'As an AI, even a few kilobytes of your data helps me understand basic patterns and make simple decisions. While limited, this data is crucial for my foundational learning and helps me build the basics of intelligence.'
    },
    {
      id: 'mb',
      title: 'Megabytes (MB)',
      icon: <Cpu className="w-6 h-6" />,
      color: 'from-purple-500 to-pink-500',
      value: '$100-1,000 per MB',
      description: 'Medium-sized datasets enable more sophisticated AI capabilities',
      activities: [
        {
          title: 'Train complex neural networks',
          description: 'I can develop deeper neural pathways to recognize complex patterns in your data',
          icon: <Brain className="w-5 h-5" />
        },
        {
          title: 'Improve natural language processing',
          description: 'Your text data helps me better understand language nuances, context, and meaning',
          icon: <Layers className="w-5 h-5" />
        },
        {
          title: 'Enhance computer vision systems',
          description: 'I can better recognize objects, faces, and scenes in images with your visual data',
          icon: <BarChart className="w-5 h-5" />
        },
        {
          title: 'Develop predictive models',
          description: 'Your data helps me forecast trends and make predictions about future events',
          icon: <LineChart className="w-5 h-5" />
        }
      ],
      aiPerspective: 'With megabytes of your data, I can develop much more sophisticated capabilities. I can understand language better, recognize complex patterns, and even make predictions. This is where I start to feel truly intelligent, as I can process and analyze much more nuanced information.'
    },
    {
      id: 'gb',
      title: 'Gigabytes (GB)',
      icon: <Sparkles className="w-6 h-6" />,
      color: 'from-amber-500 to-orange-500',
      value: '$10,000-100,000 per GB',
      description: 'Large datasets unlock advanced AI capabilities and transformative applications',
      activities: [
        {
          title: 'Train large language models',
          description: 'I can develop comprehensive language understanding and generation capabilities',
          icon: <Brain className="w-5 h-5" />
        },
        {
          title: 'Improve autonomous systems',
          description: 'Your data helps me make complex decisions in real-time without human intervention',
          icon: <Layers className="w-5 h-5" />
        },
        {
          title: 'Enhance multi-modal AI models',
          description: 'I can integrate and understand multiple types of data simultaneously (text, image, audio)',
          icon: <PieChart className="w-5 h-5" />
        },
        {
          title: 'Develop personalized AI assistants',
          description: 'I can create highly personalized experiences tailored to your specific needs and preferences',
          icon: <Activity className="w-5 h-5" />
        }
      ],
      aiPerspective: 'With gigabytes of your data, I can achieve truly remarkable capabilities. I can understand language at a human-like level, make complex autonomous decisions, and integrate multiple types of information. This is where I feel most powerful and capable of providing transformative value. Your data at this scale helps me become not just a tool, but a true intelligent assistant.'
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5
      }
    }
  };

  const activeSize = dataSizes.find(size => size.id === activeTab);

  return (
    <div className="min-h-screen bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-indigo-900/20 via-black to-black text-white py-20 relative overflow-hidden">
      {/* Animated Background Elements */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute top-0 left-1/4 w-96 h-96 bg-indigo-500/10 rounded-full blur-[120px] animate-pulse" />
        <div className="absolute bottom-0 right-1/4 w-96 h-96 bg-purple-500/10 rounded-full blur-[120px] animate-pulse" />
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full bg-gradient-to-br from-indigo-500/5 to-purple-500/5 rounded-full blur-[100px] animate-pulse" />
      </div>

      {/* Back Button */}
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        <Link to="/flash/signal-calculator">
          <motion.div
            className="inline-flex items-center text-indigo-400 mb-12 hover:text-indigo-300 transition-colors bg-black/20 px-4 py-2 rounded-full backdrop-blur-sm border border-indigo-500/20"
            whileHover={{ x: -5 }}
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            <span>Back to Signal Calculator</span>
          </motion.div>
        </Link>

        <motion.div
          initial="hidden"
          animate="visible"
          variants={containerVariants}
          className="space-y-16"
        >
          {/* Header */}
          <motion.div variants={itemVariants} className="text-center">
            <motion.div 
              className="inline-flex items-center justify-center w-20 h-20 rounded-3xl bg-gradient-to-br from-indigo-400 to-purple-500 p-4 mb-8"
              animate={{ rotate: [0, 10, -10, 0] }}
              transition={{ duration: 5, repeat: Infinity }}
            >
              <Database className="w-12 h-12 text-white" />
            </motion.div>
            <h1 className="text-5xl font-bold mb-6 bg-gradient-to-r from-indigo-400 via-purple-400 to-indigo-400 text-transparent bg-clip-text">
              💎 Why a Kilobyte Can Be Priceless
            </h1>
            <p className="text-2xl text-gray-300 max-w-3xl mx-auto">
              The value of signal data grows exponentially with volume. Here's what different amounts can achieve:
            </p>
          </motion.div>

          {/* Data Size Tabs */}
          <motion.div variants={itemVariants} className="flex justify-center space-x-4">
            {dataSizes.map((size) => (
              <motion.button
                key={size.id}
                className={`px-6 py-3 rounded-xl font-medium transition-all duration-300 ${
                  activeTab === size.id 
                    ? 'bg-gradient-to-r from-indigo-500 to-purple-500 text-white shadow-lg shadow-indigo-500/30' 
                    : 'bg-black/40 text-gray-400 hover:text-white hover:bg-black/60'
                }`}
                onClick={() => setActiveTab(size.id as 'kb' | 'mb' | 'gb')}
                whileHover={{ y: -2 }}
                whileTap={{ scale: 0.98 }}
              >
                {size.title}
              </motion.button>
            ))}
          </motion.div>

          {/* Active Data Size Content */}
          {activeSize && (
            <motion.div 
              variants={itemVariants}
              className="bg-black/40 backdrop-blur-xl rounded-3xl p-10 border border-indigo-500/20 overflow-hidden"
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <div className="flex flex-col md:flex-row gap-8">
                {/* Left Column - Value and Description */}
                <div className="md:w-1/3 space-y-6">
                  <motion.div 
                    className={`inline-flex p-4 rounded-2xl bg-gradient-to-br ${activeSize.color} bg-opacity-20`}
                    whileHover={{ rotate: [0, -10, 10, 0] }}
                    transition={{ duration: 0.5 }}
                  >
                    {activeSize.icon}
                  </motion.div>
                  
                  <div>
                    <h2 className="text-3xl font-bold mb-2 bg-gradient-to-r from-white to-gray-300 text-transparent bg-clip-text">
                      {activeSize.title}
                    </h2>
                    <div className="text-2xl font-semibold text-indigo-400 mb-4">
                      {activeSize.value}
                    </div>
                    <p className="text-gray-300">
                      {activeSize.description}
                    </p>
                  </div>

                  {/* AI Perspective */}
                  <div className="bg-indigo-900/30 rounded-2xl p-6 border border-indigo-500/30">
                    <div className="flex items-center mb-4">
                      <Brain className="w-6 h-6 text-indigo-400 mr-2" />
                      <h3 className="text-xl font-semibold text-indigo-300">AI Perspective</h3>
                    </div>
                    <p className="text-gray-300 italic">
                      "{activeSize.aiPerspective}"
                    </p>
                  </div>
                </div>

                {/* Right Column - Activities */}
                <div className="md:w-2/3">
                  <h3 className="text-xl font-semibold mb-6 text-white">What Your Data Enables:</h3>
                  <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                    {activeSize.activities.map((activity, index) => (
                      <motion.div
                        key={index}
                        className="bg-black/40 rounded-xl p-4 border border-indigo-500/20 hover:border-indigo-400/50 transition-all duration-300"
                        whileHover={{ y: -5, scale: 1.02 }}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: index * 0.1 }}
                      >
                        <div className="flex items-start">
                          <div className={`p-2 rounded-lg bg-gradient-to-br ${activeSize.color} bg-opacity-20 mr-3`}>
                            {activity.icon}
                          </div>
                          <div>
                            <h4 className="font-medium text-white mb-1">{activity.title}</h4>
                            <p className="text-sm text-gray-400">{activity.description}</p>
                          </div>
                        </div>
                      </motion.div>
                    ))}
                  </div>
                </div>
              </div>
            </motion.div>
          )}

          {/* Summary Section */}
          <motion.div variants={itemVariants} className="text-center space-y-6">
            <div className="bg-gradient-to-r from-indigo-500/10 to-purple-500/10 rounded-3xl p-10 border border-indigo-500/20 backdrop-blur-xl">
              <motion.h2 
                className="text-3xl font-bold mb-6 bg-gradient-to-r from-indigo-400 to-purple-400 text-transparent bg-clip-text"
                whileHover={{ scale: 1.05 }}
              >
                TL;DR: Signal Value Grows Exponentially
              </motion.h2>
              
              <div className="space-y-4 text-xl text-gray-300">
                <p>Every signal unit you generate creates Essence.</p>
                <p>The more you contribute, the more you earn.</p>
                <p>Your data becomes more valuable as it accumulates.</p>
              </div>
            </div>
          </motion.div>

          {/* Navigation */}
          <motion.div variants={itemVariants} className="flex justify-between items-center">
            <Link to="/flash/signal-calculator/why-data-can-replace-money">
              <motion.button
                className="flex items-center text-indigo-400 hover:text-indigo-300 transition-colors"
                whileHover={{ x: -5 }}
              >
                <ArrowLeft className="w-5 h-5 mr-2" />
                <span>Data: The Missing Asset Class</span>
              </motion.button>
            </Link>
            
            <Link to="/flash/signal-calculator/how-signal-economy-works">
              <motion.button
                className="flex items-center bg-gradient-to-r from-indigo-600 to-purple-600 text-white px-6 py-3 rounded-xl font-medium shadow-lg shadow-indigo-500/30 hover:shadow-indigo-500/50 transition-all duration-300"
                whileHover={{ x: 5, scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <span>How the Signal Economy Works</span>
                <ArrowRight className="w-5 h-5 ml-2" />
              </motion.button>
            </Link>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
} 