import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { ArrowLeft } from 'lucide-react';
import { Link } from 'react-router-dom';
import Modal from '../shared/Modal';
import TwitchAIContactForm from '../forms/TwitchAIContactForm';

export default function TwitchCaseStudy() {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const openContactModal = () => setIsContactModalOpen(true);
  const closeContactModal = () => setIsContactModalOpen(false);
  
  return (
    <div className="min-h-screen bg-black text-white">
      <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
        <div className="mb-8">
          <Link to="/">
            <motion.button
              className="flex items-center text-gray-400 hover:text-white"
              whileHover={{ x: -5 }}
              transition={{ duration: 0.2 }}
            >
              <ArrowLeft className="w-5 h-5 mr-2" />
              Back to Home
            </motion.button>
          </Link>
        </div>
        
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className="text-4xl md:text-5xl font-bold mb-6 bg-gradient-to-r from-purple-400 to-purple-600 text-transparent bg-clip-text">
            AI Avatar Twitch Co-Host: Gaming Revolution
          </h1>
          
          <div className="mb-8">
            <div className="flex flex-wrap gap-2 mb-4">
              <span className="px-3 py-1 text-sm bg-purple-900/50 text-purple-300 rounded-full">
                AI Avatar
              </span>
              <span className="px-3 py-1 text-sm bg-purple-900/50 text-purple-300 rounded-full">
                Gaming
              </span>
              <span className="px-3 py-1 text-sm bg-purple-900/50 text-purple-300 rounded-full">
                Live Streaming
              </span>
            </div>
          </div>
          
          <div className="prose prose-invert max-w-none mb-12">
            <p className="text-xl text-gray-300 mb-6">
              We successfully created an AI clone of our founder Milan Cheeks to co-host Twitch streams
              and play Call of Duty Black Ops 6 Zombies, demonstrating advanced AI personality replication
              and real-time gaming interaction.
            </p>
            
            <h2 className="text-2xl font-bold mt-8 mb-4">The Challenge: Walking Through Terminus</h2>
            <p className="mb-4">
              One of the most impressive demonstrations of our AI Avatar technology was when the AI
              walked us through playing the challenging Zombies mode in Call of Duty Black Ops 6,
              specifically helping to beat the notoriously difficult "Terminus" level.
            </p>
            
            <p className="mb-4">
              The AI Avatar was able to provide real-time guidance, strategy recommendations, and even
              react to in-game events with appropriate commentary and emotional responses, creating a
              truly interactive and engaging experience for both the streamer and viewers.
            </p>
            
            <h2 className="text-2xl font-bold mt-8 mb-4">Key Achievements</h2>
            
            <ul className="list-disc pl-6 mb-6 space-y-2">
              <li>
                <strong>Personality Replication:</strong> The AI accurately mimicked the founder's
                speaking style, humor, and gaming knowledge.
              </li>
              <li>
                <strong>Real-time Decision Making:</strong> The AI provided strategic advice based on
                changing game conditions and enemy patterns.
              </li>
              <li>
                <strong>Viewer Engagement:</strong> The AI interacted with chat, answered questions
                about gameplay, and maintained natural conversation throughout the stream.
              </li>
              <li>
                <strong>Game Expertise:</strong> The AI demonstrated deep knowledge of Black Ops 6
                mechanics, maps, and zombie strategies.
              </li>
            </ul>
            
            <h2 className="text-2xl font-bold mt-8 mb-4">See It In Action</h2>
            <p className="mb-6">
              Watch the clip below to see how our AI Avatar helped navigate through the challenging
              Terminus level, offering guidance and creating an engaging streaming experience:
            </p>
          </div>
          
          <div className="mb-16 flex justify-center bg-gray-900 p-4 rounded-xl shadow-lg">
            <div className="relative w-full" style={{ paddingTop: '56.25%' }}>
              <iframe 
                src="https://clips.twitch.tv/embed?clip=AssiduousAltruisticDiamondPipeHype-x__TQ8dunnX8gOVV&parent=localhost&parent=selflabs.com&parent=www.selflabs.com&parent=m.selflabs.com&parent=127.0.0.1" 
                frameBorder="0" 
                allowFullScreen={true} 
                scrolling="no" 
                title="AI Twitch Co-Host Gaming Clip"
                className="absolute top-0 left-0 w-full h-full rounded-lg shadow-lg"
              ></iframe>
            </div>
          </div>
          
          <div className="prose prose-invert max-w-none mb-12">
            <h2 className="text-2xl font-bold mt-8 mb-4">Technology Behind the Scenes</h2>
            <p className="mb-4">
              Our AI Avatar technology combines several advanced AI components:
            </p>
            
            <ul className="list-disc pl-6 mb-6 space-y-2">
              <li>
                <strong>Neural Voice Replication:</strong> Creates a lifelike voice clone that matches
                the founder's natural speech patterns and intonations.
              </li>
              <li>
                <strong>Real-time Gaming Analytics:</strong> Processes visual game data to make
                informed strategic recommendations.
              </li>
              <li>
                <strong>Personality Framework:</strong> Custom-built system that captures and replicates
                the nuances of personal expression, humor, and knowledge.
              </li>
              <li>
                <strong>Contextual Memory:</strong> The AI maintains awareness of the ongoing game state
                and conversation history to provide coherent responses.
              </li>
            </ul>
            
            <h2 className="text-2xl font-bold mt-8 mb-4">Future Applications</h2>
            <p className="mb-4">
              This case study demonstrates the potential of AI Avatars beyond just gaming:
            </p>
            
            <ul className="list-disc pl-6 mb-6 space-y-2">
              <li>Virtual coaching and training across various disciplines</li>
              <li>Content creator assistance and scaling</li>
              <li>Interactive media experiences with personalized guidance</li>
              <li>Brand representation that maintains consistent personality</li>
            </ul>
            
            <p className="mt-8 text-gray-300">
              Interested in how this technology can enhance your brand or content creation?
              <button 
                onClick={openContactModal}
                className="text-purple-400 hover:text-purple-300 ml-2 underline focus:outline-none"
              >
                Contact us to learn more.
              </button>
            </p>
          </div>
        </motion.div>
      </div>
      
      {/* Contact Form Modal */}
      <Modal 
        isOpen={isContactModalOpen} 
        onClose={closeContactModal}
        title="AI Twitch Co-Host Inquiry"
      >
        <TwitchAIContactForm />
      </Modal>
    </div>
  );
} 