import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronDown, ChevronRight, DollarSign, Lock, Wallet, BarChart3 } from 'lucide-react';

export default function ValueCreationLoop() {
  const [expandedItem, setExpandedItem] = useState<string | null>(null);
  
  const steps = [
    {
      id: 'ai-companies',
      icon: <DollarSign className="w-6 h-6 text-indigo-400" />,
      title: "AI companies pay real money for access to human signal",
      details: (
        <div className="mt-4 bg-black/30 p-6 rounded-xl border border-indigo-500/30">
          <h4 className="text-lg font-semibold text-indigo-400 mb-4">How AI Companies Benefit</h4>
          <ul className="space-y-4">
            <li className="flex items-start">
              <span className="text-indigo-400 mr-2 mt-1">•</span>
              <div>
                <p className="font-medium text-white">High-Quality Training Data</p>
                <p className="text-gray-400">Clean, consensual human signal creates better AI models</p>
              </div>
            </li>
            <li className="flex items-start">
              <span className="text-indigo-400 mr-2 mt-1">•</span>
              <div>
                <p className="font-medium text-white">Ethical Sourcing</p>
                <p className="text-gray-400">Avoid copyright and privacy issues with opt-in data</p>
              </div>
            </li>
            <li className="flex items-start">
              <span className="text-indigo-400 mr-2 mt-1">•</span>
              <div>
                <p className="font-medium text-white">Diverse Perspectives</p>
                <p className="text-gray-400">Access to global signals creates more balanced AI</p>
              </div>
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: 'blockchain-vault',
      icon: <Lock className="w-6 h-6 text-indigo-400" />,
      title: "Payments are secured in a blockchain vault",
      details: (
        <div className="mt-4 bg-black/30 p-6 rounded-xl border border-indigo-500/30">
          <h4 className="text-lg font-semibold text-indigo-400 mb-4">Secure Value Storage</h4>
          <ul className="space-y-4">
            <li className="flex items-start">
              <span className="text-indigo-400 mr-2 mt-1">•</span>
              <div>
                <p className="font-medium text-white">Transparent Tracking</p>
                <p className="text-gray-400">All transactions recorded on an immutable ledger</p>
              </div>
            </li>
            <li className="flex items-start">
              <span className="text-indigo-400 mr-2 mt-1">•</span>
              <div>
                <p className="font-medium text-white">Smart Contract Distribution</p>
                <p className="text-gray-400">Automatic payment routing based on signal contribution</p>
              </div>
            </li>
            <li className="flex items-start">
              <span className="text-indigo-400 mr-2 mt-1">•</span>
              <div>
                <p className="font-medium text-white">Censorship Resistance</p>
                <p className="text-gray-400">Protection against tampering or unauthorized access</p>
              </div>
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: 'essence-tokens',
      icon: <Wallet className="w-6 h-6 text-indigo-400" />,
      title: "Essence tokens are minted as a stable, signal-backed currency",
      details: (
        <div className="mt-4 bg-black/30 p-6 rounded-xl border border-indigo-500/30">
          <h4 className="text-lg font-semibold text-indigo-400 mb-4">The Essence Token</h4>
          <ul className="space-y-4">
            <li className="flex items-start">
              <span className="text-indigo-400 mr-2 mt-1">•</span>
              <div>
                <p className="font-medium text-white">1:1 Backing</p>
                <p className="text-gray-400">Each token is fully backed by real financial value</p>
              </div>
            </li>
            <li className="flex items-start">
              <span className="text-indigo-400 mr-2 mt-1">•</span>
              <div>
                <p className="font-medium text-white">Not Speculative</p>
                <p className="text-gray-400">Protected from market volatility by real-world backing</p>
              </div>
            </li>
            <li className="flex items-start">
              <span className="text-indigo-400 mr-2 mt-1">•</span>
              <div>
                <p className="font-medium text-white">Signal Economy Utility</p>
                <p className="text-gray-400">Powers exchanges and transactions within the ecosystem</p>
              </div>
            </li>
          </ul>
        </div>
      ),
    },
    {
      id: 'users-earn',
      icon: <BarChart3 className="w-6 h-6 text-indigo-400" />,
      title: "Users earn Essence through their daily activities",
      details: (
        <div className="mt-4 bg-black/30 p-6 rounded-xl border border-indigo-500/30">
          <h4 className="text-lg font-semibold text-indigo-400 mb-4">How You Generate Value</h4>
          <ul className="space-y-4">
            <li className="flex items-start">
              <span className="text-indigo-400 mr-2 mt-1">•</span>
              <div>
                <p className="font-medium text-white">Movement Data</p>
                <p className="text-gray-400">Walking, exercise, and activity patterns create value</p>
              </div>
            </li>
            <li className="flex items-start">
              <span className="text-indigo-400 mr-2 mt-1">•</span>
              <div>
                <p className="font-medium text-white">Attention & Focus</p>
                <p className="text-gray-400">Your attention is valuable and you should be paid for it</p>
              </div>
            </li>
            <li className="flex items-start">
              <span className="text-indigo-400 mr-2 mt-1">•</span>
              <div>
                <p className="font-medium text-white">Emotional Processing</p>
                <p className="text-gray-400">Authentic emotional patterns improve AI understanding</p>
              </div>
            </li>
            <li className="flex items-start">
              <span className="text-indigo-400 mr-2 mt-1">•</span>
              <div>
                <p className="font-medium text-white">Dream & Sleep Patterns</p>
                <p className="text-gray-400">Sleep cycles and dream state data provide valuable insights</p>
              </div>
            </li>
          </ul>
        </div>
      ),
    },
  ];

  return (
    <div className="py-16 bg-black">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="mb-12"
        >
          <h2 className="text-4xl font-bold mb-6 text-center bg-gradient-to-r from-indigo-400 via-purple-400 to-indigo-400 text-transparent bg-clip-text">
            1. Value Creation Loop
          </h2>
          <p className="text-lg text-center text-gray-400 max-w-3xl mx-auto">
            Discover how value flows through the Signal Economy, creating a fair and transparent system
          </p>
        </motion.div>

        <div className="space-y-4">
          {steps.map((step) => (
            <motion.div
              key={step.id}
              className="bg-black/40 rounded-xl border border-indigo-500/20 overflow-hidden"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.3 }}
            >
              <motion.button
                className="w-full flex items-center justify-between p-6 text-left"
                onClick={() => setExpandedItem(expandedItem === step.id ? null : step.id)}
                whileHover={{ backgroundColor: 'rgba(99, 102, 241, 0.05)' }}
              >
                <div className="flex items-center">
                  <div className="mr-4 text-indigo-400 bg-black/30 p-2 rounded-lg">
                    {step.icon}
                  </div>
                  <span className="text-gray-200">{step.title}</span>
                </div>
                <div>
                  {expandedItem === step.id ? (
                    <ChevronDown className="h-5 w-5 text-indigo-400" />
                  ) : (
                    <ChevronRight className="h-5 w-5 text-indigo-400" />
                  )}
                </div>
              </motion.button>
              
              <AnimatePresence>
                {expandedItem === step.id && (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: 'auto', opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    className="px-6 pb-6 overflow-hidden"
                  >
                    {step.details}
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
} 