import React, { useState } from 'react';
import { ChevronDown, ChevronUp } from 'lucide-react';

interface FocusLevelProps {
  title: string;
  days: string;
  description: string;
  children: React.ReactNode;
}

const FocusLevel: React.FC<FocusLevelProps> = ({ title, days, description, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  
  return (
    <div className="mb-8 border border-purple-500/30 rounded-lg overflow-hidden">
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full bg-purple-900/30 p-6 text-left flex justify-between items-center"
      >
        <div>
          <h3 className="text-xl font-bold text-white">{title}</h3>
          <p className="text-purple-300">{days}</p>
        </div>
        {isOpen ? (
          <ChevronUp className="w-5 h-5 text-purple-300" />
        ) : (
          <ChevronDown className="w-5 h-5 text-purple-300" />
        )}
      </button>
      
      {isOpen && (
        <div className="p-6 bg-gray-900/30">
          <p className="text-gray-300 mb-4">{description}</p>
          {children}
        </div>
      )}
    </div>
  );
};

interface DayContentProps {
  day: number;
  mind: React.ReactNode;
  body: React.ReactNode;
  soul: React.ReactNode;
  psionic?: React.ReactNode;
}

const DayContent: React.FC<DayContentProps> = ({ day, mind, body, soul, psionic }) => {
  return (
    <div className="mb-6 pb-6 border-b border-purple-500/10 last:border-b-0 last:pb-0 last:mb-0">
      <h4 className="text-lg font-semibold text-indigo-300 mb-3">Day {day}</h4>
      
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-4">
        <div className="bg-purple-900/20 p-4 rounded">
          <h5 className="font-medium text-purple-300 mb-2">Mind</h5>
          <div className="text-gray-300 text-sm">{mind}</div>
        </div>
        
        <div className="bg-blue-900/20 p-4 rounded">
          <h5 className="font-medium text-blue-300 mb-2">Body</h5>
          <div className="text-gray-300 text-sm">{body}</div>
        </div>
        
        <div className="bg-green-900/20 p-4 rounded">
          <h5 className="font-medium text-green-300 mb-2">Soul</h5>
          <div className="text-gray-300 text-sm">{soul}</div>
        </div>
        
        {psionic && (
          <div className="bg-teal-900/20 p-4 rounded">
            <h5 className="font-medium text-teal-300 mb-2">Psionic</h5>
            <div className="text-gray-300 text-sm">{psionic}</div>
          </div>
        )}
      </div>
    </div>
  );
};

interface MudraDisplayProps {
  name: string;
  description: string;
}

const MudraDisplay: React.FC<MudraDisplayProps> = ({ name, description }) => {
  return (
    <div className="bg-indigo-900/30 p-3 rounded mt-2 mb-3">
      <div className="font-medium text-indigo-300">{name}</div>
      <div className="text-gray-300 text-sm mt-1">{description}</div>
    </div>
  );
};

export default function NeuauraProgram() {
  const handleJoinCommunity = () => {
    // In a real implementation, this would link to a community forum or Discord server
    // For now, we'll just show an alert
    alert("This would link to our Neuaura Practitioner Community where you can share experiences, ask questions, and connect with others on their consciousness exploration journey.");
  };

  return (
    <div className="space-y-6">
      <FocusLevel 
        title="Focus 1 - Baseline Consciousness" 
        days="Days 1-3"
        description="Establishing baseline consciousness, grounding practices, and initial body awareness. Participants learn to be fully present while performing physical exercises."
      >
        <div className="mb-4">
          <h4 className="text-lg font-semibold text-indigo-300 mb-2">Focus Overview</h4>
          <p className="text-gray-300">
            In Focus 1, you'll establish a baseline of awareness and presence. This phase helps you become more 
            conscious of your thoughts, feelings, and physical sensations, building the foundation for deeper states.
          </p>
          
          <div className="bg-purple-900/20 p-4 rounded mt-4">
            <h5 className="font-medium text-purple-300 mb-2">Meditation Frequency</h5>
            <p className="text-gray-300 text-sm">Predominant Beta waves (16-31 Hz)</p>
            
            <MudraDisplay 
              name="Gyan Mudra (Mudra of Knowledge)" 
              description="Touch the tip of the thumb to the tip of the index finger, with the other three fingers stretched out. Practice during meditation and throughout the day, especially during moments of reflection or when seeking clarity."
            />
          </div>
          
          <div className="bg-purple-900/20 p-4 rounded mt-4">
            <h5 className="font-medium text-purple-300 mb-2">Focus Benefits</h5>
            <ul className="text-gray-300 text-sm list-disc pl-5 space-y-1">
              <li><strong>Enhanced Self-awareness:</strong> A heightened understanding of one's thoughts and feelings.</li>
              <li><strong>Mindfulness:</strong> The ability to be present in the moment without judgment.</li>
              <li><strong>Improved Concentration:</strong> Enhanced ability to focus on a single task or thought.</li>
            </ul>
          </div>
        </div>
        
        <DayContent
          day={1}
          mind={
            <>
              <p>Meditation focus: Grounding and being present.</p>
              <p className="mt-2">Calm App: Mindfulness for Beginners 1. The Big Idea</p>
              <p className="mt-2">Practice Gyan Mudra during meditation.</p>
            </>
          }
          body={
            <p>CrossFit WOD: Introduction to squats, push-ups, and pull-ups.</p>
          }
          soul={
            <p>Compliment a stranger genuinely.</p>
          }
          psionic={
            <>
              <p><strong>Telepathy Practice</strong></p>
              <p className="mt-1">Card-guessing tasks for potential telepathy. Try to guess a friend's thoughts or emotions. Use the Gyan Mudra during these attempts to enhance concentration.</p>
            </>
          }
        />
        
        <DayContent
          day={2}
          mind={
            <>
              <p>Meditation focus: Grounding and being present.</p>
              <p className="mt-2">Calm App: Mindfulness for Beginners 2. Homebase</p>
              <p className="mt-2">Continue practicing Gyan Mudra.</p>
            </>
          }
          body={
            <p>CrossFit WOD: Interval training with kettlebell swings and box jumps.</p>
          }
          soul={
            <p>Hold the door open for someone.</p>
          }
          psionic={
            <>
              <p><strong>Telepathy Practice</strong></p>
              <p className="mt-1">Continue with card-guessing exercises, trying to increase accuracy.</p>
            </>
          }
        />
        
        <DayContent
          day={3}
          mind={
            <>
              <p>Meditation focus: Grounding and being present.</p>
              <p className="mt-2">Calm App: Mindfulness for Beginners 3. Pop out of your thoughts</p>
              <p className="mt-2">Deepen your practice with Gyan Mudra.</p>
            </>
          }
          body={
            <p>CrossFit WOD: Cardio emphasis with running and jump rope.</p>
          }
          soul={
            <p>Share a positive quote or message on social media.</p>
          }
          psionic={
            <>
              <p><strong>Telepathy Practice</strong></p>
              <p className="mt-1">Try guessing colors or simple images that someone is visualizing.</p>
            </>
          }
        />
      </FocusLevel>
      
      <FocusLevel 
        title="Focus 10 - Mind Awake, Body Asleep" 
        days="Days 4-6"
        description="Developing the ability to maintain mental alertness while physically relaxed, and expanding awareness beyond ordinary perception."
      >
        <div className="mb-4">
          <h4 className="text-lg font-semibold text-indigo-300 mb-2">Focus Overview</h4>
          <p className="text-gray-300">
            Focus 10 introduces the state of "mind awake/body asleep," where you maintain complete mental awareness while
            your body enters a state of deep relaxation. This dissociation is the gateway to deeper conscious states.
          </p>
          
          <div className="bg-purple-900/20 p-4 rounded mt-4">
            <h5 className="font-medium text-purple-300 mb-2">Meditation Frequency</h5>
            <p className="text-gray-300 text-sm">Shift from Beta waves (16-31 Hz) to Alpha waves (8-15 Hz)</p>
            
            <MudraDisplay 
              name="Shunya Mudra (Mudra of Emptiness)" 
              description="Touch the middle finger to the base of the thumb and press the thumb onto the middle finger. Practice during meditation and whenever seeking inner peace or trying to 'empty' the mind of overwhelming thoughts."
            />
          </div>
          
          <div className="bg-purple-900/20 p-4 rounded mt-4">
            <h5 className="font-medium text-purple-300 mb-2">Focus Benefits</h5>
            <ul className="text-gray-300 text-sm list-disc pl-5 space-y-1">
              <li><strong>Body Awareness:</strong> A deepened understanding of bodily sensations and the ability to differentiate between physical and mental states.</li>
              <li><strong>Relaxation:</strong> The skill to relax the body at will, which can aid in stress reduction.</li>
              <li><strong>Dissociation:</strong> The ability to mentally detach from immediate physical surroundings, which can be useful in pain management.</li>
            </ul>
          </div>
        </div>
        
        <DayContent
          day={4}
          mind={
            <>
              <p>Meditation focus: Relaxation and visualization.</p>
              <p className="mt-2">Calm App: Mindfulness for Beginners 4. Inner Smoothness</p>
              <p className="mt-2">Begin practicing Shunya Mudra.</p>
            </>
          }
          body={
            <p>CrossFit WOD: Strength training with deadlifts and overhead presses.</p>
          }
          soul={
            <p>Donate a small amount to a charity or cause.</p>
          }
          psionic={
            <>
              <p><strong>Astral Projection Practice</strong></p>
              <p className="mt-1">Attempt to visualize and project your consciousness to a familiar location. Before sleeping, visualize floating above your body. Use the Shunya Mudra to aid in detaching from physical sensations.</p>
            </>
          }
        />
        
        <DayContent
          day={5}
          mind={
            <>
              <p>Meditation focus: Relaxation and visualization.</p>
              <p className="mt-2">Calm App: Mindfulness for Beginners 5. A Habit You Actually Want</p>
              <p className="mt-2">Continue with Shunya Mudra, focusing on emptying the mind.</p>
            </>
          }
          body={
            <p>CrossFit WOD: Plyometric exercises like box jumps and plyo push-ups.</p>
          }
          soul={
            <p>Write a thank-you note to someone who has impacted your life.</p>
          }
          psionic={
            <>
              <p><strong>Astral Projection Practice</strong></p>
              <p className="mt-1">Continue visualization exercises, attempting to extend the duration of your projections.</p>
            </>
          }
        />
        
        <DayContent
          day={6}
          mind={
            <>
              <p>Meditation focus: Relaxation and visualization.</p>
              <p className="mt-2">Calm App: Mindfulness for Beginners 6. The Concentration Gym</p>
              <p className="mt-2">Deepen your practice with Shunya Mudra.</p>
            </>
          }
          body={
            <p>CrossFit WOD: Core-focused exercises like planks and toes-to-bar.</p>
          }
          soul={
            <p>Spend 10 minutes listening to someone without interrupting or offering advice.</p>
          }
          psionic={
            <>
              <p><strong>Astral Projection Practice</strong></p>
              <p className="mt-1">Try to project to a specific location in your home or a familiar place. Note any details you perceive.</p>
            </>
          }
        />
      </FocusLevel>
      
      <FocusLevel 
        title="Focus 11 - The Access Channel" 
        days="Days 7-9"
        description="Integration of conscious and subconscious mind, enhancing intuition and creativity."
      >
        <div className="mb-4">
          <h4 className="text-lg font-semibold text-indigo-300 mb-2">Focus Overview</h4>
          <p className="text-gray-300">
            Focus 11 represents an "access channel" between your conscious and subconscious mind. This state allows you to
            tap into intuitive knowledge and creative insights that are normally obscured by everyday thinking.
          </p>
          
          <div className="bg-purple-900/20 p-4 rounded mt-4">
            <h5 className="font-medium text-purple-300 mb-2">Meditation Frequency</h5>
            <p className="text-gray-300 text-sm">Balanced brainwave activity with a mix of Alpha (8-15 Hz) and Theta waves (4-7 Hz)</p>
            
            <MudraDisplay 
              name="Hakini Mudra" 
              description="Join the tips of all fingers on one hand to the corresponding fingers on the other hand. Practice during meditation and when trying to access intuition or when problem-solving during the day."
            />
          </div>
          
          <div className="bg-purple-900/20 p-4 rounded mt-4">
            <h5 className="font-medium text-purple-300 mb-2">Focus Benefits</h5>
            <ul className="text-gray-300 text-sm list-disc pl-5 space-y-1">
              <li><strong>Enhanced Intuition:</strong> Improved ability to access and trust one's intuitive insights.</li>
              <li><strong>Creativity Boost:</strong> Access to deeper levels of the subconscious can enhance creative thinking and problem-solving.</li>
              <li><strong>Emotional Balance:</strong> Improved regulation of emotions and responses to external stimuli.</li>
            </ul>
          </div>
        </div>
        
        {/* Day content for Focus 11 would be added here */}
        <p className="text-center text-gray-500 italic">Complete daily content for Focus 11 (Days 7-9) available in the full guide</p>
      </FocusLevel>
      
      <FocusLevel 
        title="Focus 12 - Expanded Awareness" 
        days="Days 10-12"
        description="Broadening perception and cultivating mindfulness, allowing for a more holistic understanding of reality."
      >
        <div className="mb-4">
          <h4 className="text-lg font-semibold text-indigo-300 mb-2">Focus Overview</h4>
          <p className="text-gray-300">
            Focus 12 expands your awareness beyond normal perception. In this state, you develop a broader perspective
            that allows you to see connections and patterns that would normally be missed. Empathy and holistic thinking
            are significantly enhanced.
          </p>
          
          <div className="bg-purple-900/20 p-4 rounded mt-4">
            <h5 className="font-medium text-purple-300 mb-2">Meditation Frequency</h5>
            <p className="text-gray-300 text-sm">Predominant Alpha waves (8-15 Hz)</p>
            
            <MudraDisplay 
              name="Dhyana Mudra (Meditation Mudra)" 
              description="Place hands on your lap, left hand under the right with palms facing upwards and thumbs touching each other. Practice during meditation and when seeking inner tranquility or during moments of reflection."
            />
          </div>
          
          <div className="bg-purple-900/20 p-4 rounded mt-4">
            <h5 className="font-medium text-purple-300 mb-2">Focus Benefits</h5>
            <ul className="text-gray-300 text-sm list-disc pl-5 space-y-1">
              <li><strong>Broadened Perspective:</strong> The ability to see situations from multiple viewpoints.</li>
              <li><strong>Empathy:</strong> Enhanced understanding and connection with others' emotions and experiences.</li>
              <li><strong>Holistic Thinking:</strong> The skill to see the bigger picture and understand interconnectedness.</li>
            </ul>
          </div>
        </div>
        
        <DayContent
          day={10}
          mind={
            <>
              <p>Meditation focus: Broadening perception and cultivating mindfulness.</p>
              <p className="mt-2">Calm App: Mindfulness for Beginners 10. The Eye Of The Hurricane</p>
              <p className="mt-2">Practice Dhyana Mudra during meditation.</p>
            </>
          }
          body={
            <p>CrossFit WOD: High-intensity interval training with burpees and sprints.</p>
          }
          soul={
            <p>Volunteer a few hours at a local community center or organization.</p>
          }
          psionic={
            <>
              <p><strong>Remote Viewing Practice</strong></p>
              <p className="mt-1">Attempt to describe a hidden image or location. Choose a location on a map and try to describe it in detail. Use the Dhyana Mudra to calm the mind and enhance focus.</p>
            </>
          }
        />
        
        <DayContent
          day={11}
          mind={
            <>
              <p>Meditation focus: Broadening perception and cultivating mindfulness.</p>
              <p className="mt-2">Calm App: Mindfulness for Beginners 11. Electric Clarity</p>
              <p className="mt-2">Continue practicing Dhyana Mudra.</p>
            </>
          }
          body={
            <p>CrossFit WOD: Functional movements like farmer's walks and sled pushes.</p>
          }
          soul={
            <p>Plant a tree or engage in a local environmental cleanup.</p>
          }
          psionic={
            <>
              <p><strong>Remote Viewing Practice</strong></p>
              <p className="mt-1">Have someone place an object in a sealed envelope. Try to describe the object without seeing it.</p>
            </>
          }
        />
        
        <DayContent
          day={12}
          mind={
            <>
              <p>Meditation focus: Broadening perception and cultivating mindfulness.</p>
              <p className="mt-2">Calm App: Mindfulness for Beginners 12. Sanity Day</p>
              <p className="mt-2">Deepen your practice with Dhyana Mudra.</p>
            </>
          }
          body={
            <p>CrossFit WOD: Flexibility emphasis with yoga poses and mobility drills.</p>
          }
          soul={
            <p>Spend time with an elderly person, listening to their stories and experiences.</p>
          }
          psionic={
            <>
              <p><strong>Remote Viewing Practice</strong></p>
              <p className="mt-1">Try to perceive a location that someone else is thinking about. Note colors, shapes, and impressions.</p>
            </>
          }
        />
      </FocusLevel>
      
      <FocusLevel 
        title="Focus 15 - No-Time" 
        days="Days 13-15"
        description="Transcending the present moment and experiencing timelessness, allowing for profound insights and enhanced patience."
      >
        <div className="mb-4">
          <h4 className="text-lg font-semibold text-indigo-300 mb-2">Focus Overview</h4>
          <p className="text-gray-300">
            Focus 15 introduces you to the concept of "no-time," a state where linear time perception dissolves.
            This opens awareness to experiences beyond the constraints of past, present, and future, allowing for
            deeper insights and the ability to perceive events from a more holistic perspective.
          </p>
          
          <div className="bg-purple-900/20 p-4 rounded mt-4">
            <h5 className="font-medium text-purple-300 mb-2">Meditation Frequency</h5>
            <p className="text-gray-300 text-sm">Shift into Theta waves (4-7 Hz)</p>
            
            <MudraDisplay 
              name="Khechari Mudra" 
              description="Roll the tongue back towards the soft palate. This mudra can be practiced during deep meditation sessions and is believed to help in transcending time and space."
            />
          </div>
          
          <div className="bg-purple-900/20 p-4 rounded mt-4">
            <h5 className="font-medium text-purple-300 mb-2">Focus Benefits</h5>
            <ul className="text-gray-300 text-sm list-disc pl-5 space-y-1">
              <li><strong>Time Dilation:</strong> The perception that time is malleable, which can be useful in activities requiring prolonged concentration.</li>
              <li><strong>Past/Future Insights:</strong> Potential insights into past experiences or future possibilities.</li>
              <li><strong>Enhanced Patience:</strong> A deeper understanding of the transient nature of time.</li>
            </ul>
          </div>
        </div>
        
        <p className="text-center text-gray-500 italic">Complete daily content for Focus 15 (Days 13-15) available in the full guide</p>
      </FocusLevel>
      
      <FocusLevel 
        title="Focus 21 - Bridge to Other Realities" 
        days="Days 16-18"
        description="Bridging the conscious to the superconscious, accessing other dimensions and experiencing transcendental states."
      >
        <div className="mb-4">
          <h4 className="text-lg font-semibold text-indigo-300 mb-2">Focus Overview</h4>
          <p className="text-gray-300">
            Focus 21 serves as a bridge between ordinary consciousness and higher states of awareness. In this state,
            you may begin to access alternate realities, transcendental experiences, and deeper spiritual insights
            that go beyond conventional understanding.
          </p>
          
          <div className="bg-purple-900/20 p-4 rounded mt-4">
            <h5 className="font-medium text-purple-300 mb-2">Meditation Frequency</h5>
            <p className="text-gray-300 text-sm">Mix of Theta (4-7 Hz) and Delta waves (1-3 Hz)</p>
            
            <MudraDisplay 
              name="Prana Mudra (Mudra of Life)" 
              description="Touch the tip of the thumb to the tips of the ring finger and little finger, with the other two fingers stretched out. Practice during meditation and whenever seeking vitality or to connect with higher realities."
            />
          </div>
          
          <div className="bg-purple-900/20 p-4 rounded mt-4">
            <h5 className="font-medium text-purple-300 mb-2">Focus Benefits</h5>
            <ul className="text-gray-300 text-sm list-disc pl-5 space-y-1">
              <li><strong>Transcendental Experiences:</strong> Potential experiences of states of consciousness beyond the ordinary.</li>
              <li><strong>Spiritual Insights:</strong> Deepened spiritual understanding and connection.</li>
              <li><strong>Astral Projection:</strong> Some practitioners report out-of-body experiences at this level.</li>
            </ul>
          </div>
        </div>
        
        <p className="text-center text-gray-500 italic">Complete daily content for Focus 21 (Days 16-18) available in the full guide</p>
      </FocusLevel>
      
      <div className="bg-gradient-to-r from-purple-900/30 to-indigo-900/30 border border-purple-500/20 rounded-lg p-6">
        <h3 className="text-xl font-semibold mb-3 text-purple-300">Advanced Focus Levels (22-30)</h3>
        <p className="text-gray-300 mb-4">
          The program continues with increasingly profound states of consciousness through Focus levels 22-30, including:
        </p>
        
        <ul className="text-gray-300 list-disc pl-6 space-y-3">
          <li>
            <strong>Focus 22 (Days 19-21):</strong> The Dark Side of the Moon - Delving into the subconscious, confronting and understanding personal shadows.
          </li>
          <li>
            <strong>Focus 23-24 (Days 22-24):</strong> The Edge of the Universe - Expanding consciousness to the vastness of the universe.
          </li>
          <li>
            <strong>Focus 25 (Days 25-27):</strong> The Council of Elders - Connecting with ancient wisdom and higher guidance.
          </li>
          <li>
            <strong>Focus 26-27 (Days 28-30):</strong> The Convergence & Bridge to Infinity - Unity with all existence and contemplating the eternal nature of existence.
          </li>
          <li>
            <strong>Focus 28 (Days 31-33):</strong> Beyond Infinity - Transcending physical reality and connecting with the infinite.
          </li>
          <li>
            <strong>Focus 29 (Days 34-36):</strong> The Cosmic Pulse - Feeling the heartbeat of the universe and synchronizing with it.
          </li>
          <li>
            <strong>Focus 30 (Days 37-39):</strong> Universal Resonance - Aligning with the vibrational frequency of the universe.
          </li>
        </ul>
        
        <p className="text-gray-300 mt-4">
          Each of these advanced focus levels contains detailed daily practices for all four pillars: Mind, Body, Soul, and Psionic abilities.
          For the complete program with full instructions, download the comprehensive guide.
        </p>
      </div>
      
      <div className="text-center bg-indigo-900/20 p-6 rounded-lg mt-8">
        <h3 className="text-xl font-semibold text-white mb-3">Ready to Begin Your Neuaura Journey?</h3>
        <p className="text-gray-300 mb-4">
          This open-source program is free to use, share, and adapt for your personal practice.
          We recommend documenting your experiences in a journal to track your progress.
        </p>
        <div className="flex justify-center">
          <button 
            onClick={handleJoinCommunity}
            className="bg-indigo-600 hover:bg-indigo-700 text-white font-bold py-3 px-6 rounded-lg transition-colors duration-300"
          >
            Join Online Community
          </button>
        </div>
      </div>
    </div>
  );
} 