import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';

export function SyncWithSignal() {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    
    const ctx = canvas.getContext('2d');
    if (!ctx) return;
    
    // Set canvas dimensions
    const resizeCanvas = () => {
      canvas.width = canvas.clientWidth * 2; // For retina displays
      canvas.height = canvas.clientHeight * 2;
    };
    
    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);
    
    // Define nodes
    const nodes: { x: number, y: number, size: number, speed: number, connections: number[], phase: number }[] = [];
    const numNodes = 12;
    const rootNode = { x: canvas.width / 2, y: canvas.height / 2, size: 8, connections: [] };
    
    // Helper function to clamp values between 0 and 1
    const clamp = (value: number): number => Math.max(0, Math.min(1, value));
    
    // Create nodes
    for (let i = 0; i < numNodes; i++) {
      const angle = (i / numNodes) * Math.PI * 2;
      const radius = 100 + Math.random() * 100;
      nodes.push({
        x: rootNode.x + Math.cos(angle) * radius,
        y: rootNode.y + Math.sin(angle) * radius,
        size: 3 + Math.random() * 4,
        speed: 0.002 + Math.random() * 0.005,
        connections: [Math.floor(Math.random() * numNodes)],
        phase: Math.random() * Math.PI * 2
      });
    }
    
    // Connect each node to root
    for (let i = 0; i < numNodes; i++) {
      if (Math.random() > 0.3) { // 70% chance to connect to root
        nodes[i].connections.push(-1); // -1 signifies connection to root
      }
    }
    
    // Add more connections between nodes
    for (let i = 0; i < numNodes; i++) {
      const connectionsCount = Math.floor(Math.random() * 2) + 1; // 1-2 connections
      for (let j = 0; j < connectionsCount; j++) {
        const targetNode = Math.floor(Math.random() * numNodes);
        if (targetNode !== i && !nodes[i].connections.includes(targetNode)) {
          nodes[i].connections.push(targetNode);
        }
      }
    }
    
    // Animation function
    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      
      // Draw connections
      for (let i = 0; i < numNodes; i++) {
        const node = nodes[i];
        node.phase += node.speed;
        
        // Update node position with gentle wobble
        node.x += Math.sin(node.phase) * 0.5;
        node.y += Math.cos(node.phase) * 0.5;
        
        for (const connectionIndex of node.connections) {
          if (connectionIndex === -1) {
            // Connection to root node
            ctx.beginPath();
            ctx.moveTo(node.x, node.y);
            ctx.lineTo(rootNode.x, rootNode.y);
            
            // Pulse animation along line
            const gradient = ctx.createLinearGradient(node.x, node.y, rootNode.x, rootNode.y);
            const pulsePosition = (Math.sin(node.phase * 3) + 1) / 2; // 0 to 1
            
            // Clamp values to ensure they're within 0-1 range
            gradient.addColorStop(0, 'rgba(138, 43, 226, 0.1)');
            gradient.addColorStop(clamp(pulsePosition - 0.05), 'rgba(138, 43, 226, 0.1)');
            gradient.addColorStop(pulsePosition, 'rgba(138, 43, 226, 0.8)');
            gradient.addColorStop(clamp(pulsePosition + 0.05), 'rgba(138, 43, 226, 0.1)');
            gradient.addColorStop(1, 'rgba(138, 43, 226, 0.1)');
            
            ctx.strokeStyle = gradient;
            ctx.lineWidth = 1;
            ctx.stroke();
          } else {
            // Connection to another node
            const targetNode = nodes[connectionIndex];
            ctx.beginPath();
            ctx.moveTo(node.x, node.y);
            ctx.lineTo(targetNode.x, targetNode.y);
            ctx.strokeStyle = 'rgba(138, 43, 226, 0.15)';
            ctx.lineWidth = 1;
            ctx.stroke();
          }
        }
      }
      
      // Draw nodes
      for (let i = 0; i < numNodes; i++) {
        const node = nodes[i];
        
        // Node glow
        ctx.beginPath();
        ctx.arc(node.x, node.y, node.size * 2, 0, Math.PI * 2);
        const glowGradient = ctx.createRadialGradient(node.x, node.y, 0, node.x, node.y, node.size * 2);
        glowGradient.addColorStop(0, 'rgba(138, 43, 226, 0.5)');
        glowGradient.addColorStop(1, 'rgba(138, 43, 226, 0)');
        ctx.fillStyle = glowGradient;
        ctx.fill();
        
        // Node itself
        ctx.beginPath();
        ctx.arc(node.x, node.y, node.size, 0, Math.PI * 2);
        ctx.fillStyle = '#8A2BE2';
        ctx.fill();
      }
      
      // Draw root node with pulsing effect
      ctx.beginPath();
      ctx.arc(rootNode.x, rootNode.y, rootNode.size * 3, 0, Math.PI * 2);
      const rootGlowGradient = ctx.createRadialGradient(
        rootNode.x, rootNode.y, 0, 
        rootNode.x, rootNode.y, rootNode.size * 3
      );
      rootGlowGradient.addColorStop(0, 'rgba(138, 43, 226, 0.8)');
      rootGlowGradient.addColorStop(1, 'rgba(138, 43, 226, 0)');
      ctx.fillStyle = rootGlowGradient;
      ctx.fill();
      
      ctx.beginPath();
      ctx.arc(rootNode.x, rootNode.y, rootNode.size, 0, Math.PI * 2);
      ctx.fillStyle = 'white';
      ctx.fill();
      
      requestAnimationFrame(animate);
    };
    
    animate();
    
    return () => {
      window.removeEventListener('resize', resizeCanvas);
    };
  }, []);
  
  return (
    <motion.div
      className="absolute w-full h-full top-0 left-0 opacity-60 pointer-events-none"
      initial={{ opacity: 0 }}
      animate={{ opacity: 0.6 }}
      transition={{ delay: 1.5, duration: 1 }}
    >
      <canvas ref={canvasRef} className="w-full h-full" />
    </motion.div>
  );
} 