import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Calendar } from 'lucide-react';
import { supabase } from '../../../utils/supabase';

export function InvestorEvents() {
  const [events, setEvents] = useState<any[]>([]);

  useEffect(() => {
    fetchEvents();
  }, []);

  async function fetchEvents() {
    try {
      const { data, error } = await supabase
        .from('investor_events')
        .select('*')
        .order('event_date', { ascending: true });

      if (error) throw error;
      setEvents(data || []);
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  }

  return (
    <div className="bg-[#0A0A0B] border border-white/10 rounded-xl p-6">
      <h2 className="text-xl font-semibold text-white mb-6">Upcoming Events</h2>
      
      <div className="space-y-4">
        {events.map((event, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: index * 0.1 }}
            className="p-4 bg-white/5 rounded-lg"
          >
            <div className="flex items-start space-x-4">
              <Calendar className="w-5 h-5 text-[#B4FF39] mt-1" />
              <div>
                <div className="text-white font-medium">{event.title}</div>
                <div className="text-sm text-gray-400 mt-1">
                  {event.date} • {event.time}
                </div>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
}