import React from 'react';
import CaseStudyCard from './CaseStudyCard';

const caseStudies = [
  {
    title: "Universal Basic Income & Data Monetization",
    description: "Exploring how AuRA's data monetization capabilities can facilitate Universal Basic Income, enabling individuals to earn substantial income by leveraging their personal data as a valuable asset.",
    image: "https://i.ibb.co/LdBWY32j/u9921589829-Universal-Based-Income-v-6-1-e6756595-fa0e-4636-93a1-a9a260fd1721-3.png",
    tags: ["AuRA Technology", "Data Monetization", "UBI"],
    learnMoreUrl: "/case-studies/universal-basic-income"
  },
  {
    title: "Cultivate Coin: Mirror Image X Beomni",
    description: "A revolutionary partnership utilizing Beomni Robots in Growboxes for sustainable agriculture, powered by the Cultivate Coin game that allows players to participate in real-world farming operations.",
    image: "https://images.unsplash.com/photo-1530836369250-ef72a3f5cda8?auto=format&fit=crop&q=80&w=800",
    tags: ["Robotics", "Sustainable Agriculture", "GameFi"],
    learnMoreUrl: "/case-studies/cultivate-coin"
  },
  {
    title: "Reflect: Relive Your Memories In The Metaverse",
    description: "An innovative simulation game that transforms personal memories into interactive VR worlds with AI-powered avatars, allowing users to revisit, reimagine, and share their most cherished moments.",
    image: "https://i.ibb.co/W4p5N6qD/u9921589829-Memories-in-a-paraell-universe-v-6-1-a2d09bd0-87b7-45c6-8ee4-7d46ce2d1376-2.png",
    tags: ["Metaverse", "Memory NFTs", "VR Experience"],
    learnMoreUrl: "/case-studies/reflect"
  },
  {
    title: "AI-Powered Sports Coaching",
    description: "Leveraging Concierge AI to revolutionize sports coaching by providing real-time insights, player development tracking, and game scenario analysis.",
    image: "https://i.ibb.co/dwbKsVms/u9921589829-Ai-sports-coaching-v-6-1-85614038-6928-4242-8e1c-763ddd03debd-3.png",
    tags: ["Concierge AI", "Sports Tech", "Player Development"],
    learnMoreUrl: "/case-studies/sports-coaching"
  },
  {
    title: "AI Avatar Twitch Co-Host",
    description: "Created an AI clone of founder Milan Cheeks to co-host Twitch streams and play Call of Duty Black Ops 6 Zombies, demonstrating advanced AI personality replication and real-time gaming interaction.",
    image: "https://images.unsplash.com/photo-1542751371-adc38448a05e?auto=format&fit=crop&q=80&w=800",
    tags: ["AI Avatar", "Gaming", "Live Streaming"],
    learnMoreUrl: "/case-studies/twitch-ai-avatar"
  },
  {
    title: "Consciousness Exploration",
    description: "A year-long journey through Monroe Institute's Focus Levels, mapping consciousness states and integrating insights into our neural interface development, advancing our understanding of human consciousness.",
    image: "https://images.unsplash.com/photo-1519681393784-d120267933ba?auto=format&fit=crop&q=80&w=800",
    tags: ["Consciousness", "Monroe Institute", "Neural Research"],
    learnMoreUrl: "/case-studies/consciousness-exploration"
  }
];

export default function CaseStudiesSection() {
  return (
    <div id="case-studies" className="py-24 bg-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-16">
          <h2 className="text-4xl font-bold text-white mb-4">Case Studies</h2>
          <p className="text-gray-400 max-w-2xl mx-auto">
            Real-world applications of our technology making a difference across industries
          </p>
        </div>
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {caseStudies.map((study, index) => (
            <CaseStudyCard key={index} {...study} />
          ))}
        </div>
      </div>
    </div>
  );
}