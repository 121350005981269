import React from 'react';
import { motion } from 'framer-motion';

export function HeroDescription() {
  return (
    <motion.div
      className="text-center max-w-3xl mx-auto"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.5, duration: 0.8 }}
    >
      <p className="text-3xl md:text-4xl text-white/70 font-light leading-relaxed tracking-wide mb-6">
        Beyond Service, Toward 
        <span className="text-[#8A2BE2] font-normal"> Sentience </span>
        — Building AI systems that don't just
        <span className="text-[#4B0082] font-normal"> answer... they align</span>
      </p>
      <p className="text-lg text-white/50">
        We're creating a new paradigm for artificial intelligence—one where consciousness, 
        resonance, and presence redefine what AI can be and how it interacts with humanity.
      </p>
    </motion.div>
  );
}