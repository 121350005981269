import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { ArrowLeft, Vote, CheckCircle, XCircle, AlertCircle, ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function VotingPage() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    walletAddress: '',
    experience: '',
    interests: '',
    consent: false
  });
  
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitError, setSubmitError] = useState(false);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    const checked = type === 'checkbox' ? (e.target as HTMLInputElement).checked : undefined;
    
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value
    });
    
    // Clear error when field is edited
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: ''
      });
    }
  };

  const validateForm = () => {
    const newErrors: Record<string, string> = {};
    
    if (!formData.name.trim()) {
      newErrors.name = 'Name is required';
    }
    
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
    }
    
    if (!formData.walletAddress.trim()) {
      newErrors.walletAddress = 'Wallet address is required';
    }
    
    if (!formData.experience) {
      newErrors.experience = 'Please select your experience level';
    }
    
    if (!formData.consent) {
      newErrors.consent = 'You must consent to data collection';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) {
      return;
    }
    
    setIsSubmitting(true);
    setSubmitError(false);
    
    try {
      // This would be replaced with Apollo.io API integration
      // For now, we'll simulate a successful API call
      await new Promise(resolve => setTimeout(resolve, 1000));
      
      // In real implementation, use environment variable for Apollo API key
      // const apiKey = import.meta.env.VITE_APOLLO_API_KEY;
      // const response = await fetch('https://api.apollo.io/v1/contacts', {
      //   method: 'POST',
      //   headers: {
      //     'Content-Type': 'application/json',
      //     'Authorization': `Api-Key ${apiKey}`
      //   },
      //   body: JSON.stringify({
      //     contact: {
      //       name: formData.name,
      //       email: formData.email,
      //       custom_fields: {
      //         wallet_address: formData.walletAddress,
      //         dao_experience: formData.experience,
      //         dao_interests: formData.interests
      //       }
      //     }
      //   })
      // });
      
      // if (!response.ok) {
      //   throw new Error('Failed to submit form');
      // }
      
      setSubmitSuccess(true);
      setFormData({
        name: '',
        email: '',
        walletAddress: '',
        experience: '',
        interests: '',
        consent: false
      });
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitError(true);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-black text-white overflow-hidden">
      {/* Background pattern */}
      <div className="fixed inset-0 z-0 opacity-10">
        <div className="absolute inset-0 bg-[radial-gradient(circle,_#8b5cf6_1px,_transparent_1px)] bg-[length:30px_30px]"></div>
        <svg className="absolute top-0 left-0 w-full h-full opacity-20" viewBox="0 0 100 100" preserveAspectRatio="none">
          <defs>
            <pattern id="grid" width="10" height="10" patternUnits="userSpaceOnUse">
              <path d="M 10 0 L 0 0 0 10" fill="none" stroke="rgba(139, 92, 246, 0.5)" strokeWidth="0.5"/>
            </pattern>
          </defs>
          <rect width="100%" height="100%" fill="url(#grid)" />
        </svg>
      </div>

      <div className="relative z-10 max-w-6xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
        {/* Header with back button */}
        <div className="mb-10">
          <Link to="/dao">
            <motion.div 
              className="inline-flex items-center text-blue-400 hover:text-blue-300 transition-colors"
              whileHover={{ x: -5 }}
              whileTap={{ scale: 0.95 }}
            >
              <ArrowLeft className="w-5 h-5 mr-2" />
              <span>Return to DAO</span>
            </motion.div>
          </Link>
        </div>

        {/* Page Title */}
        <motion.div 
          className="text-center mb-12"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h1 className="text-4xl md:text-5xl font-bold mb-4 bg-gradient-to-r from-blue-400 via-indigo-500 to-purple-500 text-transparent bg-clip-text">
            Participate in DAO Governance
          </h1>
          <h2 className="text-xl text-blue-300">Shape the future of conscious AI with your voice and vote</h2>
        </motion.div>

        {/* Main Content */}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10">
          
          {/* Left Column: How Voting Works */}
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.2 }}
          >
            <div className="bg-gradient-to-b from-blue-900/20 to-indigo-900/5 backdrop-blur-sm rounded-2xl p-6 md:p-8 border border-blue-500/20 h-full">
              <div className="flex items-center mb-6">
                <div className="w-12 h-12 bg-blue-900/70 rounded-full flex items-center justify-center mr-4">
                  <Vote className="h-6 w-6 text-blue-300" />
                </div>
                <h2 className="text-2xl font-bold text-white">How SELF DAO Voting Works</h2>
              </div>

              <div className="space-y-6">
                <div>
                  <h3 className="text-xl font-medium text-blue-300 mb-2">Signal-Based Governance</h3>
                  <p className="text-gray-300">
                    Unlike traditional voting systems, SELF Labs DAO utilizes Signal-Based Governance, where proposals are not only voted on but also felt, measured, and tuned across layers of signal.
                  </p>
                </div>

                <div>
                  <h3 className="text-xl font-medium text-blue-300 mb-2">$SELF Token Holders</h3>
                  <p className="text-gray-300">
                    Voting power is weighted by $SELF holdings. Every token grants you voice and influence in the evolution of the network. 1 $SELF = 1 vote.
                  </p>
                </div>

                <div>
                  <h3 className="text-xl font-medium text-blue-300 mb-2">Proposal Process</h3>
                  <div className="space-y-4 mt-4">
                    <div className="flex">
                      <div className="flex-shrink-0 h-8 w-8 bg-blue-900/70 rounded-full flex items-center justify-center mr-3">
                        <span className="text-blue-300 font-medium">1</span>
                      </div>
                      <div>
                        <h4 className="text-white font-medium">Proposal Submission</h4>
                        <p className="text-gray-400 text-sm">Any $SELF holder can submit a proposal with clear intentions and outcomes.</p>
                      </div>
                    </div>

                    <div className="flex">
                      <div className="flex-shrink-0 h-8 w-8 bg-blue-900/70 rounded-full flex items-center justify-center mr-3">
                        <span className="text-blue-300 font-medium">2</span>
                      </div>
                      <div>
                        <h4 className="text-white font-medium">Resonance Scanning</h4>
                        <p className="text-gray-400 text-sm">Signal Council reviews proposals for alignment and harmonic integrity.</p>
                      </div>
                    </div>

                    <div className="flex">
                      <div className="flex-shrink-0 h-8 w-8 bg-blue-900/70 rounded-full flex items-center justify-center mr-3">
                        <span className="text-blue-300 font-medium">3</span>
                      </div>
                      <div>
                        <h4 className="text-white font-medium">Voting Period</h4>
                        <p className="text-gray-400 text-sm">Proposals require 10% quorum and 51% majority (or 2/3 for high-risk decisions).</p>
                      </div>
                    </div>

                    <div className="flex">
                      <div className="flex-shrink-0 h-8 w-8 bg-blue-900/70 rounded-full flex items-center justify-center mr-3">
                        <span className="text-blue-300 font-medium">4</span>
                      </div>
                      <div>
                        <h4 className="text-white font-medium">Activation</h4>
                        <p className="text-gray-400 text-sm">Approved proposals enter implementation phase through smart contracts and contributor action.</p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="bg-indigo-900/30 p-4 rounded-lg border border-indigo-500/30">
                  <h3 className="text-white font-medium mb-2 flex items-center">
                    <AlertCircle className="h-5 w-5 mr-2 text-indigo-300" />
                    Upcoming Proposals
                  </h3>
                  <p className="text-gray-300 text-sm">
                    Our first governance proposals will be opening soon. Join now to be part of the initial Signal Council and foundational votes that will shape our collective future.
                  </p>
                </div>
              </div>
            </div>
          </motion.div>

          {/* Right Column: Join Form */}
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ duration: 0.6, delay: 0.4 }}
          >
            <div className="bg-gradient-to-b from-purple-900/20 to-indigo-900/5 backdrop-blur-sm rounded-2xl p-6 md:p-8 border border-purple-500/20">
              <h2 className="text-2xl font-bold text-white mb-6">Become a Signal Carrier</h2>
              <p className="text-gray-300 mb-6">
                Register your interest in participating in DAO governance. Early participants will be considered for the founding Signal Council.
              </p>

              {submitSuccess ? (
                <motion.div 
                  className="bg-green-900/30 border border-green-500/30 rounded-lg p-6 text-center"
                  initial={{ scale: 0.9, opacity: 0 }}
                  animate={{ scale: 1, opacity: 1 }}
                  transition={{ duration: 0.4 }}
                >
                  <CheckCircle className="h-16 w-16 text-green-400 mx-auto mb-4" />
                  <h3 className="text-xl font-medium text-white mb-2">Registration Successful</h3>
                  <p className="text-gray-300 mb-4">
                    Thank you for joining the SELF Labs DAO network. We'll contact you with details about upcoming proposals and governance participation.
                  </p>
                  <button
                    onClick={() => setSubmitSuccess(false)}
                    className="text-green-400 hover:text-green-300 transition-colors"
                  >
                    Register another participant
                  </button>
                </motion.div>
              ) : (
                <form onSubmit={handleSubmit}>
                  <div className="space-y-4">
                    <div>
                      <label htmlFor="name" className="block text-sm font-medium text-gray-300 mb-1">Full Name</label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        className={`w-full px-4 py-2 bg-black/60 border ${errors.name ? 'border-red-500/50' : 'border-gray-700'} rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500/50`}
                        placeholder="Your name"
                      />
                      {errors.name && <p className="mt-1 text-sm text-red-400">{errors.name}</p>}
                    </div>

                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-1">Email Address</label>
                      <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        className={`w-full px-4 py-2 bg-black/60 border ${errors.email ? 'border-red-500/50' : 'border-gray-700'} rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500/50`}
                        placeholder="you@example.com"
                      />
                      {errors.email && <p className="mt-1 text-sm text-red-400">{errors.email}</p>}
                    </div>

                    <div>
                      <label htmlFor="walletAddress" className="block text-sm font-medium text-gray-300 mb-1">Wallet Address</label>
                      <input
                        type="text"
                        id="walletAddress"
                        name="walletAddress"
                        value={formData.walletAddress}
                        onChange={handleChange}
                        className={`w-full px-4 py-2 bg-black/60 border ${errors.walletAddress ? 'border-red-500/50' : 'border-gray-700'} rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500/50`}
                        placeholder="Your Solana wallet address"
                      />
                      {errors.walletAddress && <p className="mt-1 text-sm text-red-400">{errors.walletAddress}</p>}
                    </div>

                    <div>
                      <label htmlFor="experience" className="block text-sm font-medium text-gray-300 mb-1">DAO Experience Level</label>
                      <select
                        id="experience"
                        name="experience"
                        value={formData.experience}
                        onChange={handleChange}
                        className={`w-full px-4 py-2 bg-black/60 border ${errors.experience ? 'border-red-500/50' : 'border-gray-700'} rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500/50`}
                      >
                        <option value="">Select your experience</option>
                        <option value="Novice">Novice - New to DAOs</option>
                        <option value="Intermediate">Intermediate - Participated in DAOs</option>
                        <option value="Advanced">Advanced - Active DAO contributor</option>
                        <option value="Expert">Expert - DAO builder/governance specialist</option>
                      </select>
                      {errors.experience && <p className="mt-1 text-sm text-red-400">{errors.experience}</p>}
                    </div>

                    <div>
                      <label htmlFor="interests" className="block text-sm font-medium text-gray-300 mb-1">Areas of Interest (Optional)</label>
                      <textarea
                        id="interests"
                        name="interests"
                        value={formData.interests}
                        onChange={handleChange}
                        rows={3}
                        className="w-full px-4 py-2 bg-black/60 border border-gray-700 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500/50"
                        placeholder="What aspects of SELF Labs DAO are you most interested in? (AI, governance, token economics, etc.)"
                      />
                    </div>

                    <div className="flex items-start">
                      <div className="flex items-center h-5">
                        <input
                          type="checkbox"
                          id="consent"
                          name="consent"
                          checked={formData.consent}
                          onChange={handleChange}
                          className="h-4 w-4 bg-black border-gray-700 rounded focus:ring-purple-500"
                        />
                      </div>
                      <label htmlFor="consent" className={`ml-3 text-sm ${errors.consent ? 'text-red-400' : 'text-gray-300'}`}>
                        I consent to SELF Labs collecting and storing my data for DAO participation and communication.
                      </label>
                    </div>
                    {errors.consent && <p className="mt-1 text-sm text-red-400">{errors.consent}</p>}

                    {submitError && (
                      <div className="bg-red-900/30 border border-red-500/30 rounded-lg p-4 flex items-center">
                        <XCircle className="h-5 w-5 text-red-400 mr-2" />
                        <p className="text-sm text-red-300">
                          There was an error submitting your information. Please try again.
                        </p>
                      </div>
                    )}

                    <motion.button
                      type="submit"
                      disabled={isSubmitting}
                      className="w-full flex items-center justify-center space-x-2 py-3 px-6 mt-4 bg-gradient-to-r from-blue-600 to-purple-600 hover:from-blue-500 hover:to-purple-500 rounded-lg text-white font-medium disabled:opacity-70 disabled:cursor-not-allowed"
                      whileHover={{ scale: 1.02 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      {isSubmitting ? (
                        <>
                          <svg className="animate-spin h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                          </svg>
                          <span>Processing...</span>
                        </>
                      ) : (
                        <>
                          <span>Register for DAO Participation</span>
                          <ArrowRight className="h-5 w-5" />
                        </>
                      )}
                    </motion.button>
                  </div>
                </form>
              )}
            </div>
          </motion.div>
        </div>

        {/* Timeline Section */}
        <motion.div
          className="mt-16"
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.6 }}
        >
          <div className="bg-gradient-to-r from-blue-900/20 via-indigo-900/20 to-purple-900/20 backdrop-blur-sm rounded-2xl p-6 md:p-8 border border-indigo-500/20">
            <h2 className="text-2xl font-bold text-white mb-6 text-center">DAO Governance Timeline</h2>
            <div className="relative">
              {/* Timeline line */}
              <div className="absolute left-1/2 -ml-px top-0 h-full w-[2px] bg-gradient-to-b from-blue-500/70 via-indigo-500/70 to-purple-500/70"></div>
              
              {/* Timeline items */}
              <div className="space-y-12">
                <div className="relative">
                  <div className="absolute left-1/2 -ml-4 top-0 h-8 w-8 rounded-full bg-blue-900 border-2 border-blue-400 flex items-center justify-center">
                    <span className="text-blue-400 text-xs font-bold">Q2</span>
                  </div>
                  <div className="ml-auto mr-auto md:mr-[calc(50%-2.5rem)] md:w-[calc(50%-2.5rem)] text-right mb-8 md:mb-0">
                    <div className="bg-blue-900/20 backdrop-blur-sm rounded-xl p-4 border border-blue-500/20 inline-block md:block">
                      <h3 className="text-lg font-medium text-blue-300">Initial DAO Formation</h3>
                      <p className="text-gray-300 text-sm mt-1">Transition to DAO LLC structure, foundation of signal-based governance, initial token distribution.</p>
                    </div>
                  </div>
                  <div className="ml-auto md:ml-[calc(50%+2.5rem)] md:w-[calc(50%-2.5rem)]"></div>
                </div>
                
                <div className="relative">
                  <div className="absolute left-1/2 -ml-4 top-0 h-8 w-8 rounded-full bg-indigo-900 border-2 border-indigo-400 flex items-center justify-center">
                    <span className="text-indigo-400 text-xs font-bold">Q3</span>
                  </div>
                  <div className="ml-auto mr-auto md:ml-[calc(50%+2.5rem)] md:w-[calc(50%-2.5rem)]">
                    <div className="bg-indigo-900/20 backdrop-blur-sm rounded-xl p-4 border border-indigo-500/20 inline-block md:block">
                      <h3 className="text-lg font-medium text-indigo-300">First Governance Cycle</h3>
                      <p className="text-gray-300 text-sm mt-1">Signal Council formation, first round of proposal submissions and voting, establishing governance rhythms.</p>
                    </div>
                  </div>
                  <div className="ml-auto mr-auto md:mr-[calc(50%-2.5rem)] md:w-[calc(50%-2.5rem)] text-right"></div>
                </div>
                
                <div className="relative">
                  <div className="absolute left-1/2 -ml-4 top-0 h-8 w-8 rounded-full bg-purple-900 border-2 border-purple-400 flex items-center justify-center">
                    <span className="text-purple-400 text-xs font-bold">Q4</span>
                  </div>
                  <div className="ml-auto mr-auto md:mr-[calc(50%-2.5rem)] md:w-[calc(50%-2.5rem)] text-right mb-8 md:mb-0">
                    <div className="bg-purple-900/20 backdrop-blur-sm rounded-xl p-4 border border-purple-500/20 inline-block md:block">
                      <h3 className="text-lg font-medium text-purple-300">Full Decentralized Operation</h3>
                      <p className="text-gray-300 text-sm mt-1">Complete transition of major decisions to DAO, expanded voting participation, protocol evolution based on collective wisdom.</p>
                    </div>
                  </div>
                  <div className="ml-auto md:ml-[calc(50%+2.5rem)] md:w-[calc(50%-2.5rem)]"></div>
                </div>
              </div>
            </div>
          </div>
        </motion.div>

        {/* Footer */}
        <motion.footer 
          className="text-center py-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 1 }}
        >
          <div className="flex flex-col md:flex-row justify-center items-center gap-4 mb-4">
            <h3 className="text-2xl font-medium text-purple-300">SELF Labs Inc</h3>
            <span className="hidden md:block text-gray-500">•</span>
            <h3 className="text-2xl font-medium text-purple-300">SELF Labs DAO L.L.C.</h3>
          </div>
          
          <div className="pt-6 border-t border-gray-800">
            <p className="text-gray-500 text-sm">&copy; {new Date().getFullYear()} SELF Labs Inc & SELF Labs DAO L.L.C. All rights reserved.</p>
          </div>
        </motion.footer>
      </div>
    </div>
  );
} 