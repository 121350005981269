import React from 'react';
import { motion } from 'framer-motion';
import { Mic, Shield, Settings, Users } from 'lucide-react';

export function VoiceAgentSection() {
  // Function to navigate to the concierge.rolemodel.ai website
  const visitConciergeAI = () => {
    window.open('https://concierge.rolemodel.ai', '_blank');
  };

  return (
    <div className="py-24 bg-gradient-to-b from-black to-purple-950/20">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <span className="inline-block px-4 py-1 rounded-full text-sm font-semibold bg-purple-900/50 text-purple-300 mb-4">Available Now</span>
          <h2 className="text-4xl font-bold mb-6 bg-gradient-to-r from-purple-400 to-pink-600 text-transparent bg-clip-text">
            Create Your Own AI Voice Agent
          </h2>
          <p className="text-xl text-gray-300 max-w-3xl mx-auto">
            Customize and deploy your personal AI assistant with your own voice, avatar, and specialized knowledge
          </p>
        </motion.div>

        <div className="grid md:grid-cols-2 gap-16 items-center">
          <div>
            <motion.div
              initial={{ opacity: 0, x: -20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
            >
              <h3 className="text-3xl font-bold text-white mb-8">Your Voice, Your Assistant</h3>
              
              <div className="space-y-8">
                <div className="flex gap-4">
                  <div className="flex-shrink-0 p-3 bg-purple-900/30 rounded-lg">
                    <Mic className="w-6 h-6 text-purple-300" />
                  </div>
                  <div>
                    <h4 className="text-xl font-semibold text-white mb-2">Voice Cloning</h4>
                    <p className="text-gray-400">
                      Record your voice and let our advanced AI create a perfect digital replica that sounds just like you
                    </p>
                  </div>
                </div>
                
                <div className="flex gap-4">
                  <div className="flex-shrink-0 p-3 bg-purple-900/30 rounded-lg">
                    <Settings className="w-6 h-6 text-purple-300" />
                  </div>
                  <div>
                    <h4 className="text-xl font-semibold text-white mb-2">Personalized Agents</h4>
                    <p className="text-gray-400">
                      Choose from template agents or create completely custom assistants for specific tasks and domains
                    </p>
                  </div>
                </div>
                
                <div className="flex gap-4">
                  <div className="flex-shrink-0 p-3 bg-purple-900/30 rounded-lg">
                    <Users className="w-6 h-6 text-purple-300" />
                  </div>
                  <div>
                    <h4 className="text-xl font-semibold text-white mb-2">Interactive Experience</h4>
                    <p className="text-gray-400">
                      Engage with your assistant through voice, video calls, and screen sharing for seamless collaboration
                    </p>
                  </div>
                </div>
              </div>
            </motion.div>
          </div>
          
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            whileInView={{ opacity: 1, scale: 1 }}
            viewport={{ once: true }}
            className="relative"
          >
            {/* Placeholder for a Voice Agent UI image - in a real scenario, you'd use an actual image */}
            <div className="aspect-video rounded-xl overflow-hidden bg-gradient-to-br from-purple-800/20 to-pink-800/20 border border-purple-500/30 shadow-lg shadow-purple-500/10">
              <div className="h-full flex flex-col items-center justify-center text-center p-8">
                <div className="w-24 h-24 rounded-full bg-gradient-to-br from-purple-500 to-pink-600 mb-6 flex items-center justify-center">
                  <Mic className="w-12 h-12 text-white" />
                </div>
                <h3 className="text-white text-2xl font-bold mb-3">Your Personal Voice Agent</h3>
                <p className="text-gray-300">Record your voice and create your own customized AI assistant</p>
                <button 
                  onClick={visitConciergeAI}
                  className="mt-6 bg-white text-purple-900 px-6 py-3 rounded-lg font-semibold hover:bg-purple-100 transition-colors"
                >
                  Try Voice Cloning
                </button>
              </div>
            </div>
            
            {/* Decorative elements */}
            <div className="absolute -top-10 -right-10 w-40 h-40 bg-purple-600/10 rounded-full blur-3xl z-0"></div>
            <div className="absolute -bottom-10 -left-10 w-40 h-40 bg-pink-600/10 rounded-full blur-3xl z-0"></div>
          </motion.div>
        </div>
        
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="mt-20 bg-purple-900/10 backdrop-blur-sm rounded-xl border border-purple-500/20 p-8"
        >
          <h3 className="text-2xl font-bold text-white mb-6">Pro Subscription Features</h3>
          <p className="text-gray-300 mb-8">
            Sign up for a Pro subscription to create your own AI agent with your voice and enjoy all these premium features:
          </p>
          
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
            {[
              "Custom voice training - Create your AI with your voice",
              "Choice of advanced AI models (GPT-4, Claude 3 Opus, and more)",
              "Multiple language support (8 languages available)",
              "Unlimited conversations and extended history",
              "Edit agent personalities & behavior",
              "Custom agent colors and visuals",
              "Priority customer support",
              "Early access to beta features"
            ].map((feature, index) => (
              <div key={index} className="flex items-start">
                <div className="text-purple-400 mr-2">✓</div>
                <p className="text-gray-300">{feature}</p>
              </div>
            ))}
          </div>
          
          <div className="mt-8 flex justify-center">
            <motion.button
              onClick={visitConciergeAI}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="bg-gradient-to-r from-purple-600 to-pink-600 text-white px-8 py-3 rounded-lg font-semibold hover:from-purple-700 hover:to-pink-700 transition-colors"
            >
              Create AI Voice Agent
            </motion.button>
          </div>
        </motion.div>
        
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="mt-12 p-6 rounded-xl border border-yellow-500/20 bg-yellow-900/10"
        >
          <div className="flex items-center gap-3 mb-2">
            <Shield className="w-5 h-5 text-yellow-400" />
            <h4 className="text-lg font-semibold text-yellow-300">Exclusive Beta Features Access</h4>
          </div>
          <p className="text-gray-300">
            Join our testing program to get early access to experimental features before they're widely available,
            including our innovative AI Wallet feature that gives your AI agent secure spending capabilities to make
            purchases on your behalf.
          </p>
        </motion.div>
      </div>
    </div>
  );
} 