import { motion } from 'framer-motion';
import { ArrowLeft, Shield, Lock, Database, Settings, Mail, Bell } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function PrivacyPolicyPage() {
  return (
    <div className="min-h-screen bg-black text-white">
      {/* Background Pattern */}
      <div className="fixed inset-0">
        <motion.div
          className="absolute inset-0"
          animate={{
            backgroundPosition: ['0% 0%', '100% 100%'],
          }}
          transition={{
            duration: 20,
            repeat: Infinity,
            ease: "linear"
          }}
          style={{
            backgroundImage: 'radial-gradient(circle at center, rgba(99, 102, 241, 0.1) 0%, transparent 20%)',
            backgroundSize: '40px 40px',
          }}
        />
      </div>

      <div className="relative max-w-4xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
        {/* Navigation */}
        <div className="mb-12">
          <Link to="/self-phone">
            <motion.button
              className="flex items-center text-gray-400 hover:text-white transition-colors"
              whileHover={{ x: -5 }}
            >
              <ArrowLeft className="w-5 h-5 mr-2" />
              Back to SELF Phone
            </motion.button>
          </Link>
        </div>

        {/* Header */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <h1 className="text-4xl md:text-5xl font-bold mb-4 bg-gradient-to-r from-indigo-400 via-purple-400 to-indigo-400 text-transparent bg-clip-text">
            SELF Phone & Flash Kit Privacy Policy
          </h1>
          <p className="text-gray-400">Effective April 2025 | SELF Labs Inc.</p>
        </motion.div>

        {/* Content */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
          className="prose prose-invert max-w-none"
        >
          {/* Overview */}
          <section className="mb-16">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <Shield className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-2xl font-bold text-white">Overview</h2>
            </div>
            <p className="text-gray-300 leading-relaxed">
              At SELF Labs, privacy is not an add-on — it's the default. The SELF Phone and SELF Flash Kit are designed from the ground up to respect, secure, and empower user privacy. This policy outlines what data we collect (if any), how it's handled, and how you remain in full control.
            </p>
          </section>

          {/* What We Collect */}
          <section className="mb-16">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <Database className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-2xl font-bold text-white">What We Collect</h2>
            </div>
            
            <div className="space-y-8">
              <div className="bg-indigo-950/40 rounded-xl p-6 border border-indigo-500/20">
                <h3 className="text-xl font-bold text-indigo-400 mb-4">Optional Data You May Share</h3>
                <ul className="space-y-3 text-gray-300">
                  <li className="flex items-start gap-2">
                    <div className="mt-1.5">•</div>
                    <span>Device type and model (for compatibility)</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <div className="mt-1.5">•</div>
                    <span>Encrypted metadata from AI agent tasks</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <div className="mt-1.5">•</div>
                    <span>Selected data categories you authorize for monetization (e.g. app usage, browsing intent)</span>
                  </li>
                </ul>
              </div>

              <div className="bg-purple-950/40 rounded-xl p-6 border border-purple-500/20">
                <h3 className="text-xl font-bold text-purple-400 mb-4">Data We Do NOT Collect</h3>
                <ul className="space-y-3 text-gray-300">
                  <li className="flex items-start gap-2">
                    <div className="mt-1.5">•</div>
                    <span>Your contacts, photos, messages, or personal files</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <div className="mt-1.5">•</div>
                    <span>Microphone or camera access (unless explicitly granted for voice agent setup)</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <div className="mt-1.5">•</div>
                    <span>Location data (unless you choose to monetize it)</span>
                  </li>
                </ul>
              </div>
            </div>
          </section>

          {/* How We Use Your Data */}
          <section className="mb-16">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <Lock className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-2xl font-bold text-white">How We Use Your Data</h2>
            </div>
            <div className="bg-gradient-to-br from-indigo-950/40 to-purple-950/40 rounded-xl p-8 border border-indigo-500/20">
              <p className="text-gray-300 mb-6">SELF Labs does not sell or share your data. If you choose to monetize your data through the Signal Network:</p>
              <ul className="space-y-4 text-gray-300">
                <li className="flex items-center gap-2">
                  <Lock className="w-5 h-5 text-indigo-400" />
                  <span>Your data is anonymized and tokenized</span>
                </li>
                <li className="flex items-center gap-2">
                  <Lock className="w-5 h-5 text-indigo-400" />
                  <span>You can revoke sharing at any time</span>
                </li>
                <li className="flex items-center gap-2">
                  <Lock className="w-5 h-5 text-indigo-400" />
                  <span>All monetization occurs transparently via the Aura Dashboard</span>
                </li>
              </ul>
            </div>
          </section>

          {/* Data Storage & Security */}
          <section className="mb-16">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <Database className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-2xl font-bold text-white">Data Storage & Security</h2>
            </div>
            <div className="grid sm:grid-cols-2 gap-6">
              <div className="bg-black/30 rounded-xl p-6 border border-indigo-500/20">
                <h3 className="font-semibold text-indigo-400 mb-2">On-Device Encryption</h3>
                <p className="text-gray-300">Data is encrypted on-device with 256-bit AES</p>
              </div>
              <div className="bg-black/30 rounded-xl p-6 border border-indigo-500/20">
                <h3 className="font-semibold text-indigo-400 mb-2">Private Routing</h3>
                <p className="text-gray-300">Private signal routing prevents external tracking</p>
              </div>
              <div className="bg-black/30 rounded-xl p-6 border border-indigo-500/20">
                <h3 className="font-semibold text-indigo-400 mb-2">No Central Storage</h3>
                <p className="text-gray-300">No central server stores raw personal data</p>
              </div>
              <div className="bg-black/30 rounded-xl p-6 border border-indigo-500/20">
                <h3 className="font-semibold text-indigo-400 mb-2">Signal Vault</h3>
                <p className="text-gray-300">Signal Vault enables selective long-term data backups (user-controlled)</p>
              </div>
            </div>
          </section>

          {/* Your Control */}
          <section className="mb-16">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <Settings className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-2xl font-bold text-white">Your Control</h2>
            </div>
            <div className="bg-gradient-to-br from-purple-950/40 to-indigo-950/40 rounded-xl p-8 border border-purple-500/20">
              <p className="text-gray-300 mb-6">Every user has full control over:</p>
              <ul className="space-y-4 text-gray-300">
                <li className="flex items-start gap-3">
                  <div className="mt-1">•</div>
                  <span>What data is collected</span>
                </li>
                <li className="flex items-start gap-3">
                  <div className="mt-1">•</div>
                  <span>What is locked, shared, or monetized</span>
                </li>
                <li className="flex items-start gap-3">
                  <div className="mt-1">•</div>
                  <span>AI agent permissions</span>
                </li>
                <li className="flex items-start gap-3">
                  <div className="mt-1">•</div>
                  <span>Data deletion and export (all available via Signal Dashboard)</span>
                </li>
              </ul>
            </div>
          </section>

          {/* Contact Us */}
          <section className="mb-16">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <Mail className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-2xl font-bold text-white">Contact Us</h2>
            </div>
            <div className="bg-gradient-to-br from-indigo-950/40 to-purple-950/40 rounded-xl p-8 border border-indigo-500/20">
              <p className="text-gray-300 mb-6">If you have questions about this policy or your data, please reach out:</p>
              <div className="space-y-4">
                <p className="text-gray-300">
                  <span className="text-indigo-400 font-semibold">Email:</span>{' '}
                  <a href="mailto:privacy@selflabs.ai" className="hover:text-indigo-400 transition-colors">
                    privacy@selflabs.ai
                  </a>
                </p>
                <p className="text-gray-300">
                  <span className="text-indigo-400 font-semibold">Mail:</span>{' '}
                  SELF Labs DAO LLC<br />
                  30 N Gould Ste 100<br />
                  Sheridan, WY 82801
                </p>
              </div>
            </div>
          </section>

          {/* Policy Updates */}
          <section className="mb-16">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <Bell className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-2xl font-bold text-white">Policy Updates</h2>
            </div>
            <div className="bg-gradient-to-br from-indigo-950/40 to-purple-950/40 rounded-xl p-8 border border-indigo-500/20">
              <p className="text-gray-300 mb-6">
                We may update this Privacy Policy as new features are released. You will be notified via the Signal Dashboard or email with clear change logs.
              </p>
              <p className="text-xl font-semibold text-indigo-400 mt-6">
                Your signal is yours. Always.
              </p>
              <p className="text-gray-400 mt-4">
                — SELF Labs
              </p>
            </div>
          </section>
        </motion.div>
      </div>
    </div>
  );
} 