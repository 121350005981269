import React from 'react';
import { motion } from 'framer-motion';
import { useRandomQuote } from '../../hooks/useRandomQuote';

export function LoadingQuote() {
  const quote = useRandomQuote();

  return (
    <motion.p
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="text-gray-400 max-w-md text-center text-lg"
    >
      {quote}
    </motion.p>
  );
}