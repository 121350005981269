import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { ArrowLeft, ArrowRight, DollarSign, Wallet, BarChart3, Lock, ExternalLink } from 'lucide-react';

export default function HowSignalEconomyWorksPage() {
  const [activeStep, setActiveStep] = useState(1);
  const [hoveredCard, setHoveredCard] = useState<string | null>(null);

  const steps = [
    { 
      icon: <DollarSign className="w-8 h-8" />, 
      text: "AI companies pay real money for access to human signal",
      details: (
        <div className="mt-6 bg-black/30 p-6 rounded-xl border border-indigo-500/30">
          <h4 className="text-xl font-semibold text-indigo-400 mb-4">Why AI Companies Need Human Signal</h4>
          <ul className="space-y-4">
            <li className="flex items-start">
              <span className="text-indigo-400 mr-2 mt-1">•</span>
              <div>
                <p className="font-medium text-white">Improved AI Training</p>
                <p className="text-gray-400">Quality human data creates more accurate and responsible AI models</p>
              </div>
            </li>
            <li className="flex items-start">
              <span className="text-indigo-400 mr-2 mt-1">•</span>
              <div>
                <p className="font-medium text-white">Legal Compliance</p>
                <p className="text-gray-400">Opt-in human signal avoids copyright and privacy lawsuits</p>
              </div>
            </li>
            <li className="flex items-start">
              <span className="text-indigo-400 mr-2 mt-1">•</span>
              <div>
                <p className="font-medium text-white">Diverse Perspectives</p>
                <p className="text-gray-400">Global signal sources reduce bias and improve model generalization</p>
              </div>
            </li>
          </ul>
        </div>
      )
    },
    { 
      icon: <Lock className="w-8 h-8" />, 
      text: "Payments are secured in a blockchain vault",
      details: (
        <div className="mt-6 bg-black/30 p-6 rounded-xl border border-indigo-500/30">
          <h4 className="text-xl font-semibold text-indigo-400 mb-4">Blockchain Security For Value</h4>
          <ul className="space-y-4">
            <li className="flex items-start">
              <span className="text-indigo-400 mr-2 mt-1">•</span>
              <div>
                <p className="font-medium text-white">Transparent Accounting</p>
                <p className="text-gray-400">All payments are recorded on an immutable public ledger</p>
              </div>
            </li>
            <li className="flex items-start">
              <span className="text-indigo-400 mr-2 mt-1">•</span>
              <div>
                <p className="font-medium text-white">Smart Contract Enforcement</p>
                <p className="text-gray-400">Automatic distribution of rewards based on signal contribution</p>
              </div>
            </li>
            <li className="flex items-start">
              <span className="text-indigo-400 mr-2 mt-1">•</span>
              <div>
                <p className="font-medium text-white">Value Preservation</p>
                <p className="text-gray-400">Funds remain 100% backed and segregated from operational expenses</p>
              </div>
            </li>
          </ul>
        </div>
      )
    },
    { 
      icon: <Wallet className="w-8 h-8" />, 
      text: "Essence tokens are minted as a stable, signal-backed currency",
      details: (
        <div className="mt-6 bg-black/30 p-6 rounded-xl border border-indigo-500/30">
          <h4 className="text-xl font-semibold text-indigo-400 mb-4">Essence: A New Kind of Value</h4>
          <ul className="space-y-4">
            <li className="flex items-start">
              <span className="text-indigo-400 mr-2 mt-1">•</span>
              <div>
                <p className="font-medium text-white">1:1 Value Backing</p>
                <p className="text-gray-400">Each Essence token is fully backed by real financial value</p>
              </div>
            </li>
            <li className="flex items-start">
              <span className="text-indigo-400 mr-2 mt-1">•</span>
              <div>
                <p className="font-medium text-white">Price Stability</p>
                <p className="text-gray-400">Not subject to market speculation or volatility like traditional cryptocurrencies</p>
              </div>
            </li>
            <li className="flex items-start">
              <span className="text-indigo-400 mr-2 mt-1">•</span>
              <div>
                <p className="font-medium text-white">Ecosystem Benefits</p>
                <p className="text-gray-400">Increased utility and bonuses when used within the Signal Economy</p>
              </div>
            </li>
          </ul>
        </div>
      )
    },
    { 
      icon: <BarChart3 className="w-8 h-8" />, 
      text: "Users earn Essence through their daily activities",
      details: (
        <div className="mt-6 bg-black/30 p-6 rounded-xl border border-indigo-500/30">
          <h4 className="text-xl font-semibold text-indigo-400 mb-4">How You Earn Essence</h4>
          <ul className="space-y-4">
            <li className="flex items-start">
              <span className="text-indigo-400 mr-2 mt-1">•</span>
              <div>
                <p className="font-medium text-white">Natural Signal Collection</p>
                <p className="text-gray-400">Walking, moving, focusing, emotional responses, sleep patterns</p>
              </div>
            </li>
            <li className="flex items-start">
              <span className="text-indigo-400 mr-2 mt-1">•</span>
              <div>
                <p className="font-medium text-white">Privacy-First Design</p>
                <p className="text-gray-400">You control what data is collected and shared</p>
              </div>
            </li>
            <li className="flex items-start">
              <span className="text-indigo-400 mr-2 mt-1">•</span>
              <div>
                <p className="font-medium text-white">Daily Rewards</p>
                <p className="text-gray-400">Consistent earnings for consistent signal generation</p>
              </div>
            </li>
          </ul>
        </div>
      )
    }
  ];

  return (
    <div className="min-h-screen bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-indigo-900/20 via-black to-black text-white py-20">
      <div className="max-w-6xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        {/* Decorative Elements */}
        <div className="absolute top-0 left-1/2 -translate-x-1/2 w-3/4 h-1/2 bg-indigo-500/10 blur-[120px] rounded-full pointer-events-none" />
        <div className="absolute bottom-0 left-1/2 -translate-x-1/2 w-3/4 h-1/2 bg-purple-500/10 blur-[120px] rounded-full pointer-events-none" />

        {/* Back Button */}
        <Link to="/flash/signal-calculator">
          <motion.div
            className="inline-flex items-center text-indigo-400 mb-12 hover:text-indigo-300 transition-colors bg-black/20 px-4 py-2 rounded-full backdrop-blur-sm border border-indigo-500/20"
            whileHover={{ x: -5 }}
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            <span>Back to Signal Calculator</span>
          </motion.div>
        </Link>

        {/* Main Content */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="mb-16 relative"
        >
          <h2 className="text-5xl font-bold mb-4 text-center bg-gradient-to-r from-indigo-400 via-purple-400 to-indigo-400 text-transparent bg-clip-text">
            How the Signal Economy Works
          </h2>
          <p className="text-lg text-center text-gray-400 mb-16 max-w-2xl mx-auto">
            Discover how value flows through the Signal Economy, creating a transparent and fair system for all participants
          </p>
          
          <div className="max-w-4xl mx-auto space-y-16">
            {/* Interactive Value Creation Loop */}
            <div className="bg-black/40 rounded-3xl p-10 border border-indigo-500/20 backdrop-blur-xl shadow-2xl shadow-indigo-500/10">
              <h3 className="text-2xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 to-purple-400 mb-8">1. Value Creation Loop</h3>
              <div className="grid gap-6">
                {steps.map((step, index) => (
                  <div key={index}>
                    <motion.div
                      className={`flex items-center p-6 rounded-2xl cursor-pointer transition-all duration-300
                        ${activeStep === index + 1 
                          ? 'bg-gradient-to-r from-indigo-500/20 to-purple-500/20 border-2 border-indigo-500/50' 
                          : 'bg-black/20 border border-indigo-500/20 hover:border-indigo-500/40'}
                      `}
                      onClick={() => setActiveStep(index + 1)}
                      whileHover={{ scale: 1.02, x: 10 }}
                      whileTap={{ scale: 0.98 }}
                    >
                      <div className="mr-6 text-indigo-400 bg-black/30 p-3 rounded-xl">
                        {step.icon}
                      </div>
                      <p className="text-lg">{step.text}</p>
                      {activeStep === index + 1 && (
                        <motion.div
                          className="ml-auto text-indigo-400"
                          initial={{ opacity: 0, scale: 0.5 }}
                          animate={{ opacity: 1, scale: 1 }}
                        >
                          <ArrowRight className="w-6 h-6" />
                        </motion.div>
                      )}
                    </motion.div>
                    <AnimatePresence>
                      {activeStep === index + 1 && (
                        <motion.div
                          initial={{ opacity: 0, height: 0 }}
                          animate={{ opacity: 1, height: "auto" }}
                          exit={{ opacity: 0, height: 0 }}
                          transition={{ duration: 0.3 }}
                          className="overflow-hidden"
                        >
                          {step.details}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                ))}
              </div>
            </div>
            
            {/* Interactive Usage Cards */}
            <div className="bg-black/40 rounded-3xl p-10 border border-indigo-500/20 backdrop-blur-xl shadow-2xl shadow-indigo-500/10">
              <h3 className="text-2xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 to-purple-400 mb-8">2. How to Use Your Essence</h3>
              <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                {[
                  { title: "Spend", description: "Use Essence on real products and services in the Signal Economy", icon: "💳" },
                  { title: "Swap", description: "Convert Essence to fiat or stablecoins at any time", icon: "🔄" },
                  { title: "Hold", description: "Stake Essence for additional rewards and benefits", icon: "💎" }
                ].map((card) => (
                  <motion.div
                    key={card.title}
                    className={`group bg-gradient-to-b from-black/60 to-black/40 p-8 rounded-2xl border transition-all duration-500
                      ${hoveredCard === card.title ? 'border-indigo-400/50 shadow-lg shadow-indigo-500/20' : 'border-indigo-500/20'}
                    `}
                    onMouseEnter={() => setHoveredCard(card.title)}
                    onMouseLeave={() => setHoveredCard(null)}
                    whileHover={{ y: -5 }}
                  >
                    <motion.div 
                      className="text-5xl mb-6"
                      animate={{ 
                        scale: hoveredCard === card.title ? 1.2 : 1,
                        y: hoveredCard === card.title ? -5 : 0
                      }}
                      transition={{ type: "spring", stiffness: 400 }}
                    >
                      {card.icon}
                    </motion.div>
                    <h4 className="text-xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 to-purple-400 mb-3">{card.title}</h4>
                    <p className="text-gray-400 group-hover:text-gray-300 transition-colors">{card.description}</p>
                  </motion.div>
                ))}
              </div>
            </div>
            
            {/* Interactive Example */}
            <motion.div
              className="bg-black/40 rounded-3xl p-10 border border-indigo-500/20 backdrop-blur-xl shadow-2xl shadow-indigo-500/10"
              whileHover={{ scale: 1.01 }}
            >
              <h3 className="text-2xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 to-purple-400 mb-8">3. Example: $100 Dataset</h3>
              <div className="space-y-6">
                <p className="text-lg text-gray-300">When an AI company purchases a $100 dataset:</p>
                {[
                  { text: "$100 is locked on-chain to back Essence", delay: 0 },
                  { text: "100 Essence tokens are minted", delay: 0.2 },
                  { text: "Signal creator receives 98 Essence (98%)", delay: 0.4, explanation: "The majority of the value goes to the signal creator as a reward for their contribution." },
                  { text: "SELF Labs DAO LLC receives 1 Essence (1%)", delay: 0.5, explanation: "A small portion is allocated to support the operations and growth of the SELF Labs DAO." },
                  { text: "National debt reduction receives 1 Essence (1%)", delay: 0.6, explanation: "This allocation helps contribute to reducing the national debt. If a significant number of citizens opt in, the cumulative effect could lead to a substantial reduction in the national debt over time." },
                  { text: "Essence can be spent, swapped, or held", delay: 0.7 }
                ].map((item, index) => (
                  <motion.div
                    key={index}
                    className="flex items-center bg-gradient-to-r from-black/40 to-black/20 p-6 rounded-2xl border border-indigo-500/20"
                    initial={{ opacity: 0, x: -20 }}
                    whileInView={{ opacity: 1, x: 0 }}
                    transition={{ delay: item.delay }}
                    viewport={{ once: true }}
                    whileHover={{ x: 10 }}
                  >
                    <span className="text-indigo-400 mr-4">→</span>
                    <span className="text-gray-300">{item.text}</span>
                    {item.explanation && (
                      <span className="text-gray-400 ml-4">- {item.explanation}</span>
                    )}
                  </motion.div>
                ))}
              </div>
            </motion.div>
            
            {/* Add a new section for charts */}
            <div className="bg-black/40 rounded-3xl p-10 border border-indigo-500/20 backdrop-blur-xl shadow-2xl shadow-indigo-500/10 mt-16">
              <h3 className="text-2xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 to-purple-400 mb-8">Impact on National Debt</h3>
              <p className="text-lg text-gray-300 mb-6">If a significant number of citizens participate, the allocation of Essence tokens to national debt reduction could have a meaningful impact. Below are projections based on different levels of participation:</p>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                <div className="bg-gradient-to-b from-black/60 to-black/40 p-8 rounded-2xl border border-indigo-500/20">
                  <h4 className="text-xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 to-purple-400 mb-3">Low Participation</h4>
                  <img src="/path/to/low-participation-chart.png" alt="Low Participation Chart" className="w-full h-auto" />
                </div>
                <div className="bg-gradient-to-b from-black/60 to-black/40 p-8 rounded-2xl border border-indigo-500/20">
                  <h4 className="text-xl font-semibold text-transparent bg-clip-text bg-gradient-to-r from-indigo-400 to-purple-400 mb-3">High Participation</h4>
                  <img src="/path/to/high-participation-chart.png" alt="High Participation Chart" className="w-full h-auto" />
                </div>
              </div>
            </div>
            
            {/* Final Statement */}
            <motion.div
              className="bg-gradient-to-r from-indigo-500/10 via-purple-500/10 to-indigo-500/10 rounded-3xl p-10 border border-indigo-500/20 backdrop-blur-xl text-center shadow-2xl shadow-indigo-500/10"
              whileHover={{ scale: 1.02 }}
            >
              <p className="text-3xl font-semibold bg-gradient-to-r from-indigo-400 via-purple-400 to-indigo-400 text-transparent bg-clip-text leading-relaxed">
                Everything is transparent.<br />
                Everything is opt-in.<br />
                You control your signal.
              </p>
            </motion.div>
          </div>
        </motion.div>

        {/* Navigation to Next Section */}
        <div className="flex justify-center mt-16">
          <Link to="/flash/signal-calculator/why-essence-dollars">
            <motion.button
              whileHover={{ scale: 1.05, y: -2 }}
              whileTap={{ scale: 0.95 }}
              className="inline-flex items-center bg-gradient-to-r from-indigo-600 to-purple-600 text-white px-10 py-5 rounded-2xl text-lg font-semibold shadow-xl shadow-indigo-500/30 hover:shadow-indigo-500/50 transition-all duration-300 backdrop-blur-xl"
            >
              Next: Why Essence &gt; Dollars
              <motion.div
                className="ml-3"
                animate={{ x: [0, 5, 0] }}
                transition={{ repeat: Infinity, duration: 1.5 }}
              >
                <ArrowRight className="w-5 h-5" />
              </motion.div>
            </motion.button>
          </Link>
        </div>
      </div>
    </div>
  );
} 