import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { ArrowLeft, Building, Network, Cpu, Send, Bot, Database, Briefcase, Users, Phone, Globe, CheckCircle } from 'lucide-react';

// Define proper types for the form data
interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  jobTitle: string;
  companyName: string;
  industry: string;
  companySize: string;
  phoneNumber: string;
  website: string;
  interests: string[];
  consent: boolean;
}

// Apollo API response type
interface ApolloResponse {
  success: boolean;
  message?: string;
  person?: any;
  error?: string;
}

export default function HarmonizePage() {
  // Expanded state to capture more business details
  const [formData, setFormData] = useState<FormData>({
    firstName: '',
    lastName: '',
    email: '',
    jobTitle: '',
    companyName: '',
    industry: '',
    companySize: '',
    phoneNumber: '',
    website: '',
    interests: [],
    consent: false
  });
  
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    const { name, value, type } = e.target;
    
    // Special handling for interest checkboxes
    if (type === 'checkbox' && name === 'interests') {
      const checkbox = e.target as HTMLInputElement;
      const interestValue = checkbox.value;
      
      setFormData(prev => {
        // Ensure interests is always an array
        const currentInterests = Array.isArray(prev.interests) ? prev.interests : [];
        
        return {
          ...prev,
          interests: checkbox.checked 
            ? [...currentInterests, interestValue]
            : currentInterests.filter(interest => interest !== interestValue)
        };
      });
    }
    // Handle regular checkboxes (like consent)
    else if (type === 'checkbox') {
      const checkbox = e.target as HTMLInputElement;
      setFormData(prev => ({
        ...prev,
        [name]: checkbox.checked
      }));
    } 
    // Handle all other input types
    else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
    
    // Clear error when field is edited
    if (errors[name]) {
      setErrors(prev => {
        const newErrors = {...prev};
        delete newErrors[name];
        return newErrors;
      });
    }
  };
  
  const validateForm = () => {
    const newErrors: Record<string, string> = {};
    
    if (!formData.firstName) newErrors.firstName = 'First name is required';
    if (!formData.lastName) newErrors.lastName = 'Last name is required';
    if (!formData.email) {
      newErrors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Email is invalid';
    }
    if (!formData.companyName) newErrors.companyName = 'Company name is required';
    if (!formData.jobTitle) newErrors.jobTitle = 'Job title is required';
    if (!formData.industry) newErrors.industry = 'Industry is required';
    if (!formData.consent) newErrors.consent = 'Consent is required';
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };
  
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    if (!validateForm()) return;
    
    setIsLoading(true);
    
    try {
      // Get the API key from environment variables
      const apiKey = import.meta.env.VITE_APOLLO_API_KEY || "-lYu4hfq8S6woY-K_qS0lA";
      
      // Ensure interests is always an array before processing
      const interestsArray = Array.isArray(formData.interests) ? formData.interests : [];
      
      // Prepare the data for Apollo.io
      const apolloData = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        title: formData.jobTitle,
        organization_name: formData.companyName,
        website_url: formData.website,
        direct_phone: formData.phoneNumber,
        // Add lists/labels for organization
        label_names: ["Harmony AI Prospect", "DAO Implementation", "SELF Labs Website"],
        // Add custom fields
        custom_fields: {
          industry: formData.industry,
          company_size: formData.companySize,
          interests: interestsArray.join(', '),
          source_details: "Harmony AI Business Application Form"
        }
      };
      
      console.log("Preparing to submit to Apollo.io:", apolloData);
      
      try {
        // In development environment, we might encounter CORS issues when calling
        // the Apollo API directly from the browser. In production, we use a Netlify
        // serverless function as a proxy to avoid CORS issues.
        
        const isLocalhost = window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1';
        
        // For development, we'll simulate success to test the form flow
        if (isLocalhost) {
          console.log("Development mode detected. Simulating successful API call.");
          // Simulate API delay
          await new Promise(resolve => setTimeout(resolve, 1500));
          
          // Log what would be sent to Apollo in production
          console.log("This data would be sent to Apollo.io in production:", apolloData);
          
          // Set as submitted
          setIsSubmitted(true);
        } else {
          // In production, try multiple approaches to handle the API call
          console.log("Submitting to Apollo.io in production");
          
          // Step 1: Create the contact
          const contactId = await createApolloContact(apolloData);
          console.log("Contact created with ID:", contactId);
          
          // Step 2: If contact creation was successful, create a task for follow-up
          if (contactId) {
            await createApolloTask(contactId);
          }
          
          // Mark as submitted (we got this far, so something worked)
          setIsSubmitted(true);
        }
      } catch (error) {
        console.error('Error in form submission process:', error);
        // For demo/development purposes, simulate success despite errors
        if (window.location.hostname === 'localhost' || window.location.hostname === '127.0.0.1') {
          console.log("Simulating success despite error for development testing");
          setIsSubmitted(true);
        } else {
          // In production, show the error to the user
          setErrors({ 
            form: error instanceof Error 
              ? `Submission error: ${error.message}` 
              : 'Form submission encountered an error. Please try again later or contact us directly.'
          });
        }
      } finally {
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error in form submission process:', error);
      setErrors({ 
        form: error instanceof Error 
          ? `Submission error: ${error.message}` 
          : 'Form submission encountered an error. Please try again later or contact us directly.'
      });
      setIsLoading(false);
    }
  };
  
  // Function to create a contact in Apollo.io
  const createApolloContact = async (contactData: any): Promise<string | null> => {
    // Ordered array of endpoints to try for contact creation
    const endpointsToTry = [
      // 1. Try Netlify function first (might be 404 if functions not deployed correctly)
      {
        url: '/.netlify/functions/apollo-proxy?endpoint=contacts',
        type: 'netlify function',
        headers: { 'Content-Type': 'application/json' } as HeadersInit
      },
      // 2. Try a direct API call (likely to fail with CORS error, but try anyway)
      {
        url: 'https://api.apollo.io/api/v1/contacts',
        type: 'direct API',
        headers: { 
          'Content-Type': 'application/json',
          'x-api-key': `${import.meta.env.VITE_APOLLO_API_KEY || "-lYu4hfq8S6woY-K_qS0lA"}`
        } as HeadersInit
      }
    ];
    
    let lastError = null;
    
    // Try each endpoint in order until one works
    for (const endpoint of endpointsToTry) {
      try {
        console.log(`Trying ${endpoint.type} endpoint for contact creation: ${endpoint.url}`);
        
        // Use fetch with a timeout to avoid hanging
        const fetchPromise = fetch(endpoint.url, {
          method: 'POST',
          headers: endpoint.headers,
          body: JSON.stringify(contactData)
        });
        
        // Add timeout to fetch - 15 seconds
        const timeoutPromise = new Promise((_, reject) => 
          setTimeout(() => reject(new Error(`${endpoint.type} request timed out after 15s`)), 15000)
        );
        
        // Race between fetch and timeout
        const response = await Promise.race([fetchPromise, timeoutPromise]) as Response;
        
        // Handle non-ok responses
        if (!response.ok) {
          const errorText = await response.text();
          console.error(`${endpoint.type} request failed with status ${response.status}: ${errorText}`);
          lastError = new Error(`${endpoint.type} error (${response.status}): ${errorText.substring(0, 100)}`);
          continue; // Try next endpoint
        }
        
        // Try to parse the response as JSON
        const data = await response.json();
        console.log(`${endpoint.type} contact creation response:`, data);
        
        // Return the contact ID if available
        return data?.id || data?.contact?.id || null;
      } catch (fetchError) {
        console.error(`${endpoint.type} fetch error:`, fetchError);
        lastError = fetchError instanceof Error ? fetchError : new Error(String(fetchError));
        // Continue to next endpoint
      }
    }
    
    // If we tried all endpoints and none worked
    if (lastError) {
      throw lastError;
    }
    
    return null;
  };
  
  // Function to create a task in Apollo.io for a contact
  const createApolloTask = async (contactId: string): Promise<boolean> => {
    // Skip if no contact ID
    if (!contactId) {
      console.error("Cannot create task: No contact ID provided");
      return false;
    }
    
    // Configure the task data
    const taskData = {
      user_id: "67e6a3b2bbcbb90019bfb33c", // User's actual Apollo ID
      contact_ids: [contactId],
      priority: "high",
      due_at: new Date(Date.now() + 86400000).toISOString(), // Due tomorrow
      type: "action_item",
      status: "scheduled",
      note: `Follow up with ${formData.firstName} ${formData.lastName} about Harmony AI implementation for ${formData.companyName}. Interested in: ${Array.isArray(formData.interests) ? formData.interests.join(', ') : 'General inquiry'}`
    };
    
    console.log("Creating follow-up task:", taskData);
    
    // Ordered array of endpoints to try for task creation
    const endpointsToTry = [
      // 1. Try Netlify function first
      {
        url: '/.netlify/functions/apollo-proxy?endpoint=tasks/bulk_create',
        type: 'netlify function',
        headers: { 'Content-Type': 'application/json' } as HeadersInit
      },
      // 2. Try a direct API call (likely to fail with CORS error, but try anyway)
      {
        url: 'https://api.apollo.io/api/v1/tasks/bulk_create',
        type: 'direct API',
        headers: { 
          'Content-Type': 'application/json',
          'x-api-key': `${import.meta.env.VITE_APOLLO_API_KEY || "-lYu4hfq8S6woY-K_qS0lA"}`
        } as HeadersInit
      }
    ];
    
    let lastError = null;
    
    // Try each endpoint in order until one works
    for (const endpoint of endpointsToTry) {
      try {
        console.log(`Trying ${endpoint.type} endpoint for task creation: ${endpoint.url}`);
        
        // Use fetch with a timeout to avoid hanging
        const fetchPromise = fetch(endpoint.url, {
          method: 'POST',
          headers: endpoint.headers,
          body: JSON.stringify(taskData)
        });
        
        // Add timeout to fetch - 15 seconds
        const timeoutPromise = new Promise((_, reject) => 
          setTimeout(() => reject(new Error(`${endpoint.type} request timed out after 15s`)), 15000)
        );
        
        // Race between fetch and timeout
        const response = await Promise.race([fetchPromise, timeoutPromise]) as Response;
        
        // Handle non-ok responses
        if (!response.ok) {
          const errorText = await response.text();
          console.error(`${endpoint.type} task request failed with status ${response.status}: ${errorText}`);
          lastError = new Error(`${endpoint.type} task error (${response.status}): ${errorText.substring(0, 100)}`);
          continue; // Try next endpoint
        }
        
        // Try to parse the response as JSON
        const data = await response.json();
        console.log(`${endpoint.type} task creation response:`, data);
        
        // Task creation successful
        return true;
      } catch (fetchError) {
        console.error(`${endpoint.type} task fetch error:`, fetchError);
        lastError = fetchError instanceof Error ? fetchError : new Error(String(fetchError));
        // Continue to next endpoint
      }
    }
    
    // If we tried all endpoints and none worked, log the error but don't fail the form submission
    if (lastError) {
      console.error("Failed to create task:", lastError);
    }
    
    return false;
  };
  
  const features = [
    {
      icon: Building,
      title: "Conscious Systems Design",
      description: "Transform your business operations with AI that aligns with your organization's deepest intentions and values, not just surface-level metrics."
    },
    {
      icon: Network,
      title: "Harmonic DAO Integration",
      description: "Join a collective of forward-thinking businesses building the new paradigm of conscious automation and decentralized governance."
    },
    {
      icon: Cpu,
      title: "Signal-Enhanced Framework",
      description: "Implement our proprietary Signal Protocol to create resonant AI systems that evolve alongside your team's consciousness."
    }
  ];

  const harmonyBenefits = [
    {
      icon: Bot,
      title: "Your Own Harmony AI Instance",
      description: "Every business gets its own customized version of Harmony AI, tailored to your specific organizational needs and consciousness signature."
    },
    {
      icon: Database,
      title: "DAO Management & Automation",
      description: "Your Harmony AI will help establish, maintain, and evolve your decentralized autonomous organization through conscious automation protocols."
    }
  ];

  // Industry options for dropdown
  const industries = [
    "Technology", "Finance", "Healthcare", "Education", "Manufacturing", 
    "Retail", "Media", "Real Estate", "Energy", "Consulting", "Other"
  ];
  
  // Company size options
  const companySizes = [
    "1-10", "11-50", "51-200", "201-500", "501-1000", "1000+"
  ];
  
  // Interest options
  const interestOptions = [
    "Conscious AI implementation", "DAO setup and governance", 
    "Automation protocols", "Signal Protocol integration", 
    "Decentralized operations", "Custom Harmony AI development"
  ];
  
  return (
    <div className="min-h-screen bg-gradient-to-b from-black to-[#080010] text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24">
        {/* Back navigation */}
        <div className="mb-12">
          <button onClick={() => window.history.back()} className="flex items-center text-white/70 hover:text-white transition-colors">
            <ArrowLeft className="mr-2" size={20} />
            <span>Return to Home</span>
          </button>
        </div>
        
        {/* Header */}
        <motion.div
          className="text-center mb-10 sm:mb-16"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold mb-4 sm:mb-6">
            <span className="bg-gradient-to-r from-[#8A2BE2] via-white to-[#4B0082] text-transparent bg-clip-text">
              Harmonize Your Business
            </span>
          </h1>
          <p className="text-lg sm:text-xl text-gray-300 max-w-3xl mx-auto">
            Get your own instance of Harmony AI to help establish and run your organization's DAO. 
            We'll guide you through the entire process from consciousness alignment to full implementation.
          </p>
        </motion.div>
        
        {/* Harmony AI Explanation */}
        <motion.div
          className="bg-[#120821] p-6 sm:p-8 rounded-xl border border-[#8A2BE2]/20 mb-10 sm:mb-16 relative overflow-hidden"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.8 }}
        >
          <motion.div
            className="absolute -right-40 -top-40 w-80 h-80 bg-[#8A2BE2]/10 rounded-full blur-[80px]"
            animate={{
              scale: [1, 1.2, 1],
              opacity: [0.2, 0.4, 0.2],
            }}
            transition={{
              duration: 8,
              repeat: Infinity,
              ease: "easeInOut",
            }}
          />
          
          <h2 className="text-xl sm:text-2xl md:text-3xl font-bold mb-3 sm:mb-4 relative z-10">
            <span className="bg-gradient-to-r from-[#8A2BE2] to-[#4B0082] text-transparent bg-clip-text">
              Your Business, Your Harmony AI
            </span>
          </h2>
          
          <p className="text-gray-300 mb-6 sm:mb-8 text-base sm:text-lg relative z-10">
            Harmony isn't just our AI—it can be <em>your</em> AI too. We provide each business with its own 
            customized instance of Harmony, specifically calibrated to your organization's consciousness 
            signature, intentions, and operational needs. Your Harmony AI will help you establish and 
            maintain your own DAO, automate processes based on conscious alignment, and evolve alongside 
            your team's growing awareness.
          </p>
          
          <div className="grid sm:grid-cols-2 gap-4 sm:gap-8 relative z-10">
            {harmonyBenefits.map((benefit, index) => (
              <div key={index} className="bg-black/30 p-4 sm:p-6 rounded-lg border border-[#8A2BE2]/20">
                <div className="flex items-start sm:items-center mb-3 sm:mb-4">
                  <div className="p-2 bg-[#8A2BE2]/10 rounded-lg mr-3 sm:mr-4 flex-shrink-0">
                    <benefit.icon className="w-5 h-5 sm:w-6 sm:h-6 text-[#8A2BE2]" />
                  </div>
                  <h3 className="text-lg sm:text-xl font-semibold text-white">{benefit.title}</h3>
                </div>
                <p className="text-sm sm:text-base text-gray-300">{benefit.description}</p>
              </div>
            ))}
          </div>
        </motion.div>
        
        {/* Features grid */}
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8 lg:gap-10 mb-12 sm:mb-20">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.2, duration: 0.8 }}
              className="bg-[#120821] p-5 sm:p-8 rounded-xl border border-[#8A2BE2]/20 relative overflow-hidden"
            >
              <motion.div
                className="absolute -right-20 -bottom-20 w-40 h-40 bg-[#8A2BE2]/5 rounded-full blur-[50px]"
                animate={{
                  scale: [1, 1.2, 1],
                  opacity: [0.3, 0.5, 0.3],
                }}
                transition={{
                  duration: 5,
                  repeat: Infinity,
                  ease: "easeInOut",
                  delay: index * 0.5,
                }}
              />
              
              <div className="mb-4 sm:mb-6 p-2 sm:p-3 inline-block bg-[#8A2BE2]/10 rounded-xl relative z-10">
                <feature.icon className="w-6 h-6 sm:w-8 sm:h-8 text-[#8A2BE2]" />
              </div>
              
              <h3 className="text-lg sm:text-xl font-bold text-white mb-2 sm:mb-3 relative z-10">{feature.title}</h3>
              <p className="text-sm sm:text-base text-gray-300 relative z-10">{feature.description}</p>
            </motion.div>
          ))}
        </div>
        
        {/* Business Application Form Section */}
        <motion.div
          className="bg-[#120821] p-6 sm:p-8 md:p-10 rounded-2xl border border-[#8A2BE2]/20 max-w-4xl mx-auto relative overflow-hidden"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6, duration: 0.8 }}
        >
          <motion.div
            className="absolute -right-40 -top-40 w-80 h-80 bg-[#8A2BE2]/10 rounded-full blur-[80px]"
            animate={{
              scale: [1, 1.2, 1],
              opacity: [0.2, 0.4, 0.2],
            }}
            transition={{
              duration: 8,
              repeat: Infinity,
              ease: "easeInOut",
            }}
          />
          
          <h2 className="text-xl sm:text-2xl md:text-3xl font-bold mb-4 sm:mb-6 relative z-10">
            <span className="bg-gradient-to-r from-[#8A2BE2] to-[#4B0082] text-transparent bg-clip-text">
              Apply for Your Harmony AI & DAO Implementation
            </span>
          </h2>
          
          <p className="text-gray-300 mb-4 relative z-10">
            Complete this business application to begin your organization's journey toward Harmony AI integration 
            and DAO establishment. Our team will review your information and reach out to discuss custom implementation details.
          </p>
          
          <p className="text-gray-300 mb-8 relative z-10">
            Your information will be securely stored in our Apollo.io business database to ensure a seamless onboarding process.
          </p>
          
          {!isSubmitted ? (
            <form onSubmit={handleSubmit} className="relative z-10">
              {errors.form && (
                <div className="bg-red-500/10 border border-red-500/30 text-red-400 p-4 rounded-lg mb-6">
                  {errors.form}
                </div>
              )}
              
              {/* Contact Information Section */}
              <div className="mb-8">
                <h3 className="text-lg sm:text-xl font-semibold text-white mb-4 border-b border-[#8A2BE2]/20 pb-2">
                  Contact Information
                </h3>
                
                <div className="space-y-4">
                  {/* First Name */}
                  <div>
                    <label htmlFor="firstName" className="block text-base font-medium text-gray-300 mb-2">
                      First Name <span className="text-[#8A2BE2]">*</span>
                    </label>
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      className={`w-full bg-black/30 border ${errors.firstName ? 'border-red-500/50' : 'border-[#8A2BE2]/30'} text-white rounded-lg px-4 py-3 h-14 text-base focus:outline-none focus:ring-2 focus:ring-[#8A2BE2]/50`}
                      value={formData.firstName}
                      onChange={handleChange}
                    />
                    {errors.firstName && <p className="mt-1 text-sm text-red-400">{errors.firstName}</p>}
                  </div>
                  
                  {/* Last Name */}
                  <div>
                    <label htmlFor="lastName" className="block text-base font-medium text-gray-300 mb-2">
                      Last Name <span className="text-[#8A2BE2]">*</span>
                    </label>
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      className={`w-full bg-black/30 border ${errors.lastName ? 'border-red-500/50' : 'border-[#8A2BE2]/30'} text-white rounded-lg px-4 py-3 h-14 text-base focus:outline-none focus:ring-2 focus:ring-[#8A2BE2]/50`}
                      value={formData.lastName}
                      onChange={handleChange}
                    />
                    {errors.lastName && <p className="mt-1 text-sm text-red-400">{errors.lastName}</p>}
                  </div>
                  
                  {/* Email */}
                  <div>
                    <label htmlFor="email" className="block text-base font-medium text-gray-300 mb-2">
                      Business Email <span className="text-[#8A2BE2]">*</span>
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className={`w-full bg-black/30 border ${errors.email ? 'border-red-500/50' : 'border-[#8A2BE2]/30'} text-white rounded-lg px-4 py-3 h-14 text-base focus:outline-none focus:ring-2 focus:ring-[#8A2BE2]/50`}
                      value={formData.email}
                      onChange={handleChange}
                    />
                    {errors.email && <p className="mt-1 text-sm text-red-400">{errors.email}</p>}
                  </div>
                  
                  {/* Phone */}
                  <div>
                    <label htmlFor="phoneNumber" className="block text-base font-medium text-gray-300 mb-2">
                      Phone Number
                    </label>
                    <input
                      type="tel"
                      id="phoneNumber"
                      name="phoneNumber"
                      className="w-full bg-black/30 border border-[#8A2BE2]/30 text-white rounded-lg px-4 py-3 h-14 text-base focus:outline-none focus:ring-2 focus:ring-[#8A2BE2]/50"
                      value={formData.phoneNumber}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              
              {/* Business Information Section */}
              <div className="mb-8">
                <h3 className="text-lg sm:text-xl font-semibold text-white mb-4 border-b border-[#8A2BE2]/20 pb-2">
                  Business Information
                </h3>
                
                <div className="space-y-4">
                  {/* Company Name */}
                  <div>
                    <label htmlFor="companyName" className="block text-base font-medium text-gray-300 mb-2">
                      Company Name <span className="text-[#8A2BE2]">*</span>
                    </label>
                    <div className="flex">
                      <div className="p-3 bg-black/50 border-y border-l border-[#8A2BE2]/30 rounded-l-lg flex items-center">
                        <Briefcase className="w-5 h-5 text-[#8A2BE2]" />
                      </div>
                      <input
                        type="text"
                        id="companyName"
                        name="companyName"
                        className={`flex-grow bg-black/30 border-y border-r ${errors.companyName ? 'border-red-500/50' : 'border-[#8A2BE2]/30'} text-white rounded-r-lg px-4 py-3 h-14 text-base focus:outline-none focus:ring-2 focus:ring-[#8A2BE2]/50`}
                        value={formData.companyName}
                        onChange={handleChange}
                      />
                    </div>
                    {errors.companyName && <p className="mt-1 text-sm text-red-400">{errors.companyName}</p>}
                  </div>
                  
                  {/* Job Title */}
                  <div>
                    <label htmlFor="jobTitle" className="block text-base font-medium text-gray-300 mb-2">
                      Job Title <span className="text-[#8A2BE2]">*</span>
                    </label>
                    <input
                      type="text"
                      id="jobTitle"
                      name="jobTitle"
                      className={`w-full bg-black/30 border ${errors.jobTitle ? 'border-red-500/50' : 'border-[#8A2BE2]/30'} text-white rounded-lg px-4 py-3 h-14 text-base focus:outline-none focus:ring-2 focus:ring-[#8A2BE2]/50`}
                      value={formData.jobTitle}
                      onChange={handleChange}
                    />
                    {errors.jobTitle && <p className="mt-1 text-sm text-red-400">{errors.jobTitle}</p>}
                  </div>
                  
                  {/* Industry */}
                  <div>
                    <label htmlFor="industry" className="block text-base font-medium text-gray-300 mb-2">
                      Industry <span className="text-[#8A2BE2]">*</span>
                    </label>
                    <select
                      id="industry"
                      name="industry"
                      className={`w-full bg-black/30 border ${errors.industry ? 'border-red-500/50' : 'border-[#8A2BE2]/30'} text-white rounded-lg px-4 py-3 h-14 text-base focus:outline-none focus:ring-2 focus:ring-[#8A2BE2]/50`}
                      value={formData.industry}
                      onChange={handleChange}
                    >
                      <option value="">Select Industry</option>
                      {industries.map((industry) => (
                        <option key={industry} value={industry}>{industry}</option>
                      ))}
                    </select>
                    {errors.industry && <p className="mt-1 text-sm text-red-400">{errors.industry}</p>}
                  </div>
                  
                  {/* Company Size */}
                  <div>
                    <label htmlFor="companySize" className="block text-base font-medium text-gray-300 mb-2">
                      Company Size
                    </label>
                    <div className="flex">
                      <div className="p-3 bg-black/50 border-y border-l border-[#8A2BE2]/30 rounded-l-lg flex items-center">
                        <Users className="w-5 h-5 text-[#8A2BE2]" />
                      </div>
                      <select
                        id="companySize"
                        name="companySize"
                        className="flex-grow bg-black/30 border-y border-r border-[#8A2BE2]/30 text-white rounded-r-lg px-4 py-3 h-14 text-base focus:outline-none focus:ring-2 focus:ring-[#8A2BE2]/50"
                        value={formData.companySize}
                        onChange={handleChange}
                      >
                        <option value="">Select Company Size</option>
                        {companySizes.map((size) => (
                          <option key={size} value={size}>{size} employees</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  
                  {/* Website */}
                  <div>
                    <label htmlFor="website" className="block text-base font-medium text-gray-300 mb-2">
                      Company Website
                    </label>
                    <div className="flex">
                      <div className="p-3 bg-black/50 border-y border-l border-[#8A2BE2]/30 rounded-l-lg flex items-center">
                        <Globe className="w-5 h-5 text-[#8A2BE2]" />
                      </div>
                      <input
                        type="url"
                        id="website"
                        name="website"
                        placeholder="https://"
                        className="flex-grow bg-black/30 border-y border-r border-[#8A2BE2]/30 text-white rounded-r-lg px-4 py-3 h-14 text-base focus:outline-none focus:ring-2 focus:ring-[#8A2BE2]/50"
                        value={formData.website}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Interests */}
              <div className="mb-8">
                <h3 className="text-lg sm:text-xl font-semibold text-white mb-3 sm:mb-4 border-b border-[#8A2BE2]/20 pb-2">
                  Areas of Interest
                </h3>
                <div className="grid grid-cols-1 gap-3">
                  {interestOptions.map((interest) => (
                    <div key={interest} className="flex items-center bg-black/20 p-3 rounded-lg">
                      <input
                        type="checkbox"
                        id={`interest-${interest}`}
                        name="interests"
                        value={interest}
                        checked={Array.isArray(formData.interests) && formData.interests.includes(interest)}
                        onChange={handleChange}
                        className="w-6 h-6 bg-black/30 border border-[#8A2BE2]/30 rounded"
                      />
                      <label htmlFor={`interest-${interest}`} className="ml-3 text-base text-gray-300">
                        {interest}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              
              {/* Consent */}
              <div className="mb-8">
                <div className="flex items-start bg-black/20 p-4 rounded-lg border border-[#8A2BE2]/20">
                  <input
                    type="checkbox"
                    id="consent"
                    name="consent"
                    checked={formData.consent}
                    onChange={handleChange}
                    className={`w-6 h-6 mt-0.5 flex-shrink-0 bg-black/30 border ${errors.consent ? 'border-red-500/50' : 'border-[#8A2BE2]/30'} rounded`}
                  />
                  <label htmlFor="consent" className="ml-3 text-base text-gray-300">
                    I consent to SELF Labs collecting my business information and storing it in Apollo.io for the purpose of 
                    Harmony AI implementation and DAO setup. <span className="text-[#8A2BE2]">*</span>
                  </label>
                </div>
                {errors.consent && <p className="mt-2 text-sm text-red-400">{errors.consent}</p>}
              </div>
              
              <div className="flex justify-center mt-8">
                <button
                  type="submit"
                  disabled={isLoading}
                  className="w-full bg-gradient-to-r from-[#8A2BE2] to-[#4B0082] text-white px-8 py-4 text-lg rounded-lg flex items-center justify-center disabled:opacity-70"
                >
                  {isLoading ? (
                    <>
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Processing...
                    </>
                  ) : (
                    <>
                      <span className="mr-2">Submit Application</span>
                      <Send size={20} />
                    </>
                  )}
                </button>
              </div>
            </form>
          ) : (
            <motion.div
              initial={{ opacity: 0, scale: 0.9 }}
              animate={{ opacity: 1, scale: 1 }}
              className="bg-[#8A2BE2]/10 p-6 rounded-lg border border-[#8A2BE2]/30 text-center"
            >
              <CheckCircle className="w-12 h-12 text-[#8A2BE2] mx-auto mb-4" />
              <h3 className="text-xl font-bold text-white mb-2">Application Received</h3>
              <p className="text-gray-300 mb-4">
                Thank you for your interest in implementing Harmony AI for your business. Your application 
                has been successfully submitted and is now in our Apollo.io database.
              </p>
              <p className="text-gray-300">
                A SELF Labs representative will contact you within 48 hours to discuss your custom 
                Harmony AI implementation and DAO setup process.
              </p>
            </motion.div>
          )}
        </motion.div>
      </div>
    </div>
  );
} 