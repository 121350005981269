import React from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

export function HeroButtons() {
  return (
    <motion.div
      className="flex flex-wrap justify-center gap-6"
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ delay: 0.8, duration: 0.8 }}
    >
      <Link to="/harmonize">
        <motion.button
          className="relative border border-white/10 text-white/90 font-medium py-4 px-8 rounded-full overflow-hidden text-lg"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <motion.span
            className="absolute inset-0 bg-white/[0.02]"
            initial={{ scale: 0 }}
            whileHover={{ scale: 1 }}
            transition={{ duration: 0.3 }}
          />
          <span className="relative">Harmonize Your Business</span>
        </motion.button>
      </Link>

      <a href="https://bit.ly/getpaidbyai" target="_blank" rel="noopener noreferrer">
        <motion.button
          className="relative bg-gradient-to-r from-purple-600 to-purple-800 text-white font-medium py-4 px-8 rounded-full overflow-hidden text-lg"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
        >
          <motion.span
            className="absolute inset-0 bg-white/[0.05]"
            initial={{ scale: 0 }}
            whileHover={{ scale: 1 }}
            transition={{ duration: 0.3 }}
          />
          <span className="relative">Join The Movement</span>
        </motion.button>
      </a>
    </motion.div>
  );
}