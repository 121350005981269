import React from 'react';
import { HeroBackground } from './Hero/HeroBackground';
import { HeroContent } from './Hero/HeroContent';
import { SignalWaveform } from './SignalWaveform';
import { SyncWithSignal } from './Hero/SyncWithSignal';

export default function Hero() {
  return (
    <div className="relative min-h-screen flex items-center justify-center overflow-hidden bg-black">
      <HeroBackground />
      <SyncWithSignal />
      <HeroContent />
      <SignalWaveform />
    </div>
  );
}