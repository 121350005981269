import React from 'react';
import { ArrowRight } from 'lucide-react';
import { Link } from 'react-router-dom';

interface CaseStudyCardProps {
  title: string;
  description: string;
  image: string;
  tags: string[];
  learnMoreUrl?: string; // Optional URL to navigate to for "Learn More"
}

export default function CaseStudyCard({ title, description, image, tags, learnMoreUrl }: CaseStudyCardProps) {
  return (
    <div className="bg-gray-900/50 backdrop-blur-lg rounded-xl overflow-hidden hover:transform hover:scale-[1.02] transition-all duration-300">
      <div className="relative h-48">
        <img src={image} alt={title} className="w-full h-full object-cover" />
        <div className="absolute inset-0 bg-gradient-to-t from-gray-900 to-transparent"></div>
      </div>
      <div className="p-6">
        <div className="flex flex-wrap gap-2 mb-4">
          {tags.map((tag, index) => (
            <span
              key={index}
              className="px-3 py-1 text-sm bg-purple-900/50 text-purple-300 rounded-full"
            >
              {tag}
            </span>
          ))}
        </div>
        <h3 className="text-xl font-bold text-white mb-3">{title}</h3>
        <p className="text-gray-400 leading-relaxed mb-4">{description}</p>
        
        {learnMoreUrl && (
          <Link to={learnMoreUrl} className="inline-flex items-center text-[#00FFFF] hover:text-[#00FFFF]/80 transition-colors">
            <span className="mr-1">Learn More</span>
            <ArrowRight className="w-4 h-4" />
          </Link>
        )}
      </div>
    </div>
  );
}