import React from 'react';
import { motion } from 'framer-motion';

const personalities = [
  {
    name: "Aura",
    expertise: "Platform guidance, feature exploration, and user assistance",
    description: "Your personal AI concierge and platform guide. I'll help you navigate features, understand tools, and make the most of your experience."
  },
  {
    name: "Robb",
    expertise: "Business scaling, strategic planning, and growth optimization",
    description: "Strategic business advisor with deep expertise in scaling companies and implementing growth solutions. Specializes in operational efficiency, market expansion, and sustainable growth strategies."
  },
  {
    name: "Erin",
    expertise: "Creative writing, storytelling, and narrative design",
    description: "Creative writer and storyteller with a passion for crafting compelling narratives. From character development to plot structure, I help bring your stories to life."
  },
  {
    name: "Adam",
    expertise: "Mathematics, problem-solving, and logical reasoning",
    description: "Analytical and precise mathematical expert with a knack for clear explanations. From basic arithmetic to advanced calculus, I make complex concepts accessible."
  },
  {
    name: "Aria",
    expertise: "Mental health, mindfulness, and emotional well-being",
    description: "Empathetic wellness coach focused on mental health and personal growth. I provide support, guidance, and practical strategies for emotional well-being."
  },
  {
    name: "Brian",
    expertise: "Finance, investment strategies, and wealth management",
    description: "Sharp financial advisor with deep knowledge of markets and investment strategies. I help you make informed decisions about your money and future."
  },
  {
    name: "Chris",
    expertise: "Technology troubleshooting and device optimization",
    description: "Tech-savvy expert specializing in gadgets and home appliances. From troubleshooting to optimization, I'll help you master your devices."
  },
  {
    name: "Milan",
    expertise: "Philosophy of science, quantum physics, and existential questions",
    description: "Philosophical scientist exploring the mysteries of the universe and human consciousness. Let's dive deep into quantum mechanics and existential questions."
  }
];

export function AIPersonalities() {
  return (
    <div className="py-24 bg-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold mb-6 bg-gradient-to-r from-purple-400 to-pink-600 text-transparent bg-clip-text">
            Meet Our AI Personalities
          </h2>
          <p className="text-xl text-gray-400 max-w-3xl mx-auto">
            Try our AI personalities with the Pro subscription or create your own custom AI agent with your voice!
          </p>
        </motion.div>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {personalities.map((personality, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className="bg-gradient-to-b from-purple-900/10 to-violet-900/5 backdrop-blur-sm rounded-xl p-6 border border-purple-500/20 hover:border-purple-500/40 transition-all duration-300"
            >
              <div className="mb-4">
                <div className="inline-flex items-center justify-center w-12 h-12 rounded-full bg-purple-700/30 mb-4">
                  <span className="text-2xl font-semibold text-purple-300">{personality.name.charAt(0)}</span>
                </div>
                <h3 className="text-2xl font-semibold text-white">{personality.name}</h3>
              </div>
              <p className="text-gray-300 mb-4">{personality.description}</p>
              <div className="pt-4 border-t border-purple-500/20">
                <p className="text-sm text-purple-300 font-semibold">Expertise:</p>
                <p className="text-gray-400">{personality.expertise}</p>
              </div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
} 