import React, { useEffect, useRef } from 'react';
import { motion } from 'framer-motion';

export function SignalWaveform() {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  
  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    
    const ctx = canvas.getContext('2d');
    if (!ctx) return;
    
    // Set canvas dimensions
    const resizeCanvas = () => {
      canvas.width = window.innerWidth;
      canvas.height = 200;
    };
    
    resizeCanvas();
    window.addEventListener('resize', resizeCanvas);
    
    // Variables for the animation
    let frequency = 0.05;
    let amplitude = 50;
    let phase = 0;
    
    // Animation function
    const animate = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      
      // Draw the wave
      ctx.beginPath();
      ctx.moveTo(0, canvas.height / 2);
      
      for (let x = 0; x < canvas.width; x++) {
        const y = (Math.sin(x * frequency + phase) * amplitude) + (canvas.height / 2);
        ctx.lineTo(x, y);
      }
      
      // Set line style
      ctx.strokeStyle = 'rgba(138, 43, 226, 0.4)';
      ctx.lineWidth = 3;
      ctx.stroke();
      
      // Update phase for animation
      phase += 0.03;
      
      // Gradually change frequency and amplitude for dynamic effect
      frequency = 0.05 + Math.sin(phase * 0.1) * 0.02;
      amplitude = 50 + Math.sin(phase * 0.05) * 20;
      
      requestAnimationFrame(animate);
    };
    
    animate();
    
    return () => {
      window.removeEventListener('resize', resizeCanvas);
    };
  }, []);
  
  return (
    <motion.div 
      className="absolute bottom-0 left-0 w-full h-[200px] z-10 opacity-70 pointer-events-none"
      initial={{ opacity: 0 }}
      animate={{ opacity: 0.7 }}
      transition={{ delay: 1, duration: 1.5 }}
    >
      <canvas ref={canvasRef} className="w-full h-full" />
    </motion.div>
  );
} 