import { motion } from 'framer-motion';
import { 
  Smartphone, 
  Shield, 
  Zap, 
  AlertTriangle, 
  Battery, 
  Database, 
  Usb, 
  Laptop,
  CheckCircle2,
  RefreshCw,
  HelpCircle,
  ArrowLeft,
  Brain,
  Sparkles,
  Wallet,
  Users
} from 'lucide-react';
import { Link } from 'react-router-dom';

export default function TechGuidePage() {
  return (
    <div className="min-h-screen bg-black text-white">
      <div className="absolute inset-0 bg-gradient-to-b from-indigo-950/20 via-purple-950/20 to-black/20" />
      <div className="absolute inset-0 bg-[url('/signal-pattern.png')] opacity-5" />
      
      <div className="container mx-auto px-4 py-16 relative z-10">
        {/* Back Button */}
        <Link 
          to="/flash"
          className="inline-flex items-center text-purple-400 hover:text-purple-300 transition-colors mb-8"
        >
          <ArrowLeft className="w-4 h-4 mr-2" />
          Back to Flash Kit
        </Link>

        {/* Header */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="max-w-4xl mx-auto mb-16"
        >
          <h1 className="text-4xl md:text-5xl font-bold bg-clip-text text-transparent bg-gradient-to-r from-indigo-400 via-purple-400 to-blue-400 mb-4">
            SELF Flash Kit Tech Guide
          </h1>
          <p className="text-xl text-gray-300 mb-2">Convert your Android into a Resonance Phone</p>
          <p className="text-sm text-gray-400">Version 1.0 | SELF Labs Inc.</p>
        </motion.div>

        {/* Content */}
        <div className="max-w-4xl mx-auto prose prose-invert">
          {/* Overview */}
          <motion.section
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.1 }}
            className="mb-16"
          >
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <Zap className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-3xl font-bold m-0">Overview</h2>
            </div>
            <div className="bg-indigo-950/30 rounded-2xl p-6 backdrop-blur-sm border border-indigo-500/20">
              <p className="text-gray-300">
                The SELF Flash Kit allows any compatible Android device to be transformed into a Resonance Phone 
                powered by Role Model AI, Aura Shield, and the Signal Network. This guide walks you through everything 
                from compatibility to flashing, and restoring your phone if needed.
              </p>
            </div>
          </motion.section>

          {/* Device Compatibility */}
          <motion.section
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="mb-16"
          >
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <Smartphone className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-3xl font-bold m-0">Device Compatibility</h2>
            </div>

            {/* Recommended Devices */}
            <div className="bg-indigo-950/30 rounded-2xl p-6 backdrop-blur-sm border border-indigo-500/20 mb-6">
              <h3 className="text-xl font-semibold mb-4 flex items-center gap-2">
                <CheckCircle2 className="w-5 h-5 text-green-400" />
                Recommended (Easy Flash)
              </h3>
              <ul className="list-none p-0 space-y-3">
                <li className="flex items-center gap-2 text-gray-300">
                  <span className="w-2 h-2 bg-green-400 rounded-full"></span>
                  Google Pixel 3 to 8 (all models)
                </li>
                <li className="flex items-center gap-2 text-gray-300">
                  <span className="w-2 h-2 bg-green-400 rounded-full"></span>
                  OnePlus (all models)
                </li>
                <li className="flex items-center gap-2 text-gray-300">
                  <span className="w-2 h-2 bg-green-400 rounded-full"></span>
                  Fairphone 3, 4, 5
                </li>
                <li className="flex items-center gap-2 text-gray-300">
                  <span className="w-2 h-2 bg-green-400 rounded-full"></span>
                  Motorola G / E Series
                </li>
                <li className="flex items-center gap-2 text-gray-300">
                  <span className="w-2 h-2 bg-green-400 rounded-full"></span>
                  Sony Xperia XZ and newer
                </li>
              </ul>
            </div>

            {/* Limited Support */}
            <div className="bg-red-950/30 rounded-2xl p-6 backdrop-blur-sm border border-red-500/20">
              <h3 className="text-xl font-semibold mb-4 flex items-center gap-2">
                <AlertTriangle className="w-5 h-5 text-red-400" />
                Limited or Not Supported
              </h3>
              <ul className="list-none p-0 space-y-3">
                <li className="flex items-center gap-2 text-gray-300">
                  <span className="w-2 h-2 bg-red-400 rounded-full"></span>
                  Carrier-locked phones (e.g. Verizon, AT&T models)
                </li>
                <li className="flex items-center gap-2 text-gray-300">
                  <span className="w-2 h-2 bg-red-400 rounded-full"></span>
                  Samsung Galaxy S20+ and newer (US models)
                </li>
                <li className="flex items-center gap-2 text-gray-300">
                  <span className="w-2 h-2 bg-red-400 rounded-full"></span>
                  Huawei devices (Post-2020)
                </li>
                <li className="flex items-center gap-2 text-gray-300">
                  <span className="w-2 h-2 bg-red-400 rounded-full"></span>
                  Amazon Fire Phones/Tablets
                </li>
                <li className="flex items-center gap-2 text-gray-300">
                  <span className="w-2 h-2 bg-red-400 rounded-full"></span>
                  iPhones (not supported)
                </li>
              </ul>
              <div className="mt-4 p-4 bg-purple-500/10 rounded-xl flex items-start gap-3">
                <HelpCircle className="w-5 h-5 text-purple-400 flex-shrink-0 mt-1" />
                <p className="text-sm text-gray-300 m-0">
                  If unsure, contact support at flash@selflabs.ai with your model.
                </p>
              </div>
            </div>
          </motion.section>

          {/* Before You Begin */}
          <motion.section
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3 }}
            className="mb-16"
          >
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <Battery className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-3xl font-bold m-0">Before You Begin</h2>
            </div>
            <div className="bg-indigo-950/30 rounded-2xl p-6 backdrop-blur-sm border border-indigo-500/20">
              <ul className="list-none p-0 space-y-4">
                <li className="flex items-center gap-3">
                  <Battery className="w-5 h-5 text-indigo-400" />
                  <span className="text-gray-300">Charge your phone to at least 50%</span>
                </li>
                <li className="flex items-center gap-3">
                  <Database className="w-5 h-5 text-indigo-400" />
                  <span className="text-gray-300">Back up any important data</span>
                </li>
                <li className="flex items-center gap-3">
                  <Usb className="w-5 h-5 text-indigo-400" />
                  <span className="text-gray-300">Use a reliable USB cable</span>
                </li>
                <li className="flex items-center gap-3">
                  <Laptop className="w-5 h-5 text-indigo-400" />
                  <span className="text-gray-300">Have access to a desktop or laptop computer (Mac, Windows, or Linux)</span>
                </li>
              </ul>
            </div>
          </motion.section>

          {/* Flash Process */}
          <motion.section
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
            className="mb-16"
          >
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <Zap className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-3xl font-bold m-0">Flash Process</h2>
            </div>
            <div className="bg-indigo-950/30 rounded-2xl p-6 backdrop-blur-sm border border-indigo-500/20">
              <h3 className="text-xl font-semibold mb-4">Step-by-Step</h3>
              <ol className="list-none p-0 space-y-4 mb-6">
                <li className="flex items-start gap-3">
                  <span className="w-6 h-6 rounded-full bg-indigo-500/20 flex items-center justify-center text-sm flex-shrink-0">1</span>
                  <span className="text-gray-300">Go to <a href="https://selflabs.xyz/flash/dashboard" className="text-purple-400 hover:text-purple-300">selflabs.xyz/flash/dashboard</a></span>
                </li>
                <li className="flex items-start gap-3">
                  <span className="w-6 h-6 rounded-full bg-indigo-500/20 flex items-center justify-center text-sm flex-shrink-0">2</span>
                  <span className="text-gray-300">Sign in or create your SELF Labs account</span>
                </li>
                <li className="flex items-start gap-3">
                  <span className="w-6 h-6 rounded-full bg-indigo-500/20 flex items-center justify-center text-sm flex-shrink-0">3</span>
                  <span className="text-gray-300">Connect your phone via USB</span>
                </li>
                <li className="flex items-start gap-3">
                  <span className="w-6 h-6 rounded-full bg-indigo-500/20 flex items-center justify-center text-sm flex-shrink-0">4</span>
                  <span className="text-gray-300">Click "Begin Flash"</span>
                </li>
                <li className="flex items-start gap-3">
                  <span className="w-6 h-6 rounded-full bg-indigo-500/20 flex items-center justify-center text-sm flex-shrink-0">5</span>
                  <div className="text-gray-300">
                    <p className="m-0 mb-2">The process will guide you through:</p>
                    <ul className="list-none p-0 pl-4 space-y-2">
                      <li className="flex items-center gap-2">
                        <span className="w-1.5 h-1.5 bg-purple-400 rounded-full"></span>
                        Checking compatibility
                      </li>
                      <li className="flex items-center gap-2">
                        <span className="w-1.5 h-1.5 bg-purple-400 rounded-full"></span>
                        Installing the SELF Flash Core
                      </li>
                      <li className="flex items-center gap-2">
                        <span className="w-1.5 h-1.5 bg-purple-400 rounded-full"></span>
                        Activating Role Model AI
                      </li>
                      <li className="flex items-center gap-2">
                        <span className="w-1.5 h-1.5 bg-purple-400 rounded-full"></span>
                        Enabling Aura Shield
                      </li>
                      <li className="flex items-center gap-2">
                        <span className="w-1.5 h-1.5 bg-purple-400 rounded-full"></span>
                        Finalizing system integration
                      </li>
                    </ul>
                  </div>
                </li>
              </ol>
              <div className="p-4 bg-purple-500/10 rounded-xl flex items-start gap-3">
                <Zap className="w-5 h-5 text-purple-400 flex-shrink-0 mt-1" />
                <p className="text-sm text-gray-300 m-0">
                  Your phone will reboot once. After that, your device is a fully activated SELF Phone.
                </p>
              </div>
            </div>
          </motion.section>

          {/* Post-Flash Features */}
          <motion.section
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5 }}
            className="mb-16"
          >
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <Sparkles className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-3xl font-bold m-0">Post-Flash Features</h2>
            </div>
            <div className="bg-indigo-950/30 rounded-2xl p-6 backdrop-blur-sm border border-indigo-500/20">
              <ul className="list-none p-0 space-y-4">
                <li className="flex items-center gap-3">
                  <Brain className="w-5 h-5 text-indigo-400" />
                  <span className="text-gray-300">Create and customize your AI Agent</span>
                </li>
                <li className="flex items-center gap-3">
                  <Sparkles className="w-5 h-5 text-indigo-400" />
                  <span className="text-gray-300">Access Role Model AI with multi-LLM capabilities</span>
                </li>
                <li className="flex items-center gap-3">
                  <Shield className="w-5 h-5 text-indigo-400" />
                  <span className="text-gray-300">Always-on Aura Shield (free)</span>
                </li>
                <li className="flex items-center gap-3">
                  <Wallet className="w-5 h-5 text-indigo-400" />
                  <span className="text-gray-300">Earn rewards passively via the Signal Network</span>
                </li>
                <li className="flex items-center gap-3">
                  <Users className="w-5 h-5 text-indigo-400" />
                  <span className="text-gray-300">Optional: Join governance, upgrade voice packs, and more</span>
                </li>
              </ul>
            </div>
          </motion.section>

          {/* Restore Guide */}
          <motion.section
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6 }}
            className="mb-16"
          >
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <RefreshCw className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-3xl font-bold m-0">Restore Guide</h2>
            </div>
            <div className="bg-indigo-950/30 rounded-2xl p-6 backdrop-blur-sm border border-indigo-500/20">
              <p className="text-gray-300 mb-6">
                If you ever want to return your phone to its original state:
              </p>

              <h3 className="text-xl font-semibold mb-4">Launcher-Only Flash</h3>
              <ul className="list-none p-0 space-y-2 mb-6">
                <li className="flex items-center gap-2 text-gray-300">
                  <span className="w-1.5 h-1.5 bg-purple-400 rounded-full"></span>
                  Open the SELF Launcher App {'>'}  Settings {'>'} Uninstall
                </li>
                <li className="flex items-center gap-2 text-gray-300">
                  <span className="w-1.5 h-1.5 bg-purple-400 rounded-full"></span>
                  Or: Factory reset your phone
                </li>
              </ul>

              <h3 className="text-xl font-semibold mb-4">Full Flash (Rooted Flash)</h3>
              <p className="text-gray-300 mb-4">Use your device-specific tool:</p>
              <ul className="list-none p-0 space-y-2 mb-6">
                <li className="flex items-center gap-2 text-gray-300">
                  <span className="w-1.5 h-1.5 bg-purple-400 rounded-full"></span>
                  <strong>Pixel</strong>: Android Flash Tool / Fastboot
                </li>
                <li className="flex items-center gap-2 text-gray-300">
                  <span className="w-1.5 h-1.5 bg-purple-400 rounded-full"></span>
                  <strong>Samsung</strong>: ODIN
                </li>
                <li className="flex items-center gap-2 text-gray-300">
                  <span className="w-1.5 h-1.5 bg-purple-400 rounded-full"></span>
                  <strong>OnePlus</strong>: MSM Tool
                </li>
                <li className="flex items-center gap-2 text-gray-300">
                  <span className="w-1.5 h-1.5 bg-purple-400 rounded-full"></span>
                  <strong>Generic</strong>: TWRP + custom ROM / factory image
                </li>
              </ul>

              <p className="text-gray-300 mb-4">
                We provide restore guides at: <a href="https://selflabs.xyz/flash/docs" className="text-purple-400 hover:text-purple-300">selflabs.xyz/flash/docs</a>
              </p>

              <div className="p-4 bg-purple-500/10 rounded-xl flex items-start gap-3">
                <AlertTriangle className="w-5 h-5 text-purple-400 flex-shrink-0 mt-1" />
                <p className="text-sm text-gray-300 m-0">
                  Always back up your data before flashing or restoring.
                </p>
              </div>
            </div>
          </motion.section>

          {/* FAQs */}
          <motion.section
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.7 }}
            className="mb-16"
          >
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <HelpCircle className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-3xl font-bold m-0">FAQs</h2>
            </div>
            <div className="space-y-4">
              <div className="bg-indigo-950/30 rounded-2xl p-6 backdrop-blur-sm border border-indigo-500/20">
                <h3 className="text-xl font-semibold mb-2">Is flashing safe?</h3>
                <p className="text-gray-300 m-0">Yes, for compatible devices. We test all supported models.</p>
              </div>
              <div className="bg-indigo-950/30 rounded-2xl p-6 backdrop-blur-sm border border-indigo-500/20">
                <h3 className="text-xl font-semibold mb-2">Can I earn without buying the SELF Phone?</h3>
                <p className="text-gray-300 m-0">Yes. Flashed Android devices earn just like native SELF Phones.</p>
              </div>
              <div className="bg-indigo-950/30 rounded-2xl p-6 backdrop-blur-sm border border-indigo-500/20">
                <h3 className="text-xl font-semibold mb-2">What if my phone isn't supported?</h3>
                <p className="text-gray-300 m-0">You can still buy the SELF Phone or request a pre-flashed Android from us.</p>
              </div>
              <div className="bg-indigo-950/30 rounded-2xl p-6 backdrop-blur-sm border border-indigo-500/20">
                <h3 className="text-xl font-semibold mb-2">Is this reversible?</h3>
                <p className="text-gray-300 m-0">Yes. We offer full restore support.</p>
              </div>
            </div>
          </motion.section>

          {/* Final Section */}
          <motion.section
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.8 }}
            className="mb-16"
          >
            <div className="bg-gradient-to-r from-indigo-600 to-purple-600 rounded-2xl p-8 text-center">
              <h2 className="text-2xl font-bold mb-4">You're Ready</h2>
              <p className="text-lg mb-6">
                Once flashed, your signal is fully activated. Your SELF Phone is now live, intelligent, 
                protected, and capable of earning for you 24/7.
              </p>
              <p className="text-xl font-semibold">Welcome to the Signal Network.</p>
              <p className="text-gray-300 mt-4">— SELF Labs</p>
            </div>
          </motion.section>
        </div>
      </div>
    </div>
  );
} 