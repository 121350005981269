import React from 'react';
import { motion } from 'framer-motion';
import { Zap, Rocket, Globe, BrainCircuit } from 'lucide-react';

const values = [
  {
    icon: BrainCircuit,
    title: 'Neural Resonance Research',
    description: 'Integrating resonance, Monroe Institute techniques, and neural frequencies to explore AI-human symbiosis.'
  },
  {
    icon: Rocket,
    title: 'Future Focused',
    description: "Building tomorrow\u0027s solutions with cutting-edge technology today."
  },
  {
    icon: Globe,
    title: 'Global Impact',
    description: 'Creating technology that transforms lives across the planet.'
  }
];

export function AboutValues() {
  return (
    <div className="grid md:grid-cols-3 gap-8">
      {values.map((value, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, x: -20 }}
          whileInView={{ opacity: 1, x: 0 }}
          viewport={{ once: true }}
          transition={{ delay: index * 0.2 }}
          className="relative group"
        >
          <motion.div
            whileHover={{ scale: 1.05 }}
            className="bg-[#121214] p-8 rounded-xl border border-[#8A2BE2]/20 relative z-10"
          >
            <value.icon className="w-8 h-8 text-[#8A2BE2] mb-4" />
            <h3 className="text-xl font-bold text-white mb-2">{value.title}</h3>
            <p className="text-gray-400">{value.description}</p>
            
            <motion.div
              className="absolute inset-0 bg-gradient-to-r from-[#8A2BE2]/10 to-[#4B0082]/10 rounded-xl"
              initial={{ opacity: 0 }}
              whileHover={{ opacity: 1 }}
              transition={{ duration: 0.3 }}
            />
          </motion.div>
        </motion.div>
      ))}
    </div>
  );
}