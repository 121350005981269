import { supabase } from './supabase';

const OPENROUTER_API_KEY = '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';
const BASE_URL = 'https://openrouter.ai/api/v1';

interface Message {
  role: 'user' | 'assistant' | 'system';
  content: string;
}

interface Initiative {
  title: string;
  description: string;
  impact: string;
}

export async function chatWithAgent(agentId: string, messages: Message[]) {
  try {
    const response = await fetch(`${BASE_URL}/chat/completions`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${OPENROUTER_API_KEY}`,
        'Content-Type': 'application/json',
        'HTTP-Referer': 'https://selflabs.xyz',
        'X-Title': 'SELF Labs AI Agents'
      },
      body: JSON.stringify({
        model: 'deepseek/deepseek-r1:free',
        messages: messages,
        temperature: 0.7,
        max_tokens: 1000
      })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`OpenRouter API error: ${errorData.error?.message || 'Unknown error'}`);
    }

    const data = await response.json();
    if (!data.choices?.[0]?.message) {
      throw new Error('Invalid response format from OpenRouter API');
    }

    return data.choices[0].message;
  } catch (error) {
    console.error('Error chatting with agent:', error);
    throw error;
  }
}

export async function generateInitiatives(agentId: string, agentRole: string) {
  const systemPrompt = `You are an AI strategic advisor for ${agentRole}. Your task is to generate strategic initiatives.
Always respond with a valid JSON array containing exactly 3 objects. Each object must have these exact fields:
{
  "title": "Brief, clear title",
  "description": "Concise description of the initiative",
  "impact": "Expected business impact"
}`;

  const userPrompt = `Generate 3 strategic initiatives for improving operations or outcomes as the ${agentRole}.
Focus on specific, actionable items with clear business impact.
Format your entire response as a JSON array of objects with title, description, and impact fields.
Keep all text concise and professional.`;

  try {
    const response = await fetch(`${BASE_URL}/chat/completions`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${OPENROUTER_API_KEY}`,
        'Content-Type': 'application/json',
        'HTTP-Referer': 'https://selflabs.xyz',
        'X-Title': 'SELF Labs AI Agents'
      },
      body: JSON.stringify({
        model: 'deepseek/deepseek-r1:free',
        messages: [
          { role: 'system', content: systemPrompt },
          { role: 'user', content: userPrompt }
        ],
        temperature: 0.7,
        max_tokens: 1000
      })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(`OpenRouter API error: ${errorData.error?.message || 'Unknown error'}`);
    }

    const data = await response.json();
    if (!data.choices?.[0]?.message?.content) {
      throw new Error('Invalid response format from OpenRouter API');
    }

    let initiatives: Initiative[];
    try {
      // Clean the response content to ensure it's valid JSON
      const content = data.choices[0].message.content.trim();
      const cleanedContent = content.replace(/```json\n?|\n?```/g, '').trim();
      initiatives = JSON.parse(cleanedContent);

      // Validate the response format
      if (!Array.isArray(initiatives)) {
        throw new Error('Response is not an array');
      }

      if (initiatives.length === 0) {
        throw new Error('No initiatives generated');
      }

      // Validate each initiative
      initiatives.forEach((initiative, index) => {
        if (!initiative.title || !initiative.description || !initiative.impact) {
          throw new Error(`Initiative at index ${index} is missing required fields`);
        }
        if (typeof initiative.title !== 'string' || 
            typeof initiative.description !== 'string' || 
            typeof initiative.impact !== 'string') {
          throw new Error(`Initiative at index ${index} has invalid field types`);
        }
      });
    } catch (parseError) {
      console.error('Error parsing initiatives:', parseError);
      console.error('Raw content:', data.choices[0].message.content);
      throw new Error(`Failed to parse AI response as valid JSON: ${parseError.message}`);
    }

    // Store initiatives in Supabase
    const { error } = await supabase
      .from('agent_initiatives')
      .insert(initiatives.map((initiative) => ({
        agent_id: agentId,
        title: initiative.title,
        description: initiative.description,
        impact: initiative.impact,
        expires_at: new Date(Date.now() + 24 * 60 * 60 * 1000).toISOString()
      })));

    if (error) {
      console.error('Supabase error:', error);
      throw new Error('Failed to store initiatives in database');
    }

    return initiatives;
  } catch (error) {
    console.error('Error generating initiatives:', error);
    throw error;
  }
}

export async function voteOnInitiative(initiativeId: string, vote: 'approve' | 'reject') {
  try {
    const user = await supabase.auth.getUser();
    if (!user.data.user?.id) {
      throw new Error('User not authenticated');
    }

    const { error } = await supabase
      .from('initiative_votes')
      .insert({
        initiative_id: initiativeId,
        user_id: user.data.user.id,
        vote
      });

    if (error) {
      if (error.code === '23505') { // Unique violation
        throw new Error('You have already voted on this initiative');
      }
      throw error;
    }

    // Check if initiative has received enough votes
    const { data: votes, error: votesError } = await supabase
      .from('initiative_votes')
      .select('vote')
      .eq('initiative_id', initiativeId);

    if (votesError) throw votesError;

    const approvalCount = votes.filter(v => v.vote === 'approve').length;
    const totalVotes = votes.length;

    // If more than 50% of votes are approvals and we have at least 3 votes
    if (totalVotes >= 3 && (approvalCount / totalVotes) > 0.5) {
      const { error: updateError } = await supabase
        .from('agent_initiatives')
        .update({ status: 'approved' })
        .eq('id', initiativeId);

      if (updateError) throw updateError;
    }

    return { success: true };
  } catch (error) {
    console.error('Error voting on initiative:', error);
    throw error;
  }
}