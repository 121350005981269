import React, { useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import ProductCard from './ProductCard';
import { products } from '../../data/products';
import { useNavigate } from 'react-router-dom';

interface ProductCarouselProps {
  onProductClick?: (productId: string) => void;
}

export default function ProductCarousel({ onProductClick }: ProductCarouselProps) {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(0);
  const navigate = useNavigate();

  const slideVariants = {
    enter: (direction: number) => ({
      x: direction > 0 ? 1000 : -1000,
      opacity: 0
    }),
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1
    },
    exit: (direction: number) => ({
      zIndex: 0,
      x: direction < 0 ? 1000 : -1000,
      opacity: 0
    })
  };

  const swipeConfidenceThreshold = 10000;
  const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity;
  };

  const paginate = (newDirection: number) => {
    setDirection(newDirection);
    setCurrentIndex((prevIndex) => {
      let newIndex = prevIndex + newDirection;
      if (newIndex < 0) newIndex = products.length - 1;
      if (newIndex >= products.length) newIndex = 0;
      return newIndex;
    });
  };

  const handleLearnMore = () => {
    if (currentIndex === 0) {
      navigate("/self-phone");
      return;
    }
    
    if (currentIndex === 1) {
      navigate("/blood");
      return;
    }
    
    if (currentIndex === 2) {
      navigate("/role-model-ai");
      return;
    }
    
    if (currentIndex === 3) {
      navigate("/cerebral-os");
      return;
    }
    
    if (currentIndex === 4) {
      navigate("/inner-voice");
      return;
    }
    
    if (currentIndex === 5) {
      navigate("/harmony-ai");
      return;
    }
    
    if (currentIndex === 6) {
      navigate("/aura-resonant-layer");
      return;
    }
    
    if (onProductClick) {
      const productId = products[currentIndex].title.toLowerCase().replace(/\s+/g, '-');
      onProductClick(productId);
    }
  };

  return (
    <div className="relative w-full overflow-hidden h-[600px]">
      <AnimatePresence initial={false} custom={direction}>
        <motion.div
          key={currentIndex}
          custom={direction}
          variants={slideVariants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 }
          }}
          drag="x"
          dragConstraints={{ left: 0, right: 0 }}
          dragElastic={1}
          onDragEnd={(e, { offset, velocity }) => {
            const swipe = swipePower(offset.x, velocity.x);
            if (swipe < -swipeConfidenceThreshold) {
              paginate(1);
            } else if (swipe > swipeConfidenceThreshold) {
              paginate(-1);
            }
          }}
          className="absolute w-full flex justify-center items-center"
        >
          <ProductCard {...products[currentIndex]} onLearnMore={handleLearnMore} />
        </motion.div>
      </AnimatePresence>

      {/* Navigation Buttons */}
      <motion.button
        className="absolute left-4 md:top-1/2 md:-translate-y-1/2 bottom-20 sm:bottom-32 p-2 md:p-3 rounded-full bg-black/70 backdrop-blur-sm border border-white/10
          hover:bg-white/10 transition-colors z-20"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={() => paginate(-1)}
      >
        <ChevronLeft className="w-5 h-5 md:w-6 md:h-6 text-white" />
      </motion.button>

      <motion.button
        className="absolute right-4 md:top-1/2 md:-translate-y-1/2 bottom-20 sm:bottom-32 p-2 md:p-3 rounded-full bg-black/70 backdrop-blur-sm border border-white/10
          hover:bg-white/10 transition-colors z-20"
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        onClick={() => paginate(1)}
      >
        <ChevronRight className="w-5 h-5 md:w-6 md:h-6 text-white" />
      </motion.button>

      {/* Progress Dots */}
      <div className="absolute bottom-8 left-1/2 -translate-x-1/2 flex space-x-2 z-10">
        {products.map((_, index) => (
          <motion.button
            key={index}
            className={`w-2 h-2 rounded-full ${
              index === currentIndex ? 'bg-white' : 'bg-white/30'
            }`}
            onClick={() => {
              setDirection(index > currentIndex ? 1 : -1);
              setCurrentIndex(index);
            }}
            whileHover={{ scale: 1.2 }}
          />
        ))}
      </div>
    </div>
  );
}