import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { 
  ArrowLeft, 
  ArrowRight, 
  Coins, 
  Sparkles, 
  Globe2, 
  Lock, 
  Leaf,
  Zap,
  LineChart,
  Users
} from 'lucide-react';

export default function WhyEssenceDollarsPage() {
  const [hoveredCard, setHoveredCard] = useState<number | null>(null);

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.5
      }
    }
  };

  const features = [
    {
      icon: <Leaf className="w-6 h-6" />,
      title: "Earn While You Live",
      description: "Generate Essence through your daily activities. No extra work required.",
      gradient: "from-green-500 to-emerald-500"
    },
    {
      icon: <Lock className="w-6 h-6" />,
      title: "Stable & Backed",
      description: "Every Essence token is backed by real money locked in a blockchain vault.",
      gradient: "from-indigo-500 to-blue-500"
    },
    {
      icon: <Globe2 className="w-6 h-6" />,
      title: "Global & Instant",
      description: "Send and spend Essence anywhere, instantly, with no fees.",
      gradient: "from-purple-500 to-pink-500"
    }
  ];

  const benefits = [
    {
      icon: <Zap className="w-5 h-5" />,
      text: "Your data has real, measurable value"
    },
    {
      icon: <Sparkles className="w-5 h-5" />,
      text: "You earn while using the apps you love"
    },
    {
      icon: <LineChart className="w-5 h-5" />,
      text: "Value flows back to the creators"
    },
    {
      icon: <Users className="w-5 h-5" />,
      text: "AI companies get better training data"
    }
  ];

  return (
    <div className="min-h-screen bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-indigo-900/20 via-black to-black text-white py-20 relative overflow-hidden">
      {/* Animated Background Elements */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute top-0 left-1/4 w-96 h-96 bg-indigo-500/10 rounded-full blur-[120px] animate-pulse" />
        <div className="absolute bottom-0 right-1/4 w-96 h-96 bg-purple-500/10 rounded-full blur-[120px] animate-pulse" />
        <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-full h-full bg-gradient-to-br from-indigo-500/5 to-purple-500/5 rounded-full blur-[100px] animate-pulse" />
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        {/* Back Button */}
        <Link to="/flash/signal-calculator">
          <motion.div
            className="inline-flex items-center text-indigo-400 mb-12 hover:text-indigo-300 transition-colors bg-black/20 px-4 py-2 rounded-full backdrop-blur-sm border border-indigo-500/20"
            whileHover={{ x: -5 }}
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            <span>Back to Signal Calculator</span>
          </motion.div>
        </Link>

        <motion.div
          initial="hidden"
          animate="visible"
          variants={containerVariants}
          className="space-y-16"
        >
          {/* Header */}
          <motion.div variants={itemVariants} className="text-center">
            <motion.div 
              className="inline-flex items-center justify-center w-20 h-20 rounded-3xl bg-gradient-to-br from-indigo-400 to-purple-500 p-4 mb-8"
              animate={{ rotate: [0, 10, -10, 0] }}
              transition={{ duration: 5, repeat: Infinity }}
            >
              <Coins className="w-12 h-12 text-white" />
            </motion.div>
            <h1 className="text-5xl font-bold mb-6 bg-gradient-to-r from-indigo-400 via-purple-400 to-indigo-400 text-transparent bg-clip-text">
              💫 Essence & Dollars: A New Value Paradigm
            </h1>
            <p className="text-2xl text-gray-300 max-w-3xl mx-auto">
              Creating complementary value that enhances traditional currency
            </p>
          </motion.div>

          {/* Features Grid */}
          <motion.div variants={itemVariants}>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
              {features.map((feature, index) => (
                <motion.div
                  key={feature.title}
                  className="relative group"
                  onMouseEnter={() => setHoveredCard(index)}
                  onMouseLeave={() => setHoveredCard(null)}
                  whileHover={{ y: -5, scale: 1.02 }}
                >
                  <div className="h-full bg-black/40 backdrop-blur-xl rounded-3xl p-8 border border-indigo-500/20 overflow-hidden transition-all duration-500">
                    {/* Gradient Overlay */}
                    <div className={`absolute inset-0 bg-gradient-to-br ${feature.gradient} opacity-0 group-hover:opacity-10 transition-opacity duration-500`} />
                    
                    {/* Content */}
                    <div className="relative z-10">
                      <div className="inline-flex p-3 rounded-2xl bg-gradient-to-br from-indigo-500/20 to-purple-500/20 mb-6">
                        {feature.icon}
                      </div>
                      <h3 className="text-xl font-semibold mb-4 text-white">
                        {feature.title}
                      </h3>
                      <p className="text-gray-400 group-hover:text-gray-300 transition-colors">
                        {feature.description}
                      </p>
                    </div>
                  </div>
                </motion.div>
              ))}
            </div>
          </motion.div>

          {/* Parallel Economy Section */}
          <motion.div variants={itemVariants}>
            <div className="bg-black/40 backdrop-blur-xl rounded-3xl p-8 border border-indigo-500/20">
              <h3 className="text-2xl font-semibold text-center mb-8 bg-gradient-to-r from-indigo-400 via-purple-400 to-indigo-400 text-transparent bg-clip-text">
                The Parallel Economy
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                {benefits.map((benefit, index) => (
                  <motion.div
                    key={index}
                    className="flex items-center space-x-4 bg-black/30 rounded-xl p-4 border border-indigo-500/20"
                    whileHover={{ x: 5 }}
                  >
                    <div className="flex-shrink-0 w-10 h-10 bg-indigo-500/20 rounded-lg flex items-center justify-center">
                      {benefit.icon}
                    </div>
                    <p className="text-gray-300">{benefit.text}</p>
                  </motion.div>
                ))}
              </div>
            </div>
          </motion.div>

          {/* Call to Action */}
          <motion.div variants={itemVariants}>
            <div className="bg-gradient-to-br from-indigo-500/10 via-purple-500/10 to-indigo-500/10 rounded-3xl p-10 border border-indigo-500/20 backdrop-blur-xl text-center">
              <motion.div
                className="space-y-4"
                animate={{ scale: [1, 1.02, 1] }}
                transition={{ duration: 4, repeat: Infinity }}
              >
                <p className="text-3xl font-semibold bg-gradient-to-r from-indigo-400 via-purple-400 to-indigo-400 text-transparent bg-clip-text leading-relaxed">
                  Join the Signal Economy.<br />
                  Start earning Essence today.<br />
                  Be part of the future.
                </p>
              </motion.div>
            </div>
          </motion.div>

          {/* Navigation */}
          <motion.div variants={itemVariants} className="flex justify-center">
            <Link to="/flash/signal-calculator/for-ai-companies">
              <motion.button
                className="inline-flex items-center bg-gradient-to-r from-indigo-600 to-purple-600 text-white px-8 py-4 rounded-xl text-lg font-semibold shadow-lg shadow-indigo-500/30 hover:shadow-indigo-500/50 transition-all duration-300"
                whileHover={{ scale: 1.05, x: 5 }}
                whileTap={{ scale: 0.95 }}
              >
                <span>Next: For AI Companies & Builders</span>
                <ArrowRight className="w-5 h-5 ml-2" />
              </motion.button>
            </Link>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
} 