import { useState, useEffect } from 'react';
import { scienceQuotes } from '../data/scienceQuotes';

export function useRandomQuote() {
  const [quote, setQuote] = useState('');

  useEffect(() => {
    const randomIndex = Math.floor(Math.random() * scienceQuotes.length);
    setQuote(scienceQuotes[randomIndex]);
  }, []);

  return quote;
}