import React from 'react';
import { motion } from 'framer-motion';
import { Check } from 'lucide-react';

const plans = [
  {
    name: "Free",
    price: "$0",
    period: "/mo",
    description: "Free forever",
    features: [
      "Access to template voices and agents",
      "Basic voice commands and responses",
      "Light/Dark mode interface",
      "Text-based chat functionality",
      "Standard conversation experience",
      "Limited chat history (7 days)",
      "Community support"
    ],
    cta: "Get Started Free",
    popular: false
  },
  {
    name: "Pro",
    price: "$10",
    period: "/mo",
    description: "Monthly billing",
    features: [
      "Everything in Free",
      "Create custom voice agents",
      "Train agents with your voice",
      "Edit agent personalities & behavior",
      "Custom agent colors and visuals",
      "Priority customer support",
      "Advanced voice commands",
      "Unlimited conversation history",
      "Access to Pro Tools",
      "Early access to beta features",
      "Multi-language support",
      "Access to premium AI models"
    ],
    cta: "Get Pro Subscription",
    popular: true
  },
  {
    name: "Expert",
    price: "$99",
    period: "/mo",
    description: "Monthly billing",
    features: [
      "Everything in Pro",
      "Extended camera access (5 hrs/month)",
      "Extended screen sharing (5 hrs/month)",
      "Premium voice models with highest quality",
      "Create your own custom tools",
      "Priority access to new features",
      "Expert Tools access",
      "Personalized agent training sessions"
    ],
    cta: "Request Expert Access",
    popular: false
  },
  {
    name: "Enterprise",
    price: "Custom",
    period: "",
    description: "Contact for pricing",
    features: [
      "Everything in Expert plan",
      "Enterprise-grade AI agent deployment",
      "Organization-wide AI automation",
      "AI agents for key company positions",
      "Dedicated account manager",
      "Custom integration development",
      "Advanced analytics dashboard",
      "24/7 priority support",
      "Custom SLA available"
    ],
    cta: "Contact Enterprise Sales",
    popular: false
  }
];

export function PricingSection() {
  // Function to navigate to the concierge.rolemodel.ai website
  const visitConciergeAI = () => {
    window.open('https://concierge.rolemodel.ai', '_blank');
  };

  return (
    <div className="py-24 bg-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-4xl font-bold mb-6 bg-gradient-to-r from-purple-400 to-pink-600 text-transparent bg-clip-text">
            Choose Your Plan
          </h2>
          <p className="text-xl text-gray-400 max-w-3xl mx-auto">
            Select the subscription that best fits your needs and start creating amazing AI voice agents today.
          </p>
        </motion.div>

        <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
          {plans.map((plan, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className={`relative bg-gradient-to-b ${
                plan.popular ? 'from-purple-900/20 to-pink-900/10' : 'from-gray-900/20 to-gray-900/5'
              } rounded-xl p-6 border ${
                plan.popular ? 'border-purple-500/40' : 'border-gray-700/40'
              } flex flex-col h-full`}
            >
              {plan.popular && (
                <div className="absolute -top-3 left-0 right-0 flex justify-center">
                  <span className="bg-purple-600 text-white text-xs font-semibold px-3 py-1 rounded-full uppercase tracking-wider">
                    Popular
                  </span>
                </div>
              )}
              
              <div className="mb-6">
                <h3 className="text-2xl font-semibold text-white mb-2">{plan.name}</h3>
                <div className="flex items-baseline">
                  <span className="text-4xl font-bold text-white">{plan.price}</span>
                  <span className="text-gray-400 ml-1">{plan.period}</span>
                </div>
                <p className="text-sm text-gray-400 mt-1">{plan.description}</p>
              </div>
              
              <div className="flex-grow space-y-3 mb-6">
                {plan.features.map((feature, i) => (
                  <div key={i} className="flex items-start">
                    <Check className={`w-5 h-5 ${plan.popular ? 'text-purple-400' : 'text-gray-400'} mr-2 flex-shrink-0 mt-0.5`} />
                    <span className="text-gray-300 text-sm">{feature}</span>
                  </div>
                ))}
              </div>
              
              <motion.button
                onClick={visitConciergeAI}
                whileHover={{ scale: 1.03 }}
                whileTap={{ scale: 0.97 }}
                className={`py-3 px-6 rounded-lg text-white font-medium ${
                  plan.popular 
                    ? 'bg-gradient-to-r from-purple-600 to-pink-600 hover:from-purple-700 hover:to-pink-700' 
                    : 'bg-gray-800 hover:bg-gray-700'
                } transition-colors duration-200 w-full`}
              >
                {plan.cta}
              </motion.button>
            </motion.div>
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="mt-16 p-8 rounded-xl bg-gradient-to-r from-purple-900/20 to-purple-900/5 border border-purple-500/20"
        >
          <h3 className="text-2xl font-bold text-white mb-4">Cryptocurrency Payments</h3>
          <p className="text-gray-300 mb-6">We're one of the only AI platforms accepting Trump coin and other cryptocurrencies for subscription payments.</p>
          
          <div className="flex flex-wrap gap-4">
            <div className="bg-gray-800/50 px-6 py-3 rounded-lg flex items-center gap-3">
              <span className="w-8 h-8 rounded-full bg-yellow-500 flex items-center justify-center text-white font-bold text-sm">T</span>
              <div>
                <p className="text-white font-medium">TRUMP Coin</p>
                <p className="text-gray-400 text-sm">Preferred payment method</p>
              </div>
            </div>
            
            <div className="bg-gray-800/50 px-6 py-3 rounded-lg flex items-center gap-3">
              <span className="w-8 h-8 rounded-full bg-blue-500/80 flex items-center justify-center text-white font-bold text-sm">S</span>
              <div>
                <p className="text-white font-medium">Solana (SOL)</p>
                <p className="text-gray-400 text-sm">Fast & low fees</p>
              </div>
            </div>
            
            <div className="bg-gray-800/50 px-6 py-3 rounded-lg flex items-center gap-3">
              <span className="w-8 h-8 rounded-full bg-green-500/80 flex items-center justify-center text-white font-bold text-sm">U</span>
              <div>
                <p className="text-white font-medium">USDC</p>
                <p className="text-gray-400 text-sm">Stable value payments</p>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
} 