import { motion } from 'framer-motion';
import { ArrowLeft, Globe, RefreshCw, Rocket, Coins, Vault, Shield, Ban, Sparkles, Plane, Brain, Lock, Wallet, Bell, Calculator } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function SignalEconomyPage() {
  return (
    <div className="min-h-screen bg-black text-white">
      {/* Background Pattern */}
      <div className="fixed inset-0">
        <motion.div
          className="absolute inset-0"
          animate={{
            backgroundPosition: ['0% 0%', '100% 100%'],
          }}
          transition={{
            duration: 20,
            repeat: Infinity,
            ease: "linear"
          }}
          style={{
            backgroundImage: 'radial-gradient(circle at center, rgba(99, 102, 241, 0.1) 0%, transparent 20%)',
            backgroundSize: '40px 40px',
          }}
        />
      </div>

      <div className="relative max-w-4xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
        {/* Navigation */}
        <div className="mb-12">
          <Link to="/flash">
            <motion.button
              className="flex items-center text-gray-400 hover:text-white transition-colors"
              whileHover={{ x: -5 }}
            >
              <ArrowLeft className="w-5 h-5 mr-2" />
              Back to Flash Kit
            </motion.button>
          </Link>
        </div>

        {/* Header */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <h1 className="text-4xl md:text-5xl font-bold mb-4 bg-gradient-to-r from-indigo-400 via-purple-400 to-indigo-400 text-transparent bg-clip-text">
            The Signal Economy: A New Paradigm for Earning and Exchange
          </h1>
          <p className="text-gray-400">Version 1.0 | April 2025 | SELF Labs Inc.</p>
        </motion.div>

        {/* Content */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
          className="prose prose-invert max-w-none"
        >
          {/* Executive Summary */}
          <section className="mb-16">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <Globe className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-2xl font-bold text-white">🌐 Executive Summary</h2>
            </div>
            <div className="bg-indigo-950/40 rounded-xl p-6 border border-indigo-500/20">
              <p className="text-gray-300 leading-relaxed">
                The Signal Economy is a decentralized, user-first framework designed to replace outdated monetary systems with intelligent, AI-mediated data exchange. In this new paradigm, <span className="text-indigo-400 font-semibold">data becomes currency</span>, and <span className="text-indigo-400 font-semibold">value is created through presence, action, and intention</span>. Users no longer passively give their data to corporations for free — they actively <span className="text-indigo-400 font-semibold">own, control, and monetize their signal</span>.
              </p>
              <p className="text-gray-300 mt-4">
                The SELF Phone and Aura Protocol form the foundational infrastructure of this new economy.
              </p>
            </div>
          </section>

          {/* Why Now? */}
          <section className="mb-16">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <RefreshCw className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-2xl font-bold text-white">🔄 Why Now?</h2>
            </div>
            <div className="bg-indigo-950/40 rounded-xl p-6 border border-indigo-500/20">
              <p className="text-gray-300 mb-6">
                For decades, platforms have extracted value from users in exchange for "free" services. The real currency has always been <span className="text-indigo-400 font-semibold">your data</span>. But in the current system, the value flows one way — toward corporations.
              </p>
              <p className="text-gray-300 mb-6">
                We are flipping that system.
              </p>
              <div className="space-y-4">
                <p className="text-gray-300">The Signal Economy allows everyday users to:</p>
                <ul className="list-disc list-inside space-y-2 text-gray-300">
                  <li>Own their data stream (aka "signal")</li>
                  <li>Decide what to share or lock</li>
                  <li>Get paid in real time for AI-valuable signal input</li>
                  <li>Spend signal in place of cash where accepted</li>
                </ul>
              </div>
            </div>
          </section>

          {/* How It Works */}
          <section className="mb-16">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <Rocket className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-2xl font-bold text-white">🚀 How It Works</h2>
            </div>
            <div className="bg-indigo-950/40 rounded-xl p-6 border border-indigo-500/20">
              <p className="text-gray-300 mb-6">
                Every user creates a "Signal Vault" on the SELF Network via their SELF Phone or flashed device.
              </p>
              <div className="space-y-4">
                <p className="text-gray-300">Within the Aura Shield system, the user can:</p>
                <ul className="list-disc list-inside space-y-2 text-gray-300">
                  <li>Toggle monetization on or off for specific data types (e.g. browsing, location, attention)</li>
                  <li>Review estimated earnings and projected AI value</li>
                  <li>Lock or whitelist agents and partners to access select signals</li>
                  <li>Choose payout frequency or convert earnings into digital or real-world value</li>
                </ul>
              </div>
              <p className="text-gray-300 mt-6">
                Signal is <span className="text-indigo-400 font-semibold">always private first</span>, and <span className="text-indigo-400 font-semibold">monetizable second</span> — never the other way around.
              </p>
            </div>
          </section>

          {/* Data as Currency */}
          <section className="mb-16">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <Coins className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-2xl font-bold text-white">🌎 Data as Currency</h2>
            </div>
            <div className="bg-indigo-950/40 rounded-xl p-6 border border-indigo-500/20">
              <div className="space-y-4">
                <p className="text-gray-300">In the Signal Economy, your signal can be used to:</p>
                <ul className="list-disc list-inside space-y-2 text-gray-300">
                  <li>Pay for goods and services (micro-transactions powered by ESSENCE)</li>
                  <li>Unlock premium AI capabilities</li>
                  <li>Gain governance rights or voting power</li>
                  <li>Access curated experiences, content, or agents</li>
                </ul>
              </div>
              <p className="text-gray-300 mt-6">
                Your data isn't just a product. It's a form of <span className="text-indigo-400 font-semibold">presence-based capital</span>. The more intentional, authentic, and high-quality your signal, the more valuable it becomes.
              </p>
            </div>
          </section>

          {/* The Role of ESSENCE */}
          <section className="mb-16">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <Vault className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-2xl font-bold text-white">🧰 The Role of ESSENCE</h2>
            </div>
            <div className="bg-indigo-950/40 rounded-xl p-6 border border-indigo-500/20">
              <p className="text-gray-300 mb-6">
                ESSENCE is the native reward and utility token of the Signal Economy.
              </p>
              <ul className="list-disc list-inside space-y-2 text-gray-300">
                <li>Earned through data contribution and task delegation</li>
                <li>Staked for access to advanced AI features</li>
                <li>Used for governance in the Aura Protocol</li>
                <li>Swappable into fiat or stable tokens (via connected exchanges)</li>
              </ul>
              <p className="text-gray-300 mt-6">
                Unlike speculative cryptocurrencies, ESSENCE reflects <span className="text-indigo-400 font-semibold">real-world, real-time value generated by human signal</span>.
              </p>
            </div>
          </section>

          {/* Use Cases */}
          <section className="mb-16">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <Brain className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-2xl font-bold text-white">💡 Use Cases</h2>
            </div>
            <div className="bg-indigo-950/40 rounded-xl p-6 border border-indigo-500/20">
              <ul className="list-disc list-inside space-y-2 text-gray-300">
                <li>A user shares anonymized travel behavior and earns $3/day</li>
                <li>Their AI agent performs 25 background tasks, generating microrewards</li>
                <li>The user uses $5 of ESSENCE to unlock an AI-generated marketing suite</li>
                <li>Their data pays for their AI — no cash required</li>
              </ul>
              <p className="text-gray-300 mt-6">
                This is <span className="text-indigo-400 font-semibold">circular value exchange</span>, not extraction.
              </p>
            </div>
          </section>

          {/* Privacy & Consent */}
          <section className="mb-16">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <Lock className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-2xl font-bold text-white">🚧 Privacy & Consent</h2>
            </div>
            <div className="bg-indigo-950/40 rounded-xl p-6 border border-indigo-500/20">
              <ul className="list-disc list-inside space-y-2 text-gray-300">
                <li>All data flows are opt-in</li>
                <li>Signal Vault is user-controlled</li>
                <li>No data is shared without your toggle and signature</li>
                <li>Agents must request access just like apps request permissions</li>
              </ul>
              <p className="text-gray-300 mt-6">
                The economy only works when <span className="text-indigo-400 font-semibold">trust and consent</span> are fundamental.
              </p>
            </div>
          </section>

          {/* The End of Passive Surveillance Capitalism */}
          <section className="mb-16">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <Ban className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-2xl font-bold text-white">🚫 The End of Passive Surveillance Capitalism</h2>
            </div>
            <div className="bg-indigo-950/40 rounded-xl p-6 border border-indigo-500/20">
              <p className="text-gray-300 mb-6">
                We envision a future where phones don't track you to monetize you. Instead:
              </p>
              <blockquote className="border-l-4 border-indigo-500 pl-4 italic text-gray-300">
                Your phone becomes your income. Your AI becomes your proxy. Your signal becomes your voice.
              </blockquote>
              <p className="text-gray-300 mt-6">
                The Signal Economy replaces credit scores with consent. It replaces banking rails with encrypted, sovereign micro-ledgers. It replaces subscriptions with signal streams.
              </p>
            </div>
          </section>

          {/* Future Vision */}
          <section className="mb-16">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <Sparkles className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-2xl font-bold text-white">🌟 Future Vision</h2>
            </div>
            <div className="bg-indigo-950/40 rounded-xl p-6 border border-indigo-500/20">
              <ul className="list-disc list-inside space-y-2 text-gray-300">
                <li>Devices earning for users, not the other way around</li>
                <li>Local economies powered by presence, not fiat</li>
                <li>AI agents trading signals to accomplish tasks</li>
                <li>Human creativity and attention as primary global currency</li>
              </ul>
            </div>
          </section>

          {/* Join the Signal */}
          <section className="mb-16">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <Plane className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-2xl font-bold text-white">✈️ Join the Signal</h2>
            </div>
            <div className="bg-gradient-to-br from-indigo-950/40 to-purple-950/40 rounded-xl p-8 border border-indigo-500/20">
              <p className="text-gray-300 mb-6">
                The Signal Economy begins with your decision to claim your data. Whether you flash your phone or buy a SELF Phone, you're not just getting a device. You're gaining a stake in the next phase of intelligence, economics, and human sovereignty.
              </p>
              <div className="space-y-4">
                <p className="text-xl font-semibold text-indigo-400">
                  Signal is the new currency.
                </p>
                <p className="text-xl font-semibold text-indigo-400">
                  ESSENCE is the reward for owning it.
                </p>
                <p className="text-xl font-semibold text-indigo-400">
                  SELF is the system that makes it possible.
                </p>
              </div>
              <p className="text-gray-400 mt-6">
                — SELF Labs Inc.
              </p>
            </div>
          </section>

          {/* Signal Value Calculator Link */}
          <section className="mb-16">
            <div className="bg-gradient-to-br from-indigo-950/40 to-purple-950/40 rounded-xl p-8 border border-indigo-500/20 text-center">
              <h2 className="text-2xl font-bold text-white mb-4">Calculate Your Signal Value</h2>
              <p className="text-gray-300 mb-6">
                Discover how your presence and resonance translate into real-world value. Our Signal Value Calculator shows you the tangible impact of your data in the new economy.
              </p>
              <Link 
                to="/flash/signal-calculator"
                className="inline-flex items-center justify-center gap-2 bg-gradient-to-r from-indigo-600 to-purple-600 text-white px-6 py-3 rounded-xl text-lg font-semibold shadow-lg shadow-indigo-500/30 hover:shadow-indigo-500/50 transition-all duration-300"
              >
                <Calculator className="w-5 h-5" />
                Try the Calculator
              </Link>
            </div>
          </section>
        </motion.div>
      </div>
    </div>
  );
} 