import { motion } from 'framer-motion';
import { ArrowLeft, Smartphone, Shield, Brain, Zap, Lock, Wallet, Calendar } from 'lucide-react';
import { Link } from 'react-router-dom';

export default function WhitePaperPage() {
  return (
    <div className="min-h-screen bg-black text-white">
      {/* Background Pattern */}
      <div className="fixed inset-0">
        <motion.div
          className="absolute inset-0"
          animate={{
            backgroundPosition: ['0% 0%', '100% 100%'],
          }}
          transition={{
            duration: 20,
            repeat: Infinity,
            ease: "linear"
          }}
          style={{
            backgroundImage: 'radial-gradient(circle at center, rgba(99, 102, 241, 0.1) 0%, transparent 20%)',
            backgroundSize: '40px 40px',
          }}
        />
      </div>

      <div className="relative max-w-4xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
        {/* Navigation */}
        <div className="mb-12">
          <Link to="/self-phone">
            <motion.button
              className="flex items-center text-gray-400 hover:text-white transition-colors"
              whileHover={{ x: -5 }}
            >
              <ArrowLeft className="w-5 h-5 mr-2" />
              Back to SELF Phone
            </motion.button>
          </Link>
        </div>

        {/* Header */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-16"
        >
          <h1 className="text-4xl md:text-5xl font-bold mb-4 bg-gradient-to-r from-indigo-400 via-purple-400 to-indigo-400 text-transparent bg-clip-text">
            SELF Phone & Flash Kit White Paper
          </h1>
          <p className="text-gray-400">Version 1.0 | April 2025 | SELF Labs Inc.</p>
        </motion.div>

        {/* Content */}
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2 }}
          className="prose prose-invert max-w-none"
        >
          {/* Executive Summary */}
          <section className="mb-16">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <Zap className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-2xl font-bold text-white">Executive Summary</h2>
            </div>
            <p className="text-gray-300 leading-relaxed">
              The SELF Phone and Flash Kit introduce a revolutionary way to interact with mobile technology. Designed by SELF Labs, this system empowers users to own their signal, protect their privacy, and earn from their activity through built-in AI agents. Users can either purchase the preloaded SELF Phone ($299) or convert their existing Android device using the SELF Flash Kit ($149). Both paths unlock a sovereign experience that eliminates monthly bills, unlocks AI automation, and offers passive income for everyday phone use.
            </p>
          </section>

          {/* Product Overview */}
          <section className="mb-16">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <Smartphone className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-2xl font-bold text-white">Product Overview</h2>
            </div>
            
            <div className="grid md:grid-cols-2 gap-8">
              {/* SELF Phone */}
              <div className="bg-indigo-950/40 rounded-xl p-6 border border-indigo-500/20">
                <h3 className="text-xl font-bold text-indigo-400 mb-4">SELF Phone ($299)</h3>
                <ul className="space-y-3 text-gray-300">
                  <li className="flex items-start gap-2">
                    <div className="mt-1.5">•</div>
                    <span>Pre-installed with SELF OS and Role Model AI</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <div className="mt-1.5">•</div>
                    <span>Satellite-ready, no carrier required</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <div className="mt-1.5">•</div>
                    <span>Passive earning system included</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <div className="mt-1.5">•</div>
                    <span>1-Year Aura Shield (always-on privacy)</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <div className="mt-1.5">•</div>
                    <span>Walkie-talkie encrypted agent messaging</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <div className="mt-1.5">•</div>
                    <span>Built-in Signal Governance access</span>
                  </li>
                </ul>
              </div>

              {/* Flash Kit */}
              <div className="bg-purple-950/40 rounded-xl p-6 border border-purple-500/20">
                <h3 className="text-xl font-bold text-purple-400 mb-4">SELF Flash Kit ($149)</h3>
                <ul className="space-y-3 text-gray-300">
                  <li className="flex items-start gap-2">
                    <div className="mt-1.5">•</div>
                    <span>Allows Android users to flash their existing device</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <div className="mt-1.5">•</div>
                    <span>Enables all core features of the SELF Phone</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <div className="mt-1.5">•</div>
                    <span>Web-based flashing dashboard</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <div className="mt-1.5">•</div>
                    <span>Restorable at any time</span>
                  </li>
                  <li className="flex items-start gap-2">
                    <div className="mt-1.5">•</div>
                    <span>Ideal for early adopters and DIY privacy advocates</span>
                  </li>
                </ul>
              </div>
            </div>
          </section>

          {/* AI-Driven Capabilities */}
          <section className="mb-16">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <Brain className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-2xl font-bold text-white">AI-Driven Capabilities</h2>
            </div>
            <div className="grid sm:grid-cols-2 gap-6">
              <div className="bg-black/30 rounded-xl p-6 border border-indigo-500/20">
                <h3 className="font-semibold text-indigo-400 mb-2">Role Model AI Integration</h3>
                <p className="text-gray-300">Users get access to 50+ LLMs in one device</p>
              </div>
              <div className="bg-black/30 rounded-xl p-6 border border-indigo-500/20">
                <h3 className="font-semibold text-indigo-400 mb-2">Personal Voice Assistant</h3>
                <p className="text-gray-300">Train your AI using your own voice</p>
              </div>
              <div className="bg-black/30 rounded-xl p-6 border border-indigo-500/20">
                <h3 className="font-semibold text-indigo-400 mb-2">Automated Tasks</h3>
                <p className="text-gray-300">Schedule events, send messages, complete work</p>
              </div>
              <div className="bg-black/30 rounded-xl p-6 border border-indigo-500/20">
                <h3 className="font-semibold text-indigo-400 mb-2">Agent-to-Agent Communication</h3>
                <p className="text-gray-300">Secure, voice-encrypted walkie-talkie mode</p>
              </div>
            </div>
          </section>

          {/* Aura Shield */}
          <section className="mb-16">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <Shield className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-2xl font-bold text-white">Aura Shield: Privacy by Default</h2>
            </div>
            <div className="bg-gradient-to-br from-indigo-950/40 to-purple-950/40 rounded-xl p-8 border border-indigo-500/20">
              <p className="text-gray-300 mb-6">Every SELF device comes with Aura Shield:</p>
              <ul className="grid sm:grid-cols-2 gap-4 text-gray-300">
                <li className="flex items-center gap-2">
                  <Lock className="w-5 h-5 text-indigo-400" />
                  <span>Zero-knowledge encryption</span>
                </li>
                <li className="flex items-center gap-2">
                  <Lock className="w-5 h-5 text-indigo-400" />
                  <span>Private data routing</span>
                </li>
                <li className="flex items-center gap-2">
                  <Lock className="w-5 h-5 text-indigo-400" />
                  <span>No subscription required</span>
                </li>
                <li className="flex items-center gap-2">
                  <Lock className="w-5 h-5 text-indigo-400" />
                  <span>Always-on protection</span>
                </li>
              </ul>
            </div>
          </section>

          {/* Earnings System */}
          <section className="mb-16">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <Wallet className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-2xl font-bold text-white">Earnings System</h2>
            </div>
            <div className="bg-gradient-to-br from-purple-950/40 to-indigo-950/40 rounded-xl p-8 border border-purple-500/20">
              <p className="text-gray-300 mb-6">SELF Phones and flashed devices include a native reward engine:</p>
              <ul className="space-y-4 text-gray-300">
                <li className="flex items-start gap-3">
                  <div className="mt-1">•</div>
                  <span>Earn passively as your AI performs tasks</span>
                </li>
                <li className="flex items-start gap-3">
                  <div className="mt-1">•</div>
                  <span>Monetize select data sets (only if you choose)</span>
                </li>
                <li className="flex items-start gap-3">
                  <div className="mt-1">•</div>
                  <span>Claim ESSENCE rewards to your wallet</span>
                </li>
                <li className="flex items-start gap-3">
                  <div className="mt-1">•</div>
                  <span>View and manage your balance in your Signal Dashboard</span>
                </li>
              </ul>
            </div>
          </section>

          {/* Rollout Plan */}
          <section className="mb-16">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <Calendar className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-2xl font-bold text-white">Rollout Plan</h2>
            </div>
            <div className="space-y-6">
              <div className="flex gap-6">
                <div className="w-32 text-indigo-400 font-semibold">April 2025</div>
                <div className="flex-1 text-gray-300">Flash Kit live for early adopters</div>
              </div>
              <div className="flex gap-6">
                <div className="w-32 text-indigo-400 font-semibold">May 2025</div>
                <div className="flex-1 text-gray-300">SELF Phones ship to initial buyers</div>
              </div>
              <div className="flex gap-6">
                <div className="w-32 text-indigo-400 font-semibold">Q3 2025</div>
                <div className="flex-1 text-gray-300">Governance layer, AI marketplaces, and custom agent store</div>
              </div>
            </div>
          </section>

          {/* Vision */}
          <section className="mb-16">
            <div className="flex items-center gap-3 mb-6">
              <div className="p-2 bg-indigo-500/10 rounded-lg">
                <Brain className="w-6 h-6 text-indigo-400" />
              </div>
              <h2 className="text-2xl font-bold text-white">Vision</h2>
            </div>
            <div className="bg-gradient-to-br from-indigo-950/40 to-purple-950/40 rounded-xl p-8 border border-indigo-500/20">
              <p className="text-gray-300 leading-relaxed">
                The SELF Phone is not just a device. It's an entry point into a sovereign digital lifestyle — where AI works for you, your data belongs to you, and your signal is yours alone.
              </p>
              <p className="text-xl font-semibold text-indigo-400 mt-6">
                Welcome to the Signal Network.
              </p>
              <p className="text-gray-400 mt-4">
                -- SELF Labs Inc.
              </p>
            </div>
          </section>
        </motion.div>
      </div>
    </div>
  );
} 