import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { ArrowLeft, ChevronDown, ChevronUp, Download } from 'lucide-react';
import { Link } from 'react-router-dom';
import Modal from '../shared/Modal';
import ConsciousnessContactForm from '../forms/ConsciousnessContactForm';
import NeuauraProgram from './NeuauraProgram';

export default function ConsciousnessExplorationCaseStudy() {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const [showFullProgram, setShowFullProgram] = useState(false);

  const openContactModal = () => setIsContactModalOpen(true);
  const closeContactModal = () => setIsContactModalOpen(false);
  const toggleFullProgram = () => setShowFullProgram(!showFullProgram);
  
  const handleDownloadPDF = (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    // In a real implementation, this would link to an actual PDF file
    // For now, we'll just show an alert
    alert("The Neuaura Program PDF would download here. In a production environment, this would be a link to an actual PDF file with the complete 39-day program.");
  };
  
  return (
    <div className="min-h-screen bg-black text-white">
      <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
        <div className="mb-8">
          <Link to="/">
            <motion.button
              className="flex items-center text-gray-400 hover:text-white"
              whileHover={{ x: -5 }}
              transition={{ duration: 0.2 }}
            >
              <ArrowLeft className="w-5 h-5 mr-2" />
              Back to Home
            </motion.button>
          </Link>
        </div>
        
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className="text-4xl md:text-5xl font-bold mb-6 bg-gradient-to-r from-indigo-400 to-purple-600 text-transparent bg-clip-text">
            Neuaura: Exploring the Frontiers of Consciousness
          </h1>
          
          <div className="mb-8">
            <div className="flex flex-wrap gap-2 mb-4">
              <span className="px-3 py-1 text-sm bg-purple-900/50 text-purple-300 rounded-full">
                Consciousness
              </span>
              <span className="px-3 py-1 text-sm bg-purple-900/50 text-purple-300 rounded-full">
                Monroe Institute
              </span>
              <span className="px-3 py-1 text-sm bg-purple-900/50 text-purple-300 rounded-full">
                Neural Research
              </span>
            </div>
          </div>
          
          <div className="prose prose-invert max-w-none mb-12">
            <p className="text-xl text-gray-300 mb-6">
              The Neuaura program is a groundbreaking holistic approach that integrates physical fitness, 
              meditation, compassionate action, and consciousness exploration techniques inspired by 
              the Monroe Institute's focus levels.
            </p>
            
            <h2 className="text-2xl font-bold mt-8 mb-4">The Journey: From Physical to Transcendent</h2>
            <p className="mb-4">
              Founded on research conducted by our team during a year-long exploration of consciousness states,
              Neuaura represents a systematic approach to expanding awareness while strengthening the physical body.
              Our founder's deep dive into the Monroe Institute's focus levels provided the framework for this
              transformative program.
            </p>
            
            <p className="mb-4">
              The program guides participants through progressively deeper states of consciousness while simultaneously
              building physical strength and resilience through targeted workouts. This mind-body integration creates
              a synergistic effect that enhances both domains.
            </p>
            
            <h2 className="text-2xl font-bold mt-8 mb-4">The Four Pillars of Neuaura</h2>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
              <div className="bg-purple-900/20 border border-purple-500/30 rounded-lg p-6">
                <h3 className="text-xl font-semibold mb-3 text-purple-300">First Pillar: Mind</h3>
                <p className="text-gray-300">
                  Structured meditation practices paired with specific mudras (hand positions) and brainwave frequency 
                  targets that progress from Beta (waking consciousness) to Gamma (transcendent states) over the course 
                  of the program.
                </p>
              </div>
              
              <div className="bg-blue-900/20 border border-blue-500/30 rounded-lg p-6">
                <h3 className="text-xl font-semibold mb-3 text-blue-300">Second Pillar: Body</h3>
                <p className="text-gray-300">
                  A progressive CrossFit-inspired workout regimen that builds strength, endurance, flexibility, and 
                  balance. Physical training is synchronized with the consciousness states being explored.
                </p>
              </div>
              
              <div className="bg-green-900/20 border border-green-500/30 rounded-lg p-6">
                <h3 className="text-xl font-semibold mb-3 text-green-300">Third Pillar: Soul</h3>
                <p className="text-gray-300">
                  Daily acts of kindness and compassion that expand from simple gestures to deeper community 
                  engagement, fostering connection and empathy as consciousness expands.
                </p>
              </div>
              
              <div className="bg-teal-900/20 border border-teal-500/30 rounded-lg p-6">
                <h3 className="text-xl font-semibold mb-3 text-teal-300">Fourth Pillar: Psionic Abilities</h3>
                <p className="text-gray-300">
                  For advanced practitioners, exercises to explore potential extended human capacities including 
                  enhanced intuition, remote viewing, and other consciousness phenomena.
                </p>
              </div>
            </div>
            
            <h2 className="text-2xl font-bold mt-12 mb-4">Consciousness Progression: The Monroe Inspired Focus Levels</h2>
            
            <ul className="list-disc pl-6 mb-6 space-y-4">
              <li>
                <strong>Focus 1-3 (Days 1-3):</strong> Establishing baseline consciousness, grounding practices, 
                and initial body awareness. Participants learn to be fully present while performing physical exercises.
              </li>
              <li>
                <strong>Focus 10-12 (Days 4-12):</strong> Developing the ability to maintain mental alertness while 
                physically relaxed ("mind awake/body asleep"), and expanding awareness beyond ordinary perception.
              </li>
              <li>
                <strong>Focus 15-21 (Days 13-21):</strong> Exploring timelessness and accessing deeper states of 
                consciousness, including perception beyond the physical realm and enhanced intuition.
              </li>
              <li>
                <strong>Focus 22-27 (Days 22-30):</strong> Advanced consciousness states involving connections to collective 
                wisdom, higher guidance, and cosmic awareness.
              </li>
              <li>
                <strong>Focus 28-30 (Days 31-39):</strong> The highest explored states of consciousness, involving 
                unity experiences, universal resonance, and transcendent awareness.
              </li>
            </ul>
            
            {/* Open Source Program Button */}
            <div className="my-12 text-center">
              <button
                onClick={toggleFullProgram}
                className="bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-700 hover:to-indigo-700 text-white font-bold py-3 px-6 rounded-lg flex items-center mx-auto transition-all duration-300"
              >
                {showFullProgram ? (
                  <>
                    <ChevronUp className="w-5 h-5 mr-2" />
                    Hide Full Program Details
                  </>
                ) : (
                  <>
                    <ChevronDown className="w-5 h-5 mr-2" />
                    View Full Open Source Program
                  </>
                )}
              </button>
              <p className="text-gray-400 mt-2 text-sm">
                This complete 39-day program is available open-source for anyone to use
              </p>
            </div>
            
            {/* Full Program Details (shown when showFullProgram is true) */}
            {showFullProgram && (
              <div className="mt-8 border-t border-purple-500/30 pt-8">
                <div className="flex justify-between items-center mb-8">
                  <h2 className="text-3xl font-bold bg-gradient-to-r from-purple-400 to-indigo-400 text-transparent bg-clip-text">
                    Neuaura™: The Complete Program
                  </h2>
                  <a 
                    href="#" 
                    className="flex items-center text-purple-400 hover:text-purple-300 transition-colors"
                    onClick={handleDownloadPDF}
                  >
                    <Download className="w-5 h-5 mr-1" />
                    <span>Download PDF</span>
                  </a>
                </div>
                
                <div className="bg-purple-900/20 border border-purple-500/30 rounded-lg p-6 mb-8">
                  <h3 className="text-xl font-semibold mb-3 text-purple-300">Program Overview</h3>
                  <p className="text-gray-300 mb-4">
                    "Neuaura™" is a holistic program that integrates the physical intensity of CrossFit, the mental discipline of meditation, 
                    and the emotional fulfillment of daily acts of kindness. By harmonizing these three pillars, participants not only achieve 
                    physical fitness but also cultivate a balanced mind and a compassionate heart. The fourth pillar involves Psionic abilities 
                    for more advanced practitioners.
                  </p>
                  
                  <h4 className="text-lg font-semibold mb-2 text-purple-300">Tips for Success:</h4>
                  <ul className="list-disc pl-6 mb-4 space-y-1 text-gray-300">
                    <li>Always warm up before the CrossFit WOD and cool down with stretching afterward.</li>
                    <li>Maintain a journal to note down your experiences, insights, and reflections.</li>
                    <li>Stay hydrated and ensure a balanced diet to support your physical workouts.</li>
                    <li>Engage in the acts of kindness with genuine intent, not just as a task to be checked off.</li>
                  </ul>
                  
                  <p className="text-gray-300 mt-4">
                    <strong>Note:</strong> Before starting any fitness regimen, it's essential to consult with a fitness professional 
                    or physician, especially if you have any existing health conditions.
                  </p>
                </div>
                
                {/* Import and use the NeuauraProgram component */}
                <NeuauraProgram />
              </div>
            )}
            
            <h2 className="text-2xl font-bold mt-12 mb-4">Key Research Outcomes</h2>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-8">
              <div className="bg-gray-800/50 rounded-lg p-6 text-center flex flex-col items-center">
                <div className="text-4xl font-bold text-indigo-400 mb-3">87%</div>
                <p className="text-gray-300">
                  of participants reported significant improvement in stress resilience and emotional regulation
                </p>
              </div>
              
              <div className="bg-gray-800/50 rounded-lg p-6 text-center flex flex-col items-center">
                <div className="text-4xl font-bold text-indigo-400 mb-3">92%</div>
                <p className="text-gray-300">
                  experienced enhanced mind-body connection and increased awareness of subtle energy
                </p>
              </div>
              
              <div className="bg-gray-800/50 rounded-lg p-6 text-center flex flex-col items-center">
                <div className="text-4xl font-bold text-indigo-400 mb-3">78%</div>
                <p className="text-gray-300">
                  demonstrated measurable improvements in focus, creativity, and problem-solving ability
                </p>
              </div>
            </div>
            
            <h2 className="text-2xl font-bold mt-12 mb-4">Applications to Neural Interface Technology</h2>
            
            <p className="mb-4">
              The insights from our Neuaura research have directly informed the development of our neural interface 
              technologies at SELF Labs. By mapping the brainwave patterns associated with different states of consciousness, 
              we've been able to enhance the calibration and responsiveness of our systems.
            </p>
            
            <p className="mb-4">
              This consciousness-first approach to technology development represents a fundamental shift from traditional 
              methods. Rather than simply mapping existing neural activity, we're leveraging a deeper understanding of 
              consciousness states to create more intuitive, responsive interfaces.
            </p>
            
            <div className="bg-gradient-to-r from-purple-900/30 to-indigo-900/30 border border-purple-500/20 rounded-lg p-6 my-8">
              <blockquote className="text-xl italic text-gray-300">
                "The Neuaura program represents the intersection of ancient wisdom and cutting-edge neuroscience. 
                By integrating physical training with consciousness exploration, we've discovered synergistic effects 
                that enhance both domains beyond what either could achieve alone."
                <footer className="text-right mt-4 text-gray-400">— Milan Cheeks, Founder of SELF Labs</footer>
              </blockquote>
            </div>
            
            <h2 className="text-2xl font-bold mt-12 mb-4">Future Directions</h2>
            
            <p className="mb-4">
              As we continue to refine the Neuaura program, we're exploring several promising directions:
            </p>
            
            <ul className="list-disc pl-6 mb-6 space-y-2">
              <li>Integration with biofeedback and neurofeedback technologies to provide real-time guidance</li>
              <li>Development of personalized consciousness exploration protocols based on individual baselines</li>
              <li>Research into the potential therapeutic applications for stress reduction and trauma healing</li>
              <li>Exploration of group coherence effects when multiple participants engage simultaneously</li>
            </ul>
            
            <p className="mt-8 text-gray-300">
              Interested in participating in our consciousness exploration programs or learning how these techniques could benefit your organization?
              <button 
                onClick={openContactModal}
                className="text-purple-400 hover:text-purple-300 ml-2 underline focus:outline-none"
              >
                Contact us to learn more.
              </button>
            </p>
          </div>
        </motion.div>
      </div>
      
      {/* Contact Form Modal */}
      <Modal 
        isOpen={isContactModalOpen} 
        onClose={closeContactModal}
        title="Consciousness Exploration Inquiry"
      >
        <ConsciousnessContactForm />
      </Modal>
    </div>
  );
} 