import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Newspaper } from 'lucide-react';
import { supabase } from '../../../utils/supabase';

export function CompanyUpdates() {
  const [updates, setUpdates] = useState<any[]>([]);

  useEffect(() => {
    fetchUpdates();
  }, []);

  async function fetchUpdates() {
    try {
      const { data, error } = await supabase
        .from('company_updates')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setUpdates(data || []);
    } catch (error) {
      console.error('Error fetching updates:', error);
    }
  }

  return (
    <div className="bg-[#0A0A0B] border border-white/10 rounded-xl p-6">
      <h2 className="text-xl font-semibold text-white mb-6">Company Updates</h2>
      
      <div className="space-y-4">
        {updates.map((update, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: index * 0.1 }}
            className="p-4 bg-white/5 rounded-lg"
          >
            <div className="flex items-start space-x-4">
              <Newspaper className="w-5 h-5 text-[#FF3366] mt-1" />
              <div>
                <div className="text-white font-medium">{update.title}</div>
                <div className="text-sm text-gray-400 mt-1">{update.date}</div>
                <p className="text-sm text-gray-300 mt-2">{update.description}</p>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
}