import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import { Brain, DollarSign, Database, Sparkles, Coins, Bot, ArrowRight, ChevronDown } from 'lucide-react';

export default function SignalCalculatorPage() {
  const [hoveredCard, setHoveredCard] = useState<string | null>(null);
  const [scrollProgress, setScrollProgress] = useState(0);
  const controls = useAnimation();

  // Handle scroll progress
  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight - windowHeight;
      const scrolled = window.scrollY;
      const progress = (scrolled / documentHeight) * 100;
      setScrollProgress(progress);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const sections = [
    {
      id: 'what-is-this',
      title: 'What Is This?',
      description: 'Learn how your daily activities generate valuable signal for AI training.',
      icon: <Brain className="w-8 h-8" />,
      gradient: 'from-blue-500 to-indigo-500',
      path: '/flash/signal-calculator/what-is-this'
    },
    {
      id: 'why-data-money',
      title: 'Data: The Missing Asset Class',
      description: 'Discover how your digital footprint becomes a valuable asset class alongside traditional currency.',
      icon: <Brain className="w-8 h-8" />,
      gradient: 'from-indigo-500 to-purple-500',
      path: '/flash/signal-calculator/why-data-can-replace-money'
    },
    {
      id: 'why-kilobyte',
      title: 'Why a Kilobyte Can Be Priceless',
      description: 'See how different amounts of signal data create value for AI models.',
      icon: <Database className="w-8 h-8" />,
      gradient: 'from-purple-500 to-pink-500',
      path: '/flash/signal-calculator/why-kilobyte-priceless'
    },
    {
      id: 'how-it-works',
      title: 'How the Signal Economy Works',
      description: 'Explore the flow of value in the signal economy and how Essence is created.',
      icon: <Sparkles className="w-8 h-8" />,
      gradient: 'from-pink-500 to-rose-500',
      path: '/flash/signal-calculator/how-signal-economy-works'
    },
    {
      id: 'why-essence',
      title: 'Essence & Dollars: A New Value Paradigm',
      description: 'Discover how Essence complements traditional currency with new value creation.',
      icon: <Coins className="w-8 h-8" />,
      gradient: 'from-rose-500 to-orange-500',
      path: '/flash/signal-calculator/why-essence-dollars'
    },
    {
      id: 'for-ai-companies',
      title: 'For AI Companies & Builders',
      description: 'Learn how to access structured, consent-based data for your AI models.',
      icon: <Bot className="w-8 h-8" />,
      gradient: 'from-orange-500 to-yellow-500',
      path: '/flash/signal-calculator/for-ai-companies'
    }
  ];

  const floatingParticles = Array(20).fill(null).map((_, i) => ({
    id: i,
    size: Math.random() * 4 + 2,
    duration: Math.random() * 20 + 10,
    delay: Math.random() * 5,
  }));

  return (
    <div className="min-h-screen bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-indigo-900/20 via-black to-black text-white py-20 relative overflow-hidden">
      {/* Floating Particles */}
      {floatingParticles.map((particle) => (
        <motion.div
          key={particle.id}
          className="absolute bg-indigo-500/20 rounded-full pointer-events-none"
          style={{
            width: particle.size,
            height: particle.size,
            left: `${Math.random() * 100}%`,
            top: `-${particle.size}px`,
          }}
          animate={{
            y: ['0vh', '100vh'],
            opacity: [0, 1, 0],
          }}
          transition={{
            duration: particle.duration,
            repeat: Infinity,
            delay: particle.delay,
            ease: 'linear',
          }}
        />
      ))}

      {/* Progress Bar */}
      <motion.div
        className="fixed top-0 left-0 right-0 h-1 bg-gradient-to-r from-indigo-500 to-purple-500 z-50"
        style={{ scaleX: scrollProgress / 100, transformOrigin: '0%' }}
      />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        {/* Decorative Elements */}
        <div className="absolute top-0 left-1/2 -translate-x-1/2 w-3/4 h-1/2 bg-indigo-500/10 blur-[120px] rounded-full pointer-events-none animate-pulse" />
        <div className="absolute bottom-0 left-1/2 -translate-x-1/2 w-3/4 h-1/2 bg-purple-500/10 blur-[120px] rounded-full pointer-events-none animate-pulse" />

        {/* Header */}
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center mb-20"
        >
          <motion.h1 
            className="text-6xl font-bold mb-6 bg-gradient-to-r from-indigo-400 via-purple-400 to-indigo-400 text-transparent bg-clip-text"
            initial={{ scale: 0.95 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.5 }}
          >
            Welcome to the Signal Economy
          </motion.h1>
          <motion.p 
            className="text-xl text-gray-400 max-w-3xl mx-auto mb-8"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
          >
            Discover how your daily activities generate valuable signal, and learn how to
            earn Essence — the first currency backed by human signal.
          </motion.p>

          {/* Scroll Indicator */}
          <motion.div
            className="flex flex-col items-center mt-12"
            animate={{ y: [0, 10, 0] }}
            transition={{ duration: 2, repeat: Infinity }}
          >
            <span className="text-sm text-gray-400 mb-2">Scroll to explore</span>
            <ChevronDown className="w-6 h-6 text-indigo-400" />
          </motion.div>
        </motion.div>

        {/* Navigation Grid */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {sections.map((section, index) => (
            <Link key={section.id} to={section.path}>
              <motion.div
                className={`group relative h-full bg-black/40 backdrop-blur-xl rounded-3xl p-8 border border-indigo-500/20 overflow-hidden transition-all duration-500
                  ${hoveredCard === section.id ? 'border-indigo-400/50 shadow-lg shadow-indigo-500/20' : 'border-indigo-500/20'}
                `}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: index * 0.1 }}
                onMouseEnter={() => setHoveredCard(section.id)}
                onMouseLeave={() => setHoveredCard(null)}
                whileHover={{ y: -5, scale: 1.02 }}
              >
                {/* Gradient Overlay */}
                <div className={`absolute inset-0 bg-gradient-to-br ${section.gradient} opacity-0 group-hover:opacity-10 transition-opacity duration-500`} />

                {/* Animated Corner Borders */}
                <div className="absolute inset-0">
                  <div className={`absolute top-0 left-0 w-8 h-8 border-t-2 border-l-2 rounded-tl-3xl transition-all duration-300 opacity-0 group-hover:opacity-100 ${hoveredCard === section.id ? 'border-indigo-400' : 'border-indigo-500/50'}`} />
                  <div className={`absolute top-0 right-0 w-8 h-8 border-t-2 border-r-2 rounded-tr-3xl transition-all duration-300 opacity-0 group-hover:opacity-100 ${hoveredCard === section.id ? 'border-indigo-400' : 'border-indigo-500/50'}`} />
                  <div className={`absolute bottom-0 left-0 w-8 h-8 border-b-2 border-l-2 rounded-bl-3xl transition-all duration-300 opacity-0 group-hover:opacity-100 ${hoveredCard === section.id ? 'border-indigo-400' : 'border-indigo-500/50'}`} />
                  <div className={`absolute bottom-0 right-0 w-8 h-8 border-b-2 border-r-2 rounded-br-3xl transition-all duration-300 opacity-0 group-hover:opacity-100 ${hoveredCard === section.id ? 'border-indigo-400' : 'border-indigo-500/50'}`} />
                </div>

                <div className="relative z-10">
                  <motion.div 
                    className={`inline-flex p-3 rounded-2xl bg-gradient-to-br ${section.gradient} bg-opacity-20 mb-6`}
                    whileHover={{ rotate: [0, -10, 10, 0] }}
                    transition={{ duration: 0.5 }}
                  >
                    <div className="text-white">
                      {section.icon}
                    </div>
                  </motion.div>
                  
                  <h2 className="text-2xl font-semibold mb-4 bg-gradient-to-r from-white to-gray-300 text-transparent bg-clip-text">
                    {section.title}
                  </h2>
                  
                  <p className="text-gray-400 mb-6 group-hover:text-gray-300 transition-colors">
                    {section.description}
                  </p>

                  <motion.div
                    className="inline-flex items-center text-indigo-400 group-hover:text-indigo-300"
                    animate={hoveredCard === section.id ? { x: 5 } : { x: 0 }}
                  >
                    Explore <ArrowRight className="w-4 h-4 ml-2" />
                  </motion.div>
                </div>
              </motion.div>
            </Link>
          ))}
        </div>

        {/* Final CTA */}
        <motion.div
          className="mt-20 text-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.6 }}
        >
          <Link to="/flash/services">
            <motion.button
              className="inline-flex items-center bg-gradient-to-r from-indigo-600 to-purple-600 text-white px-10 py-5 rounded-2xl text-lg font-semibold shadow-xl shadow-indigo-500/30 hover:shadow-indigo-500/50 transition-all duration-300 backdrop-blur-xl relative overflow-hidden group"
              whileHover={{ scale: 1.05, y: -2 }}
              whileTap={{ scale: 0.95 }}
            >
              {/* Button Shine Effect */}
              <div className="absolute inset-0 w-1/4 h-full bg-white/20 skew-x-[25deg] -translate-x-full group-hover:translate-x-[400%] transition-transform duration-1000" />
              
              <span className="relative z-10">View All Essence-Powered Services</span>
              <motion.div
                className="relative z-10 ml-3"
                animate={{ x: [0, 5, 0] }}
                transition={{ repeat: Infinity, duration: 1.5 }}
              >
                <ArrowRight className="w-5 h-5" />
              </motion.div>
            </motion.button>
          </Link>
        </motion.div>
      </div>
    </div>
  );
} 