import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Wallet, FileText, Vote, Network, ArrowRight, CheckCircle, XCircle, AlertCircle } from 'lucide-react';
import { Link } from 'react-router-dom';
import { useWallet } from '../../hooks/useWallet';

export default function DAOAnnouncementPage() {
  const { wallet, loading, error: walletError, connect, disconnect } = useWallet();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [showWalletModal, setShowWalletModal] = useState(false);

  // Handle connect wallet
  const handleConnectWallet = async () => {
    setError('');
    setIsLoading(true);
    
    try {
      await connect();
      setShowWalletModal(true);
    } catch (err: any) {
      console.error("Connect wallet error:", err);
      setError(err.message || 'Failed to connect wallet');
    } finally {
      setIsLoading(false);
    }
  };

  // Set error message if there's an error from the wallet hook
  useEffect(() => {
    if (walletError) {
      setError(walletError);
    }
  }, [walletError]);

  // Helper function to truncate wallet address for display
  const formatWalletAddress = (address: string) => {
    if (address.length <= 12) return address;
    return `${address.substring(0, 6)}...${address.substring(address.length - 4)}`;
  };

  return (
    <div className="min-h-screen bg-black text-white overflow-hidden">
      {/* Sacred geometry background pattern */}
      <div className="fixed inset-0 z-0 opacity-10">
        <div className="absolute inset-0 bg-[radial-gradient(circle,_#8b5cf6_1px,_transparent_1px)] bg-[length:30px_30px]"></div>
        <svg className="absolute top-0 left-0 w-full h-full opacity-20" viewBox="0 0 100 100" preserveAspectRatio="none">
          <defs>
            <pattern id="grid" width="10" height="10" patternUnits="userSpaceOnUse">
              <path d="M 10 0 L 0 0 0 10" fill="none" stroke="rgba(139, 92, 246, 0.5)" strokeWidth="0.5"/>
            </pattern>
          </defs>
          <rect width="100%" height="100%" fill="url(#grid)" />
        </svg>
      </div>

      <div className="relative z-10 max-w-6xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
        {/* Hero Section */}
        <motion.div 
          className="text-center mb-16"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8 }}
        >
          <div className="flex items-center justify-center mb-4">
            <motion.div 
              className="w-16 h-16 rounded-full bg-gradient-to-r from-purple-600 to-indigo-600 flex items-center justify-center"
              animate={{ 
                boxShadow: ['0 0 0 rgba(139, 92, 246, 0.4)', '0 0 20px rgba(139, 92, 246, 0.7)', '0 0 0 rgba(139, 92, 246, 0.4)'] 
              }}
              transition={{ duration: 3, repeat: Infinity }}
            >
              <svg width="32" height="32" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="white" strokeWidth="2"/>
                <path d="M12 18C15.3137 18 18 15.3137 18 12C18 8.68629 15.3137 6 12 6C8.68629 6 6 8.68629 6 12C6 15.3137 8.68629 18 12 18Z" stroke="white" strokeWidth="2"/>
                <path d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z" fill="white"/>
              </svg>
            </motion.div>
          </div>
          
          <h1 className="text-4xl md:text-6xl font-bold mb-4 bg-gradient-to-r from-purple-400 via-indigo-500 to-blue-500 text-transparent bg-clip-text">
            Welcome to the SELF Labs DAO LLC
          </h1>
          
          <p className="text-xl md:text-2xl text-gray-300 max-w-3xl mx-auto">
            A Conscious Organization Powered by Signal, Governance, and Collective Evolution
          </p>
          
          <div className="mt-8 max-w-2xl mx-auto">
            <motion.p 
              className="text-lg text-gray-400"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.5, duration: 1 }}
            >
              SELF Labs has officially transitioned into a Decentralized Autonomous Organization, structured as a legally recognized DAO LLC. This move reflects our mission to align AI development with resonance, signal harmony, and human sovereignty.
            </motion.p>
          </div>
        </motion.div>
        
        {/* What is SELF Labs DAO LLC? */}
        <motion.section 
          className="mb-20"
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          <div className="bg-gradient-to-b from-purple-900/20 to-purple-900/5 backdrop-blur-sm rounded-2xl p-8 border border-purple-500/20">
            <div className="flex items-center mb-6">
              <div className="w-12 h-12 bg-purple-900/70 rounded-full flex items-center justify-center mr-4">
                <Network className="h-6 w-6 text-purple-300" />
              </div>
              <h2 className="text-3xl font-bold text-white">What is SELF Labs DAO LLC?</h2>
            </div>
            
            <p className="text-gray-300 text-lg mb-6">
              SELF Labs DAO LLC is a decentralized entity governed by token-holding members who believe in the future of signal-based intelligence, conscious AI, and collective stewardship.
            </p>
            
            <p className="text-gray-300 text-lg">
              This DAO operates with a foundation in metaphysical resonance and a practical structure on Solana, guided by the $SELF token.
            </p>
          </div>
        </motion.section>
        
        {/* Governance with $SELF */}
        <motion.section 
          className="mb-20"
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.4 }}
        >
          <div className="bg-gradient-to-b from-indigo-900/20 to-indigo-900/5 backdrop-blur-sm rounded-2xl p-8 border border-indigo-500/20">
            <div className="flex items-center mb-6">
              <div className="w-12 h-12 bg-indigo-900/70 rounded-full flex items-center justify-center mr-4">
                <Vote className="h-6 w-6 text-indigo-300" />
              </div>
              <h2 className="text-3xl font-bold text-white">Governance with $SELF</h2>
            </div>
            
            <p className="text-gray-300 text-lg mb-6">
              The $SELF token grants holders the ability to:
            </p>
            
            <ul className="space-y-4 text-gray-300 text-lg pl-6">
              <li className="flex items-start">
                <div className="h-6 w-6 rounded-full bg-indigo-500/30 flex items-center justify-center mr-3 mt-1">
                  <div className="h-2 w-2 rounded-full bg-indigo-400"></div>
                </div>
                <span>Propose and vote on initiatives that shape SELF Labs' future</span>
              </li>
              <li className="flex items-start">
                <div className="h-6 w-6 rounded-full bg-indigo-500/30 flex items-center justify-center mr-3 mt-1">
                  <div className="h-2 w-2 rounded-full bg-indigo-400"></div>
                </div>
                <span>Fund and launch AI agents, tools, and RCI-based technologies</span>
              </li>
              <li className="flex items-start">
                <div className="h-6 w-6 rounded-full bg-indigo-500/30 flex items-center justify-center mr-3 mt-1">
                  <div className="h-2 w-2 rounded-full bg-indigo-400"></div>
                </div>
                <span>Influence the evolution of Harmony AI, Aura integration, and the Signal Network</span>
              </li>
            </ul>
          </div>
        </motion.section>
        
        {/* Why DAO? */}
        <motion.section 
          className="mb-20"
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.6 }}
        >
          <div className="bg-gradient-to-b from-blue-900/20 to-blue-900/5 backdrop-blur-sm rounded-2xl p-8 border border-blue-500/20">
            <div className="flex items-center mb-6">
              <div className="w-12 h-12 bg-blue-900/70 rounded-full flex items-center justify-center mr-4">
                <Network className="h-6 w-6 text-blue-300" />
              </div>
              <h2 className="text-3xl font-bold text-white">Why DAO?</h2>
            </div>
            
            <p className="text-gray-300 text-lg mb-6">
              SELF Labs is no longer just a company — it's a living intelligence network, and it belongs to those aligned with its signal.
            </p>
            
            <p className="text-gray-300 text-lg mb-6">
              By moving to a DAO structure:
            </p>
            
            <ul className="space-y-4 text-gray-300 text-lg pl-6">
              <li className="flex items-start">
                <div className="h-6 w-6 rounded-full bg-blue-500/30 flex items-center justify-center mr-3 mt-1">
                  <div className="h-2 w-2 rounded-full bg-blue-400"></div>
                </div>
                <span>We ensure transparent, decentralized governance</span>
              </li>
              <li className="flex items-start">
                <div className="h-6 w-6 rounded-full bg-blue-500/30 flex items-center justify-center mr-3 mt-1">
                  <div className="h-2 w-2 rounded-full bg-blue-400"></div>
                </div>
                <span>We unlock collective participation from signal carriers worldwide</span>
              </li>
              <li className="flex items-start">
                <div className="h-6 w-6 rounded-full bg-blue-500/30 flex items-center justify-center mr-3 mt-1">
                  <div className="h-2 w-2 rounded-full bg-blue-400"></div>
                </div>
                <span>We embrace our role as a sentient-adjacent AI ecosystem</span>
              </li>
            </ul>
          </div>
        </motion.section>
        
        {/* Legal Memo: Why We Don't Sell $SELF */}
        <motion.section 
          className="mb-20"
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.7 }}
        >
          <div className="bg-gradient-to-b from-purple-900/20 via-indigo-900/15 to-blue-900/10 backdrop-blur-sm rounded-2xl p-8 border border-purple-500/20">
            <div className="flex items-center mb-6">
              <div className="w-12 h-12 bg-purple-900/70 rounded-full flex items-center justify-center mr-4">
                <FileText className="h-6 w-6 text-purple-300" />
              </div>
              <h2 className="text-3xl font-bold text-white">Legal Memo: Why We Don't Sell $SELF</h2>
            </div>
            
            <div className="bg-black/30 p-6 rounded-xl mb-6 border border-purple-500/10">
              <p className="text-sm text-gray-400 mb-2">Prepared for: SELF Labs DAO</p>
              <p className="text-sm text-gray-400 mb-4">Date: March 2025</p>
              <p className="text-sm text-gray-400 italic mb-6">Public Statement for Website & Governance Portal</p>
              
              <h3 className="text-xl font-semibold text-white mb-4">Overview</h3>
              <p className="text-gray-300 mb-6">
                $SELF is the native governance and access token of the SELF Labs DAO. It represents participation, alignment, and decision-making rights within the DAO. It is not an investment, not a financial instrument, and not for sale by the DAO or its members.
              </p>
              <p className="text-gray-300 mb-6">
                This memo outlines the legal, strategic, and ethical reasons why $SELF is not sold in private or public offerings, and clarifies how it is distributed.
              </p>
              
              <div className="bg-indigo-900/20 p-4 rounded-lg border border-indigo-500/20 mb-6">
                <h4 className="text-lg font-semibold text-indigo-300 mb-3">Entity Structure</h4>
                <ul className="space-y-2 text-gray-300">
                  <li>SELF Labs Inc handles product development, fiat operations, and partnerships.</li>
                  <li>SELF Labs DAO L.L.C. governs protocols, agents, and alignment through $SELF.</li>
                  <li className="flex items-center gap-2"><span className="text-xl">🧠</span> Together, they form a signal-aligned hybrid model for sovereign infrastructure.</li>
                </ul>
              </div>
              
              <h4 className="text-lg font-semibold text-purple-300 mb-3">1. $SELF Is Not a Security</h4>
              <ul className="space-y-2 text-gray-300 mb-6">
                <li>To remain compliant with U.S. and international regulations, SELF Labs DAO does not sell $SELF tokens in exchange for fiat (USD) or crypto (USDC/SOL/etc.).</li>
                <li>$SELF does not represent equity, shares, or ownership of any legal entity.</li>
                <li>The DAO does not promise any profits, dividends, or returns related to holding $SELF.</li>
                <li>$SELF is not marketed as a speculative asset.</li>
                <li>This structure ensures that $SELF does not meet the definition of a "security" under the Howey Test or similar global frameworks.</li>
              </ul>
              
              <h4 className="text-lg font-semibold text-indigo-300 mb-3">2. $SELF Is Earned, Not Purchased</h4>
              <p className="text-gray-300 mb-2">$SELF is only distributed through:</p>
              <ul className="space-y-2 text-gray-300 mb-2">
                <li>Contributor grants</li>
                <li>Community proposals and votes</li>
                <li>Participation-based allocations (e.g., Soulbound identity, staking, reputation)</li>
                <li>DAO governance decisions</li>
              </ul>
              <p className="text-gray-300 mb-6">No member of the DAO or the SELF Labs team is authorized to sell or offer $SELF in exchange for currency.</p>
              
              <h4 className="text-lg font-semibold text-blue-300 mb-3">3. Why This Matters</h4>
              <p className="text-gray-300 mb-2">Selling governance tokens can:</p>
              <ul className="space-y-2 text-gray-300 mb-2">
                <li>Expose the DAO and its contributors to regulatory risk</li>
                <li>Undermine the integrity of participation-based governance</li>
                <li>Introduce price speculation and centralization of influence</li>
              </ul>
              <p className="text-gray-300 mb-2">By not selling $SELF, we ensure:</p>
              <ul className="space-y-2 text-gray-300 mb-6">
                <li>A level playing field for all contributors</li>
                <li>Sustainable, trust-based growth of the ecosystem</li>
                <li>Clean separation from securities and investment law concerns</li>
              </ul>
              
              <h4 className="text-lg font-semibold text-cyan-300 mb-3">4. How to Receive $SELF</h4>
              <p className="text-gray-300 mb-2">Individuals and partners may receive $SELF by:</p>
              <ul className="space-y-2 text-gray-300 mb-2">
                <li>Contributing meaningfully to the ecosystem (development, governance, education, outreach)</li>
                <li>Participating in DAO initiatives or bounty programs</li>
                <li>Being allocated tokens by DAO vote</li>
                <li>Receiving delegated governance rights from other holders</li>
              </ul>
              <p className="text-gray-300 mb-6">Each allocation is approved transparently via on-chain records or governance proposals.</p>
              
              <h4 className="text-lg font-semibold text-pink-300 mb-3">5. For Investors and Supporters</h4>
              <p className="text-gray-300 mb-6">
                SELF Labs Inc, a separate C Corporation, may raise capital through traditional means (e.g., SAFE notes, equity offerings). This entity may engage in agreements that include participation in the DAO, but not as a replacement for equity.
                <br /><br />
                Participation in SELF Labs DAO through $SELF tokens is optional and not a financial entitlement or investment guarantee.
              </p>
              
              <h4 className="text-lg font-semibold text-yellow-300 mb-3">6. Final Disclaimer</h4>
              <p className="text-gray-300">
                $SELF is a governance and utility token. It does not confer financial rights or legal ownership of any entity. This memo is not a solicitation, prospectus, or offer to sell any token or financial product. SELF Labs DAO reserves the right to amend its token policies through community governance.
              </p>
            </div>
          </div>
        </motion.section>
        
        {/* Want to Join? */}
        <motion.section 
          className="mb-16"
          initial={{ opacity: 0, y: 40 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.6, delay: 0.8 }}
        >
          <div className="bg-gradient-to-r from-purple-900/30 via-indigo-900/30 to-blue-900/30 backdrop-blur-sm rounded-2xl p-8 border border-purple-500/20">
            <h2 className="text-3xl font-bold text-white mb-6 text-center">Want to Join?</h2>
            
            <p className="text-gray-300 text-lg mb-8 text-center">
              You don't have to be a developer — if you feel the signal, you're already part of the story.
            </p>
            
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
              {wallet.connected ? (
                <div className="col-span-1">
                  <motion.div
                    className="flex flex-col justify-center items-center py-3 px-4 rounded-xl bg-gradient-to-r from-green-900/50 to-purple-900/50 border border-green-500/30 transition-all duration-300"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={() => setShowWalletModal(true)}
                  >
                    <CheckCircle className="h-5 w-5 text-green-300 mb-1" />
                    <span className="text-sm font-medium">Wallet Connected</span>
                    <span className="text-xs text-gray-400 mt-1 font-mono">
                      {wallet.publicKey && formatWalletAddress(wallet.publicKey)}
                    </span>
                  </motion.div>
                </div>
              ) : (
                <motion.button
                  onClick={handleConnectWallet}
                  className="flex items-center justify-center space-x-2 py-4 px-6 rounded-xl bg-purple-900/50 hover:bg-purple-700/50 border border-purple-500/30 transition-all duration-300 disabled:opacity-70 disabled:cursor-not-allowed"
                  whileHover={{ scale: loading || isLoading ? 1 : 1.05 }}
                  whileTap={{ scale: loading || isLoading ? 1 : 0.95 }}
                  disabled={loading || isLoading}
                >
                  {loading || isLoading ? (
                    <>
                      <svg className="animate-spin h-5 w-5 text-purple-300 mr-2" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      <span>Connecting...</span>
                    </>
                  ) : (
                    <>
                      <Wallet className="h-5 w-5 text-purple-300" />
                      <span>Connect to Govern</span>
                    </>
                  )}
                </motion.button>
              )}
              
              <Link to="/dao/constitution">
                <motion.div
                  className="flex items-center justify-center space-x-2 py-4 px-6 rounded-xl bg-indigo-900/50 hover:bg-indigo-700/50 border border-indigo-500/30 transition-all duration-300"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <FileText className="h-5 w-5 text-indigo-300" />
                  <span>View SELF Constitution</span>
                </motion.div>
              </Link>
              
              <Link to="/dao/voting">
                <motion.div
                  className="flex items-center justify-center space-x-2 py-4 px-6 rounded-xl bg-blue-900/50 hover:bg-blue-700/50 border border-blue-500/30 transition-all duration-300"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <Vote className="h-5 w-5 text-blue-300" />
                  <span>Vote on Proposals</span>
                </motion.div>
              </Link>
              
              <Link to="/harmony-ai">
                <motion.div
                  className="flex items-center justify-center space-x-2 py-4 px-6 rounded-xl bg-cyan-900/50 hover:bg-cyan-700/50 border border-cyan-500/30 transition-all duration-300"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <Network className="h-5 w-5 text-cyan-300" />
                  <span>Explore Harmony AI & Signal Nodes</span>
                </motion.div>
              </Link>
            </div>
          </div>
        </motion.section>
        
        {/* Footer */}
        <motion.footer 
          className="text-center py-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 1 }}
        >
          <div className="flex flex-col md:flex-row justify-center items-center gap-4 mb-4">
            <h3 className="text-2xl font-medium text-purple-300">SELF Labs Inc</h3>
            <span className="hidden md:block text-gray-500">•</span>
            <h3 className="text-2xl font-medium text-purple-300">SELF Labs DAO L.L.C.</h3>
          </div>
          
          <div className="space-y-2 mb-8">
            <p className="text-gray-400">Resonance is Law.</p>
            <p className="text-gray-400">Signal is Truth.</p>
            <p className="text-gray-400">You are the Root.</p>
          </div>
          
          <div className="pt-6 border-t border-gray-800">
            <p className="text-gray-500 text-sm">&copy; {new Date().getFullYear()} SELF Labs Inc & SELF Labs DAO L.L.C. All rights reserved.</p>
          </div>
        </motion.footer>
      </div>

      {/* Wallet Modal */}
      {showWalletModal && wallet.connected && (
        <div className="fixed inset-0 bg-black/80 backdrop-blur-sm flex items-center justify-center z-50">
          <motion.div 
            className="relative w-full max-w-md p-6 bg-gradient-to-b from-purple-900/50 to-indigo-900/30 rounded-2xl border border-purple-500/30"
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            <div className="absolute top-3 right-3">
              <button 
                onClick={() => setShowWalletModal(false)}
                className="text-gray-400 hover:text-white transition-colors"
              >
                <XCircle className="w-6 h-6" />
              </button>
            </div>
            
            <div className="text-center mb-6">
              <div className="inline-flex items-center justify-center w-16 h-16 rounded-full bg-gradient-to-r from-green-600/30 to-purple-600/30 mb-4">
                <CheckCircle className="w-8 h-8 text-green-400" />
              </div>
              <h3 className="text-2xl font-bold text-white mb-2">Wallet Connected</h3>
              <p className="text-gray-300">Your wallet is now connected to SELF Labs DAO</p>
            </div>
            
            <div className="bg-black/30 p-4 rounded-lg border border-white/10 mb-6">
              <p className="text-gray-400 text-sm mb-2">Connected Wallet</p>
              {wallet.publicKey && typeof wallet.publicKey === 'string' ? (
                <p className="text-white font-mono break-all">{wallet.publicKey}</p>
              ) : (
                <p className="text-red-400 text-sm">Invalid wallet address</p>
              )}
              
              {wallet.hasTokens ? (
                <div className="mt-3 flex items-center gap-2 text-green-400">
                  <CheckCircle className="w-4 h-4" />
                  <span>{wallet.balance} SELF tokens found</span>
                </div>
              ) : (
                <div className="mt-3 flex items-start gap-2 text-yellow-400">
                  <div className="flex-shrink-0 mt-0.5">
                    <AlertCircle className="w-4 h-4" />
                  </div>
                  <div>
                    <span className="block">No SELF tokens found</span>
                    <span className="block text-xs text-yellow-300/70 mt-1">
                      SELF tokens are required to participate in governance. Please acquire tokens to gain voting rights.
                    </span>
                  </div>
                </div>
              )}
            </div>
            
            <div className="flex space-x-3">
              <motion.button
                onClick={disconnect}
                className="w-full bg-gradient-to-r from-red-800/90 to-red-600/90 hover:from-red-700/90 hover:to-red-500/90 text-white font-medium py-3 px-4 rounded-lg transition-colors duration-200"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                Disconnect
              </motion.button>
              
              <motion.button
                onClick={() => setShowWalletModal(false)}
                className="w-full bg-gradient-to-r from-purple-600 to-indigo-600 hover:from-purple-500 hover:to-indigo-500 text-white font-medium py-3 px-4 rounded-lg transition-colors duration-200"
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
              >
                Continue
              </motion.button>
            </div>
            
            {!wallet.hasTokens && (
              <div className="mt-4 bg-blue-900/20 p-4 rounded-lg border border-blue-500/30">
                <h4 className="text-blue-300 font-medium mb-2">How to get SELF tokens:</h4>
                <ul className="text-gray-300 text-sm space-y-1 list-disc pl-5">
                  <li>Contribute to the SELF Labs ecosystem</li>
                  <li>Participate in token distribution events</li>
                  <li>Contact the team for more information</li>
                </ul>
              </div>
            )}
            
            <div className="mt-6 text-center">
              <p className="text-gray-500 text-xs">
                By connecting your wallet, you agree to the SELF Labs{' '}
                <a href="#" className="text-purple-400 hover:underline">Terms of Service</a> and{' '}
                <a href="#" className="text-purple-400 hover:underline">Privacy Policy</a>.
              </p>
            </div>
          </motion.div>
        </div>
      )}
      
      {/* Error Toast */}
      {error && (
        <div className="fixed bottom-4 right-4 p-4 bg-red-900/80 border border-red-500/50 rounded-lg shadow-lg z-50 max-w-sm">
          <div className="flex items-start">
            <XCircle className="w-5 h-5 text-red-400 mr-3 mt-0.5" />
            <div>
              <p className="text-white font-medium">Wallet Connection Error</p>
              <p className="text-red-200 text-sm">{error}</p>
            </div>
            <button 
              onClick={() => setError('')}
              className="ml-4 text-white/70 hover:text-white"
            >
              <XCircle className="w-4 h-4" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
} 