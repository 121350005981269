import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { ArrowLeft, Leaf, Bot, Gamepad2, Users, Sprout, Briefcase, Share2 } from 'lucide-react';
import { Link } from 'react-router-dom';
import Modal from '../shared/Modal';
import ContactForm from '../forms/ContactForm';

export default function CultivateCoinCaseStudy() {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const openContactModal = () => setIsContactModalOpen(true);
  const closeContactModal = () => setIsContactModalOpen(false);
  
  return (
    <div className="min-h-screen bg-black text-white">
      <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
        <div className="mb-8">
          <Link to="/">
            <motion.button
              className="flex items-center text-gray-400 hover:text-white"
              whileHover={{ x: -5 }}
              transition={{ duration: 0.2 }}
            >
              <ArrowLeft className="w-5 h-5 mr-2" />
              Back to Home
            </motion.button>
          </Link>
        </div>
        
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className="text-4xl md:text-5xl font-bold mb-6 bg-gradient-to-r from-green-400 to-teal-600 text-transparent bg-clip-text">
            Cultivate Coin: Mirror Image X Beomni
          </h1>
          
          <div className="mb-8">
            <div className="flex flex-wrap gap-2 mb-4">
              <span className="px-3 py-1 text-sm bg-green-900/50 text-green-300 rounded-full">
                Robotics
              </span>
              <span className="px-3 py-1 text-sm bg-green-900/50 text-green-300 rounded-full">
                Sustainable Agriculture
              </span>
              <span className="px-3 py-1 text-sm bg-green-900/50 text-green-300 rounded-full">
                GameFi
              </span>
            </div>
          </div>
          
          <div className="prose prose-invert max-w-none mb-12">
            <p className="text-xl text-gray-300 mb-6">
              A revolutionary partnership between Beomni & Mirror Image that utilizes advanced robotics and gamification to 
              create a sustainable agricultural ecosystem, connecting players to real-world farming operations.
            </p>
            
            <h2 className="text-2xl font-bold mt-8 mb-4">Overview</h2>
            <p className="mb-4">
              The Cultivate Coin initiative represents a groundbreaking partnership between Beomni and Mirror Image, 
              leveraging cutting-edge robotics and gamification to revolutionize sustainable agriculture. This case study 
              explores how Beomni Robots, deployed in specialized Growboxes, are controlled through an innovative gaming 
              platform called Cultivate Coin.
            </p>
            
            <p className="mb-4">
              This partnership bridges the virtual and physical worlds, allowing game players to participate in real-world 
              agricultural operations, from growing produce and flowers to cannabis cultivation and honey manufacturing. The 
              system creates a closed-loop ecosystem where players' actions in the game directly impact actual farming operations, 
              creating a new paradigm in sustainable agriculture and user engagement.
            </p>
            
            <div className="my-12">
              <img 
                src="https://images.unsplash.com/photo-1530836369250-ef72a3f5cda8?auto=format&fit=crop&q=80&w=1200" 
                alt="Sustainable Agriculture Concept" 
                className="w-full h-64 md:h-96 object-cover rounded-xl"
              />
            </div>
            
            <h2 className="text-2xl font-bold mt-12 mb-4">The Growbox System</h2>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-8">
              <div className="bg-gradient-to-b from-green-900/20 to-green-900/5 rounded-xl p-6 border border-green-500/20">
                <div className="flex items-center mb-4">
                  <div className="w-12 h-12 bg-green-900/70 rounded-full flex items-center justify-center mr-4">
                    <Leaf className="h-6 w-6 text-green-300" />
                  </div>
                  <h3 className="text-xl font-semibold text-white">Sustainable Infrastructure</h3>
                </div>
                <p className="text-gray-300">
                  The Growboxes are approximately 50ft facilities powered by atmospheric water generators, solar panels, 
                  and windmills, creating a self-sustaining agricultural environment. This infrastructure allows for 
                  year-round cultivation regardless of external conditions, optimizing resource usage and minimizing 
                  environmental impact.
                </p>
              </div>
              
              <div className="bg-gradient-to-b from-green-900/20 to-green-900/5 rounded-xl p-6 border border-green-500/20">
                <div className="flex items-center mb-4">
                  <div className="w-12 h-12 bg-green-900/70 rounded-full flex items-center justify-center mr-4">
                    <Bot className="h-6 w-6 text-green-300" />
                  </div>
                  <h3 className="text-xl font-semibold text-white">Beomni Robotics Integration</h3>
                </div>
                <p className="text-gray-300">
                  Each Growbox features a dedicated station for Beomni Robots, allowing them to charge and rest when not 
                  actively tending to crops. These robots perform the physical agricultural tasks, from planting and 
                  watering to pruning and harvesting, all guided by the players' inputs through the Cultivate Coin game.
                </p>
              </div>
            </div>
            
            <div className="bg-gradient-to-r from-green-900/20 to-teal-900/20 rounded-xl p-8 mb-12 relative overflow-hidden">
              <div className="absolute -top-10 -left-10 w-40 h-40 bg-green-600/10 rounded-full blur-3xl z-0"></div>
              <div className="absolute -bottom-10 -right-10 w-40 h-40 bg-teal-600/10 rounded-full blur-3xl z-0"></div>
              <div className="relative z-10">
                <h3 className="text-xl font-semibold mb-4 text-white">Distribution Network</h3>
                <p className="text-gray-300">
                  The produce grown in the Growboxes is distributed through InGrown Organics, which has established contracts 
                  with major retailers including Whole Foods, Target, Amazon, and 13,000 retail locations through partnership 
                  with Bangalla. This extensive distribution network ensures that the sustainably grown produce reaches a wide 
                  consumer base.
                </p>
              </div>
            </div>
            
            <h2 className="text-2xl font-bold mt-12 mb-4">What Is Cultivate Coin?</h2>
            
            <p className="mb-6">
              Cultivate Coin is more than just a cryptocurrency – it's a role-playing simulation game that connects players 
              to real-world agricultural operations. The game serves as the interface between players, Beomni Robots, and the 
              Growboxes, allowing virtual actions to translate into physical farming activities.
            </p>
            
            <h3 className="text-xl font-semibold mt-8 mb-4">How Does It Work?</h3>
            
            <p className="mb-6">
              Players participate in the Cultivate Coin ecosystem by answering trivia questions and making decisions that 
              affect the actual growing operations. The game assigns specific roles to players, each with unique responsibilities 
              and rewards within the ecosystem. To access the game and earn Cultivate Coins, players must own role-specific NFTs 
              in their digital wallets.
            </p>
            
            <h2 className="text-2xl font-bold mt-12 mb-4">Player Roles in the Ecosystem</h2>
            
            <div className="space-y-6 mb-12">
              <div className="flex flex-col md:flex-row gap-6 items-start">
                <div className="flex-shrink-0 w-16 h-16 bg-gradient-to-br from-green-600 to-teal-600 rounded-full flex items-center justify-center">
                  <Briefcase className="h-8 w-8 text-white" />
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-3 text-green-300">The Operators</h3>
                  <p className="text-gray-300">
                    Operators function as managers for each Growbox, making strategic decisions about deployment locations, 
                    expansion rates, and overseeing operations. These players shape the growth and direction of the entire 
                    agricultural network, influencing where and how many Growboxes are deployed annually.
                  </p>
                </div>
              </div>
              
              <div className="flex flex-col md:flex-row gap-6 items-start">
                <div className="flex-shrink-0 w-16 h-16 bg-gradient-to-br from-green-600 to-teal-600 rounded-full flex items-center justify-center">
                  <Sprout className="h-8 w-8 text-white" />
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-3 text-green-300">The Farmers</h3>
                  <p className="text-gray-300">
                    Farmers are the only players with direct control over the Beomni Robots. Through a trivia-based system, 
                    they make decisions about plant care (such as when to trim plants or how much water to provide). Correct 
                    answers earn Cultivate Coins, while incorrect choices provide educational feedback without rewards, creating 
                    a learning-based incentive system.
                  </p>
                </div>
              </div>
              
              <div className="flex flex-col md:flex-row gap-6 items-start">
                <div className="flex-shrink-0 w-16 h-16 bg-gradient-to-br from-green-600 to-teal-600 rounded-full flex items-center justify-center">
                  <Users className="h-8 w-8 text-white" />
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-3 text-green-300">The Negotiators</h3>
                  <p className="text-gray-300">
                    Negotiators vote on bids from retail partners for the harvested produce. For example, when a Growbox yields 
                    500 pounds of lettuce, Negotiators review and select the highest bid from retail partners. Once accepted, 
                    the profits from the sale are added to the Cultivate Coin Liquidity Pool and distributed to all players 
                    participating in that specific Growbox's operation.
                  </p>
                </div>
              </div>
            </div>
            
            <h2 className="text-2xl font-bold mt-12 mb-4">Data Sharing and Technology Integration</h2>
            
            <div className="bg-gradient-to-r from-green-900/20 to-teal-900/20 rounded-xl p-8 mb-12 relative overflow-hidden">
              <div className="absolute -top-10 -left-10 w-40 h-40 bg-green-600/10 rounded-full blur-3xl z-0"></div>
              <div className="absolute -bottom-10 -right-10 w-40 h-40 bg-teal-600/10 rounded-full blur-3xl z-0"></div>
              <div className="relative z-10 space-y-4">
                <p className="text-gray-300">
                  To participate in the Cultivate Coin ecosystem, players must own a Mirror Image One Console – the first 
                  Metaverse Gaming Console designed to reward users for:
                </p>
                <ul className="list-disc pl-6 space-y-2 text-gray-300">
                  <li>Playing games</li>
                  <li>Visiting metaverses</li>
                  <li>Developing programs for Web 3</li>
                  <li>Purchasing NFTs from partners</li>
                </ul>
                
                <p className="text-gray-300 mt-4">
                  The console stores personal data for each user and provides options to either monetize or protect this data. 
                  Users who choose to monetize their data can upload aspects of their "consciousness" into the Beomni robots 
                  through their Reflection Avatars, which contain various data sets:
                </p>
                <ul className="list-disc pl-6 space-y-2 text-gray-300">
                  <li>Personality</li>
                  <li>Voice</li>
                  <li>Mannerisms</li>
                  <li>Experience</li>
                  <li>Health Data</li>
                  <li>Education</li>
                </ul>
                
                <p className="text-gray-300 mt-4">
                  This integration allows for a deeper connection between players and the physical farming operations, with the 
                  Beomni robots essentially becoming extensions of the players themselves.
                </p>
              </div>
            </div>
            
            <h2 className="text-2xl font-bold mt-12 mb-4">Conclusion</h2>
            
            <p className="mb-4">
              The Cultivate Coin partnership between Beomni and Mirror Image represents a significant advancement in both 
              sustainable agriculture and gamified user engagement. By connecting virtual gameplay to real-world agricultural 
              operations through advanced robotics, the initiative creates a new paradigm for food production, distribution, 
              and user participation.
            </p>
            
            <p className="mb-4">
              This case study demonstrates how emerging technologies like robotics, blockchain, and data sharing can converge 
              to create innovative solutions for sustainable agriculture while providing engaging, educational, and potentially 
              profitable experiences for participants across the globe.
            </p>
            
            <div className="bg-gradient-to-r from-green-900/20 to-teal-900/20 rounded-xl p-8 mt-12 mb-8">
              <h3 className="text-xl font-semibold mb-4 text-center text-white">Interested in Learning More About Cultivate Coin?</h3>
              <p className="text-gray-300 text-center mb-6">
                Discover how you can participate in this revolutionary approach to sustainable agriculture through gaming and robotics.
              </p>
              <div className="flex justify-center">
                <motion.button
                  onClick={openContactModal}
                  className="bg-gradient-to-r from-green-600 to-teal-600 hover:from-green-700 hover:to-teal-700 text-white px-8 py-3 rounded-lg font-semibold"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Contact Us to Learn More
                </motion.button>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
      
      {/* Contact Form Modal */}
      <Modal 
        isOpen={isContactModalOpen} 
        onClose={closeContactModal}
        title="Learn More About Cultivate Coin"
      >
        <ContactForm />
      </Modal>
    </div>
  );
} 