import React, { useState } from 'react';
import { AtSign, User, Building, Check, Briefcase, Users } from 'lucide-react';

// Define the structure of our form data
interface SportsCoachingFormData {
  firstName: string;
  lastName: string;
  email: string;
  organization: string;
  sportType: string;
  teamSize: string;
  message: string;
  consent: boolean;
}

// Define the error structure to allow string messages
interface FormErrors {
  firstName?: string;
  lastName?: string;
  email?: string;
  organization?: string;
  sportType?: string;
  teamSize?: string;
  message?: string;
  consent?: string;
}

// Define the structure of the API response
interface ApolloResponse {
  status: string;
  message: string;
}

export default function SportsCoachingContactForm() {
  // State for form data
  const [formData, setFormData] = useState<SportsCoachingFormData>({
    firstName: '',
    lastName: '',
    email: '',
    organization: '',
    sportType: '',
    teamSize: '',
    message: '',
    consent: false,
  });

  // State for form errors
  const [errors, setErrors] = useState<FormErrors>({});
  
  // State for submission status
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [submitError, setSubmitError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  // Handle form field changes
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
    const { name, value, type } = e.target;
    
    // For checkbox inputs, we need to get the checked property instead of value
    const val = type === 'checkbox' ? (e.target as HTMLInputElement).checked : value;
    
    // Update form data state
    setFormData(prev => ({
      ...prev,
      [name]: val
    }));
    
    // Clear any error for this field when it's modified
    if (errors[name as keyof FormErrors]) {
      setErrors(prev => ({
        ...prev,
        [name]: undefined
      }));
    }
  };

  // Validate form
  const validateForm = () => {
    const newErrors: FormErrors = {};
    
    // Required fields validation
    if (!formData.firstName.trim()) newErrors.firstName = "First name is required";
    if (!formData.lastName.trim()) newErrors.lastName = "Last name is required";
    
    // Email validation
    if (!formData.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = "Email is invalid";
    }
    
    if (!formData.organization.trim()) newErrors.organization = "Organization is required";
    if (!formData.sportType.trim()) newErrors.sportType = "Sport type is required";
    if (!formData.teamSize.trim()) newErrors.teamSize = "Team size is required";
    if (!formData.message.trim()) newErrors.message = "Message is required";
    if (!formData.consent) newErrors.consent = "You must agree to be contacted";
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    
    // Validate form before submission
    if (!validateForm()) return;
    
    setIsLoading(true);
    setSubmitError('');
    
    try {
      // Prepare data for Apollo.io
      const apolloData = {
        first_name: formData.firstName,
        last_name: formData.lastName,
        email: formData.email,
        organization_name: formData.organization,
        title: "Sports Team Representative",
        custom_fields: {
          sport_type: formData.sportType,
          team_size: formData.teamSize,
          message: formData.message,
          inquiry_type: "AI Sports Coaching",
        },
        tags: ["sports coaching", "ai analysis"]
      };
      
      // In development, we'll simulate a successful submission
      if (process.env.NODE_ENV !== 'production') {
        // Simulate API delay
        await new Promise(resolve => setTimeout(resolve, 1500));
        
        console.log('Development mode - would send to Apollo.io:', apolloData);
        
        // Simulate successful response
        const mockResponse: ApolloResponse = {
          status: 'success',
          message: 'Contact information submitted successfully'
        };
        
        // Handle successful submission
        setIsSubmitted(true);
      } else {
        // In production, use the Netlify function to proxy the request
        const response = await fetch('/.netlify/functions/apollo-proxy?endpoint=contacts', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify(apolloData)
        });
        
        const data: ApolloResponse = await response.json();
        
        if (response.ok) {
          setIsSubmitted(true);
        } else {
          throw new Error(data.message || 'Failed to submit form');
        }
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setSubmitError(error instanceof Error ? error.message : 'An unexpected error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  // Show success message after submission
  if (isSubmitted) {
    return (
      <div className="text-center py-8">
        <div className="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100 mb-4">
          <Check className="h-6 w-6 text-green-600" />
        </div>
        <h3 className="text-lg font-medium text-white mb-2">Request Submitted!</h3>
        <p className="text-gray-400 mb-6">
          Thank you for your interest in our AI Sports Coaching system. Our team will contact you shortly to discuss how we can help optimize your team's performance.
        </p>
        <button
          onClick={() => setIsSubmitted(false)}
          className="bg-blue-600 hover:bg-blue-700 text-white px-4 py-2 rounded-md transition duration-300"
        >
          Submit Another Request
        </button>
      </div>
    );
  }

  return (
    <form onSubmit={handleSubmit} className="space-y-4 p-4">
      <div className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
        {/* First Name field */}
        <div>
          <label htmlFor="firstName" className="block text-sm font-medium text-gray-300 mb-1">
            First Name *
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <User className="h-5 w-5 text-gray-500" />
            </div>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              className={`bg-gray-900 border ${errors.firstName ? 'border-red-500' : 'border-gray-700'} text-white rounded-md block w-full pl-10 pr-3 py-2 focus:outline-none focus:ring-1 focus:ring-blue-500`}
              placeholder="John"
            />
          </div>
          {errors.firstName && <p className="mt-1 text-sm text-red-500">{errors.firstName}</p>}
        </div>

        {/* Last Name field */}
        <div>
          <label htmlFor="lastName" className="block text-sm font-medium text-gray-300 mb-1">
            Last Name *
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <User className="h-5 w-5 text-gray-500" />
            </div>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              className={`bg-gray-900 border ${errors.lastName ? 'border-red-500' : 'border-gray-700'} text-white rounded-md block w-full pl-10 pr-3 py-2 focus:outline-none focus:ring-1 focus:ring-blue-500`}
              placeholder="Doe"
            />
          </div>
          {errors.lastName && <p className="mt-1 text-sm text-red-500">{errors.lastName}</p>}
        </div>
      </div>

      {/* Email field */}
      <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-300 mb-1">
          Email Address *
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <AtSign className="h-5 w-5 text-gray-500" />
          </div>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className={`bg-gray-900 border ${errors.email ? 'border-red-500' : 'border-gray-700'} text-white rounded-md block w-full pl-10 pr-3 py-2 focus:outline-none focus:ring-1 focus:ring-blue-500`}
            placeholder="john.doe@example.com"
          />
        </div>
        {errors.email && <p className="mt-1 text-sm text-red-500">{errors.email}</p>}
      </div>

      {/* Organization field */}
      <div>
        <label htmlFor="organization" className="block text-sm font-medium text-gray-300 mb-1">
          Organization/Team *
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            <Building className="h-5 w-5 text-gray-500" />
          </div>
          <input
            type="text"
            id="organization"
            name="organization"
            value={formData.organization}
            onChange={handleChange}
            className={`bg-gray-900 border ${errors.organization ? 'border-red-500' : 'border-gray-700'} text-white rounded-md block w-full pl-10 pr-3 py-2 focus:outline-none focus:ring-1 focus:ring-blue-500`}
            placeholder="Wildcats Basketball"
          />
        </div>
        {errors.organization && <p className="mt-1 text-sm text-red-500">{errors.organization}</p>}
      </div>
      
      <div className="grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2">
        {/* Sport Type field */}
        <div>
          <label htmlFor="sportType" className="block text-sm font-medium text-gray-300 mb-1">
            Sport Type *
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Briefcase className="h-5 w-5 text-gray-500" />
            </div>
            <select
              id="sportType"
              name="sportType"
              value={formData.sportType}
              onChange={handleChange}
              className={`bg-gray-900 border ${errors.sportType ? 'border-red-500' : 'border-gray-700'} text-white rounded-md block w-full pl-10 pr-3 py-2 focus:outline-none focus:ring-1 focus:ring-blue-500`}
            >
              <option value="">Select Sport</option>
              <option value="Basketball">Basketball</option>
              <option value="Football">Football</option>
              <option value="Soccer">Soccer</option>
              <option value="Baseball">Baseball</option>
              <option value="Volleyball">Volleyball</option>
              <option value="Tennis">Tennis</option>
              <option value="Track & Field">Track & Field</option>
              <option value="Swimming">Swimming</option>
              <option value="Hockey">Hockey</option>
              <option value="Other">Other</option>
            </select>
          </div>
          {errors.sportType && <p className="mt-1 text-sm text-red-500">{errors.sportType}</p>}
        </div>

        {/* Team Size field */}
        <div>
          <label htmlFor="teamSize" className="block text-sm font-medium text-gray-300 mb-1">
            Team Size *
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Users className="h-5 w-5 text-gray-500" />
            </div>
            <select
              id="teamSize"
              name="teamSize"
              value={formData.teamSize}
              onChange={handleChange}
              className={`bg-gray-900 border ${errors.teamSize ? 'border-red-500' : 'border-gray-700'} text-white rounded-md block w-full pl-10 pr-3 py-2 focus:outline-none focus:ring-1 focus:ring-blue-500`}
            >
              <option value="">Select Size</option>
              <option value="Individual">Individual Athlete</option>
              <option value="Small (5-15)">Small Team (5-15 athletes)</option>
              <option value="Medium (16-30)">Medium Team (16-30 athletes)</option>
              <option value="Large (31-50)">Large Team (31-50 athletes)</option>
              <option value="XLarge (50+)">Very Large Team (50+ athletes)</option>
            </select>
          </div>
          {errors.teamSize && <p className="mt-1 text-sm text-red-500">{errors.teamSize}</p>}
        </div>
      </div>

      {/* Message field */}
      <div>
        <label htmlFor="message" className="block text-sm font-medium text-gray-300 mb-1">
          Message *
        </label>
        <textarea
          id="message"
          name="message"
          rows={4}
          value={formData.message}
          onChange={handleChange}
          className={`bg-gray-900 border ${errors.message ? 'border-red-500' : 'border-gray-700'} text-white rounded-md block w-full px-3 py-2 focus:outline-none focus:ring-1 focus:ring-blue-500`}
          placeholder="Please describe your needs and any specific performance challenges your team is facing."
        />
        {errors.message && <p className="mt-1 text-sm text-red-500">{errors.message}</p>}
      </div>

      {/* Consent checkbox */}
      <div className="flex items-start">
        <div className="flex items-center h-5">
          <input
            id="consent"
            name="consent"
            type="checkbox"
            checked={formData.consent}
            onChange={handleChange}
            className="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-700 rounded bg-gray-900"
          />
        </div>
        <div className="ml-3 text-sm">
          <label htmlFor="consent" className={`font-medium ${errors.consent ? 'text-red-500' : 'text-gray-300'}`}>
            I agree to be contacted about my inquiry *
          </label>
        </div>
      </div>

      {/* Submit error message */}
      {submitError && (
        <div className="bg-red-900/50 border border-red-500 rounded-md p-3 text-sm text-red-300">
          {submitError}
        </div>
      )}

      {/* Submit button */}
      <div className="pt-2">
        <button
          type="submit"
          disabled={isLoading}
          className={`w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 ${
            isLoading ? 'opacity-70 cursor-not-allowed' : ''
          }`}
        >
          {isLoading ? 'Submitting...' : 'Submit Inquiry'}
        </button>
      </div>
      
      <p className="text-xs text-gray-500 text-center mt-4">
        Your information will be securely processed according to our privacy policy and will only be used to contact you regarding your inquiry.
      </p>
    </form>
  );
} 