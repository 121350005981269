import React from 'react';
import { motion } from 'framer-motion';
import { Beaker } from 'lucide-react';

export function BeakerAnimation() {
  return (
    <div className="relative">
      {/* Glow effect */}
      <motion.div
        className="absolute inset-0 bg-[#00FFFF]/20 blur-[50px] rounded-full"
        animate={{
          scale: [1, 1.2, 1],
          opacity: [0.3, 0.5, 0.3],
        }}
        transition={{
          duration: 2,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      />

      {/* Beaker container */}
      <motion.div
        className="relative"
        animate={{
          y: [0, -5, 0],
        }}
        transition={{
          duration: 4,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      >
        {/* Beaker icon */}
        <Beaker className="w-24 h-24 text-[#00FFFF]" strokeWidth={1} />

        {/* Liquid animation */}
        <motion.div
          className="absolute bottom-[20%] left-1/2 -translate-x-1/2 w-14 rounded-b-lg overflow-hidden"
          style={{ height: '60%' }}
        >
          {/* Liquid base */}
          <motion.div
            className="absolute inset-0 bg-gradient-to-t from-[#00FFFF] to-[#00FFFF]/50"
            animate={{
              height: ["60%", "75%", "60%"],
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              ease: "easeInOut"
            }}
            style={{
              filter: "brightness(1.2) saturate(1.2)",
            }}
          />

          {/* Bubbles */}
          {[...Array(3)].map((_, i) => (
            <motion.div
              key={i}
              className="absolute w-1.5 h-1.5 bg-white/30 rounded-full"
              animate={{
                y: [0, -40, 0],
                x: [-2, 2, -2],
                opacity: [0, 1, 0],
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                delay: i * 0.4,
                ease: "easeInOut"
              }}
              style={{
                left: `${30 + i * 15}%`,
                bottom: "0%",
              }}
            />
          ))}

          {/* Surface reflection */}
          <motion.div
            className="absolute left-0 right-0 h-px bg-white/50"
            animate={{
              top: ["60%", "75%", "60%"],
            }}
            transition={{
              duration: 2,
              repeat: Infinity,
              ease: "easeInOut"
            }}
          />
        </motion.div>
      </motion.div>

      {/* Shine effect */}
      <motion.div
        className="absolute top-1/4 left-1/4 w-1 h-8 bg-white/20 rounded-full transform -rotate-45"
        animate={{
          opacity: [0.2, 0.4, 0.2],
        }}
        transition={{
          duration: 2,
          repeat: Infinity,
          ease: "easeInOut"
        }}
      />
    </div>
  );
}