import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { TrendingUp, Users, DollarSign, Activity } from 'lucide-react';
import { supabase } from '../../../utils/supabase';
import { calculateCompanyMetrics } from '../../../utils/metrics';

const defaultMetrics = [
  {
    label: 'Current Valuation',
    value: '$2.8B',
    change: '+12.5%',
    icon: DollarSign,
    color: 'text-[#00FFFF]'
  },
  {
    label: 'Active Users',
    value: '193,432',
    change: '+28.4%',
    icon: Users,
    color: 'text-[#FF3366]'
  },
  {
    label: 'Revenue Growth',
    value: '156%',
    change: '+42.3%',
    icon: TrendingUp,
    color: 'text-[#B4FF39]'
  },
  {
    label: 'AI Model Usage',
    value: '890K',
    change: '+35.7%',
    icon: Activity,
    color: 'text-[#00FFFF]'
  }
];

interface Metrics {
  current_valuation: string;
  active_users: string;
  revenue_growth: string;
  ai_usage: string;
}

export function FinancialMetrics() {
  const [metrics, setMetrics] = useState(defaultMetrics);

  useEffect(() => {
    fetchMetrics();
  }, []);

  async function fetchMetrics() {
    try {
      // First try to calculate fresh metrics
      await calculateCompanyMetrics();
      
      // Then fetch the updated metrics
      const { data, error } = await supabase
        .from('company_metrics')
        .select('*')
        .single();

      if (error) throw error;
      
      if (data) {
        setMetrics([
          {
            label: 'Current Valuation',
            value: data.current_valuation,
            change: '+12.5%',
            icon: DollarSign,
            color: 'text-[#00FFFF]'
          },
          {
            label: 'Active Users',
            value: data.active_users,
            change: '+28.4%',
            icon: Users,
            color: 'text-[#FF3366]'
          },
          {
            label: 'Revenue Growth',
            value: data.revenue_growth,
            change: '+42.3%',
            icon: TrendingUp,
            color: 'text-[#B4FF39]'
          },
          {
            label: 'AI Model Usage',
            value: data.ai_usage,
            change: '+35.7%',
            icon: Activity,
            color: 'text-[#00FFFF]'
          }
        ]);
      }
    } catch (error) {
      console.error('Error fetching metrics:', error);
    }
  }

  return (
    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-4">
      {metrics.map((metric, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: index * 0.1 }}
          className="bg-[#0A0A0B] border border-white/10 rounded-xl p-6"
        >
          <div className="flex items-start justify-between">
            <metric.icon className={`w-5 h-5 ${metric.color}`} />
            <span className="text-green-400 text-sm">{metric.change}</span>
          </div>
          
          <div className="mt-4">
            <div className="text-2xl font-bold text-white">{metric.value}</div>
            <div className="text-sm text-gray-400">{metric.label}</div>
          </div>
        </motion.div>
      ))}
    </div>
  );
}