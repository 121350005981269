export const scienceQuotes = [
  "Recalibrating the quantum flux capacitor...",
  "Synthesizing artificial consciousness...",
  "Calculating the meaning of life...",
  "Converting caffeine into code...",
  "Bending the laws of physics...",
  "Teaching AI to appreciate cat videos...",
  "Downloading more RAM...",
  "Reversing the polarity of the neutron flow...",
  "Expanding neural pathways...",
  "Accelerating particles and breaking paradigms..."
];