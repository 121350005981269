import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { Plus, Minus } from 'lucide-react';

interface FaqItemProps {
  question: string;
  answer: string;
  isActive: boolean;
  onClick: () => void;
}

export function FaqItem({ question, answer, isActive, onClick }: FaqItemProps) {
  return (
    <div 
      className={`border-b border-emerald-900/30 py-4 cursor-pointer ${isActive ? 'pb-4' : ''}`}
      onClick={onClick}
    >
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-medium text-white">{question}</h3>
        <button className="flex-shrink-0 ml-4">
          {isActive ? (
            <Minus className="h-5 w-5 text-emerald-400" />
          ) : (
            <Plus className="h-5 w-5 text-emerald-400" />
          )}
        </button>
      </div>
      
      <AnimatePresence>
        {isActive && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 'auto', opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="overflow-hidden"
          >
            <p className="mt-4 text-gray-400">{answer}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
} 