import React, { useState } from 'react';
import { motion } from 'framer-motion';
import {
  BookOpen,
  MessageSquare,
  Users,
  Phone,
  Video,
  Code,
  Mail
} from 'lucide-react';
import { AgentCard } from './AgentCard';
import { EmailAgentCard } from './EmailAgent/EmailAgentCard';
import { AgentDetails } from './AgentDetails';

const agents = [
  {
    id: 'bookkeeping',
    title: 'Bookkeeping Agent',
    description: 'Automated financial management and bookkeeping using Stripe and QuickBooks integration',
    icon: BookOpen,
    metrics: [
      { label: 'Transactions Processed', value: '1,234' },
      { label: 'Monthly Revenue', value: '$125K' }
    ]
  },
  {
    id: 'social-media',
    title: 'Social Media Agent',
    description: 'AI-driven social media management and content creation',
    icon: MessageSquare,
    metrics: [
      { label: 'Posts Created', value: '89' },
      { label: 'Engagement Rate', value: '4.2%' }
    ]
  },
  {
    id: 'lead-gen',
    title: 'Lead Generation Agent',
    description: 'Automated lead generation and outreach using Apollo.io',
    icon: Users,
    metrics: [
      { label: 'Leads Generated', value: '342' },
      { label: 'Conversion Rate', value: '2.8%' }
    ]
  },
  {
    id: 'meeting',
    title: 'Meeting Agent',
    description: 'AI-powered meeting host using HeyGen for investor meetings',
    icon: Video,
    metrics: [
      { label: 'Meetings Hosted', value: '24' },
      { label: 'Meeting Hours', value: '36' }
    ]
  },
  {
    id: 'phone',
    title: 'Phone Agent',
    description: 'Automated call handling and management for investors',
    icon: Phone,
    metrics: [
      { label: 'Calls Handled', value: '156' },
      { label: 'Avg. Duration', value: '4.2m' }
    ]
  },
  {
    id: 'cto',
    title: 'CTO Coding Agent',
    description: 'AI-powered software development and project management',
    icon: Code,
    metrics: [
      { label: 'Projects', value: '8' },
      { label: 'Commits', value: '234' }
    ]
  }
];

export function AIAgentsSection() {
  const [selectedAgent, setSelectedAgent] = useState<string | null>(null);

  return (
    <div className="space-y-6">
      <div className="flex items-center justify-between">
        <h2 className="text-xl font-semibold text-white">AI Agents</h2>
        <div className="flex items-center space-x-4">
          <div className="flex items-center space-x-2">
            <div className="w-2 h-2 bg-green-500 rounded-full animate-pulse" />
            <span className="text-sm text-gray-400">4 Active</span>
          </div>
          <div className="flex items-center space-x-2">
            <div className="w-2 h-2 bg-yellow-500 rounded-full" />
            <span className="text-sm text-gray-400">2 Idle</span>
          </div>
        </div>
      </div>

      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
        <EmailAgentCard onViewDetails={() => setSelectedAgent('email')} />
        {agents.map((agent) => (
          <AgentCard
            key={agent.id}
            {...agent}
            status={Math.random() > 0.5 ? 'active' : 'idle'}
            lastActivity="2 minutes ago"
            onViewDetails={() => setSelectedAgent(agent.id)}
          />
        ))}
      </div>

      {selectedAgent && (
        <AgentDetails
          agent={selectedAgent === 'email' ? {
            id: 'email',
            title: 'Email Management Agent',
            description: 'AI-powered email management with smart filtering and automated responses',
            icon: Mail,
            metrics: [
              { label: 'Emails Handled', value: '127' },
              { label: 'Response Rate', value: '94.2%' }
            ]
          } : agents.find(a => a.id === selectedAgent)!}
          onClose={() => setSelectedAgent(null)}
        />
      )}
    </div>
  );
}