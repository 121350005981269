import React from 'react';
import { motion } from 'framer-motion';
import { format } from 'date-fns';

interface WelcomeSectionProps {
  investorName: string;
}

export function WelcomeSection({ investorName }: WelcomeSectionProps) {
  const currentTime = new Date();
  const hour = currentTime.getHours();
  
  let greeting = 'Good evening';
  if (hour < 12) {
    greeting = 'Good morning';
  } else if (hour < 18) {
    greeting = 'Good afternoon';
  }

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      className="mb-8"
    >
      <h1 className="text-3xl font-bold text-white mb-2">
        {greeting}, {investorName}
      </h1>
      <p className="text-gray-400">
        {format(currentTime, 'EEEE, MMMM d, yyyy')}
      </p>
    </motion.div>
  );
}