import { createContext, useContext, ReactNode } from 'react';
import { loadStripe } from '@stripe/stripe-js';

interface StripeContextType {
  openCheckout: (productType: 'phone' | 'flash') => Promise<void>;
}

const StripeContext = createContext<StripeContextType | undefined>(undefined);

// Initialize Stripe
const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY || 'pk_live_51N6cuWBL4xBXOGrq81HP95fIcPjlrjHpMcUhIdYCTBxGQmm3MjvvmFjLFhLx1aa3NtAlRPXZvc2rKUvKbQ7fM8WU00hRUKcP65');

export function StripeProvider({ children }: { children: ReactNode }) {
  const openCheckout = async (productType: 'phone' | 'flash') => {
    try {
      const stripe = await stripePromise;
      if (!stripe) throw new Error('Stripe failed to load');

      const productId = productType === 'flash' 
        ? import.meta.env.VITE_STRIPE_PRODUCT_ID_FLASH
        : import.meta.env.VITE_STRIPE_PRODUCT_ID;

      if (!productId) {
        throw new Error(`Missing Stripe product ID for ${productType}`);
      }

      const priceAmount = productType === 'flash' ? 14900 : 29900; // $149 for Flash Kit, $299 for SELF Phone

      const response = await fetch('/.netlify/functions/create-checkout-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          productId,
          priceAmount,
        }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Failed to create checkout session');
      }

      const session = await response.json();

      const result = await stripe.redirectToCheckout({
        sessionId: session.sessionId,
      });

      if (result.error) {
        throw new Error(result.error.message);
      }
    } catch (error) {
      console.error('Error opening checkout:', error);
      throw error;
    }
  };

  return (
    <StripeContext.Provider value={{ openCheckout }}>
      {children}
    </StripeContext.Provider>
  );
}

export function useStripe() {
  const context = useContext(StripeContext);
  if (context === undefined) {
    throw new Error('useStripe must be used within a StripeProvider');
  }
  return context;
} 