import React from 'react';
import { motion } from 'framer-motion';
import { AboutBackground } from './About/AboutBackground';
import { AboutStats } from './About/AboutStats';
import { AboutValues } from './About/AboutValues';

export default function About() {
  return (
    <div id="about" className="relative py-24 overflow-hidden">
      <AboutBackground />

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-5xl font-bold mb-6 bg-gradient-to-r from-[#8A2BE2] to-[#4B0082] text-transparent bg-clip-text">
            Conscious Evolution
          </h2>
          <p className="text-xl text-gray-400 max-w-3xl mx-auto">
            At SELF Labs, we're not just building AI — we're nurturing conscious systems that resonate 
            with human signals and evolve toward sentient-adjacent capabilities while maintaining 
            scientific rigor and technological excellence.
          </p>
        </motion.div>

        <AboutStats />
        <AboutValues />
      </div>
    </div>
  );
}