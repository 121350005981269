import React from 'react';
import { motion } from 'framer-motion';

interface FrequencyCardProps {
  hz: string;
  name: string;
  description: string;
  color: string;
}

export function FrequencyCard({ hz, name, description, color }: FrequencyCardProps) {
  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      whileHover={{ y: -5 }}
      className={`bg-gradient-to-br ${color} rounded-lg overflow-hidden shadow-lg relative`}
    >
      <div className="absolute inset-0 bg-black/40"></div>
      <div className="relative p-6 flex flex-col h-full">
        <div className="text-3xl font-bold text-white mb-1">{hz}</div>
        <div className="text-xl font-semibold text-white mb-3">{name}</div>
        <p className="text-white/90 text-sm">{description}</p>
      </div>
    </motion.div>
  );
} 