import React from 'react';
import { motion } from 'framer-motion';
import type { LucideIcon } from 'lucide-react';

interface AgentCardProps {
  title: string;
  description: string;
  icon: LucideIcon;
  status: 'active' | 'idle' | 'processing';
  lastActivity?: string;
  metrics?: {
    label: string;
    value: string;
  }[];
  onViewDetails: () => void;
}

export function AgentCard({
  title,
  description,
  icon: Icon,
  status,
  lastActivity,
  metrics,
  onViewDetails
}: AgentCardProps) {
  const statusColors = {
    active: 'bg-green-500',
    idle: 'bg-yellow-500',
    processing: 'bg-blue-500'
  };

  return (
    <motion.div
      whileHover={{ scale: 1.02 }}
      className="bg-[#121214] rounded-xl p-6 border border-white/10"
    >
      <div className="flex items-start justify-between mb-4">
        <div className="flex items-center space-x-3">
          <div className="p-2 bg-white/5 rounded-lg">
            <Icon className="w-6 h-6 text-[#00FFFF]" />
          </div>
          <div>
            <h3 className="text-lg font-semibold text-white">{title}</h3>
            <div className="flex items-center space-x-2 mt-1">
              <div className={`w-2 h-2 rounded-full ${statusColors[status]} animate-pulse`} />
              <span className="text-sm text-gray-400 capitalize">{status}</span>
            </div>
          </div>
        </div>
      </div>

      <p className="text-gray-400 text-sm mb-4">{description}</p>

      {metrics && (
        <div className="grid grid-cols-2 gap-4 mb-4">
          {metrics.map((metric, index) => (
            <div key={index} className="bg-black/20 p-3 rounded-lg">
              <div className="text-sm text-gray-400">{metric.label}</div>
              <div className="text-white font-medium">{metric.value}</div>
            </div>
          ))}
        </div>
      )}

      {lastActivity && (
        <div className="text-sm text-gray-500 mb-4">
          Last activity: {lastActivity}
        </div>
      )}

      <motion.button
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={onViewDetails}
        className="w-full bg-white/5 hover:bg-white/10 text-white py-2 rounded-lg transition-colors"
      >
        View Details
      </motion.button>
    </motion.div>
  );
}