import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { ArrowLeft, Brain, Cpu, Zap, Gamepad2, MessageSquare, Headphones, Network } from 'lucide-react';
import { Link } from 'react-router-dom';
import Modal from '../../shared/Modal';
import ContactForm from '../../forms/ContactForm';

export default function CerebralOSPage() {
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);

  const openContactModal = () => setIsContactModalOpen(true);
  const closeContactModal = () => setIsContactModalOpen(false);
  
  return (
    <div className="min-h-screen bg-black text-white">
      <div className="max-w-4xl mx-auto px-4 py-16 sm:px-6 lg:px-8">
        <div className="mb-8">
          <Link to="/">
            <motion.button
              className="flex items-center text-gray-400 hover:text-white"
              whileHover={{ x: -5 }}
              transition={{ duration: 0.2 }}
            >
              <ArrowLeft className="w-5 h-5 mr-2" />
              Back to Home
            </motion.button>
          </Link>
        </div>
        
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <h1 className="text-4xl md:text-5xl font-bold mb-6 bg-gradient-to-r from-cyan-500 to-blue-600 text-transparent bg-clip-text">
            Cerebral OS: The Neural Interface Revolution
          </h1>
          
          <div className="mb-8">
            <div className="flex flex-wrap gap-2 mb-4">
              <span className="px-3 py-1 text-sm bg-cyan-900/50 text-cyan-300 rounded-full">
                Brain-Computer Interface
              </span>
              <span className="px-3 py-1 text-sm bg-cyan-900/50 text-cyan-300 rounded-full">
                Neural Engineering
              </span>
              <span className="px-3 py-1 text-sm bg-cyan-900/50 text-cyan-300 rounded-full">
                AI Integration
              </span>
              <span className="px-3 py-1 text-sm bg-cyan-900/50 text-cyan-300 rounded-full">
                Immersive Computing
              </span>
            </div>
          </div>
          
          <div className="prose prose-invert max-w-none mb-12">
            <p className="text-xl text-gray-300 mb-6 leading-relaxed">
              <span className="font-bold text-cyan-400">Cerebral OS</span> is a revolutionary brain-computer interface operating system 
              enabling seamless neural control of connected devices and applications. Developed through years of cutting-edge 
              research by SELF Labs, Cerebral OS represents the next evolution in human-computer interaction, allowing direct 
              neural command of technology with just your thoughts.
            </p>
            
            <div className="my-10 bg-gradient-to-r from-cyan-900/30 to-blue-900/30 rounded-xl p-8">
              <h3 className="text-xl font-bold mb-6 text-center">The Future of Human-Computer Interaction</h3>
              
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="bg-cyan-900/20 border border-cyan-500/30 rounded-lg p-6">
                  <div className="flex items-start mb-3">
                    <Brain className="w-6 h-6 text-cyan-400 mr-2 mt-1" />
                    <h4 className="text-lg font-semibold text-cyan-300">Neural Command Interface</h4>
                  </div>
                  <p className="text-gray-300">
                    Control devices and applications with nothing but your thoughts through our advanced neural decoding algorithms
                    that translate brain activity into precise digital commands.
                  </p>
                </div>
                
                <div className="bg-blue-900/20 border border-blue-500/30 rounded-lg p-6">
                  <div className="flex items-start mb-3">
                    <Cpu className="w-6 h-6 text-blue-400 mr-2 mt-1" />
                    <h4 className="text-lg font-semibold text-blue-300">Universal Device Control</h4>
                  </div>
                  <p className="text-gray-300">
                    One interface to rule them all. Cerebral OS connects seamlessly with smart devices, computers, 
                    gaming systems, and IoT devices for effortless control across your digital ecosystem.
                  </p>
                </div>
                
                <div className="bg-indigo-900/20 border border-indigo-500/30 rounded-lg p-6">
                  <div className="flex items-start mb-3">
                    <Zap className="w-6 h-6 text-indigo-400 mr-2 mt-1" />
                    <h4 className="text-lg font-semibold text-indigo-300">Real-time Thought Translation</h4>
                  </div>
                  <p className="text-gray-300">
                    Experience near-zero latency between intent and action with our proprietary neural signal processing 
                    that converts thoughts to digital commands in milliseconds.
                  </p>
                </div>
                
                <div className="bg-purple-900/20 border border-purple-500/30 rounded-lg p-6">
                  <div className="flex items-start mb-3">
                    <Network className="w-6 h-6 text-purple-400 mr-2 mt-1" />
                    <h4 className="text-lg font-semibold text-purple-300">Cross-platform Integration</h4>
                  </div>
                  <p className="text-gray-300">
                    Seamlessly works across operating systems, applications, and devices with our adaptive 
                    neural interface technology that's compatible with virtually any digital ecosystem.
                  </p>
                </div>
              </div>
            </div>
            
            <h2 className="text-2xl font-bold mt-12 mb-4">Revolutionary Applications</h2>
            
            <div className="space-y-8 mb-12">
              <div className="flex flex-col md:flex-row gap-6 items-start">
                <div className="bg-gradient-to-br from-cyan-600 to-cyan-800 rounded-full p-4 flex items-center justify-center w-16 h-16 md:w-20 md:h-20 flex-shrink-0">
                  <Gamepad2 className="w-8 h-8 text-white" />
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2 text-cyan-300">BCI Gaming Controllers</h3>
                  <p className="text-gray-300">
                    Experience gaming like never before with our neural controllers that transform your thoughts into in-game actions.
                    Enjoy faster reaction times, intuitive control, and immersive gameplay without traditional controllers.
                    Our adaptive neural interfaces learn your play style for increasingly precise response to your intentions.
                  </p>
                </div>
              </div>
              
              <div className="flex flex-col md:flex-row gap-6 items-start">
                <div className="bg-gradient-to-br from-blue-600 to-blue-800 rounded-full p-4 flex items-center justify-center w-16 h-16 md:w-20 md:h-20 flex-shrink-0">
                  <MessageSquare className="w-8 h-8 text-white" />
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2 text-blue-300">BCI AI Agents</h3>
                  <p className="text-gray-300">
                    Communicate with advanced AI agents using just your thoughts. Our neural interface enables direct thought-to-text 
                    conversation with AI assistants, allowing for private, seamless interactions at the speed of thought.
                    Perfect for professionals who need discreet, hands-free access to AI capabilities in any environment.
                  </p>
                </div>
              </div>
              
              <div className="flex flex-col md:flex-row gap-6 items-start">
                <div className="bg-gradient-to-br from-indigo-600 to-indigo-800 rounded-full p-4 flex items-center justify-center w-16 h-16 md:w-20 md:h-20 flex-shrink-0">
                  <Headphones className="w-8 h-8 text-white" />
                </div>
                <div>
                  <h3 className="text-xl font-semibold mb-2 text-indigo-300">Immersive Computing</h3>
                  <p className="text-gray-300">
                    Transform how you work and create with Cerebral OS. Control applications, manipulate 3D models, 
                    write code, or navigate complex datasets with intuitive neural commands that enhance productivity
                    and creative flow. Experience computing that feels like a natural extension of your mind.
                  </p>
                </div>
              </div>
            </div>
            
            <div className="bg-gradient-to-r from-cyan-900/30 to-blue-900/30 border border-cyan-500/20 rounded-lg p-6 my-8">
              <blockquote className="text-xl italic text-gray-300">
                "Cerebral OS represents a paradigm shift in how humans interact with technology. By eliminating 
                the barriers between mind and machine, we're creating more natural, intuitive, and powerful ways
                to control the digital world around us."
                <footer className="text-right mt-4 text-gray-400">— SELF Labs Research Team</footer>
              </blockquote>
            </div>
            
            <h2 className="text-2xl font-bold mt-12 mb-4">Technical Specifications</h2>
            
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-12">
              <div className="bg-gradient-to-b from-cyan-900/20 to-cyan-900/10 border border-cyan-500/20 rounded-lg p-6">
                <h3 className="text-xl font-semibold mb-3 text-cyan-300">Neural Interface</h3>
                <ul className="list-disc pl-6 space-y-2 text-gray-300">
                  <li>Non-invasive EEG/MEG hybrid sensors</li>
                  <li>Adaptive neural signal processing</li>
                  <li>256-channel high-definition mapping</li>
                  <li>Individual brain pattern calibration</li>
                  <li>Machine learning personalization</li>
                </ul>
              </div>
              
              <div className="bg-gradient-to-b from-blue-900/20 to-blue-900/10 border border-blue-500/20 rounded-lg p-6">
                <h3 className="text-xl font-semibold mb-3 text-blue-300">System Performance</h3>
                <ul className="list-disc pl-6 space-y-2 text-gray-300">
                  <li>Sub-10ms response latency</li>
                  <li>99.7% command accuracy (post-calibration)</li>
                  <li>8+ hour continuous operation battery life</li>
                  <li>Wireless connectivity (Bluetooth 5.2, Wi-Fi 6E)</li>
                  <li>Compatible with Windows, macOS, iOS, Android</li>
                </ul>
              </div>
            </div>
            
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-12">
              <div className="bg-gray-800/50 rounded-lg p-6 text-center flex flex-col items-center">
                <div className="text-4xl font-bold text-cyan-400 mb-3">100+</div>
                <p className="text-gray-300">
                  compatible applications and games with native Cerebral OS integration
                </p>
              </div>
              
              <div className="bg-gray-800/50 rounded-lg p-6 text-center flex flex-col items-center">
                <div className="text-4xl font-bold text-cyan-400 mb-3">30%</div>
                <p className="text-gray-300">
                  average improvement in task completion speed compared to traditional interfaces
                </p>
              </div>
              
              <div className="bg-gray-800/50 rounded-lg p-6 text-center flex flex-col items-center">
                <div className="text-4xl font-bold text-cyan-400 mb-3">5x</div>
                <p className="text-gray-300">
                  faster response time in gaming applications compared to traditional controllers
                </p>
              </div>
            </div>
            
            <h2 className="text-2xl font-bold mt-12 mb-4">Interested in Cerebral OS?</h2>
            
            <p className="mb-4">
              Cerebral OS is currently available through our early access program for select partners and developers.
              Our BCI gaming controllers and AI agent interfaces are available for purchase to qualified customers.
            </p>
            
            <p className="mb-4">
              Our team works closely with each client to understand your specific requirements and develop
              tailored solutions for your needs, whether for personal use, professional applications, or integration 
              into existing systems.
            </p>
            
            <div className="bg-gradient-to-r from-cyan-900/20 to-blue-900/20 rounded-lg p-8 mb-8">
              <h3 className="text-xl font-semibold mb-3 text-center text-cyan-300">Ready to Experience the Future of Human-Computer Interaction?</h3>
              <p className="text-gray-300 text-center mb-6">
                Cerebral OS is transforming how we interact with technology. 
                Join the neural revolution today.
              </p>
              <div className="flex justify-center">
                <motion.button
                  onClick={openContactModal}
                  className="bg-gradient-to-r from-cyan-600 to-blue-600 hover:from-cyan-700 hover:to-blue-700 text-white px-8 py-3 rounded-lg font-semibold"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  Contact Us to Purchase
                </motion.button>
              </div>
            </div>
            
            <p className="mt-8 text-gray-300">
              Want to learn more about Cerebral OS technology and how it could transform your digital experience?
              <button 
                onClick={openContactModal}
                className="text-cyan-400 hover:text-cyan-300 ml-2 underline focus:outline-none"
              >
                Contact our team to schedule a demonstration.
              </button>
            </p>
          </div>
        </motion.div>
      </div>
      
      {/* Contact Form Modal */}
      <Modal 
        isOpen={isContactModalOpen} 
        onClose={closeContactModal}
        title="Cerebral OS Purchase Inquiry"
      >
        <ContactForm />
      </Modal>
    </div>
  );
} 