import React from 'react';
import { motion } from 'framer-motion';
import { HeroTitle } from './HeroTitle';
import { HeroDescription } from './HeroDescription';
import { HeroButtons } from './HeroButtons';

export function HeroContent() {
  return (
    <motion.div
      className="relative z-10 max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-32 md:py-48"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1 }}
    >
      <div className="flex flex-col items-center justify-center space-y-16">
        <HeroTitle />
        <HeroDescription />
        <HeroButtons />
      </div>
    </motion.div>
  );
}