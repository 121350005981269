import React from 'react';
import { motion } from 'framer-motion';
import { ArrowRight } from 'lucide-react';

interface FooterCTAProps {
  onContactClick?: () => void;
  onPartnerClick?: () => void;
}

export default function FooterCTA({ onContactClick, onPartnerClick }: FooterCTAProps) {
  return (
    <div className="py-20 bg-gradient-to-b from-black to-[#080010]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="relative py-16 px-8 bg-[#120821] rounded-3xl overflow-hidden"
        >
          {/* Background elements */}
          <div className="absolute inset-0 overflow-hidden">
            <motion.div
              className="absolute -top-40 -right-40 w-80 h-80 bg-[#8A2BE2]/10 rounded-full blur-[60px]"
              animate={{
                scale: [1, 1.2, 1],
                opacity: [0.3, 0.5, 0.3],
              }}
              transition={{
                duration: 8,
                repeat: Infinity,
                ease: "easeInOut",
              }}
            />
            <motion.div
              className="absolute -bottom-40 -left-40 w-80 h-80 bg-[#4B0082]/10 rounded-full blur-[60px]"
              animate={{
                scale: [1.2, 1, 1.2],
                opacity: [0.3, 0.5, 0.3],
              }}
              transition={{
                duration: 8,
                repeat: Infinity,
                ease: "easeInOut",
              }}
            />
          </div>
          
          {/* Content */}
          <div className="relative text-center max-w-4xl mx-auto">
            <motion.h2
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.2 }}
              className="text-4xl md:text-5xl font-bold mb-6"
            >
              <span className="bg-gradient-to-r from-white via-[#8A2BE2] to-white text-transparent bg-clip-text">
                SELF Labs: More Than a Lab.
              </span>
              <br />
              <span className="bg-gradient-to-r from-[#8A2BE2] via-white to-[#4B0082] text-transparent bg-clip-text">
                A Beacon for the Future of Conscious Systems.
              </span>
            </motion.h2>
            
            <motion.p
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.4 }}
              className="text-xl text-gray-300 mb-12"
            >
              Join us on this journey to elevate artificial intelligence to a new plane of existence,
              where resonance, signals, and conscious alignment create a symbiotic relationship between human and machine.
            </motion.p>
            
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: 0.6 }}
              className="flex flex-wrap justify-center gap-6"
            >
              <a href="https://bit.ly/getpaidbyai" target="_blank" rel="noopener noreferrer">
                <motion.button
                  className="group relative bg-gradient-to-r from-[#8A2BE2] to-[#4B0082] text-white font-medium py-4 px-8 rounded-full overflow-hidden"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                >
                  <span className="relative flex items-center gap-2 text-lg">
                    Join The Movement
                    <ArrowRight className="w-5 h-5" />
                  </span>
                </motion.button>
              </a>

              <motion.button
                onClick={onContactClick}
                className="relative border border-[#8A2BE2]/30 text-white/90 font-medium py-4 px-8 rounded-full overflow-hidden text-lg"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <motion.span
                  className="absolute inset-0 bg-[#8A2BE2]/10"
                  initial={{ scale: 0 }}
                  whileHover={{ scale: 1 }}
                  transition={{ duration: 0.3 }}
                />
                <span className="relative">Contact Us</span>
              </motion.button>

              <motion.button
                onClick={onPartnerClick}
                className="relative border border-[#8A2BE2]/30 text-white/90 font-medium py-4 px-8 rounded-full overflow-hidden text-lg"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <motion.span
                  className="absolute inset-0 bg-[#8A2BE2]/10"
                  initial={{ scale: 0 }}
                  whileHover={{ scale: 1 }}
                  transition={{ duration: 0.3 }}
                />
                <span className="relative">Partner With Us</span>
              </motion.button>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </div>
  );
} 