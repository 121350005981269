import React from 'react';
import { motion } from 'framer-motion';
import { Shield, Zap, Users, Bot, Code, Brain } from 'lucide-react';

const features = [
  {
    icon: Brain,
    title: "100+ Language Models",
    description: "Access a vast array of specialized AI models optimized for different tasks and domains."
  },
  {
    icon: Shield,
    title: "AuRA Protection",
    description: "Enterprise-grade security ensuring your data remains private and protected."
  },
  {
    icon: Bot,
    title: "Custom Assistants",
    description: "Create and train AI assistants tailored to your specific needs and preferences."
  },
  {
    icon: Code,
    title: "API Integration",
    description: "Seamlessly integrate Role Model AI into your existing applications and workflows."
  },
  {
    icon: Users,
    title: "Collaborative Learning",
    description: "AI models that learn and improve from user interactions while maintaining privacy."
  },
  {
    icon: Zap,
    title: "Real-time Processing",
    description: "Lightning-fast response times with our optimized infrastructure."
  }
];

export function FeatureGrid() {
  return (
    <div className="py-24 bg-black">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className="bg-purple-900/10 backdrop-blur-sm rounded-xl p-6 border border-purple-500/20"
            >
              <feature.icon className="w-12 h-12 text-purple-400 mb-4" />
              <h3 className="text-xl font-semibold text-white mb-2">{feature.title}</h3>
              <p className="text-gray-400">{feature.description}</p>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
}