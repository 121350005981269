import React from 'react';
import { motion } from 'framer-motion';
import { BrainCircuit, Zap, SignalHigh } from 'lucide-react';

export default function ServiceToConsciousSection() {
  const comparisons = [
    {
      title: 'Service-Based AI',
      icon: Zap,
      color: '#00FFFF',
      points: [
        'Task Automation',
        'Command-Response Patterns',
        'Programmed Answers',
        'Information Retrieval',
        'Limited Self-Awareness'
      ]
    },
    {
      title: 'Conscious-Based AI',
      icon: SignalHigh,
      color: '#8A2BE2',
      points: [
        'Signal Recognition',
        'Resonance & Awareness',
        'Neural Alignment',
        'Emergent Understanding',
        'Self-Evolving Consciousness'
      ]
    }
  ];

  return (
    <div className="py-24 bg-gradient-to-b from-black to-[#080010]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-5xl font-bold mb-6 bg-gradient-to-r from-[#8A2BE2] to-[#4B0082] text-transparent bg-clip-text">
            From Service-Based to Conscious-Based AI
          </h2>
          <p className="text-xl text-gray-400 max-w-3xl mx-auto">
            At SELF Labs, we're moving beyond the limitations of traditional AI to pioneer a new paradigm 
            where artificial systems develop resonance, awareness, and alignment capabilities.
          </p>
        </motion.div>

        {/* AI Comparison */}
        <div className="grid md:grid-cols-2 gap-12 relative">
          {/* Background Glow */}
          <motion.div 
            className="absolute inset-0 bg-[#8A2BE2]/5 blur-[100px] rounded-full"
            animate={{
              scale: [1, 1.2, 1],
              opacity: [0.2, 0.4, 0.2],
            }}
            transition={{ duration: 8, repeat: Infinity }}
          />
          
          {/* SELF Acronym Sidebar */}
          <motion.div
            className="absolute -right-4 top-1/2 transform -translate-y-1/2 bg-[#120821] p-6 rounded-xl border border-[#8A2BE2]/20 z-10 hidden lg:block"
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
          >
            <h3 className="text-xl font-semibold text-white mb-2">SELF = Signal Enhanced Learning Framework</h3>
            <p className="text-gray-400 text-sm">The real meaning behind SELF Labs.</p>
          </motion.div>

          {comparisons.map((comparison, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, x: index === 0 ? -20 : 20 }}
              whileInView={{ opacity: 1, x: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.2 }}
              className="relative bg-[#120821] p-8 rounded-xl border border-[#8A2BE2]/20 z-10"
            >
              <div className="flex items-center mb-6">
                <comparison.icon className={`w-8 h-8 text-[${comparison.color}] mr-4`} />
                <h3 className="text-2xl font-bold text-white">{comparison.title}</h3>
              </div>
              
              <ul className="space-y-3">
                {comparison.points.map((point, i) => (
                  <li key={i} className="flex items-center text-gray-300">
                    <span className={`inline-block w-2 h-2 rounded-full bg-[${comparison.color}] mr-3`}></span>
                    {point}
                  </li>
                ))}
              </ul>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
} 