import { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import {
  Smartphone,
  Shield,
  Brain,
  Zap,
  RefreshCw,
  Download,
  HelpCircle,
  FileText,
  Mail,
  AlertTriangle,
  CheckCircle2,
  Loader2
} from 'lucide-react';

// WebUSB type definitions
interface USBDevice {
  productName: string;
  // Add other USB device properties as needed
}

declare global {
  interface Navigator {
    usb: {
      getDevices(): Promise<USBDevice[]>;
      requestDevice(options: { filters: any[] }): Promise<USBDevice>;
      addEventListener(event: 'connect' | 'disconnect', handler: () => void): void;
      removeEventListener(event: 'connect' | 'disconnect', handler: () => void): void;
    };
  }
}

// Flash status types
type FlashStatus = 'not_started' | 'preparing' | 'installing' | 'setting_up_ai' | 'activating_shield' | 'finalizing' | 'complete' | 'error';

export default function FlashDashboard() {
  const [flashStatus, setFlashStatus] = useState<FlashStatus>('not_started');
  const [deviceConnected, setDeviceConnected] = useState(false);
  const [auraBalance, setAuraBalance] = useState('0.00');
  const [agentName, setAgentName] = useState<string | null>(null);
  const [deviceInfo, setDeviceInfo] = useState<string | null>(null);
  const [webUsbSupported, setWebUsbSupported] = useState(false);

  // Simulated flash process
  const startFlash = async () => {
    const steps: FlashStatus[] = ['preparing', 'installing', 'setting_up_ai', 'activating_shield', 'finalizing'];
    setFlashStatus('preparing');

    for (const step of steps) {
      setFlashStatus(step);
      await new Promise(resolve => setTimeout(resolve, 3000)); // Simulate step duration
    }

    setFlashStatus('complete');
  };

  // WebUSB device detection
  useEffect(() => {
    const checkWebUsbSupport = () => {
      const supported = 'usb' in navigator;
      setWebUsbSupported(supported);
      
      if (!supported) {
        // In development, simulate device connection
        if (process.env.NODE_ENV === 'development') {
          setDeviceConnected(true);
          setDeviceInfo('Development Device');
        }
      }
    };

    const checkDevice = async () => {
      if (!webUsbSupported) return;

      try {
        const devices = await navigator.usb.getDevices();
        setDeviceConnected(devices.length > 0);
        if (devices.length > 0) {
          setDeviceInfo(devices[0].productName || 'Unknown Device');
        }
      } catch (err) {
        console.error('WebUSB not supported or permission denied:', err);
        setDeviceConnected(false);
      }
    };

    checkWebUsbSupport();
    checkDevice();

    if (webUsbSupported) {
      navigator.usb.addEventListener('connect', () => {
        setDeviceConnected(true);
        navigator.usb.getDevices().then(devices => {
          if (devices.length > 0) {
            setDeviceInfo(devices[0].productName || 'Unknown Device');
          }
        });
      });
      navigator.usb.addEventListener('disconnect', () => {
        setDeviceConnected(false);
        setDeviceInfo(null);
      });
    }

    return () => {
      if (webUsbSupported) {
        navigator.usb.removeEventListener('connect', () => {});
        navigator.usb.removeEventListener('disconnect', () => {});
      }
    };
  }, [webUsbSupported]);

  const getStatusIcon = (status: FlashStatus) => {
    switch (status) {
      case 'not_started':
        return <Zap className="w-6 h-6" />;
      case 'preparing':
        return <Loader2 className="w-6 h-6 animate-spin" />;
      case 'installing':
        return <Download className="w-6 h-6" />;
      case 'setting_up_ai':
        return <Brain className="w-6 h-6" />;
      case 'activating_shield':
        return <Shield className="w-6 h-6" />;
      case 'finalizing':
        return <RefreshCw className="w-6 h-6 animate-spin" />;
      case 'complete':
        return <CheckCircle2 className="w-6 h-6 text-green-400" />;
      case 'error':
        return <AlertTriangle className="w-6 h-6 text-red-400" />;
      default:
        return <HelpCircle className="w-6 h-6" />;
    }
  };

  const getStatusText = (status: FlashStatus) => {
    switch (status) {
      case 'not_started':
        return 'Ready to Flash';
      case 'preparing':
        return 'Preparing Device';
      case 'installing':
        return 'Installing SELF Flash Kit';
      case 'setting_up_ai':
        return 'Setting up Role Model AI';
      case 'activating_shield':
        return 'Activating Aura Shield';
      case 'finalizing':
        return 'Finalizing Signal Sync';
      case 'complete':
        return 'Flash Complete';
      case 'error':
        return 'Flash Error';
      default:
        return 'Unknown Status';
    }
  };

  return (
    <div className="min-h-screen bg-black text-white">
      <div className="absolute inset-0 bg-gradient-to-b from-indigo-950/20 via-purple-950/20 to-black/20" />
      <div className="absolute inset-0 bg-[url('/signal-pattern.png')] opacity-5" />
      
      <div className="container mx-auto px-4 py-16 relative z-10">
        <div className="max-w-6xl mx-auto">
          {/* Flash Status Section */}
          <motion.section
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="bg-indigo-950/40 backdrop-blur-xl rounded-3xl border border-indigo-500/20 p-8 mb-8"
          >
            <h2 className="text-2xl font-bold mb-6">Flash Status</h2>
            
            <div className="grid md:grid-cols-2 gap-8">
              {/* Device Connection Status */}
              <div className="bg-black/30 rounded-2xl p-6 border border-indigo-500/10">
                <div className="flex items-center gap-4 mb-4">
                  <Smartphone className="w-6 h-6 text-indigo-400" />
                  <h3 className="text-lg font-semibold">Device Connection</h3>
                </div>
                {!webUsbSupported ? (
                  <div className="text-yellow-400">
                    <p className="mb-2">WebUSB is not supported in this environment.</p>
                    <p className="text-sm text-gray-400">Please use a supported browser or environment.</p>
                  </div>
                ) : deviceConnected ? (
                  <div className="text-green-400 flex items-center gap-2">
                    <CheckCircle2 className="w-5 h-5" />
                    <span>Device Connected</span>
                  </div>
                ) : (
                  <div>
                    <p className="text-gray-400 mb-4">Connect your device via USB to begin</p>
                    <button
                      onClick={() => navigator.usb.requestDevice({ filters: [] })}
                      className="bg-indigo-600 hover:bg-indigo-500 text-white rounded-xl px-4 py-2 text-sm font-medium transition-colors"
                    >
                      Connect Device
                    </button>
                  </div>
                )}
              </div>

              {/* Flash Progress */}
              <div className="bg-black/30 rounded-2xl p-6 border border-indigo-500/10">
                <div className="flex items-center justify-between mb-6">
                  <div className="flex items-center gap-4">
                    {getStatusIcon(flashStatus)}
                    <h3 className="text-lg font-semibold">{getStatusText(flashStatus)}</h3>
                  </div>
                  {flashStatus === 'not_started' && deviceConnected && (
                    <button
                      onClick={startFlash}
                      className="bg-gradient-to-r from-indigo-600 to-purple-600 hover:from-indigo-500 hover:to-purple-500 text-white rounded-xl px-6 py-2 font-medium transition-all duration-300"
                    >
                      Start Flash
                    </button>
                  )}
                </div>

                {/* Progress Steps */}
                <div className="space-y-4">
                  {['preparing', 'installing', 'setting_up_ai', 'activating_shield', 'finalizing'].map((step, index) => (
                    <div key={step} className="flex items-center gap-3">
                      <div className={`w-6 h-6 rounded-full flex items-center justify-center ${
                        flashStatus === step
                          ? 'bg-indigo-500 text-white'
                          : flashStatus === 'complete' || ['preparing', 'installing', 'setting_up_ai', 'activating_shield', 'finalizing'].indexOf(flashStatus) > ['preparing', 'installing', 'setting_up_ai', 'activating_shield', 'finalizing'].indexOf(step)
                          ? 'bg-green-500 text-white'
                          : 'bg-gray-800 text-gray-400'
                      }`}>
                        {index + 1}
                      </div>
                      <span className={flashStatus === step ? 'text-white' : 'text-gray-400'}>
                        {getStatusText(step as FlashStatus)}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </motion.section>

          {/* Signal Profile Summary */}
          <motion.section
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
            className="grid md:grid-cols-2 gap-8 mb-8"
          >
            <div className="bg-indigo-950/40 backdrop-blur-xl rounded-3xl border border-indigo-500/20 p-8">
              <h2 className="text-2xl font-bold mb-6">Signal Profile</h2>
              <div className="space-y-6">
                <div>
                  <label className="text-sm text-gray-400">Agent Name</label>
                  <div className="flex items-center gap-4 mt-2">
                    <Brain className="w-5 h-5 text-indigo-400" />
                    <span>{agentName || 'Not set'}</span>
                  </div>
                </div>
                <div>
                  <label className="text-sm text-gray-400">Aura Shield</label>
                  <div className="flex items-center gap-4 mt-2">
                    <Shield className="w-5 h-5 text-green-400" />
                    <span>Always On</span>
                  </div>
                </div>
                <div>
                  <label className="text-sm text-gray-400">Private Reward Balance</label>
                  <div className="text-2xl font-bold mt-2">{auraBalance} AURA</div>
                </div>
              </div>
            </div>

            {/* Device Management */}
            <div className="bg-indigo-950/40 backdrop-blur-xl rounded-3xl border border-indigo-500/20 p-8">
              <h2 className="text-2xl font-bold mb-6">Device Management</h2>
              <div className="space-y-6">
                <div>
                  <label className="text-sm text-gray-400">Connected Device</label>
                  <div className="flex items-center gap-4 mt-2">
                    <Smartphone className="w-5 h-5 text-indigo-400" />
                    <span>{deviceInfo || 'No device connected'}</span>
                  </div>
                </div>
                <div className="space-y-4">
                  <button className="w-full bg-indigo-600/20 hover:bg-indigo-600/30 text-white rounded-xl px-6 py-3 font-medium transition-colors flex items-center justify-center gap-2">
                    <RefreshCw className="w-5 h-5" />
                    Re-flash Device
                  </button>
                  <button className="w-full bg-red-600/20 hover:bg-red-600/30 text-white rounded-xl px-6 py-3 font-medium transition-colors flex items-center justify-center gap-2">
                    <Download className="w-5 h-5" />
                    Restore Original OS
                  </button>
                </div>
              </div>
            </div>
          </motion.section>

          {/* Help & Documentation */}
          <motion.section
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
            className="bg-indigo-950/40 backdrop-blur-xl rounded-3xl border border-indigo-500/20 p-8"
          >
            <h2 className="text-2xl font-bold mb-6">Help & Documentation</h2>
            <div className="grid md:grid-cols-3 gap-6">
              <a
                href="/flash/docs/restore-guide.pdf"
                target="_blank"
                className="bg-black/30 rounded-xl p-6 border border-indigo-500/10 hover:bg-black/40 transition-colors"
              >
                <FileText className="w-6 h-6 text-indigo-400 mb-4" />
                <h3 className="font-semibold mb-2">Restore Guide</h3>
                <p className="text-sm text-gray-400">Step-by-step guide to restore your original OS</p>
              </a>
              <a
                href="/flash/docs/compatibility"
                target="_blank"
                className="bg-black/30 rounded-xl p-6 border border-indigo-500/10 hover:bg-black/40 transition-colors"
              >
                <Smartphone className="w-6 h-6 text-indigo-400 mb-4" />
                <h3 className="font-semibold mb-2">Compatible Devices</h3>
                <p className="text-sm text-gray-400">Full list of supported Android devices</p>
              </a>
              <a
                href="mailto:flash@selflabs.ai"
                className="bg-black/30 rounded-xl p-6 border border-indigo-500/10 hover:bg-black/40 transition-colors"
              >
                <Mail className="w-6 h-6 text-indigo-400 mb-4" />
                <h3 className="font-semibold mb-2">Contact Support</h3>
                <p className="text-sm text-gray-400">Email us at flash@selflabs.ai</p>
              </a>
            </div>
          </motion.section>
        </div>
      </div>
    </div>
  );
} 