import React from 'react';
import { motion } from 'framer-motion';
import { X } from 'lucide-react';
import { DocumentManager } from './ContentManager/DocumentManager';
import { UpdatesManager } from './ContentManager/UpdatesManager';
import { EventsManager } from './ContentManager/EventsManager';
import { MetricsManager } from './ContentManager/MetricsManager';

interface AdminDashboardProps {
  onClose: () => void;
}

export default function AdminDashboard({ onClose }: AdminDashboardProps) {
  return (
    <div className="fixed inset-0 bg-black/90 backdrop-blur-sm z-50 overflow-y-auto">
      <div className="min-h-screen py-12 px-4">
        <div className="max-w-6xl mx-auto">
          <div className="flex justify-between items-center mb-8">
            <h1 className="text-2xl font-bold text-white">Admin Dashboard</h1>
            <motion.button
              onClick={onClose}
              className="text-gray-400 hover:text-white p-2"
              whileHover={{ scale: 1.1 }}
            >
              <X className="w-6 h-6" />
            </motion.button>
          </div>

          <div className="grid gap-8 mb-8">
            <MetricsManager />
            <DocumentManager />
            <UpdatesManager />
            <EventsManager />
          </div>
        </div>
      </div>
    </div>
  );
}