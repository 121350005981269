import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Plus, Trash2, Calendar, AlertCircle } from 'lucide-react';
import { supabase } from '../../../utils/supabase';

export function EventsManager() {
  const [events, setEvents] = useState<any[]>([]);
  const [showForm, setShowForm] = useState(false);
  const [currentEvent, setCurrentEvent] = useState({
    title: '',
    description: '',
    event_date: '',
    event_time: ''
  });
  const [error, setError] = useState('');

  useEffect(() => {
    fetchEvents();
  }, []);

  async function fetchEvents() {
    try {
      const { data, error } = await supabase
        .from('investor_events')
        .select('*')
        .order('event_date', { ascending: true });

      if (error) throw error;
      setEvents(data || []);
    } catch (error) {
      console.error('Error fetching events:', error);
      setError('Failed to load events');
    }
  }

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    try {
      setError('');
      const { error } = await supabase
        .from('investor_events')
        .insert([currentEvent]);

      if (error) throw error;
      setShowForm(false);
      setCurrentEvent({ title: '', description: '', event_date: '', event_time: '' });
      fetchEvents();
    } catch (error) {
      console.error('Error creating event:', error);
      setError('Failed to create event');
    }
  }

  async function handleDelete(id: string) {
    try {
      const { error } = await supabase
        .from('investor_events')
        .delete()
        .eq('id', id);

      if (error) throw error;
      setEvents(events.filter(event => event.id !== id));
    } catch (error) {
      console.error('Error deleting event:', error);
      setError('Failed to delete event');
    }
  }

  return (
    <div className="bg-[#121214] rounded-xl p-6 border border-white/10">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold text-white">Investor Events</h2>
        <motion.button
          onClick={() => setShowForm(true)}
          className="flex items-center gap-2 bg-[#FF3366] text-white px-4 py-2 rounded-lg"
          whileHover={{ scale: 1.05 }}
        >
          <Plus className="w-4 h-4" />
          New Event
        </motion.button>
      </div>

      {error && (
        <div className="flex items-center gap-2 p-4 mb-6 bg-red-500/10 border border-red-500/20 rounded-lg text-red-400">
          <AlertCircle className="w-5 h-5" />
          <p>{error}</p>
        </div>
      )}

      {showForm && (
        <form onSubmit={handleSubmit} className="mb-8 space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Title
            </label>
            <input
              type="text"
              value={currentEvent.title}
              onChange={(e) => setCurrentEvent({ ...currentEvent, title: e.target.value })}
              className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-3 text-white"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Description
            </label>
            <textarea
              value={currentEvent.description}
              onChange={(e) => setCurrentEvent({ ...currentEvent, description: e.target.value })}
              className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-3 text-white h-32"
              required
            />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Date
              </label>
              <input
                type="date"
                value={currentEvent.event_date}
                onChange={(e) => setCurrentEvent({ ...currentEvent, event_date: e.target.value })}
                className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-3 text-white"
                required
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Time
              </label>
              <input
                type="time"
                value={currentEvent.event_time}
                onChange={(e) => setCurrentEvent({ ...currentEvent, event_time: e.target.value })}
                className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-3 text-white"
                required
              />
            </div>
          </div>
          <div className="flex justify-end gap-4">
            <button
              type="button"
              onClick={() => setShowForm(false)}
              className="px-4 py-2 text-gray-400 hover:text-white"
            >
              Cancel
            </button>
            <motion.button
              type="submit"
              className="bg-[#FF3366] text-white px-6 py-2 rounded-lg"
              whileHover={{ scale: 1.05 }}
            >
              Save Event
            </motion.button>
          </div>
        </form>
      )}

      <div className="space-y-4">
        {events.map((event) => (
          <motion.div
            key={event.id}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            className="p-4 bg-black/20 rounded-lg"
          >
            <div className="flex justify-between items-start mb-2">
              <div>
                <h3 className="text-white font-medium">{event.title}</h3>
                <div className="flex items-center gap-2 text-gray-400 text-sm mt-1">
                  <Calendar className="w-4 h-4" />
                  <span>
                    {new Date(event.event_date).toLocaleDateString()} at {event.event_time}
                  </span>
                </div>
              </div>
              <motion.button
                onClick={() => handleDelete(event.id)}
                className="text-gray-400 hover:text-red-400 p-1"
                whileHover={{ scale: 1.1 }}
              >
                <Trash2 className="w-4 h-4" />
              </motion.button>
            </div>
            <p className="text-gray-400 text-sm mt-2">{event.description}</p>
          </motion.div>
        ))}
      </div>
    </div>
  );
}