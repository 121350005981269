import React from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import { LoadingQuote } from './LoadingQuote';
import { BeakerAnimation } from './BeakerAnimation';
import { useLoading } from '../../hooks/useLoading';

export function LoadingScreen() {
  const { isLoading } = useLoading();

  return (
    <AnimatePresence>
      {isLoading && (
        <motion.div
          initial={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-black z-50 flex items-center justify-center"
        >
          <motion.div 
            className="text-center"
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            transition={{ duration: 0.3 }}
          >
            <BeakerAnimation />
            <div className="mt-12">
              <LoadingQuote />
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}