import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Plus, Trash2, Edit2, AlertCircle } from 'lucide-react';
import { supabase } from '../../../utils/supabase';

export function UpdatesManager() {
  const [updates, setUpdates] = useState<any[]>([]);
  const [showForm, setShowForm] = useState(false);
  const [currentUpdate, setCurrentUpdate] = useState({ title: '', content: '' });
  const [error, setError] = useState('');

  useEffect(() => {
    fetchUpdates();
  }, []);

  async function fetchUpdates() {
    try {
      const { data, error } = await supabase
        .from('company_updates')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setUpdates(data || []);
    } catch (error) {
      console.error('Error fetching updates:', error);
      setError('Failed to load updates');
    }
  }

  async function handleSubmit(e: React.FormEvent) {
    e.preventDefault();
    try {
      setError('');
      const { error } = await supabase
        .from('company_updates')
        .insert([currentUpdate]);

      if (error) throw error;
      setShowForm(false);
      setCurrentUpdate({ title: '', content: '' });
      fetchUpdates();
    } catch (error) {
      console.error('Error creating update:', error);
      setError('Failed to create update');
    }
  }

  async function handleDelete(id: string) {
    try {
      const { error } = await supabase
        .from('company_updates')
        .delete()
        .eq('id', id);

      if (error) throw error;
      setUpdates(updates.filter(update => update.id !== id));
    } catch (error) {
      console.error('Error deleting update:', error);
      setError('Failed to delete update');
    }
  }

  return (
    <div className="bg-[#121214] rounded-xl p-6 border border-white/10">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold text-white">Company Updates</h2>
        <motion.button
          onClick={() => setShowForm(true)}
          className="flex items-center gap-2 bg-[#FF3366] text-white px-4 py-2 rounded-lg"
          whileHover={{ scale: 1.05 }}
        >
          <Plus className="w-4 h-4" />
          New Update
        </motion.button>
      </div>

      {error && (
        <div className="flex items-center gap-2 p-4 mb-6 bg-red-500/10 border border-red-500/20 rounded-lg text-red-400">
          <AlertCircle className="w-5 h-5" />
          <p>{error}</p>
        </div>
      )}

      {showForm && (
        <form onSubmit={handleSubmit} className="mb-8 space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Title
            </label>
            <input
              type="text"
              value={currentUpdate.title}
              onChange={(e) => setCurrentUpdate({ ...currentUpdate, title: e.target.value })}
              className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-3 text-white"
              required
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Content
            </label>
            <textarea
              value={currentUpdate.content}
              onChange={(e) => setCurrentUpdate({ ...currentUpdate, content: e.target.value })}
              className="w-full bg-black/50 border border-white/10 rounded-lg px-4 py-3 text-white h-32"
              required
            />
          </div>
          <div className="flex justify-end gap-4">
            <button
              type="button"
              onClick={() => setShowForm(false)}
              className="px-4 py-2 text-gray-400 hover:text-white"
            >
              Cancel
            </button>
            <motion.button
              type="submit"
              className="bg-[#FF3366] text-white px-6 py-2 rounded-lg"
              whileHover={{ scale: 1.05 }}
            >
              Save Update
            </motion.button>
          </div>
        </form>
      )}

      <div className="space-y-4">
        {updates.map((update) => (
          <motion.div
            key={update.id}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            className="p-4 bg-black/20 rounded-lg"
          >
            <div className="flex justify-between items-start mb-2">
              <h3 className="text-white font-medium">{update.title}</h3>
              <div className="flex gap-2">
                <motion.button
                  onClick={() => handleDelete(update.id)}
                  className="text-gray-400 hover:text-red-400 p-1"
                  whileHover={{ scale: 1.1 }}
                >
                  <Trash2 className="w-4 h-4" />
                </motion.button>
              </div>
            </div>
            <p className="text-gray-400 text-sm">{update.content}</p>
            <p className="text-gray-500 text-xs mt-2">
              {new Date(update.created_at).toLocaleDateString()}
            </p>
          </motion.div>
        ))}
      </div>
    </div>
  );
}