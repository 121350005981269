import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { ArrowLeft, ArrowRight, Smartphone, Music, Video, Activity, Mic, Phone, ShoppingBag, Tv, Heart, Search, Bot } from 'lucide-react';

export default function WhatIsThisPage() {
  const [hoveredActivity, setHoveredActivity] = useState<number | null>(null);

  const dailyActivities = [
    { icon: <Smartphone />, text: "Commute using Google Maps or Uber", color: "from-blue-500 to-cyan-500" },
    { icon: <Music />, text: "Listen to playlists on Spotify or Apple Music", color: "from-green-500 to-emerald-500" },
    { icon: <Video />, text: "Scroll TikTok, Instagram, or YouTube Shorts", color: "from-purple-500 to-pink-500" },
    { icon: <Activity />, text: "Track steps on your phone or smartwatch", color: "from-red-500 to-orange-500" },
    { icon: <Mic />, text: "Talk to Siri or Alexa", color: "from-indigo-500 to-purple-500" },
    { icon: <Phone />, text: "Join Zoom calls or leave voice notes", color: "from-blue-500 to-indigo-500" },
    { icon: <ShoppingBag />, text: "Order food from DoorDash or Instacart", color: "from-emerald-500 to-teal-500" },
    { icon: <Tv />, text: "Binge shows on Netflix or Hulu", color: "from-rose-500 to-pink-500" },
    { icon: <Heart />, text: "Meditate with Headspace or Calm", color: "from-purple-500 to-violet-500" },
    { icon: <Search />, text: "Google something random", color: "from-cyan-500 to-blue-500" },
    { icon: <Bot />, text: "Ask ChatGPT to help you out...", color: "from-violet-500 to-indigo-500" }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <div className="min-h-screen bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-indigo-900/20 via-black to-black text-white py-20 relative overflow-hidden">
      {/* Animated Background Gradient */}
      <div className="absolute inset-0 bg-gradient-to-b from-indigo-500/5 to-purple-500/5 animate-pulse" />
      
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        {/* Back Button */}
        <Link to="/flash/signal-calculator">
          <motion.div
            className="inline-flex items-center text-indigo-400 mb-12 hover:text-indigo-300 transition-colors bg-black/20 px-4 py-2 rounded-full backdrop-blur-sm border border-indigo-500/20"
            whileHover={{ x: -5 }}
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            <span>Back to Signal Calculator</span>
          </motion.div>
        </Link>

        {/* Main Content */}
        <motion.div
          initial="hidden"
          animate="visible"
          variants={containerVariants}
          className="space-y-12"
        >
          {/* Header */}
          <motion.div variants={itemVariants} className="text-center">
            <h1 className="text-5xl font-bold mb-6 bg-gradient-to-r from-indigo-400 via-purple-400 to-indigo-400 text-transparent bg-clip-text">
              🧠 What Is This?
            </h1>
            <p className="text-2xl text-gray-300 leading-relaxed">
              Every day, you generate something more valuable than money:
            </p>
            <motion.div 
              className="mt-4 text-3xl font-semibold bg-gradient-to-r from-blue-400 via-purple-400 to-pink-400 text-transparent bg-clip-text"
              animate={{ opacity: [0.5, 1, 0.5] }}
              transition={{ duration: 3, repeat: Infinity }}
            >
              Your presence. Your focus. Your movement. Your signal.
            </motion.div>
          </motion.div>

          {/* Daily Activities Grid */}
          <motion.div variants={itemVariants} className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {dailyActivities.map((activity, index) => (
              <motion.div
                key={index}
                className={`relative group bg-black/40 backdrop-blur-xl rounded-2xl p-6 border border-indigo-500/20 overflow-hidden transition-all duration-300
                  ${hoveredActivity === index ? 'border-indigo-400/50 shadow-lg shadow-indigo-500/20' : 'border-indigo-500/20'}
                `}
                onMouseEnter={() => setHoveredActivity(index)}
                onMouseLeave={() => setHoveredActivity(null)}
                whileHover={{ scale: 1.02, y: -5 }}
              >
                <div className={`absolute inset-0 bg-gradient-to-r ${activity.color} opacity-0 group-hover:opacity-10 transition-opacity duration-500`} />
                <div className="flex items-center space-x-4">
                  <motion.div 
                    className={`p-3 rounded-xl bg-gradient-to-r ${activity.color} bg-opacity-20`}
                    whileHover={{ rotate: [0, -10, 10, 0] }}
                  >
                    {activity.icon}
                  </motion.div>
                  <span className="text-gray-300 group-hover:text-white transition-colors">{activity.text}</span>
                </div>
              </motion.div>
            ))}
          </motion.div>

          {/* Value Proposition */}
          <motion.div variants={itemVariants} className="space-y-8">
            <div className="bg-black/40 backdrop-blur-xl rounded-2xl p-8 border border-indigo-500/20">
              <motion.p 
                className="text-xl text-gray-300 leading-relaxed"
                animate={{ opacity: [0.7, 1, 0.7] }}
                transition={{ duration: 4, repeat: Infinity }}
              >
                That signal trains AI systems.<br />
                It powers billion-dollar platforms.<br />
                And you've never seen a penny of it.
              </motion.p>
            </div>

            <div className="bg-gradient-to-r from-indigo-500/10 to-purple-500/10 rounded-2xl p-8 border border-indigo-500/20 text-center">
              <motion.h2 
                className="text-3xl font-bold mb-6 bg-gradient-to-r from-indigo-400 to-purple-400 text-transparent bg-clip-text"
                whileHover={{ scale: 1.05 }}
              >
                SELF Labs flips the system.
              </motion.h2>
              <p className="text-xl text-gray-300 mb-8">
                We created Essence — the first currency you earn simply by being alive.<br />
                And you can spend it on the things you already use every day:
              </p>
              <motion.div 
                className="text-2xl font-semibold bg-gradient-to-r from-blue-400 via-purple-400 to-pink-400 text-transparent bg-clip-text"
                animate={{ opacity: [0.7, 1, 0.7] }}
                transition={{ duration: 3, repeat: Infinity }}
              >
                Music. Rides. Food. Your phone bill. Subscriptions. Wellness.
              </motion.div>
            </div>

            <div className="bg-black/40 backdrop-blur-xl rounded-2xl p-8 border border-indigo-500/20 text-center">
              <motion.p 
                className="text-2xl font-semibold bg-gradient-to-r from-indigo-400 via-purple-400 to-indigo-400 text-transparent bg-clip-text"
                animate={{ opacity: [0.7, 1, 0.7] }}
                transition={{ duration: 4, repeat: Infinity }}
              >
                You're not the product anymore.<br />
                You're the producer.<br />
                And now, you get paid.
              </motion.p>
            </div>
          </motion.div>

          {/* Navigation */}
          <motion.div variants={itemVariants} className="flex justify-center mt-12">
            <Link to="/flash/signal-calculator/why-data-can-replace-money">
              <motion.button
                className="inline-flex items-center bg-gradient-to-r from-indigo-600 to-purple-600 text-white px-10 py-5 rounded-2xl text-lg font-semibold shadow-xl shadow-indigo-500/30 hover:shadow-indigo-500/50 transition-all duration-300 backdrop-blur-xl relative overflow-hidden group"
                whileHover={{ scale: 1.05, y: -2 }}
                whileTap={{ scale: 0.95 }}
              >
                {/* Button Shine Effect */}
                <div className="absolute inset-0 w-1/4 h-full bg-white/20 skew-x-[25deg] -translate-x-full group-hover:translate-x-[400%] transition-transform duration-1000" />
                
                <span className="relative z-10">Next: Why Data Can Replace Money</span>
                <motion.div
                  className="relative z-10 ml-3"
                  animate={{ x: [0, 5, 0] }}
                  transition={{ repeat: Infinity, duration: 1.5 }}
                >
                  <ArrowRight className="w-5 h-5" />
                </motion.div>
              </motion.button>
            </Link>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
} 