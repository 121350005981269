import React from 'react';
import { motion } from 'framer-motion';

const stats = [
  { label: 'Neural Resonance Efficiency', value: '98%' },
  { label: 'Signal Recognition Depth', value: '24/7' },
  { label: 'Consciousness Alignment Level', value: '85%' },
];

export function AboutStats() {
  return (
    <div className="grid grid-cols-3 gap-8 mb-20">
      {stats.map((stat, index) => (
        <motion.div
          key={index}
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: index * 0.2 }}
          className="text-center"
        >
          <motion.div
            whileHover={{ scale: 1.1 }}
            className="text-4xl font-bold text-[#8A2BE2] mb-2"
          >
            {stat.value}
          </motion.div>
          <div className="text-gray-400">{stat.label}</div>
        </motion.div>
      ))}
    </div>
  );
}