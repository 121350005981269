import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { motion, useAnimation } from 'framer-motion';
import { 
  ArrowLeft, ArrowRight, MapPin, Mic, Globe, Users, Heart, 
  Sparkles, Brain, MessageSquare, Calendar, Camera, ShoppingBag,
  Gamepad, Book, Music
} from 'lucide-react';

export default function WhyDataCanReplaceMoneyPage() {
  const [hoveredCard, setHoveredCard] = useState<number | null>(null);
  const controls = useAnimation();

  const dataValues = [
    {
      icon: <MapPin className="w-6 h-6" />,
      title: "Location Data",
      value: "$100+",
      period: "per user",
      color: "from-blue-500 to-cyan-500",
      description: "Your movement patterns power maps, local recommendations, and transportation optimization",
      details: ["Powers navigation apps", "Improves local search", "Optimizes delivery routes", "Traffic prediction"]
    },
    {
      icon: <Mic className="w-6 h-6" />,
      title: "Voice Recordings",
      value: "$50+",
      period: "per hour",
      color: "from-purple-500 to-pink-500",
      description: "Your voice helps train speech recognition and language understanding models",
      details: ["Speech recognition", "Voice assistants", "Language translation", "Accent adaptation"]
    },
    {
      icon: <Globe className="w-6 h-6" />,
      title: "Browsing History",
      value: "$30+",
      period: "per month",
      color: "from-green-500 to-emerald-500",
      description: "Your online behavior shapes content recommendations and search results",
      details: ["Search optimization", "Content recommendations", "Ad targeting", "UX improvement"]
    },
    {
      icon: <Users className="w-6 h-6" />,
      title: "Social Interactions",
      value: "$20+",
      period: "per connection",
      color: "from-orange-500 to-red-500",
      description: "Your network helps improve friend suggestions and content discovery",
      details: ["Friend recommendations", "Content sharing patterns", "Group dynamics", "Influence mapping"]
    },
    {
      icon: <Heart className="w-6 h-6" />,
      title: "Health Metrics",
      value: "$200+",
      period: "per year",
      color: "from-rose-500 to-pink-500",
      description: "Your wellness data advances medical research and health monitoring",
      details: ["Health predictions", "Treatment optimization", "Wellness recommendations", "Research insights"]
    },
    {
      icon: <MessageSquare className="w-6 h-6" />,
      title: "Chat & Messages",
      value: "$40+",
      period: "per month",
      color: "from-violet-500 to-purple-500",
      description: "Your communication patterns improve language models and chatbots",
      details: ["Chatbot training", "Language processing", "Sentiment analysis", "Response prediction"]
    },
    {
      icon: <Calendar className="w-6 h-6" />,
      title: "Schedule Data",
      value: "$25+",
      period: "per month",
      color: "from-teal-500 to-green-500",
      description: "Your calendar habits enhance scheduling and productivity tools",
      details: ["Meeting optimization", "Time management", "Productivity patterns", "Event recommendations"]
    },
    {
      icon: <Camera className="w-6 h-6" />,
      title: "Visual Data",
      value: "$75+",
      period: "per month",
      color: "from-blue-500 to-indigo-500",
      description: "Your photos and videos train image recognition and AR models",
      details: ["Image recognition", "AR features", "Visual search", "Style transfer"]
    },
    {
      icon: <ShoppingBag className="w-6 h-6" />,
      title: "Purchase History",
      value: "$45+",
      period: "per month",
      color: "from-amber-500 to-orange-500",
      description: "Your shopping patterns power recommendation engines",
      details: ["Product recommendations", "Price optimization", "Inventory prediction", "Trend analysis"]
    }
  ];

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 }
  };

  return (
    <div className="min-h-screen bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-indigo-900/20 via-black to-black text-white py-20 relative overflow-hidden">
      {/* Animated Background Elements */}
      <div className="absolute inset-0">
        <div className="absolute top-0 left-1/2 -translate-x-1/2 w-3/4 h-1/2 bg-indigo-500/10 blur-[120px] rounded-full animate-pulse" />
        <div className="absolute bottom-0 left-1/2 -translate-x-1/2 w-3/4 h-1/2 bg-purple-500/10 blur-[120px] rounded-full animate-pulse" />
        {Array.from({ length: 20 }).map((_, i) => (
          <motion.div
            key={i}
            className="absolute bg-indigo-500/20 rounded-full"
            style={{
              width: Math.random() * 4 + 2,
              height: Math.random() * 4 + 2,
              left: `${Math.random() * 100}%`,
              top: `${Math.random() * 100}%`,
            }}
            animate={{
              y: [0, -20, 0],
              opacity: [0.5, 1, 0.5],
            }}
            transition={{
              duration: Math.random() * 2 + 3,
              repeat: Infinity,
              ease: "easeInOut",
              delay: Math.random() * 2,
            }}
          />
        ))}
      </div>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 relative">
        {/* Back Button */}
        <Link to="/flash/signal-calculator">
          <motion.div
            className="inline-flex items-center text-indigo-400 mb-16 hover:text-indigo-300 transition-colors bg-black/20 px-4 py-2 rounded-full backdrop-blur-sm border border-indigo-500/20"
            whileHover={{ x: -5 }}
          >
            <ArrowLeft className="w-4 h-4 mr-2" />
            <span>Back to Signal Calculator</span>
          </motion.div>
        </Link>

        {/* Main Content */}
        <motion.div
          initial="hidden"
          animate="visible"
          variants={containerVariants}
          className="space-y-16"
        >
          {/* Header */}
          <motion.div variants={itemVariants} className="text-center">
            <motion.div 
              className="inline-flex items-center justify-center w-20 h-20 rounded-3xl bg-gradient-to-br from-indigo-400 to-purple-500 p-4 mb-8"
              animate={{ rotate: [0, 10, -10, 0] }}
              transition={{ duration: 5, repeat: Infinity }}
            >
              <Brain className="w-12 h-12 text-white" />
            </motion.div>
            <h1 className="text-5xl font-bold mb-6 bg-gradient-to-r from-indigo-400 via-purple-400 to-indigo-400 text-transparent bg-clip-text">
              Data: The Missing Asset Class
            </h1>
            <p className="text-2xl text-gray-300 max-w-3xl mx-auto">
              In the AI era, your daily digital footprint is becoming as valuable as traditional assets. Here's what makes your data a premium asset:
            </p>
          </motion.div>

          {/* Data Value Cards */}
          <motion.div variants={itemVariants} className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {dataValues.map((item, index) => (
              <motion.div
                key={index}
                className={`group relative bg-black/40 backdrop-blur-xl rounded-3xl p-8 border border-indigo-500/20 overflow-hidden transition-all duration-300
                  ${hoveredCard === index ? 'border-indigo-400/50 shadow-lg shadow-indigo-500/20 scale-105' : 'border-indigo-500/20'}
                `}
                onMouseEnter={() => setHoveredCard(index)}
                onMouseLeave={() => setHoveredCard(null)}
                whileHover={{ y: -5 }}
              >
                {/* Gradient Background */}
                <div className={`absolute inset-0 bg-gradient-to-br ${item.color} opacity-0 group-hover:opacity-10 transition-opacity duration-500`} />
                
                {/* Content */}
                <div className="relative z-10">
                  <motion.div 
                    className={`inline-flex p-3 rounded-2xl bg-gradient-to-br ${item.color} bg-opacity-20 mb-6`}
                    whileHover={{ rotate: [0, -10, 10, 0] }}
                    transition={{ duration: 0.5 }}
                  >
                    {item.icon}
                  </motion.div>
                  
                  <h3 className="text-xl font-semibold mb-2 text-white">
                    {item.title}
                  </h3>
                  
                  <div className="flex items-baseline mb-4">
                    <span className="text-3xl font-bold bg-gradient-to-r from-white to-gray-300 text-transparent bg-clip-text">
                      {item.value}
                    </span>
                    <span className="ml-2 text-gray-400">
                      {item.period}
                    </span>
                  </div>
                  
                  <p className="text-gray-400 group-hover:text-gray-300 transition-colors mb-4">
                    {item.description}
                  </p>

                  <div className="grid grid-cols-2 gap-2">
                    {item.details.map((detail, i) => (
                      <div 
                        key={i}
                        className="text-sm text-gray-500 bg-black/20 rounded-lg px-2 py-1 text-center"
                      >
                        {detail}
                      </div>
                    ))}
                  </div>
                </div>
              </motion.div>
            ))}
          </motion.div>

          {/* Value Proposition */}
          <motion.div variants={itemVariants} className="space-y-8">
            <div className="bg-gradient-to-r from-indigo-500/10 to-purple-500/10 rounded-3xl p-10 border border-indigo-500/20 text-center backdrop-blur-xl">
              <motion.div 
                className="inline-flex items-center justify-center w-16 h-16 rounded-2xl bg-gradient-to-br from-indigo-500 to-purple-500 p-4 mb-6"
                whileHover={{ rotate: 360 }}
                transition={{ duration: 0.5 }}
              >
                <Sparkles className="w-8 h-8 text-white" />
              </motion.div>
              
              <motion.h2 
                className="text-3xl font-bold mb-6 bg-gradient-to-r from-indigo-400 to-purple-400 text-transparent bg-clip-text"
                whileHover={{ scale: 1.05 }}
              >
                That's where SELF Labs comes in.
              </motion.h2>
              
              <div className="space-y-4 text-xl text-gray-300">
                <p>We help you harness the value of your digital footprint as a new asset class.</p>
                <p>Convert your daily activities into valuable signal and get compensated fairly.</p>
              </div>
            </div>

            <motion.div 
              className="bg-black/40 backdrop-blur-xl rounded-3xl p-10 border border-indigo-500/20 text-center"
              whileHover={{ scale: 1.02 }}
            >
              <p className="text-2xl text-gray-300">
                Your data is a valuable asset that works alongside traditional currency.
                <span className="block mt-4 text-xl font-semibold bg-gradient-to-r from-indigo-400 via-purple-400 to-indigo-400 text-transparent bg-clip-text">
                  Start treating your digital footprint as the valuable asset it is.
                </span>
              </p>
            </motion.div>
          </motion.div>

          {/* Navigation */}
          <motion.div variants={itemVariants} className="flex justify-center mt-12">
            <Link to="/flash/signal-calculator/why-kilobyte-priceless">
              <motion.button
                className="inline-flex items-center bg-gradient-to-r from-indigo-600 to-purple-600 text-white px-10 py-5 rounded-2xl text-lg font-semibold shadow-xl shadow-indigo-500/30 hover:shadow-indigo-500/50 transition-all duration-300 backdrop-blur-xl relative overflow-hidden group"
                whileHover={{ scale: 1.05, y: -2 }}
                whileTap={{ scale: 0.95 }}
              >
                {/* Button Shine Effect */}
                <div className="absolute inset-0 w-1/4 h-full bg-white/20 skew-x-[25deg] -translate-x-full group-hover:translate-x-[400%] transition-transform duration-1000" />
                
                <span className="relative z-10">Next: Why a Kilobyte Can Be Priceless</span>
                <motion.div
                  className="relative z-10 ml-3"
                  animate={{ x: [0, 5, 0] }}
                  transition={{ repeat: Infinity, duration: 1.5 }}
                >
                  <ArrowRight className="w-5 h-5" />
                </motion.div>
              </motion.button>
            </Link>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
} 