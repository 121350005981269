import React from 'react';
import { motion } from 'framer-motion';
import { LogOut, Bell, Shield, Wallet } from 'lucide-react';

interface DashboardHeaderProps {
  isAdmin?: boolean;
  onAdminClick?: () => void;
  walletAddress?: string | null;
}

export function DashboardHeader({ isAdmin = false, onAdminClick, walletAddress }: DashboardHeaderProps) {
  // Format wallet address for display
  const formatWalletAddress = (address: string | null | undefined): string => {
    if (!address || typeof address !== 'string') {
      return 'Unknown Address';
    }
    return `${address.substring(0, 6)}...${address.substring(address.length - 4)}`;
  };

  return (
    <header className="bg-[#0A0A0B] border-b border-white/10">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-16">
          <h1 className="text-xl font-semibold text-white">Governance Dashboard</h1>
          
          <div className="flex items-center space-x-4">
            {walletAddress && typeof walletAddress === 'string' && (
              <div className="hidden md:flex items-center bg-black/30 px-3 py-1.5 rounded-full border border-white/10">
                <Wallet className="w-4 h-4 text-[#00FFFF] mr-2" />
                <span className="text-gray-400 text-xs font-mono">
                  {formatWalletAddress(walletAddress)}
                </span>
              </div>
            )}
            
            {isAdmin && (
              <motion.button
                onClick={onAdminClick}
                whileHover={{ scale: 1.05 }}
                className="flex items-center space-x-2 text-[#FF3366] hover:text-white"
              >
                <Shield className="w-5 h-5" />
                <span>Admin</span>
              </motion.button>
            )}

            <motion.button
              whileHover={{ scale: 1.05 }}
              className="p-2 text-gray-400 hover:text-white"
            >
              <Bell className="w-5 h-5" />
            </motion.button>
            
            <motion.button
              whileHover={{ scale: 1.05 }}
              className="flex items-center space-x-2 text-[#00FFFF] hover:text-white"
            >
              <LogOut className="w-5 h-5" />
              <span>Sign Out</span>
            </motion.button>
          </div>
        </div>
      </div>
    </header>
  );
}