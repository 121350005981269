import { useState } from 'react';
import { Dialog } from '@headlessui/react';
import { motion } from 'framer-motion';
import { CreditCard, Loader2 } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useStripe } from '../../contexts/StripeContext';

interface StripeCheckoutModalProps {
  isOpen: boolean;
  onClose: () => void;
  isFlashKit?: boolean;
}

export default function StripeCheckoutModal({ isOpen, onClose, isFlashKit = false }: StripeCheckoutModalProps) {
  const navigate = useNavigate();
  const { openCheckout } = useStripe();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleCheckout = async () => {
    try {
      setIsLoading(true);
      await openCheckout('phone');
    } catch (error) {
      console.error('Error:', error);
      setError(error instanceof Error ? error.message : 'An error occurred');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className="relative z-50"
    >
      <div className="fixed inset-0 bg-black/80 backdrop-blur-sm" aria-hidden="true" />

      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel className="w-full max-w-lg rounded-3xl bg-indigo-950/50 backdrop-blur-xl border border-indigo-500/20 p-8">
          <Dialog.Title className="text-2xl font-bold text-white mb-6">
            Complete Your {isFlashKit ? 'Flash Kit' : 'Pre-order'}
          </Dialog.Title>

          <div className="bg-indigo-900/30 rounded-2xl p-6 mb-8 border border-indigo-500/10">
            <h3 className="text-lg font-semibold text-indigo-300 mb-2">Order Summary</h3>
            <div className="flex justify-between items-center text-white mb-2">
              <span>{isFlashKit ? 'Complete Flash Kit' : 'SELF Phone Founder\'s Edition'}</span>
              <span>${isFlashKit ? '149' : '299'}</span>
            </div>
            <div className="text-sm text-gray-400">
              {isFlashKit 
                ? 'Includes AI agent, Aura Shield, Role Model AI, and full system integration.'
                : 'Includes lifetime satellite connectivity, Role Model AI, and early access.'
              }
            </div>
          </div>

          {error && (
            <div className="mb-4 p-4 bg-red-500/10 border border-red-500/20 rounded-xl text-red-400 text-sm">
              {error}
            </div>
          )}

          <div className="space-y-4">
            <button
              onClick={handleCheckout}
              disabled={isLoading}
              className="w-full flex items-center justify-center gap-2 bg-indigo-600 hover:bg-indigo-500 disabled:bg-indigo-800 text-white rounded-xl px-6 py-3 font-medium transition-colors"
            >
              {isLoading ? (
                <>
                  <Loader2 className="w-5 h-5 animate-spin" />
                  Processing...
                </>
              ) : (
                <>
                  <CreditCard className="w-5 h-5" />
                  Pay with Card
                </>
              )}
            </button>
          </div>

          <div className="mt-6 text-center">
            <p className="text-sm text-gray-400">
              Secure payment powered by Stripe. Your data is encrypted.
            </p>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
} 